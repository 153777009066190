<!-- LoginStatistics -->
<template>
  <section class="LoginStatistics">
    <page-top :title="$t('LoginStatistics.LoginStatistics_tit')">
      <span
        style="font-size: 26px; padding-bottom: 10px"
        class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-tabs v-model="tabValue" type="border-card">
      <el-tab-pane
        :label="$t('AverageOrderPrice.AverageOrderPrice_content.restaurant')"
        name="restaurant"
      >
        <PagesComponents
          :type="2"
          v-if="tabValue == 'restaurant'"
        ></PagesComponents>
      </el-tab-pane>
      <el-tab-pane
        :label="$t('AverageOrderPrice.AverageOrderPrice_content.supplier')"
        name="supplier"
      >
        <PagesComponents
          :type="1"
          v-if="tabValue == 'supplier'"
        ></PagesComponents>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "@/components/PageTop/PageTop";
import PagesComponents from "./PagesComponents/PagesComponents";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "LoginStatistics",
  components: { pageTop, PagesComponents },
  data() {
    //这里存放数据
    return {
      tabValue: "restaurant",
      restaurant_id: "",
      datetime: "",
      tableData: [],
      restaurantList: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeDatetime(val) {
      this.datetime = val || "";
      this.getKeywordFrequency();
    },
    // 获取餐厅选择器
    getRestaurantList() {
      this.$http.restaurantShopSelector().then((res) => {
        this.restaurantList = res.data;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getKeywordFrequency();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getKeywordFrequency();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRestaurantList();
  },
};
</script>
<style src='./LoginStatistics.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>