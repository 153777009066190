<template>
  <div class="favorite-container" id="CustomerAuthGroup">
    <page-top :title="$t('backend_submenu.C89D43@custom_restaurant_auth_group')">
      <span style="font-size: 26px" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-yellow"
            :perm="16073286282103"
            @click="add">
        </KtButton>
      </section>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          style="width: 100%; margin-top: 20px"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
        <el-table-column
            prop="group_id"
            :label="$t('supplier_power_grouping.C9B87D@grouping_id')"
        ></el-table-column>
        <el-table-column :label="$t('aboutus.06951D@Group_Name')" prop="name_hk" v-if="lang">
        </el-table-column>
        <el-table-column :label="$t('aboutus.06951D@Group_Name')" prop="name_en" v-else>
        </el-table-column>
        <el-table-column
            prop="create_time"
            :label="$t('public.D17BCA@created_time')"
            show-overflow-tooltip
            :formatter="dateTimeFormat"
        ></el-table-column>
        <el-table-column
            prop="modify_time"
            :label="$t('public.E1A718@update_time')"
            show-overflow-tooltip
            :formatter="dateTimeFormat"
        ></el-table-column>
        <el-table-column
            :label="$t('public.03EA09@operation')">
          <template slot-scope="scope">
            <KtButton
                type="info"
                :label="$t('public.B6D57F@btn_modify')"
                plain
                :perm="16073287443375"
                @click="updateGroup(scope.row)">
            </KtButton>
            <KtButton
                type="primary"
                label="快速套用權限"
                plain
                :perm="16074118604211"
                @click="quicklyApplyPermission(scope.row)">
            </KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                plain
                :perm="16074119556640"
                @click="deleteGroup(scope.row)">
            </KtButton>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[50,100,250,500]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <el-dialog :title="addModify==0 ? $t('public.DC9752@btn_add') : $t('public.549A91@edit')" width="50%"
               :visible.sync="dialogVisible" top="8vh">
      <el-form :model="dialogForm" ref="dialogForm" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="18" :offset="3">
            <!--     群組名稱(中文)       -->
            <el-form-item prop="name_hk" :label="$t('public.98E123@group_name_hk')">
              <el-input v-model="dialogForm.name_hk"></el-input>
            </el-form-item>
            <!--     群組名稱(英文)       -->
            <el-form-item prop="name_en" :label="$t('public.A5E465@group_name_en')">
              <el-input v-model="dialogForm.name_en"></el-input>
            </el-form-item>
            <el-form-item label="">
              <!--        群組清單    -->
              <section v-for="parent in groupNameList" :key="parent.id" class="module-container">
                <el-checkbox-group v-model="selectList">
                  <div class="module-title">
                    <section>
                      <!--                    主要群組-->
                      <el-checkbox :value="parent.id" :label="parent.id" @change="parentChange($event, parent)">
                        {{ parent.name }}
                      </el-checkbox>
                    </section>
                  </div>
                  <!--             次群組   -->
                  <div class="menu-container" v-for="menu in parent.children" :key="menu.id">
                  <span class="menu-title">
                    <el-checkbox :value="menu.id" :label="menu.id" @change="childChange($event, menu)">
                      {{ menu.name }}
                    </el-checkbox>
                  </span>
                    <div style="display: none">
                    <span class="btn-name" v-for="btn in menu.children" :key="btn.id">
                      <el-checkbox :value="btn.id" :label="btn.id">
                        {{ btn.name_hk }}
                      </el-checkbox>
                    </span>
                    </div>
                  </div>
                </el-checkbox-group>
              </section>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" type="primary" center=true @click="addOrModify">
          {{ addModify == 0 ? $t('public.DC9752@btn_add') : $t('public.A738BA@update') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 快速套用表單   -->
    <el-dialog width="50%" :visible.sync="quicklyDialogVisible" top="8vh">
      <el-form :model="quicklyDialogForm" ref="quicklyDialogForm" :rules="quickRules" label-width="150px">
        <el-scrollbar style="height: 100%;">
          <el-row>
            <el-col :span="18" :offset="3" style="padding-bottom: 10px">
              <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"></el-input>
            </el-col>
            <el-col :span="18" :offset="3">
              <section class="restaurant-container" v-for="restaurant in search"
                       :key="restaurant.cid">
                <el-checkbox-group v-model="quicklyDialogForm.selectRestaurant">
                  <el-checkbox :label="restaurant.cid" :key="restaurant.cid" :value="restaurant.cid">
                    {{ restaurant.company_name }}
                  </el-checkbox>
                </el-checkbox-group>
              </section>
            </el-col>
          </el-row>
        </el-scrollbar>
      </el-form>
      <span slot="footer">
        <el-button size="small" type="primary" center=true @click="applyAuthClick">
          {{ $t('public.56E9D8@apply') }}
        </el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import pageTop from "components/PageTop/PageTop"
import KtButton from "components/ktButton/KtButton"
import api from "@/api"

export default {
  name: "",
  computed: {
    search() {
      if (this.searchText == "") {
        return this.restaurantList
      } else {
        return this.restaurantList.filter(i => {
          return i.company_name.indexOf(this.searchText) > -1
        })
      }
    }
  },
  data() {
    return {
      //搜尋文字
      searchText: "",
      //餐廳清單
      restaurantList: [],
      //當前語言
      lang: this.$i18n.locale == 'hk',
      //群組清單名稱
      groupNameList: [],
      //選取權限的清單
      selectList: [],
      //btn_id_json
      btnSelectList: [],
      //restaurant
      project: "restaurant",
      //status
      addModify: "",
      //數據
      tableData: [],
      //分頁顯示數量
      pageSize: 50,
      //當前分頁
      currentPage: 1,
      //總數量
      total: 0,
      //群組id
      gid: "",
      loading: false,
      //視窗顯示
      dialogVisible: false,
      //form data
      dialogForm: {
        name_hk: "",
        name_en: "",
        type: "",
      },
      rules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },
      //快速套用視窗顯示
      quicklyDialogVisible: false,
      quicklyDialogForm: {
        selectRestaurant: [],
        data: "",
      },
      quickRules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
      },
    };
  },
  components: {
    pageTop,
    KtButton,
  },
  created() {
    this.getAuthNameList()
    this.getCustomAuthGroupList()
    this.getAllRestaurantList()
  },
  methods: {
    //大量套用授權
    applyAuthClick() {
      this.$http.quicklyApplyAuth({
        restaurantList: this.quicklyDialogForm.selectRestaurant,
        route_id_json: this.quicklyDialogForm.data.route_id_json,
        btn_id_json: this.quicklyDialogForm.data.btn_id_json,
      }).then((res) => {
        if (res.errno == 0) {
          this.quicklyDialogForm.selectRestaurant = []
          this.searchText = ""
          this.quicklyDialogVisible = false
          this.quicklyDialogForm.data = ""
          this.clean()
        }
      })
    },
    //取得餐廳清單
    getAllRestaurantList() {
      this.$http.getAllRestaurantList().then((res) => {
        this.restaurantList = res.data
      })
    },
    //新增或修改
    addOrModify(gid) {
      this.$refs["dialogForm"].validate(valid => {
        //驗證
        if (valid) {
          if (this.addModify == 0) {
            //新增
            this.$http.addCustomAuthGroup({
              project: this.project,
              name_hk: this.dialogForm.name_hk,
              name_en: this.dialogForm.name_en,
              route_id_json: JSON.stringify(this.selectList),
              btn_id_json: JSON.stringify(this.btnSelectList),
            }).then((res) => {
              if (res.errno == 0) {
                this.getCustomAuthGroupList()
                //清空
                this.clean()
                this.dialogVisible = false
              }
            })
          } else {
            //更新
            this.$http.updateCustomAuthGroup({
              group_id: this.gid,
              project: this.project,
              name_hk: this.dialogForm.name_hk,
              name_en: this.dialogForm.name_en,
              route_id_json: JSON.stringify(this.selectList),
              btn_id_json: JSON.stringify(this.btnSelectList),
            }).then((res) => {
              if (res.errno == 0) {
                this.getCustomAuthGroupList()
                //清空
                this.clean()
                this.dialogVisible = false
              }
            })
          }
        } else {
          //驗證失敗
          this.$alert(this.$t('shop-goods.5E8023@Please_complete_the_form'))
        }
      })
    },
    //父類別改變時
    parentChange(status, parent) {
      let childArray = parent.children.map((i) => i.id)
      //勾選時
      if (status) {
        //merge array
        this.selectList.push.apply(this.selectList, childArray)
        //取得child Array
        let child = parent.children.map((i) => i)
        //在取得btn Array
        child.map((c) => {
          let btnArray = c.children.map((btn) => btn.id)
          //再將btn array merge
          this.btnSelectList.push.apply(this.btnSelectList, btnArray)
        })
      } else {
        //回傳刪除後的勾選清單
        this.selectList = this.selectList.filter(function (e) {
          return this.indexOf(e) < 0
        }, childArray)

        //取得child Array
        let child = parent.children.map((i) => i)
        child.map((c) => {
          let btnArray = c.children.map((btn) => btn.id)
          //再將btn array merge
          this.btnSelectList = this.btnSelectList.filter(function (e) {
            return this.indexOf(e) < 0
          }, btnArray)
        })
      }
    },
    //子類別被改變時
    childChange(status, child) {
      let childArray = child.children.map((i) => i.id)
      //勾選時
      if (status) {
        //merge array
        this.btnSelectList.push.apply(this.btnSelectList, childArray)
      } else {
        //回傳刪除後的勾選清單
        this.btnSelectList = this.btnSelectList.filter(function (e) {
          return this.indexOf(e) < 0
        }, childArray)
      }
    },
    //取得授權名稱清單
    getAuthNameList() {
      this.$http.getAuthNameList({
        project: this.project,
      }).then((res) => {
        //設定群組名稱清單
        this.groupNameList = res.data
        //加入checked
        this.groupNameList.map((item) => {
          //添加屬性給主要類別checked
          this.$set(item, 'checked', false)
          //添加主要名稱
          this.$set(item, 'name', this.lang ? item.name_hk : item.name_en)
          if (item.children.length > 0) {
            item.children.map((child) => {
              //添加次要名稱
              this.$set(child, 'name', this.lang ? child.name_hk : child.name_en)
              //添加屬性給次要類別checked
              this.$set(child, 'checked', false)
            })
          }
        })
      })
    },
    //取得自訂授權清單
    getCustomAuthGroupList() {
      this.$http.getCustomAuthGroupList({
        'project': this.project,
      }).then((res) => {
        this.tableData = res.data
      })
    },
    //新增
    add() {
      this.addModify = 0
      this.dialogVisible = true
      //清空
      this.clean()
    },
    //更新自訂群組
    updateGroup(row) {
      this.addModify = 1
      this.dialogVisible = true
      this.clean()
      this.gid = row.group_id
      this.dialogForm.name_hk = row.name_hk
      this.dialogForm.name_en = row.name_en
      this.selectList = JSON.parse(row.route_id_json)
      this.btnSelectList = JSON.parse(row.btn_id_json)
    },
    // 刪除
    deleteGroup(row) {
      this.$http.delCustomAuthGroup({
        group_id: row.group_id,
      }).then((res) => {
        if (res.errno == 0) {
          this.getCustomAuthGroupList()
          //清空
          this.clean()
          this.dialogVisible = false
        }
      })
    },
    //快速套用權限
    quicklyApplyPermission(row) {
      this.clean()
      this.quicklyDialogVisible = true
      this.quicklyDialogForm.data = row
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
    //清空
    clean() {
      this.gid = ""
      this.dialogForm.name_en = ""
      this.dialogForm.name_hk = ""
      this.selectList = []
      this.btnSelectList = []
      this.quicklyDialogForm.selectRestaurant = []
    },
    //timestamp轉換成日期與時間
    dateTimeFormat(row, column) {
      let dt = row[column.property]
      if (dt == undefined)
        return ""
      else {
        return this.formatDate(new Date(dt * 1000), "yyyy-MM-dd hh:mm:ss")
      }
    },
    formatDate(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + '';
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str));
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ('00' + str).substr(str.length);
    },
  },
}
</script>
<style lang="scss">
#CustomerAuthGroup {

  .restaurant-container {
    margin-left: 0%;
    padding-top: 1px;
  }

  // tree
  .module-container {
    margin-left: 0%;
    padding-top: 1px;
    border-bottom: 1px solid #f5f5f5;

    .module-title {
      margin-bottom: 0;
    }

    .menu-container {
      display: flex;
      padding-left: 20px;

      .menu-title {
        > label {
          width: 130px;
        }
      }

      .btn-name {
        > label {
          width: 110px;
        }

        > span {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>