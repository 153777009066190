<!-- PagesComponents -->
<template>
  <section class="PagesComponents">
    <section class="justify-between mb20">
      <section class="flex-items">
        <el-date-picker
            v-model="datetime"
            type="daterange"
            value-format="timestamp"
            @change="changeDatetime"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
            :end-placeholder="$t('placeholder.ABB7CF@end_time')"
            :default-time="['00:00:00', '23:59:59']"
            style="width: 220px"
            class="mr10"
        ></el-date-picker>
        <el-select
            v-model="company_id"
            class="mr10"
            @change="changeFilter"
            clearable
            filterable
            :placeholder="type == 2?$t('AverageOrderPrice.AverageOrderPrice_content.ChooseARestaurant'):$t('AverageOrderPrice.AverageOrderPrice_content.ChooseSupplier')"
            style="width: 250px"
        >
          <template v-if="type == 2">
            <el-option
                v-for="item in CompanyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </template>
          <template v-else>
            <el-option
                v-for="item in CompanyList"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id"
            ></el-option>
          </template>
        </el-select>
      </section>
      <el-button
          type="primary"
          class="table-btn btn-gray"
          size="default"
          @click="downloadexcel"
      >{{ $t("aboutus.2AECD0@Export_Order") }}
      </el-button
      >
    </section>
    <section>
      <el-table
          border
          :data="tableData"
          v-loading="loading"
          :row-class-name="$tableRowClassName"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :highlight-current-row="true"
          :default-sort="{ prop: 'login_num', order: 'descending' }"
          @sort-change="sortChange"
      >
        <el-table-column prop="user_name" :label="$t('aboutus.E56154@user_name')"></el-table-column>
        <el-table-column
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')"
        ></el-table-column>
        <el-table-column prop="is_superuser" :label="$t('backend_authrity_user.B5AC7E@isSuper')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.is_superuser | isSuperuser }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="login_num" sortable="custom"
                         :label="$t('LoginStatistics.LoginStatistics_content.NumberOfLogins')"></el-table-column>
        <!-- <el-table-column
          prop="add_up_login_time"
          :label="'累计登录时间'"
        ></el-table-column> -->
        <el-table-column
            prop="last_login" sortable="custom"
            :label="$t('backend_shopUser_supplierUser.82F60F@last_login_time')"
        ></el-table-column>
      </el-table>
      <section
          style="margin-top: 20px; display: flex; justify-content: flex-end"
      >
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </section>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "PagesComponents",
  components: {},
  data() {
    //这里存放数据
    return {
      company_id: "",
      datetime: "",
      tableData: [],
      CompanyList: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      // 0=沒有選擇, 1=>登入排序
      sort_type: 1,
      // 1=>遞減 2=>遞增
      sort: 2,
    };
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  filters: {
    isSuperuser(value) {
      return value ? "是" : "否";
    },
    isRestaurant(value) {
      return value == 2 ? "餐厅" : "供应商";
    },
  },
  //方法集合
  methods: {
    //排序改變時
    sortChange({column, prop, order}) {
      this.sort_type = prop == 'login_num' && order ? 1 : 0
      this.sort = order == "ascending" ? 1 : 2
      this.getLoginStatistics()
    },
    getLoginStatistics() {
      let params = {
        project: this.type,
        company_id: this.company_id || "",
        start_time: this.datetime ? this.datetime[0] / 1000 : "",
        end_time: this.datetime ? this.datetime[1] / 1000 : "",
        size: this.pageSize,
        page: this.currentPage,
        sort_type: this.sort_type,
        sort: this.sort
      }
      this.loading = true
      this.$http.loginStatistics(params).then((res) => {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.sum
      });
    },
    changeDatetime(val) {
      this.datetime = val || ""
      this.currentPage = 1
      this.getLoginStatistics()
    },
    changeFilter() {
      this.currentPage = 1
      this.getLoginStatistics()
    },
    downloadexcel() {
      this.$http.loginStatisticsExcel({
        project: this.type,
        company_id: this.company_id || "",
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
        size: this.pageSize,
        page: this.currentPage,
        sort_type: this.sort_type,
        sort: this.sort
      }).then((res) => {
        this.downloadFile(res, this.type == 2 ? "LoginStatistics_Restaurant.xlsx" : "LoginStatistics_Supplier.xlsx");
      });
    },
    // 获取选择器
    getCompanyList() {
      if (this.type == 2) {
        this.$http.restaurantShopSelector().then((res) => {
          this.CompanyList = res.data.filter(s => {
            return s.id != 41 && s.id != 45 && s.id != 47 && s.id != 68 &&
                s.id != 74 && s.id != 423 && s.id != 428 && s.id != 432 &&
                s.id != 596 && s.id != 597 && s.id != 598 && s.id != 599 &&
                s.id != 600 && s.id != 601 && s.id != 718 && s.id != 807 &&
                s.id != 825
          })
        })
      } else {
        this.$http.b2bSupplierSelector().then((res) => {
          this.CompanyList = res.data.filter(s => {
            return s.company_id != 43 && s.company_id != 44 && s.company_id != 46 && s.company_id != 429 &&
                s.company_id != 587 && s.company_id != 588
          })
        })
      }
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getLoginStatistics()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getLoginStatistics()
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLoginStatistics()
    this.getCompanyList()
  },
};
</script>
<style src='./PagesComponents.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
