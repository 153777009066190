<!-- KeyWords -->
<template>
  <section class="KeyWords">
    <page-top :title="$t('KeyWords.KeyWords_tit')">
      <span
        style="font-size: 26px; padding-bottom: 10px"
        class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-card>
      <section>
        <section class="justify-between" style="padding-bottom: 10px">
          <section class="flex-items">
            <el-date-picker
              v-model="datetime"
              type="daterange"
              value-format="timestamp"
              @change="changeDatetime"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
              class="mr10"
            ></el-date-picker>
            <el-select
              v-model="restaurant_id"
              class="mr10"
              @change="changeFilter"
              clearable
              filterable
              :placeholder="$t('AverageOrderPrice.AverageOrderPrice_content.ChooseARestaurant')"
              style="width: 250px"
            >
              <el-option
                v-for="item in restaurantList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </section>
          <el-button
            type="primary"
            class="table-btn btn-gray"
            size="default"
            @click="downloadexcel"
            >{{ $t("aboutus.2AECD0@Export_Order") }}</el-button
          >
        </section>
        <el-table
            border
          :data="tableData"
          v-loading="loading"
          :row-class-name="$tableRowClassName"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :highlight-current-row="true"
        >
          <el-table-column prop="keyword" :label="$t('KeyWords.KeyWords_content.KeyWords')"></el-table-column>
          <el-table-column prop="restaurant_name" :label="$t('aboutus.E8221A@restaurant_name')"></el-table-column>
          <el-table-column prop="number" :label="$t('KeyWords.KeyWords_content.frequency')"></el-table-column>
        </el-table>
        <section
          style="margin-top: 20px; display: flex; justify-content: flex-end"
        >
          <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </section>
      </section>
    </el-card>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "@/components/PageTop/PageTop";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "KeyWords",
  components: { pageTop },
  data() {
    //这里存放数据
    return {
      restaurant_id: "",
      datetime: "",
      tableData: [],
      restaurantList: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getKeywordFrequency() {
      let params = {
        size: this.pageSize,
        page: this.currentPage,
        restaurant_id: this.restaurant_id || "",
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
      };
      this.loading = true;
      this.$http.keywordFrequency(params).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },
    changeDatetime(val) {
      this.datetime = val || "";
      this.currentPage = 1;
      this.getKeywordFrequency();
    },
    downloadexcel() {
      this.$http
        .keywordFrequencyExcel({
          size: this.pageSize,
          page: this.currentPage,
          restaurant_id: this.restaurant_id || "",
          start_time: this.datetime[0] / 1000 || "",
          end_time: this.datetime[1] / 1000 || "",
        })
        .then((res) => {
          this.downloadFile(res);
        });
    },
    changeFilter() {
      this.currentPage = 1;
      this.getKeywordFrequency();
    },
    // 获取餐厅选择器
    getRestaurantList() {
      this.$http.restaurantShopSelector().then((res) => {
        this.restaurantList = res.data.filter(s=>{
          return s.id!=41 && s.id!=45 && s.id!=47 && s.id!=68 &&
              s.id!=74 && s.id!=423 && s.id!=428
        })
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getKeywordFrequency();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getKeywordFrequency();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getKeywordFrequency();
    this.getRestaurantList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src='./KeyWords.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
