<template>
  <div id="groupConfig">
    <page-top :title="$t('whatsapp_group.9EFF12@name')">
      <span style="font-size:26px;" class="fa fa-whatsapp"></span>
    </page-top>
    <el-card>
      <el-row>
        <el-col :span="24">
          <el-form label-width="200px" size="medium" style="padding-top: 20px">
            <!--        餐廳選單      -->
            <el-form-item :label="$t('backend_authrity_authrityList.C90CD7@restaurant_tab')" style="width: 81%">
              <el-select v-model="rid" @change="restaurantChange()" class="mt10 input restaurant"
                         clearable filterable remote>
                <el-option v-for="v in restaurantList" :key="v.cid" :value="v.cid"
                           :label="v.company_name">
                </el-option>
              </el-select>
            </el-form-item>
            <!--        搜尋方框      -->
            <el-form-item v-if="rid">
              <section>
                <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                          style="padding-bottom: 5px;padding-right: 20px;border-radius: 10px;width: 80%">
                </el-input>
              </section>
            </el-form-item>
            <!--        供應商清單      -->
            <el-form-item v-if="rid">
              <el-table
                  v-loading="loading"
                  :data="search"
                  :element-loading-text="$t('public.4ABAB1@loading')"
                  style="width: 80%;margin-top:20px;">
                <el-table-column
                    prop="name"
                    :label="$t('backend_shop_supplierList.995038@company_name')"
                    align="center"
                    show-overflow-tooltip>
                  <template scope="scope">
                    <span slot="append">
                      {{
                        groupList.filter((e) => {
                          return scope.row.cid == e.cid
                        })[0].name
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                    :label="$t('whatsapp_group.6AE824@group_id')"
                    align="center"
                    show-overflow-tooltip>
                  <template scope="scope">
                    <div slot="append">
                      <el-input type="text" size="small" :placeholder="$t('whatsapp_group.C1E643@placeholder')"
                                v-model="groupList.filter((e)=>{return scope.row.cid==e.cid})[0].group_id"></el-input>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-row>
                <kt-button
                    class="table-btn btn-yellow btn-update"
                    :label="$t('public.A738BA@update')"
                    :perm="16113585191986"
                    @click="updateWhatsAppGroupList">
                </kt-button>
                <section class="pagination-container">
                  <el-pagination
                      class="pagination"
                      @size-change="handleSizeChange"
                      :page-sizes="[50,100,250,500]"
                      :page-size="pageSize"
                      @current-change="handleCurrentChange"
                      :current-page="page"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="supplierLength"
                  ></el-pagination>
                </section>
              </el-row>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import pageTop from "components/PageTop/PageTop";
import ktButton from "../../components/ktButton/KtButton.vue";

export default {
  name: "",
  components: {
    pageTop,
    ktButton,
  },
  created() {
    this.init()
  },
  data() {
    return {
      //群組清單
      groupList: [],
      //loading
      loading: true,
      //每頁數量
      pageSize: 50,
      //當前頁數
      page: 1,
      //供應商總數量
      supplierLength: 0,
      //供應商列表
      supplierList: [],
      //搜尋文字
      searchText: "",
      //餐廳列表
      restaurantList: [],
      //供應商清單
      //資料
      rid: "",
    }
  },
  computed: {
    search() {
      if (this.searchText == "") {
        return this.groupList.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
      } else {
        return this.groupList.filter(i => {
          return i.name.indexOf(this.searchText) > -1
        })
      }
    }
  },
  methods: {
    //更新WhatsAppGroup
    updateWhatsAppGroupList() {
      this.$http.updateWhatsAppGroupList({
        cid: this.rid,
        group_list: JSON.stringify(this.groupList)
      })
    },
    //取得餐廳WhatsApp權限
    getRestaurantWhatsAppGroup() {
      this.$http.getRestaurantWhatsAppGroup({
        cid: this.rid
      }).then((res) => {
        let groupList = res.data.group_list
        if (res.data.is_new) {
          //尚未被設定
          this.groupList = groupList
        } else {
          this.groupList = JSON.parse(groupList)
          if (this.supplierLength != this.groupList.length) {
            this.$http.getAllSupplierList().then(res => {
              let allSupplier = res.data.list
              if (this.supplierList > this.groupList.length) {
                //差集
                let data = [...allSupplier].filter(x => [...this.groupList].every(y => y.cid !== x.company_id))
                data.forEach(d => {
                  this.groupList.push({
                    cid: d.company_id,
                    name: d.company_name,
                    group_id: "",
                  })
                })
              } else {
                let data = [...this.groupList].filter(x => [...allSupplier].some(y => x.cid === y.company_id))
                this.groupList = data
              }
            })
          }
        }
        //解除loading
        this.loading = false
      })
    },
    //初始化
    init() {
      this.getAllRestaurantList()
      this.getSupplierList()
    },
    //取得餐廳列表
    getAllRestaurantList() {
      this.$http.getAllRestaurantList().then((res) => {
        this.restaurantList = res.data
      })
    },
    //取得供應商列表
    getSupplierList(status) {
      this.$http.getEnableSupplierList({
        page: this.page,
        size: this.pageSize,
      }).then((res) => {
        this.supplierLength = res.data.sum
        this.supplierList = res.data.list
        if (status == 1) {
          this.getRestaurantWhatsAppGroup()
        }
      })
    },
    //餐廳選項異動時
    restaurantChange() {
      this.groupList = []
      this.getRestaurantWhatsAppGroup()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getSupplierList(1)
    },
    handleCurrentChange(val) {
      this.page = val
      this.getSupplierList(1)
    },
  },
}
</script>

<style lang="scss" scoped>
#groupConfig {
  .restaurant {
    width: 100%;
    text-align: center;
  }

  .pagination-container {
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;

    .pagination {
      float: right;
    }
  }

  .btn-update {
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
  }
}
</style>