<template>
  <b-navbar id="header" class="header d-print-none">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class="la la-bars" />
        </a>
      </b-nav-item>
      <!-- <b-form class="d-sm-down-none ml-5" inline>
        <el-input prefix-icon="el-icon-search" v-model="search"></el-input>
      </b-form>-->
    </b-nav>

    <b-nav class="ml-auto">

      <!-- 消息通知 -->
      <b-nav-item-dropdown class="settingsDropdown info" right>
        <template slot="button-content">
          <el-badge :value="chatInform.badge_sum" :hidden="!badgeShow" class="item">
            <i class="fa fa-envelope px-2" />
          </el-badge>
        </template>
        <b-dropdown-item-button
          v-if="Boolean(chatInform.list&&chatInform.list.length)"
          style=" width: 100%;
    height: 50px;"
        ></b-dropdown-item-button>
        <b-dropdown-item-button v-for="(v,i) in chatInform.list" :key="i" @click="goPo(v)">
          <el-badge :value="v.badge" style="width:100%" :hidden="!badgeShow" class="item">
            <div style="font-size:16px;color:#000;font-weight:500;margin-bottom: 3px;">{{v.title}}</div>
            <div style="font-size:12px;margin-bottom: 3px;">{{v.id}}</div>
            <div style="font-size:12px;margin-bottom: 3px;">{{v.message_content}}</div>
            <div style="font-size:12px;margin-bottom: 3px;">{{v.date}}</div>
          </el-badge>
          <b-dropdown-divider />
        </b-dropdown-item-button>
        <b-dropdown-item-button
          class="read"
          v-if="Boolean(chatInform.list&&chatInform.list.length)"
          @click="clearAllInform"
        >{{$t('aboutus.2F9ADA@Mark_as_read')}}</b-dropdown-item-button>
        <b-dropdown-item-button v-else @click="clearAllInform">{{$t('aboutus.882633@No_Notice')}}</b-dropdown-item-button>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown class="language" left>
        <template slot="button-content">
          <img
            v-if="language == 'hk'"
            src="@/assets/hk.png"
            style="width:30px;margin-right:5px;"
            alt
          />
          <img src="@/assets/en.png" v-else style="width:30px;margin-right:5px" alt />
        </template>
        <b-dropdown-item-button @click="tradition">
          <img src="@/assets/hk.png" style="width:25px;margin-right:5px;" alt />
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button @click="english">
          <img src="@/assets/en.png" style="width:25px;margin-right:5px" alt />
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
      <!-- 账户管理 -->
      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none" no-caret>
        <template slot="button-content">
          <span class="avatar thumb-sm float-left mr-2">
            <img class="rounded-circle" :src="userInfo.avatar" alt="..." />
          </span>
        </template>
        <b-dropdown-item-button @click="logout">
          <i style="font-size:16px;padding-right:5px;" class="la la-sign-out"></i>
          {{ $t('public.707A1E@logout') }}
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button @click="modifyPass">
          <i style="font-size:16px;padding-right:5px;" class="la la-edit"></i>
          {{ $t('public.2FC133@modify_pass') }}
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import api from "../../api/index";
import { mapState, mapActions } from "vuex";
import $ from "jquery";

export default {
  name: "Headed",
  data() {
    return {
      search: "",
      showDialog: false,
      language: "",
      chatInform: {},
      badgeShow: ""
    };
  },
  props: {
    userInfo: ""
  },
  watch: {
    chat_inform: {
      handler(newData, oldData) {
        console.log("新通知", newData);
        let flag = true;
        if (newData.push_data.badge_sum > 0) {
          newData.push_data.list.forEach((v, i) => {
            if (v.id == this.$store.state.room_id) {
              flag = false;
            }
          });
          if (flag) {
            this.chatInform = newData.push_data;
            this.badgeShow = true;
          }
        } else {
          this.badgeShow = false;
          this.chatInform = newData.push_data;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic
    }),
    ...mapState("websocket", {
      chat_inform: state => state.chat_inform
    })
  },
  methods: {
    ...mapActions("layout", [
      "toggleSidebar",
      "switchSidebar",
      "changeSidebarActive"
    ]),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    logout() {
      api.logout().then(res => {
        localStorage.clear();
        this.$router.push("/login");
      });
      //  this.$remlocalStorage('token');
    },
    // simplify () {
    //   this.$i18n.locale = 'cn';
    //    this.$setlocalStorage('lang', 'cn');
    //   this.$router.push('/space')
    // },
    tradition() {
      this.$i18n.locale = "hk";
      this.$setlocalStorage("lang", "hk");
      this.language = "hk";
      this.$router.push("/space");
      if (Boolean(this.$getlocalStorage("backstageToken"))) {
        api.userChangeLang();
      }
      this.global.initWebSocket();
    },
    english() {
      this.$i18n.locale = "en";
      this.language = "en";
      this.$setlocalStorage("lang", "en");
      this.$router.push("/space");
      if (Boolean(this.$getlocalStorage("backstageToken"))) {
        api.userChangeLang();
      }
      this.global.initWebSocket();
    },
    // 清楚所有聊天记录
    clearAllInform() {
      this.$http.clearAllInform().then(res => {});
    },
    //去往聊天室
    goPo(v) {
      if (v.type == "place_order") {
        this.$router.push({
          name: "orderDetail",
          query: { order_no: v.id }
        });
      }
    },
    // 获取通知消息
    getInform() {
      this.$http.getInform().then(res => {
        this.chatInform = res.data;
        if (res.data.badge_sum > 0) {
          this.badgeShow = true;
        } else {
          this.badgeShow = false;
        }
        console.log("聊天通知", res);
      });
    },
    modifyPass() {
      this.$emit("modifyPass", !this.showDialog);
    }
  },
  created() {
    this.getInform();
    this.language = this.$getlocalStorage("lang") || "hk";
    if (window.innerWidth > 576) {
      setTimeout(() => {
        const $chatNotification = $("#chat-notification");
        $chatNotification
          .removeClass("hide")
          .addClass("animated fadeIn")
          .one(
            "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
            () => {
              $chatNotification.removeClass("animated fadeIn");
              setTimeout(() => {
                $chatNotification
                  .addClass("animated fadeOut")
                  .one(
                    "webkitAnimationEnd mozAnimationEnd MSAnimationEnd" +
                      " oanimationend animationend",
                    () => {
                      $chatNotification.addClass("hide");
                    }
                  );
              }, 6000);
            }
          );
        $chatNotification
          .siblings("#toggle-chat")
          .append('<i class="chat-notification-sing animated bounceIn"></i>');
      }, 4000);
    }
  }
};
</script>

<style src="./Header.scss" lang="scss" />
