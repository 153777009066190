<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-15 16:30:23
 * @LastEditTime: 2019-11-23 15:10:50
 * @LastEditors: Please set LastEditors
 -->
<!--  -->
<template>
  <div class="print" ref="print">
    <el-card body-style="padding: 20px 35px">
      <el-button
        type="primary"
        class="no-print"
        @click="print"
      >{{$t('restaurant_buy_print.945C82@Print_immediately')}}</el-button>
      <section class="head">
        <img class="logo" :src="pageData.supplier_logo" alt />
        <!-- <div class="logo"></div> -->
        <section class="invoiceInfo">
          <h1 style="font-weight: bold">INVOICE</h1>
          <div class="invoiceId">
            <span>{{$t('placeholder.5C894A@order_code')}}&nbsp;:&nbsp;{{pageData.order_no}}</span>
            <span
              v-if="pageData.invoice_no"
            >{{$t('orderDetail.AB7C91@invoice_number')}}&nbsp;:&nbsp;{{pageData.invoice_no}}</span>
            <span
              v-if="pageData.payment_due"
              style="color:#f24040"
            >{{$t('restaurant_buy_print.470F44@Latest_date')}}&nbsp;:&nbsp;{{pageData.payment_due}}</span>
          </div>
        </section>
      </section>
      <h4
        style="margin-top: 20px;font-weight: 500; background-color: #ccc; padding: 5px 10px;border:1px solid #ccc"
      >{{$t('restaurant_buy_print.9C5FBF@supplier_info')}}</h4>

      <section class="supplier">
        <section>
          <span>{{$t('restaurant_supplier_products.FEF412@supplier_name')}} :</span>
          <span>{{!pageData.display_name?pageData.seller_company_name:pageData.display_name}}</span>
        </section>
        <section>
          <span>{{$t('public.bank_info')}} :</span>
          <span>{{pageData.payment_str}}</span>
        </section>
        <section>
          <span>{{$t('orderDetail.7FC644@supplier_tele')}} :</span>
          <span>{{pageData.seller_company_phone}}</span>
        </section>
      </section>
      <h4
        style="margin-top: 20px;font-weight: 500; background-color: #ccc; padding: 5px 10px;border:1px solid #ccc"
      >{{$t('restaurant_buy_print.59DDF6@order_info')}}</h4>
      <section class="info">
        <section class="restaurantInfo">
          <section>
            <span>{{$t('backend_shop_supplierList.995038@company_name')}} :</span>
            <span>{{pageData.receiver_company_name}}</span>
          </section>
          <section>
            <span>{{$t('restaurant_buy_print.B06A44@Branches_stores')}} :</span>
            <span>{{pageData.buyer_shop_name}}</span>
          </section>
          <section>
            <span>{{$t('restaurant_buy_print.2886D5@Order_store_phone')}} :</span>
            <span>{{pageData.buyer_shop_phone}}</span>
          </section>
          <section>
            <span>{{$t('orderDetail.6F5A1A@delivery_adderss')}} :</span>
            <span>{{pageData.receiver_address}}</span>
          </section>
          <section>
            <span>{{$t('orderDetail.082549@order_time')}} :</span>
            <span>{{pageData.create_time}}</span>
          </section>
          <!-- <section>
            <span>订单状态 :</span>
            <span>{{pageData.order_status}}</span>
          </section>
          <section>
            <span>订单支付状态 :</span>
            <span>{{pageData.pay_status}}</span>
          </section>
          <section>
            <span>付款方式 :</span>
            <span>{{pageData.pay_method}}</span>
          </section>-->
        </section>
      </section>
      <h4
        style="margin-top: 20px;font-weight: 500; background-color: #ccc; padding: 5px 10px; margin-bottom: 30px;"
      >{{$t('restaurant_buy_print.2DC8EA@product_info')}}</h4>

      <section class="printTable">
        <table border="1">
          <thead>
            <tr>
              <th>{{$t('public.711B38@serial_number')}}</th>
              <th>{{$t('restaurant_supplier_products.3B1C04@product_id')}}</th>
              <th>{{$t('restaurant_supplier_products.CE8240@product_name')}}</th>
              <th>{{$t('restaurant_inventory.198560@sku_name')}}</th>
              <th>{{$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')}}</th>
              <th>{{$t('restaurant_buy_B2BProduct.EF1083@unit_price')}}</th>
              <th>{{$t('orderDetail.7CA68E@total_price')}}</th>
              <th>{{$t('orderDetail.CAF2C7@number')}}</th>
              <th>{{$t('orderDetail.3BB7C2@Accept_number')}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,i) in pageData.goods_list" :key="i">
              <td>{{v.serial_num}}</td>
              <td>{{v.gid}}</td>
              <td>{{v.goods_name}}</td>
              <td>{{v.sku_name}}</td>
              <td>{{v.unit_num}}/{{v.unit_name}}</td>
              <td>{{v.receive_unit_price}}</td>
              <td>{{v.final_goods_price}}</td>
              <td>{{v.buy_num}}</td>
              <td>{{v.receive_num}}</td>
            </tr>
          </tbody>
        </table>
        <div class="append">
          <div>
            <span>{{$t('aboutus.09C1E0@Total_amount_of_goods')}} :</span>
            <span>{{pageData.receive_goods_price}}</span>
          </div>
          <div>
            <span>{{$t('restaurant_supplier_supplierList.2E31F9@fee')}} :</span>
            <span>{{pageData.receive_deliver_fee}}</span>
          </div>
          <div>
            <span>{{$t('shop_cart.A91ABF@order_price')}} :</span>
            <span>{{pageData.final_price}}</span>
          </div>
        </div>
      </section>
      <section class="notice">
        <span>NOTICE:</span>
        <span>The amount of the Purchase Order is the agreed fixed price and shall not be exceeded without advanced written consent.</span>
      </section>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "../../components/PageTop/PageTop";
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "print",
  components: {
    pageTop
  },
  data() {
    //这里存放数据
    return {
      pageData: {},
      type: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.type = this.$route.query.type;
    this.getData();
  },
  //方法集合
  methods: {
    getData() {
      let order_no = this.$route.query.order_no;
      this.$http.printOrderPDF({ order_no }).then(res => {
        console.log(res);
        this.pageData = res.data;
      });
    },
    print() {
      console.log(navigator.userAgent);
      console.log(navigator.userAgent.indexOf("Edge"));

      if (
        navigator.userAgent.indexOf("Edge") > -1 ||
        navigator.userAgent.indexOf("Chrome") == -1
      ) {
        this.$confirm(
          "想要体验最佳效果，建议请使用谷歌游览器(Chrome)打印, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.$print(this.$refs.print);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消打印"
            });
          });
      } else {
        this.$print(this.$refs.print);
      }
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss'  src="./print.scss"  />
//@import url(); 引入公共css类
