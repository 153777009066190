
<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.92671D@supplier_user_man')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
      </section>
      <el-table
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        :data="tableData"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <el-table-column
          prop="company_name"
          :label="$t('backend_shopUser_supplierUser.E916BD@company_name')"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="uid"
          :label="$t('backend_shopUser_supplierUser.CB120A@user_id')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_name"
          :label="$t('backend_shopUser_supplierUser.26E957@username')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="nick_name"
          :label="$t('backend_shopUser_supplierUser.062848@nickname')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          :label="$t('backend_shopUser_supplierUser.9CD9E0@status')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="is_superuser"
          :label="$t('backend_shopUser_supplierUser.B3BDE4@isSuper')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="last_login_time"
          :label="$t('backend_shopUser_supplierUser.82F60F@last_login_time')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('public.D17BCA@created_time')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('public.03EA09@operation')"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <kt-button
              v-if="scope.row.status=='NORMAL'"
              :perm="862"
              :label="$t('public.DBC110@locked')"
              @click="alterUserStatus(scope.row.uid,1)"
            ></kt-button>
            <kt-button
              v-else
              :perm="862"
              :label="$t('public.564C21@unlock')"
              @click="alterUserStatus(scope.row.uid,0)"
            ></kt-button>
            <kt-button
              type="info"
              :perm="16347121231692"
              :label="$t('public.A1E5C5@reset_password')"
              @click="resetPassword(scope.row.uid)">

            </kt-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="sum"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import api from "../../api/index";
import pageTop from "../../components/PageTop/PageTop";
export default {
  watch:{
    searchText(){
      this.getData()
    }
  },
  data() {
    return {
      loading: true,
      select: {
        search: ""
      },
      //搜尋文字
      searchText: null,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      sum: 0
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getData();
  },
  methods: {
    //重置密碼
    resetPassword(uid) {
      api.resetPassword({
        uid: uid,
      })
    },
    // 餐厅用户状态更改
    alterUserStatus(uid, e) {
      api
        .lockSupplierUser({
          uid,
          type: e
        })
        .then(res => {
          if (res.errno == 0) {
            this.getData();
          }
        });
    },
    getData() {
      api.getSupplierUser({
          size: this.pageSize,
          page: this.currentPage,
          search:this.searchText,
        }).then(res => {
          this.tableData = res.data.list;
          this.sum = res.data.sum;
          this.loading = false;
        });
    },
    toAdd() {
      this.$router.push("/app/addFavorite");
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    fixed(row) {
      this.dialogFormVisible = true;
      Object.assign(this.dialogForm, row);
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  section {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>
