<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 16:39:02
 * @LastEditTime: 2019-08-14 18:12:14
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div :id="this.id" :style="{ width: '100%', height: '300px' }"></div>
</template>
<script>
export default {
  name: 'hello',
  props: {
    options: {}
  },
  mounted () {
    this.drawLine();
  },
  computed: {
    id () {
      return parseInt(Math.random() * 1000000)
    },
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(this.id))
      // 绘制图表
      myChart.setOption(this.options)

      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>