var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:{ sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened },on:{"mouseenter":_vm.sidebarMouseEnter,"mouseleave":_vm.sidebarMouseLeave}},[_vm._m(0),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('div',{staticStyle:{"width":"50px","height":"50px","margin":"0 auto","border-radius":"50%","overflow":"hidden"}},[_c('img',{staticStyle:{"display":"block","width":"100%","height":"auto"},attrs:{"src":_vm.userInfo.avatar}})]),_c('p',{staticStyle:{"text-align":"center","margin":"10px 0","font-size":"16px"}},[_vm._v(_vm._s(_vm.userInfo.user_name))]),(_vm.userInfo.is_superuser)?_c('p',{staticStyle:{"text-align":"center","font-size":"16px"}},[_vm._v(_vm._s(_vm.$t('aboutus.09AE4A@primary_account')))]):_vm._e()]),_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"header":_vm.$t('backend_submenu.ABAAF2@dashboard'),"link":"/app/dashboard","iconName":"fa-dashboard","index":"dashboard","isHeader":"","perm":888}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('statistics'),"link":"/Statistics","iconName":"fa-bar-chart-o","index":"Statistics","isHeader":"","perm":_vm.hasRoutePerms(16171940481727)?1:_vm.hasRoutePerms(16171945565643)?1:_vm.hasRoutePerms(16171943527328)?1:_vm.hasRoutePerms(16171948681727)?1:_vm.hasRoutePerms(16171949501071)?1:_vm.hasRoutePerms(16171950282578)?1:_vm.hasRoutePerms(16171951957887)?1:_vm.hasRoutePerms(16171953041822)?1:_vm.hasRoutePerms(16171956147084)?1:_vm.hasRoutePerms(16171958068080)?1:_vm.hasRoutePerms(16303957249994)?1:0,"childrenLinks":[
        {perm:16171945565643, header: 'GMV', link: '/app/Statistics/GMV' },
        {perm:16171943527328, header: _vm.$t('AverageOrderPrice.AverageOrderPrice_tit'), link: '/app/Statistics/AverageOrderPrice' },
        {perm:16171948681727, header: _vm.$t('KeyWords.KeyWords_tit'), link: '/app/Statistics/KeyWords' },
        {perm:16171949501071, header: _vm.$t('ActiveUser.ActiveUser_tit'), link: '/app/Statistics/ActiveUser' },
        {perm:16171950282578, header: _vm.$t('CumulativeSalesVolume.CumulativeSalesVolume_tit'), link: '/app/Statistics/CumulativeSalesVolume' },
        {perm:16171951957887, header: _vm.$t('LoginStatistics.LoginStatistics_tit'), link: '/app/Statistics/LoginStatistics' },
        {perm:16171953041822, header: _vm.$t('GoodsSales.GoodsSales_tit'), link: '/app/Statistics/GoodsSales' },
        {perm:16171956147084, header: _vm.$t('CommodityAnalysis.CommodityAnalysis_tit'), link: '/app/Statistics/CommodityAnalysis' },
        {perm:16171958068080, header: _vm.$t('FunctionUsageRecord.FunctionUsageRecord_tit'), link: '/app/Statistics/FunctionUsageRecord' },
        {perm: 16303957249994, header: _vm.$t('OrderUser.title'),  link: '/app/Statistics/OrderUser'}
      ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('backend_submenu.ED96C7@authrity'),"link":"/app/authority","iconName":"fa-american-sign-language-interpreting","index":"authority","isHeader":"","perm":_vm.hasRoutePerms(850)?1:_vm.hasRoutePerms(636)?1:_vm.hasRoutePerms(845)?1:_vm.hasRoutePerms(990)?1:0,"childrenLinks":[
        {perm:850, header: _vm.$t('backend_submenu.4A302E@authrityList'), link: '/app/authority/authority' },
        {perm:636, header: _vm.$t('backend_submenu.21BF37@grouping'), link: '/app/authority/grouping' },
        {perm:845, header: _vm.$t('backend_submenu.61A3ED@user'), link: '/app/authority/user' },
        {perm:990, header: _vm.$t('backend_submenu.6A0B0E@Operation_record'), link: '/app/authority/log' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('backend_submenu.0FED78@config'),"link":"/app/configItem","iconName":"fa-cog","index":"configItem","isHeader":"","perm":_vm.hasRoutePerms(1257)?1:_vm.hasRoutePerms(6820)?1:_vm.hasRoutePerms(2869)?1:_vm.hasRoutePerms(752)?1:_vm.hasRoutePerms(3563)?1:0,"childrenLinks":[

        {perm:1257,header: _vm.$t('backend_web_adv.Z25E52@web_adv'), link: '/app/configItem/webadv' },
        {perm:1257,header: _vm.$t('backend_submenu.0265E5@Advertisement'), link: '/app/configItem/advertising' },
        {perm:6820,header: _vm.$t('backend_submenu.157A9D@platform_Info'), link: '/app/configItem/platform' },
        {perm:2869,header: _vm.$t('backend_submenu.4F09AA@index'), link: '/app/configItem/homeClassify' },
        {perm:752,header: _vm.$t('backend_submenu.49DFF3@integral'), link: '/app/configItem/integral' },
        {perm:3563,header: _vm.$t('aboutus.6505D3@Plan_Subscription'), link: '/app/configItem/plan' },
        {perm:4122,header: _vm.$t('aboutus.CEA76C@Product_unit'), link: '/app/configItem/ProductUnit' },
        {perm:16257529458819,header: _vm.$t('public.9AF601@unit_converter') , link: '/app/configItem/unitConverter' },
        {perm:16570954479775,header: _vm.$t('aboutus.3C4EF5@Customer_testimonials') , link: '/app/configItem/homeVideo' },
        {perm:16627316323651, header: _vm.$t('specialArea.title'), link: '/app/configItem/specialArea' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('backend_submenu.AD8E7D@Merchant_man'),"link":"/app/shopManage","iconName":"fa-diamond","index":"shopManage","isHeader":"","perm":_vm.hasRoutePerms(686)?1:_vm.hasRoutePerms(948)?1:_vm.hasRoutePerms(16128655484976)?1:_vm.hasRoutePerms(16136614302385)?1:_vm.hasRoutePerms(16185741672988)?1:_vm.hasRoutePerms(16588324268534)?1:0,"childrenLinks":[
        {perm:686, header: _vm.$t('backend_submenu.411879@restaurant_list'), link: '/app/shopManage/restaurantList' },
        {perm:948, header: _vm.$t('backend_submenu.BE2794@supplier_list'), link: '/app/shopManage/supplierList' },
        {perm:16128655484976, header: _vm.$t('backend_submenu.DD811F@payment_list'), link: '/app/shopManage/paymentList' },
        {perm: 16136614302385, header: _vm.$t('MarketRank.E3564A@name'), link: '/app/shopManage/marketRank' },
        {perm:16185741672988, header: _vm.$t('pos.DD6478@title'), link: '/app/shopManage/ManagePos' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('backend_submenu.100AE5@Merchant_user_management'),"link":"/app/userManage","iconName":"fa-user","index":"userManage","isHeader":"","perm":_vm.hasRoutePerms(911)?1:_vm.hasRoutePerms(908)?1:0,"childrenLinks":[
        { perm:911, header: _vm.$t('backend_submenu.526167@restaurant_user_man'), link: '/app/userManage/restaurantUser' ,},
        { perm : 911, header : _vm.$t('backend_submenu.5815A5@restaurant_permission_management'), link : '/Restaurant/PermissionManage' },
        { perm : 16073265229291, header : _vm.$t('backend_submenu.C89D43@custom_restaurant_auth_group'), link : '/app/Restaurant/CustomPermissionGroup' },
        { perm : 16113583886897, header : _vm.$t('whatsapp_group.9EFF12@name'), link : '/app/userManage/groupConfig' },
        { perm:908, header: _vm.$t('backend_submenu.92671D@supplier_user_man'), link: '/app/userManage/supplierUser' } ]}}),_c('NavLink',{attrs:{"header":_vm.$t('backend_submenu.96B8E2@Product_classification'),"link":"/app/productCategory","iconName":"fa-braille","index":"productCategory","isHeader":"","perm":723}}),_c('NavLink',{attrs:{"header":_vm.$t('aboutus.9B9285@Bill_Classification'),"link":"/app/BillClassification","iconName":"fa-university","index":"BillClassification","isHeader":"","perm":15834014934913}}),_c('NavLink',{attrs:{"header":_vm.$t('invoice.AE64AD@menu'),"link":"/app/invoice","iconName":"fa-money","index":"BillClassification","isHeader":"","perm":16144170744786}}),_c('NavLink',{attrs:{"header":_vm.$t('supplier_order.E0C2CB@order_management'),"link":"/app/order","iconName":"fa-list-alt","index":"productCategory","isHeader":"","perm":_vm.hasRoutePerms(15792558088380)?1:_vm.hasRoutePerms(15792558088380)?1:0,"childrenLinks":[
        { perm:15792558088380, header: _vm.$t('supplier_order.E0C2CB@order_management'), link: '/app/order/purchase' ,},
        { perm:15792558088380, header: _vm.$t('backend_submenu.A15265@add_order'), link: '/app/order/addGoodsOrder' } ]}}),_c('NavLink',{attrs:{"header":_vm.$t('rating.AED585@rating_manage'),"link":"/app/RateManage","iconName":"fa-diamond","index":"RateManage","isHeader":"","perm":16167868752230}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('aboutus.3697EB@Blog'),"link":"/app/Blog","iconName":"fa-user","index":"Blog","isHeader":"","perm":_vm.hasRoutePerms(15894504589622)?1:_vm.hasRoutePerms(15894505672627)?1:0,"childrenLinks":[
        { perm:15894504589622, header: _vm.$t('aboutus.E7F9ED@Blog_type'), link: '/app/Blog/BlogCategory' ,},
        { perm:15894505672627, header: _vm.$t('aboutus.B99A84@Blog_list'), link: '/app/Blog/BlogList' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('AppGuideline.title'),"link":"/app/AppGuideline","iconName":"fa-user","index":"AppGuideline","isHeader":"","perm":_vm.hasRoutePerms(16636649816586)?1:_vm.hasRoutePerms(16636652192938)?_vm.hasRoutePerms(16636652601330):0,"childrenLinks":[
        { perm:16636652192938, header: _vm.$t('AppGuideline.AppGuidelineCategory'), link: '/app/AppGuideline/AppGuidelineCategory' ,},
        { perm:16636652601330, header: _vm.$t('AppGuideline.AppGuidelineList'), link: '/app/AppGuideline/AppGuidelineList' } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('index.CA31BF@mail'),"link":"/app/Email","iconName":"fa fa-envelope","index":"Email","isHeader":"","perm":_vm.hasRoutePerms(15942063038883)?1:_vm.hasRoutePerms(15942064631361)?1:0,"childrenLinks":[
        { perm:15942063038883, header: _vm.$t('backend_submenu.EF42B5@email_list'), link: '/app/Email' ,},
        { perm:15942064631361, header: _vm.$t('index.ABA598@Marketing_email'), link: '/app/email-marketing' } ]}}),_c('NavLink',{attrs:{"badge":("" + _vm.notReadCount),"header":_vm.$t('aboutus.B36F4C@chat_record'),"link":"/app/chatrecord","iconName":"fa-file-text-o","index":"productCategory","isHeader":"","perm":3249}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticStyle:{"text-align":"center","padding":"10px 0"}},[_c('div',{staticClass:"item-logo"},[_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/headLogo.png")}})])])}]

export { render, staticRenderFns }