<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-25 19:44:24
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="order-container">
    <page-top :title="$t('restaurant_submenu.396A54@general_order')">
      <span style="font-size:26px;" class="fa fa-list-alt"></span>
    </page-top>
    <el-card>
      <section style="display:flex;justify-content: space-between;">
        <el-form :inline="true" :model="search" class="demo-form-inline">
          <el-form-item>
            <el-date-picker
                v-model="search.datetime"
                type="daterange"
                value-format="timestamp"
                @change="changeDatetime"
                range-separator="~"
                :start-placeholder="$t('placeholder.524620@start_time')"
                :end-placeholder="$t('placeholder.ABB7CF@end_time')"
                :default-time="['00:00:00', '23:59:59']"
                style="width:220px"
            ></el-date-picker>
          </el-form-item>
          <!-- 供应商 -->
          <el-form-item>
            <el-select
                collapse-tags
                multiple
                v-model="search.supplier_id"
                remote
                filterable
                @change="changeSupplier"
                clearable
                :placeholder="$t('placeholder.3F6A37@supplier')"
            >
              <el-checkbox style="text-align: right;width: 100%;padding-right: 1vw" @change="selectAllSupplier">
                {{ $t('shop_cart.7E9DE5@check_all') }}
              </el-checkbox>
              <el-option
                  v-for="item in orderSelector"
                  :key="item.company_id"
                  :label="item.company_name"
                  :value="item.company_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 门店 -->
          <el-form-item>
            <el-cascader
                v-model="idArr"
                :options="ShopSelector"
                clearable
                filterable
                :filter-method="shopFilter"
                @clear="clear"
                :props="{children:'shop_list',label:'name',value:'id',checkStrictly:true }"
                @change="getCheckedNodes"
                :placeholder="$t('aboutus.033E98@Please_Select_Restaurant')"
            ></el-cascader>
          </el-form-item>
          <!-- 订单状态 -->
          <el-form-item>
            <el-select
                v-model="search.status_no"
                @change="changeOrderStatus"
                :placeholder="$t('placeholder.D4E968@order_status')"
                clearable
            >
              <el-option :label="$t('public.64203E@to_be_determined')" :value="1"></el-option>
              <el-option :label="$t('orderDetail.EB17E9@Refuse_order')" :value="2"></el-option>
              <el-option :label="$t('orderDetail.73FE8F@Cancel_order')" :value="3"></el-option>
              <el-option :label="$t('public.471669@Confirmed_order')" :value="4"></el-option>
              <el-option :label="$t('public.8E9DAD@Some_accept')" :value="5"></el-option>
              <el-option :label="$t('orderDetail.7879F8@have_received')" :value="6"></el-option>
            </el-select>
          </el-form-item>
          <!-- 支付状态 -->
          <el-form-item>
            <el-select
                v-model="search.pay_status"
                @change="changePayStatus"
                :placeholder="$t('public.180B5F@Payment_status')"
                clearable
            >
              <el-option :label="$t('aboutus.323AC0@Awaiting_Payment')" :value="1"></el-option>
              <el-option :label="$t('aboutus.C7977E@Payment_Receieved')" :value="2"></el-option>
              <el-option :label="$t('aboutus.245620@Overdue')" :value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select
                v-model="search.order_type"
                @change="changeOrderType"
                :placeholder="$t('aboutus.572BF7@Order_Type')"
                clearable
            >
              <el-option :label="'B2B' + $t('backend_dashboard.C8866F@order')" :value="1"></el-option>
              <el-option :label="$t('aboutus.915ECD@General_order')" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
                v-model="search.order_no"
                @blur="changeOrderNo"
                :placeholder="$t('placeholder.5C894A@order_code')"
                @clear="clear"
                clearable
            >
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </el-form-item>
        </el-form>
        <section></section>
      </section>
      <section style="display:flex;justify-content: space-between;">
        <el-form :inline="true" :model="modify" class="demo-form-inline">
          <!--     訂單狀態     -->
          <el-form-item>
            <el-select
                v-model="modify.status_no"
                :placeholder="$t('placeholder.D4E968@order_status')"
                clearable
            >
              <el-option :label="$t('public.64203E@to_be_determined')" :value="1"></el-option>
              <el-option :label="$t('orderDetail.EB17E9@Refuse_order')" :value="2"></el-option>
              <el-option :label="$t('orderDetail.73FE8F@Cancel_order')" :value="3"></el-option>
              <el-option :label="$t('public.471669@Confirmed_order')" :value="4"></el-option>
              <el-option :label="$t('public.8E9DAD@Some_accept')" :value="5"></el-option>
              <el-option :label="$t('orderDetail.7879F8@have_received')" :value="6"></el-option>
            </el-select>
          </el-form-item>
          <!--      付款狀態    -->
          <el-form-item>
            <el-select
                v-model="modify.pay_status"
                :placeholder="$t('public.180B5F@Payment_status')"
                clearable
            >
              <el-option :label="$t('aboutus.323AC0@Awaiting_Payment')" :value="1"></el-option>
              <el-option :label="$t('aboutus.C7977E@Payment_Receieved')" :value="2"></el-option>
              <el-option :label="$t('aboutus.245620@Overdue')" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <!--      付款方式    -->
          <el-form-item>
            <el-select
                v-model="modify.pay_method"
                :placeholder="$t('aboutus.F31309@fee_ways')"
                clearable>
              <el-option :label="$t('public.2E733D@Cash_on_delivery')" :value="1"></el-option>
              <el-option :label="$t('public.5D8689@Delayable_payment')" :value="2"></el-option>
              <el-option :label="$t('public.CC56D2@Designated_monthly_date')" :value="3"></el-option>
              <el-option :label="$t('public.6458EF@credit_card')" :value="4"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="changeStatus">
              {{ $t('aboutus.CAAF92@Download_bulk_edit_Excel') }}
            </el-button>
            <el-button size="small" type="danger" @click="multiOrderDelete">{{ $t('public.CFD51@multi_delete') }}
            </el-button>
          </el-form-item>
        </el-form>
      </section>
      <section style="display:flex;justify-content:flex-start;">
        <el-button size="small" type="success" @click="exportCurrentData">{{ $t('public.F15E31@export_current_data') }}
        </el-button>
      </section>
      <!-- 表格 -->
      <el-table
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          border
          class="mt20"
          :row-class-name="$tableRowClassName"
          :data="tableData"
          :highlight-current-row="true"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column
            type="selection">
        </el-table-column>
        <el-table-column prop="order_no" :label="$t('restaurant_buy_genner.30D594@order_id')"></el-table-column>
        <el-table-column
            prop="is_b2b"
            :label="$t('restaurant_buy_genner.7B0C54@type')"
            width="120px"
        >
          <template scope="scope">
            <span v-if="scope.row.is_b2b == 1">{{ $t('aboutus.6B3562@Marketplace_Order') }}</span>
            <span v-else>{{ $t('aboutus.915ECD@General_order') }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="buyer_shop_name"
            :label="$t('restaurant_buy_genner.FE6497@store')"
            width="100px"
        ></el-table-column>
        <el-table-column
            prop="user_name"
            :label="$t('restaurant_buy_genner.E3B214@place_order_user')"
            width="120px"
        ></el-table-column>
        <el-table-column
            prop="supplier_name"
            :label="$t('restaurant_supplier_products.FEF412@supplier_name')"
        ></el-table-column>
        <el-table-column prop="final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
          <template scope="scope">
            <span v-if="scope.row.final_price">
              {{
                scope.row.final_price
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="order_status"
            :label="$t('restaurant_buy_genner.17E296@order_status')"
            width="120"
        >
          <template scope="scope">
            <span :style="scope.row.order_color | sizeColor">
              {{
                scope.row.order_status
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
            prop="pay_status"
            :label="$t('restaurant_buy_genner.759F57@pay_status')"
        >
          <template scope="scope">
            <section :style="scope.row.pay_colour | sizeColor">
              <div v-if="scope.row.order_color==1 && scope.row.pay_colour==1">
                {{ `${scope.row.pay_status}(${scope.row.pay_method})` }}
              </div>
              <div v-else>{{ scope.row.pay_status }}</div>
              <div v-show="scope.row.payment_due">({{ scope.row.payment_due }})</div>
            </section>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
        <el-table-column prop="option" :label="$t('public.03EA09@operation')" min-width="100">
          <template slot-scope="scope">
            <el-row>
              <KtButton
                  type="primary"
                  :label="$t('invoice.FD5EA1@download_pdf')"
                  :perm="16371481789058"
                  style="margin-bottom: 5px"
                  @click="getPDF(scope.row.order_no)"
              ></KtButton>
            </el-row>
            <el-row>
              <KtButton
                  type="warning"
                  :label="$t('public.653358@view')"
                  :perm="15792559729133"
                  style="margin-bottom: 5px"
                  @click="rowClick(scope.row)"
              ></KtButton>
            </el-row>
            <el-row>
              <KtButton
                  type="info"
                  :label="$t('public.1FBB5E@btn_delete')"
                  :perm="16184117215378"
                  style="margin-bottom: 5px"
                  @click="deleteOrder(scope.row.oid)"
              ></KtButton>
            </el-row>
            <el-row>
              <KtButton
                  type="danger"
                  :label="$t('public.B52DEF@resend_order_email')"
                  :perm="16221288919168"
                  style="margin-bottom: 5px"
                  @click="resendOrderEmail(scope.row.oid, scope.row.buyer_company_id)"
              ></KtButton>
            </el-row>
            <el-row>
              <KtButton
                  type="success"
                  :label="$t('public.B52DE5@resend_order_whatsapp')"
                  :perm="16221309927383"
                  @click="resendOrderWhatsApp(scope.row.order_no, scope.row.buyer_company_id)"
              ></KtButton>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <section style="margin-top:20px;display:flex;justify-content:flex-end;">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>

      </section>
    </el-card>
    <!-- dialog -->
    <el-dialog
        :title="$t('public.553159@tips')"
        :visible.sync="centerDialogVisible"
        width="25%"
        center
    >
      <span slot="title" class="dialog-title">
        {{
          $t('restaurant_buy_genner.97F49A@created_order')
        }}
      </span>
      <div class="category">
        <div class="title">{{ $t('restaurant_buy_genner.99D144@type') }}</div>
        <div class="mt20">
          <el-radio-group v-model="supplierType" @change="getSupplierType">
            <el-radio :label="1">
              {{
                $t('restaurant_buy_genner.13B04D@Optional_supplier')
              }}
            </el-radio>
            <el-radio class="ml50" :label="2" :disabled="isdisabled">B2B</el-radio>
          </el-radio-group>
        </div>
        <div class="title mt20">{{ $t('restaurant_buy_genner.59739F@choose_supplier') }}</div>
        <el-select
            class="mt20 input"
            v-model="supplier_id"
            @change="selectSupplier"
            @focus="decideType"
            size="small"
            :placeholder="$t('placeholder.C209ED@select')"
        >
          <el-option
              v-for="item in SupplierSelect"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
          ></el-option>
        </el-select>
      </div>
      <span class="notice mt20">
        {{
          $t('restaurant_buy_genner.4C75AE@tips')
        }}
      </span>
      <span slot="footer" class="dialog-footer">
        <b-btn class="table-btn btn-yellow" @click="goAddOrder">
          {{
            $t('restaurant_buy_genner.452B30@created')
          }}
        </b-btn>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import {Message} from "element-ui";
import JSZipUtils from "jszip-utils";
import PizZip from "pizzip";
import {saveAs} from "file-saver";
import ImageModule from 'docxtemplater-image-module-free'

export default {
  data() {
    return {
      lang: this.$i18n.locale == 'hk',
      multipleSelection: [],
      modify: {
        status_no: "",
        pay_status: "",
        pay_method: "",
      },
      search: {
        datetime: "",
        supplier_id: [],
        restaurant_id: "",
        order_no: "",
        status_no: this.$route.query.unfinish || "",
        shop_id: "",
        pay_status: this.$route.query.unpay || "",
        order_type: "",
      },
      supplierType: "",
      centerDialogVisible: false,
      datetime: "",
      type: "",
      orderSelector: [],
      SupplierSelect: [],
      isdisabled: false,
      supplier: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      supplier_id: "",
      ShopSelector: [],
      idArr: [],
    };
  },
  components: {
    pageTop,
    KtButton
  },
  filters: {
    sizeColor: function (value) {
      return `color:${value == 1 ? "green" : value == 2 ? "#f2cd33" : value == 3 ? "#f24040" : ""}`;
    }
  },
  created() {
    // this.search.pay_status =
    this.getTableData();
    this.getB2bOrderSupplier()
    this.getB2bOrderRestaurant()
  },
  methods: {
    //下載PDF
    async getPDF(orderNo) {
      if (orderNo) {
        this.$http.getOrderInfoForPDF({
          order_no: orderNo
        }).then(res => {
          let data = res.data
          let extension = data.ext
          let info = {}
          let that = this
          let image = new Image()
          image.onload = function () {
            let canvas = document.createElement('canvas')
            canvas.width = 200
            canvas.height = 200
            let context = canvas.getContext('2d')
            context.scale(200 / canvas.width, 200 / canvas.height)
            context.drawImage(image, 0, 0, 200, 200)
            let dataUrl = canvas.toDataURL(`image/${extension}`)
            info.logo = dataUrl
            info.order_no = data.order_no
            info.supplier_name = data.seller_company_name
            info.supplier_phone = data.seller_company_phone
            info.buy_company_name = data.receiver_company_name
            info.buy_shop_name = data.buyer_shop_name
            info.buy_shop_phone = data.buyer_shop_phone
            info.buy_address = data.receiver_address
            info.create_time = data.create_time
            info.goods_price = data.receive_goods_price
            info.fee = data.receive_deliver_fee
            info.order_price = data.final_price
            info.list = []
            data.goods_list.forEach((g, k) => {
              info.list.push({
                no: k + 1,
                gid: g.gid,
                goods_name: g.goods_name,
                goods_spec: g.sku_name,
                unit_str: `${g.receive_num}/${g.unit_name}`,
                goods_price: g.receive_unit_price,
                total_price: g.final_goods_price,
                buy_num: g.buy_num,
                recive_num: g.receive_num,
              })
            })
            let path = that.lang ? "/order_ch.docx" : "/order_en.docx"
            JSZipUtils.getBinaryContent(path, (error, content) => {
              if (error) {
                Message.warning('轉檔失敗，請聯繫工程師進行修復!')
                return
              }

              let opts = {}
              opts.centered = false //Set to true to always center images
              opts.fileType = "docx" //Or pptx
              opts.getImage = function (chartId) {
                return base64DataURLToArrayBuffer(chartId)
              }

              //Pass the function that return image size
              opts.getSize = function () {
                // 設定圖片大小
                return [100, 100]
              }
              const imageModule = new ImageModule(opts)
              //set content
              let zip = new PizZip(content)
              //doc
              let docxtemplater = require("docxtemplater")
              let doc = new docxtemplater()
              doc.attachModule(imageModule)
              doc.loadZip(zip)
              doc.setData(info)
              try {
                doc.render()
                let word = doc.getZip().generate()
                that.$http.exportInvoiceToPDF({
                  file: word,
                }).then(res => {
                  that.downloadPDF(res, `${data.order_no}.pdf`)
                })
              } catch (e) {
                Message.warning('轉檔失敗，請聯繫工程師進行修復!')
                return
              }
            })
          }
          image.src = `data:image/${extension};base64,${data.supplier_logo}`

          function base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/
            if (!base64Regex.test(dataURL)) {
              return false
            }

            const stringBase64 = dataURL.replace(base64Regex, "")
            let binaryString;
            if (typeof window !== "undefined") {
              binaryString = window.atob(stringBase64);
            } else {
              binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              const ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }
            return bytes.buffer;
          }
        })
      }
    },
    shopFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    //匯出當前資料
    exportCurrentData() {
      this.$http.exportOrderExcel({
        supplier_id: JSON.stringify(this.search.supplier_id),
        restaurant_id: this.search.restaurant_id || "",
        shop_id: this.search.shop_id,
        pay_status: this.search.pay_status,
        order_type: this.search.order_type,
        status_no: this.search.status_no,
        order_no: this.search.order_no,
        start_time: this.search.datetime[0] / 1000 || "",
        end_time: this.search.datetime[1] / 1000 || ""
      }).then(res => {
        this.downloadFile(res)
      })
    },
    //大量刪除訂單狀態
    multiOrderDelete() {
      let that = this

      function getData() {
        let data = []
        that.multipleSelection.forEach(s => {
          data.push(s.oid)
        })
        return data
      }

      if (!getData().length) {
        Message.warning("請選擇要刪除的訂單")
        return
      }

      this.$http.multiOrderDelete({
        order_json: JSON.stringify(getData()),
      }).then(res => {
        if (res.errno == 0) {
          this.multipleSelection = []
          this.getTableData()
        }
      })
    },
    //大量修改訂單狀態
    changeStatus() {
      let that = this

      function getData() {
        let data = []
        that.multipleSelection.forEach(s => {
          data.push(s.oid)
        })
        return data
      }

      if (!getData().length) {
        Message.warning("請選擇要修改的訂單")
        return
      }

      if (!this.modify.status_no && !this.modify.pay_status && !this.modify.pay_method) {
        Message.warning("請選擇要修改的訂單狀態")
        return
      }

      this.$http.multiChangeOrderStatus({
        order_json: JSON.stringify(getData()),
        status_no: this.modify.status_no,
        pay_status: this.modify.pay_status,
        pay_method: this.modify.pay_method
      }).then(res => {
        if (res.errno == 0) {
          this.multipleSelection = []
          this.modify.status_no = ""
          this.modify.pay_method = ""
          this.modify.pay_status = ""
          this.getTableData()
        }
      })
    },
    handleSelectionChange(v) {
      this.multipleSelection = v
    },
    //重新派送訂單WhatsApp
    resendOrderWhatsApp(order_no, company_id) {
      this.$http.resendOrderWhatsApp({
        order_no: order_no,
        company_id: company_id
      })
    },
    //重新派送訂單郵件
    resendOrderEmail(oid, company_id) {
      this.$http.resendOrderEmail({
        oid: oid,
        company_id: company_id
      })
    },
    deleteOrder(oid) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel"),
          }
      ).then(() => {
        this.$http.deleteOrder({oid: oid}).then(res => {
          if (res.errno == 0) {
            this.getTableData()
          }
        })
      })
    },
    //選取所有的供應商
    selectAllSupplier(e) {
      //清空供應商清單
      this.search.supplier_id = []
      if (e) {
        this.orderSelector.forEach(c => {
          this.search.supplier_id.push(c.company_id)
        })
      }
    },
    //供應商改變時
    changeSupplier() {
      this.currentPage = 1
      this.getTableData()
    },
    //訂單狀態改變時
    changeOrderStatus() {
      this.currentPage = 1
      this.getTableData()
    },
    //支付狀態改變時
    changePayStatus() {
      this.currentPage = 1
      this.getTableData()
    },
    //訂單種類改變時
    changeOrderType() {
      this.currentPage = 1
      this.getTableData()
    },
    //訂單編號改變時
    changeOrderNo() {
      if (this.currentPage != 1) {
        this.currentPage = 1
      }
      this.getTableData()
    },
    //获取数据
    getTableData() {
      this.loading = true;
      let params = {
        size: this.pageSize,
        page: this.currentPage,
        supplier_id: JSON.stringify(this.search.supplier_id),
        restaurant_id: this.search.restaurant_id || "",
        shop_id: this.search.shop_id,
        pay_status: this.search.pay_status,
        order_type: this.search.order_type,
        status_no: this.search.status_no,
        order_no: this.search.order_no,
        start_time: this.search.datetime[0] / 1000 || "",
        end_time: this.search.datetime[1] / 1000 || ""
      };

      this.$http.getOrderList(params).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.sum;
      });
    },
    getB2bOrderRestaurant() {
      this.$http.getB2bOrderRestaurant().then(res => {
        this.ShopSelector = res.data
      })
    },
    getB2bOrderSupplier() {
      this.$http.getB2bOrderSupplier().then(res => {
        this.orderSelector = res.data
      })
    },
    //单价输入框
    inputPrice(row, index) {
      row = {
        order_gid: row.order_gid,
        unit_price: row.unit_price,
        buy_num: row.buy_num
      };

      if (this.goods_json.length) {
        this.goods_json.forEach((v, i) => {
          if (v.order_gid == row.order_gid) {
            this.goods_json.splice(i, 1);
          }
        });
        this.goods_json.push(row);
      } else {
        this.goods_json.push(row);
      }
      // console.log(this.goods_json);
    },
    //判断是否勾选了供应商类型
    decideType() {
      if (!this.supplierType) {
        this.$message.warning(
            this.$t("shop-goods.3D33DE@Please_tick_the_category")
        );
      }
    },
    //计数器
    handleChange(row, index) {
      row = {
        order_gid: row.order_gid,
        unit_price: row.unit_price,
        buy_num: row.buy_num
      };

      if (this.goods_json.length) {
        this.goods_json.forEach((v, i) => {
          if (v.order_gid == row.order_gid) {
            this.goods_json.splice(i, 1);
          }
        });
        this.goods_json.push(row);
      } else {
        this.goods_json.push(row);
      }
      // console.log(this.goods_json);
    },
    //选择供应商
    selectSupplier(e) {

      this.SupplierSelect.forEach((v, i) => {
        if (v.company_id == e) {
          this.supplier = v;
        }
      });
    },
    // 打开弹框
    add() {
      this.supplier_id = "";
      this.supplierType = "";
      this.SupplierSelect = [];
      this.centerDialogVisible = true;
    },
    getSupplierType(val) {
      this.getSupplierSelect();
    },
    // 弹框确认
    goAddOrder() {
      if (!this.supplier_id) {
        this.$message.warning(
            this.$t("shop-goods.1213D7@Please_select_supplier")
        );
        return;
      }
      this.centerDialogVisible = false;
      this.$setlocalStorage(
          "supplierType",
          JSON.stringify({
            supplierType: this.supplierType,
            supplier: this.supplier
          })
      );
      this.$router.push("AddOrder");
    },
    //供应商选择器
    getSupplierSelect() {
      this.supplier_id = "";
      this.$http.supplierSelector({type: this.supplierType}).then(res => {
        // console.log(res);
        this.SupplierSelect = res.data;
      });
    },
    clear() {
      this.currentPage = 1
      this.getTableData();
    },
    // 去详情页
    rowClick(row) {
      // console.log("row==>", row);
      this.$router.push({
        name: "orderDetail",
        query: {
          order_no: row.order_no,
          type: row.is_b2b == "YES" ? 1 : 0
        }
      });
    },
    getCheckedNodes(idArr) {
      // console.log(idArr)
      this.search.restaurant_id = idArr[0];
      this.search.shop_id = idArr[1] || "";
      this.currentPage = 1
      this.getTableData();
    },
    //时间搜索
    changeDatetime(val) {
      this.search.datetime = val || "";
      this.currentPage = 1
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.box-card {
  display: flex;

  .choose-item {
    display: flex;
    align-items: center;

    .item-one {
      margin-right: 20px;
      max-width: 18%;

      .item-title {
        padding-right: 20px;
      }
    }

    .item-one.sel {
      width: 12%;
    }

    .choose-btn {
      width: 80px;
      height: 32px;
      border: none;
      background-image: linear-gradient(
              -90deg,
              #ff9805 0%,
              #f2cd33 100%
      ) !important;
      color: #fff;
      margin-left: 20px;
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.dialog-title {
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  display: block;
  line-height: 16px;
  font-size: 16px;
  color: #333333;
}

.notice {
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
}

.input {
  width: 80%;
}
</style>
