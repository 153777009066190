/*
 * @Author: your name
 * @Date: 2019-08-31 18:57:10
 * @LastEditTime: 2019-11-15 17:45:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\i18n\lang\en.js
 */
import enLocale from "element-ui/lib/locale/lang/en";

export default {
    ...enLocale, 'AppGuideline': {
        'title': "App Guideline",
        'AppGuidelineCategory': 'App Guideline Category',
        'AppGuidelineList': 'App Guideline List',
    }, 'specialArea': {
        'title': 'Special Area', 'groupBuy': 'Group Buy', 'discount': 'Discount', 'goods_picture': 'Goods Picture',
    }, "register_user": {
        "title": 'Register User',
        "restaurant_user": "Restaurant Register User",
        "brc": "Business Registration Certificate",
        "br_no": "BR Number",
        "br_address": "BR Address",
        "br_pic": "BR Picture",
        "company_address": "Company Address",
        "approve": "Approve",
        "reject": "Reject",
    }, "restaurant_classify": {
        "title": "Restaurant Classify",
    }, "homeVideo": {
        "title": "Home Video", "video_id": "Video ID",
    }, OrderUser: {
        title: "Order User",

    }, "hidden_or_show": {
        "EAD5E1@display_all_supplier": "Display All Supplier",
        "DE2D1E@display_none_hidden_supplier": "Display none hidden of Supplier",
        "DFE51A@display_hide_supplier": "Display Hidden of Supplier",
        "EAD5E1@display_all_restaurant": "Display All Restaurant",
        "DE2D1E@display_none_hidden_restaurant": "Display none hidden of Restaurant",
        "DFE51A@display_hide_restaurant": "Display Hidden of Restaurant",
        "FEA51D@show_label": "Show to which restaurants to see",
        "AD5E46@tip": "When no restaurant is selected, it will be visible to all restaurants",
    }, "product_delivery": {
        'F5EA13@product_delivery': "Product Delivery",
        'AD58EF@supplier_direct_shipping': "Supplier Direct Shipping",
        'F5E1A3@in_store_pickup': "In-store Pickup",
    }, statistics: "Statistics", AverageOrderPrice: {
        AverageOrderPrice_tit: "Average Order Price", AverageOrderPrice_content: {
            restaurant: "Restaurant",
            ChooseARestaurant: "Choose A Restaurant",
            ChooseSupplier: "Choose A Supplier",
            all: "All",
            general: "General",
            supplier: "Supplier",
        },
    }, KeyWords: {
        KeyWords_tit: "Key Words", KeyWords_content: {
            KeyWords: "Key Words", frequency: "frequency",
        },
    }, ActiveUser: {
        ActiveUser_tit: "Active User", ActiveUser_content: {
            OrderNumber: "Order Number",
            RestaurantName: "Restaurant Name",
            StoreName: "Store Name",
            last_login_time: "Last Login Time",
            current_login_time: "Current Login Time",
        },
    }, CumulativeSalesVolume: {
        CumulativeSalesVolume_tit: "Order Quantity And Amount", CumulativeSalesVolume_content: {
            TotalSales: "Sales Amount", SalesAmount: "Total Sales",
        },
    }, LoginStatistics: {
        LoginStatistics_tit: "Login Statistics", LoginStatistics_content: {
            NumberOfLogins: "Number Of Logins",
        },
    }, GoodsSales: {
        GoodsSales_tit: "Commodity Sales",
    }, CommodityAnalysis: {
        CommodityAnalysis_tit: "Commodity Clicks / Purchases", CommodityAnalysis_content: {
            TheNumberOfClicks: "The Number Of Clicks", Purchases: "Purchases", Sales: "Sales Figures",
        },
    }, FunctionUsageRecord: {
        FunctionUsageRecord_tit: "Function Usage Record", FunctionUsageRecord_content: {
            FunctionName: "Function Name", UsageCount: "Usage Count", NumberOfRestaurants: "Number Of Restaurants",
        },
    }, rating: {
        "AED585@rating_manage": "Rate Manage",
        "ED2551@product_reviews": "Product Reviews",
        "ADE215@top": "Top",
        "ED215F@bottom": "Bottom",
        "D54EA1@sortBy": "Sort By",
        "AE2121@filterBy": "Filter by",
        "BF215E1@All_Reviews": "All Reviews",
        "D545F1@Display_Reviews": "Display Reviews",
        "F21565@Not_Display_Reviews": "Not Display Reviews",
        "A12E51@one_star_only": "1 Star Only",
        "A12E52@two_star_only": "2 Star Only",
        "A12E53@three_star_only": "3 Star Only",
        "A12E54@four_star_only": "4 Star Only",
        "A12E55@five_star_only": "5 Star Only",
        "E2DF56@all": "All",
        "F54645@filter": "Filter",
        "B25ED5@Approve": "Approve",
        "A2575E@Hide": "Hide",
    }, invoice: {
        "FD5EA1@download_pdf": "Download PDF",
        "BA1FF5@free": "Free",
        "AE5D11@manage_invoice": "Manage Invoice",
        "AE64AD@menu": "Invoice Panel",
        "F9100D@invoice_date": "Invoice Date",
        "0DE312@invoice_number": "Invoice Number",
        "A886987@gmv": "GMV",
        "EA5264@cost_ratio": "%",
        "9E1612@invoice_amount": "Invoice Amount",
        "84D521@pay_status": "Payment Status",
        "ED8512@click_to_see_invoice": "Click to see invoice",
        "F5D21D@unpaid": "Unpaid",
        "A8312E@paid": "Paid",
        "85EA12@click_to_email_client": "Click to email client",
        "E5712A@export_current_month_btn": "Export this month's invoice to Excel",
        "E86FF82@export_current_year_btn": "Export this year's invoice to Excel",
    }, MarketRank: {
        "FA6851@start": "Started",
        "B1122F@end": "Ended",
        "AE8188@unstarted": "Unstarted",
        "E3564A@name": "Supplier and Product ranking",
        "A85E67@soft_content": "The smaller the sort value, the earlier it will be displayed, otherwise the later it will be displayed",
    }, payment: {
        "A3DE8B@placeholder": "Please input payee ID", "B3DEED@payee_id": "Payee ID",
    }, pos: {
        "DD6478@title": "Manage POS", "64C88A@enable_pos": "Enable POS", "9A6E1F@pos_id": "POS ID",
    }, whatsapp_group: {
        "9EFF12@name": "WhatsApp groups for restaurants",
        "6AE824@group_id": "Group ID",
        "C1E643@placeholder": "Please input WhatsApp Group ID",
    }, status: {
        "861FAE@enable": "Enable", "EA56D8@disable": "Disable",
    }, backend_web_adv: {
        "Z25E52@web_adv": "Web Advertisement",
        "E52D69@class1": "Offer 1",
        "E52D70@class2": "Offer 2 slider",
        "E52D71@class3": "Offer 3",
        "E52D72@class4": "Offer 4",
        "E52D73@class5": "Offer 5",
        "E52D74@class5_slider": "Offer 5 slider",
        "E52D75@class6": "Offer 6",
    }, backend_authrity_authrityList: {
        "A7F8C9@backend_tab": "Backend",
        "C90CD7@restaurant_tab": "Restaurant",
        "85EDE9@supplier_tab": "Supplier",
        "B2EBC3@authrity_id": "Authrity Id",
        "1DB5E9@simplified_Chinese": "Simplified Chinese",
        "A75174@Traditional_Chinese": "Traditional Chinese",
        "B25958@english": "English",
        "D22040@router": "Router",
        "468002@api": "API",
        "12349D@type": "Type",
        "0310FF@Superior": "Superior",
    }, backend_authrity_grouping: {
        "31BA13@grouping_id": "Group ID",
        "5EDDBF@grouping_name": "Permission Group",
        "4A9FA6@isSuper": "Superadmin",
        "26BE7D@admin": "Admin",
        "28C052@store": "Branch",
        "422A88@delivery_date": "Please select delivery date",
        "B3402A@edit_name": "Please fill in the group name",
        "F1B23F@edit_role": "Please Select Roles",
        "422561@edit_store": "Please Select Branch",
    }, backend_authrity_user: {
        "9A4F2F@username": "Username",
        "8319AA@nickname": "Nickname",
        "CA6925@avatar": "Profile Picture",
        "29E7EE@last_ip": "Last Login IP",
        "BAF37C@last_login_time": "Last Login Time",
        "07D89C@status": "Status",
        "BBA818@grouping_id": "Staff Type",
        "948EEB@password": "Password",
        "B5AC7E@isSuper": "Super Admin",
    }, backend_authrity_operationLog: {
        "8CF925@operationUser": "User",
        "3531D8@Controller": "Controller",
        "F5AD79@methods": "Methods",
        "AC377D@operation_info": "Usage Info",
        "E3B71C@operation_time": "Operation Time",
    }, public: {
        "unconfirmed_order": "Unconfirmed Orders",
        "asia_miles": "Asia Miles",
        "asia_miles_membership_no": "Asia Miles Membership Number",
        "locale": "Locale",
        "bank_info": "Bank Information",
        "A1E5C5@reset_password": "Reset Password",
        "F15E31@export_current_data": "Export Current Data",
        "FE11E4@add_goods": "Add Product",
        "CFD51@multi_delete": "Multi Delete Order",
        "ADF5E@email_tip": 'There are multiple Emails, you can separate them by "comma" / "semicolon" or "newline""',
        "B52DE5@resend_order_whatsapp": "Reissue Order to WhatsApp",
        "B52DEF@resend_order_email": "Reissue Order to Email",
        "A1F551@last_payment_day": "Last payment date",
        "9AF601@unit_converter": "Unit Converter",
        "56E9D8@apply": "Apply",
        "98E123@group_name_hk": "Group Name(Chinese)",
        "A5E465@group_name_en": "Group Name(English)",
        "DC9752@btn_add": "Add New",
        "B6D57F@btn_modify": "Edit",
        "1FBB5E@btn_delete": "Delete",
        "2C74E6@btn_delete_more": "Batch Deletion",
        "711B38@serial_number": "Serial Number",
        "03EA09@operation": "Action",
        "25F6B0@tips": "Cannot Edit Permission or Delete Superadmin",
        "D17BCA@created_time": "Date",
        "E1A718@update_time": "Update Time",
        "564C21@unlock": "Unlock",
        "DBC110@locked": "Locked",
        "72A46B@reset_pass": "Reset Password",
        "A738BA@update": "Update",
        "B3669C@show": "Show",
        "8B9314@hide": "Hide",
        "4ABAB1@loading": "Loading",
        "84FA27@no_pictures": "No Pictures",
        "549A91@edit": "Edit",
        "3B45A2@confirm": "Confirm",
        "836FD4@cancel": "Cancel",
        "D74E99@simplified_chinese": "Simplified Chinese",
        "51D01B@traditional_chinese": "Traditional Chinese",
        "154BAB@english": "English",
        "A82133@username": "Username",
        "BB249F@supplier_name": "Supplier",
        "BFFB0F@creation_time": "Date",
        "97656C@Download_the_product_excel_template": "Download Excel Template",
        "6796E1@Download_product_excel": "Export to Excel",
        "37249B@pload_supplier_products": "Mass Upload",
        "507DF9@new_attribute": "New Attribute",
        "C82510@order_amount": "Order Amount",
        "180B5F@Payment_status": "Payment Status",
        "83746D@download_excel": "Download Template",
        "32355B@download_excel": "Export to Excel",
        "8D75D5@Hong_Kong_Administrative_Region": "District",
        "93EC9B@upload": "Mass Upload",
        "417BDC@E-mail": "Email",
        "821DEE@E-mail_order": "Order Notification E-Mail",
        "A98F12@E-mail_message": "New Message Notification E-Mail",
        "6EF123@E-mail_invoice": "Invoice Email",
        "2E733D@Cash_on_delivery": "Cash on Delivery",
        "5D8689@Delayable_payment": "Credit Term",
        "CC56D2@Designated_monthly_date": "Monthly Settlement",
        "6458EF@credit_card": "Credit Card",
        "653358@view": "View",
        "B8C509@Order_user": "Order User",
        "427B43@Minimum_amount": "Minimum Amount",
        "6D13C6@delete_tip": "Confirm Deletion?",
        "E91E1D@addr_hk": "Address(Chinese)",
        "966929@addr_en": "Address(English)",
        "4BF093@desc_hk": "Description(Chinese)",
        "90B2DC@desc_en": "Description(English)",
        "1A3EBC@save": "Save",
        "553159@tips": "Tips",
        "B6E56E@no_attr": "No Specification",
        "707A1E@logout": "Logout",
        "2FC133@modify_pass": "Change Password",
        "5AD435@Total": "Total Amount Payable (including Delivery Fee)",
        "AE2731@select_product": "Please Select  Product",
        "733AA9@Network": "Network error, please try again later!",
        "4F36DD@Operation_successful": "Operation Successfully！",
        "4A5563@min_number": "Minimum Purchase Quantity",
        "64203E@to_be_determined": "Pending Confirmation",
        "471669@Confirmed_order": "Confirmed",
        "8E9DAD@Some_accept": "Some Accepted",
        "4E63B1@unread": "Unread",
        "0E51AE@changeProduct": "Change Product",
        "A5864E@changeOrder": "Change Order",
        "5E1AAE@changeDeliverFee": "Change Deliver Fee",
    }, placeholder: {
        "524620@start_time": "Start Time",
        "ABB7CF@end_time": "End Time",
        "C209ED@select": "Please Select",
        "9A92DD@username": "Username",
        "F0DDC0@select": "Please Select",
        "3F6A37@supplier": "Select Supplier",
        "D4E968@order_status": "Order Status",
        "5C894A@order_code": "Order No.",
        "7C1BF7@search_key": "Search for Products or Keywords",
        "F8EA00@select_type": "Choose Type",
        "D20BE9@Please_input": "Please Input",
    }, backend_submenu: {
        "C89D43@custom_restaurant_auth_group": "Custom Restaurant Auth Group",
        "ABAAF2@dashboard": "Dashboard",
        "ED96C7@authrity": "Permissions Control",
        "4A302E@authrityList": "Permission List",
        "21BF37@grouping": "Permission Group",
        "61A3ED@user": "Username",
        "6A0B0E@Operation_record": "Usage Record",
        "0FED78@config": "Config",
        "0265E5@Advertisement": "Advertisement",
        "157A9D@platform_Info": "Platform Information",
        "4F09AA@index": "Home  Category",
        "49DFF3@integral": "Integral",
        "AD8E7D@Merchant_man": "Merchant Management",
        "411879@restaurant_list": "Restaurant List",
        "BE2794@supplier_list": "Supplier List",
        "AE5F11@export_supplier_product_list": "Export Supplier Product List",
        "BCC156@export_supplier_list": "Export Supplier List",
        "BCC157@export_@restaurant_list": "Export Restaurant List",
        "100AE5@Merchant_user_management": "Merchant User",
        "5815A5@restaurant_permission_management": "Restaurant Permission Management",
        "526167@restaurant_user_man": "Restaurant User",
        "92671D@supplier_user_man": "Supplier User",
        "96B8E2@Product_classification": "Category",
        "A15265@add_order": "Add order",
        "A15264@order_manage": "Order Managenent",
        "EF42B5@email_list": "Email List",
        "DD811F@payment_list": "Payment Manage",
    }, backend_config_Advertisement: {
        "0672CC@ad_id": "Advertisement ID",
        "D34399@ad_type": "Advertisement Type",
        "88A5B8@ad_title": "Title",
        "88A6D5@ad_url": "Jump link",
        "46F8F7@isShow": "open or not",
        "1E4172@sort": "Sort",
        "8A317E@photo": "Advertising Picture",
        "23FE59@title_en": "Title(English)",
        "005381@title_hk": "Title(Chinese)",
        "EA667C@top_banner": "First Page Top Carousel",
        "136CD4@bottom_banner": "First Page Bottom Carousel",
        "778D25@med_banner": "4 Advertisements in the middle of the front page",
    }, backend_config_platformInfo: {
        "837359@email": "Email",
        "936621@phone": "Contact Number",
        "607689@Copyright": "Copyright",
        "D6C85F@bottomLogo": "Logo at the bottom of the home page",
    }, backend_config_home: {
        "1DC9DD@classify_id": "Category ID",
        "9266EE@classify_photo": "Category Pictures",
        "47380E@sort": "Sort",
        "966A47@classify_name": "Category  Name",
        "A7684E@isShow": "Show or not",
    }, backend_config_integral: {
        "BEC1AD@bonus_rate": "Bonus Point Proportion",
        "0B80C2@deduce_rate": "Percentage of Points Deducted",
        "160EFC@isBonus": "Whether to open bonus points",
    }, backend_shop_supplierList: {
        "B85EE6@display_name": "Display Name",
        "B32F11@display_name_hk": "Display Name(Chinese)",
        "A36EFF@display_name_en": "Display Name(English)",
        "8F71F5@company_id": "Company ID",
        "995038@company_name": "Company Name",
        "E919F3@super_user": "Company Super User",
        "5A75EE@Contact_person": "Contact Person",
        "F9618A@company_email": "Company Email",
        "65868F@company_phone": "Company Telephone",
        "760B82@company_status": "Status",
        "51CC83@company_desc": "Company Introduction",
        "D4ED09@registration_time": "Registration Time",
        "F703D1@company_name_hk": "Company Name(Chinese)",
        "394FBB@company_name_en": "Company Name(English)",
        "B08D37@addr_hk": "Address(Chinese)",
        "EFBD4B@addr_en": "Address(English)",
        "5AEC72@remark_hk": "Remark(Chinese)",
        "EF978E@remark_en": "Remark(English)",
    }, backend_shopUser_supplierUser: {
        "E916BD@company_name": "Company Name",
        "CB120A@user_id": "User ID",
        "26E957@username": "Username",
        "062848@nickname": "Nickname",
        "9CD9E0@status": "Status",
        "B3BDE4@isSuper": "Super User",
        "82F60F@last_login_time": "Last Login Time",
    }, backend_productClassify: {
        "CFB3A7@classify_id": "Category ID",
        "3CAC83@classify_name": "Category  Name",
        "ED8AF1@status": "Status",
        "B39EE1@classify_photo": "Category Pictures",
        "1CDE5B@classify_name_hk": "Product Category (Chinese)",
        "667CB5@classify_name_en": "Product Category (English)",
        "EB6F82@add_fast": "Quick Add Category",
        "F93B5D@default": "Default top-level menu",
    }, backend_emailList: {
        "1892CE@email_type": "Mailbox Type",
        "A062DF@email_title": "Mail Title",
        "D7675B@send_address": "Sending Email Address",
        "28EE7F@receive_address": "Receive Email Adress",
        "C31D79@isReaded": "Is it read",
        "0C2F58@send_time": "Send Email Time",
        "B1D7F4@read_time": "Read Time",
    }, supplier_power_grouping: {
        "3FD60B@Rights_group": "Rights Group",
        "C9B87D@grouping_id": "Group ID",
        "4AE8C4@Group_name": "Full/Part Time",
        "A70BF8@super_administrator": "Super Admin",
        "0E5FF1@operation": "Action",
        "25BFF5@super_administrator_notmodify": "Super Admin cannot be edited",
    }, supplier_power_childUser: {
        "DE359D@nickname": "Nickname",
        "825849@head_portrait": "Profile Picture",
        "B2778D@super_user": "Super Admin",
        "AADC1A@LastLogin_Time": "Last Login Time",
        "F18109@state": "Status",
        "65D941@Subuser_management": "User Management",
        "D9FF40@Last_login_IP": "Last Login IP",
    }, backend_dashboard: {
        "E2556A@total_gmv": "Total GMV",
        "E2556B@current_year_gmv": "Current Year GMV",
        "E2556C@current_month_gmv": "Current Month GMV",
        "E2556D@total_order_count": "Total Order Count",
        "E2556E@current_year_order_count": "Current Year Order Count",
        "E2556F@current_month_order_count": "Current Month Order Count",
        "E2556G@total_order_avg": "Total Order Average Price",
        "E2556H@current_year_order_avg": "Current Year Order Average Price",
        "E2556I@current_month_order_avg": "Current Month Order Average Price",
        "E2556J@active_restaurant_count": "Active Restaurant Count",
        "E2556K@supplier_count": "Supplier Count",
        "A12475@top_ten_keywords": "Current Month Top 10 Keywords",
        "A12476@top_ten_purchase_restaurant": "Current Month Top 10 Purchase Restaurant",
        "A12477@top_ten_sale_good": "Current Month Top 10 Sale Good",
        "C8866F@order": "Order",
        "BF8D82@unfinished_order": "Outstanding Order",
        "FD5734@restaurant": "Number of Branches",
    }, supplier_submenu: {
        "AD3C65@commodity_management": "Product Management",
        "B77F3A@authority_management": "Permissions Control",
        "99DB4A@Setting": "Settings",
        "12FC12@control_panel": "Dashboard",
    }, supplier_supplier_produceManage: {
        "4E60F8@Management_products": "Product Listing",
        "F12B2D@input_the_key_words": "Input the key words",
        "C84B53@goods_ID": "Product ID",
        "8D36FA@classification_of_goods": "Category",
        "ADD410@Commodity_images": "Image",
        "753635@price": "Price",
        "72E6C1@putaway": "Active",
        "4D7AF5@Quantity_unit": "Unit",
        "B8BD33@product_name": "Product",
    }, restaurant_submenu: {
        "E0E5FB@dashboard": "Dashboard",
        "1B2E2F@authrity": "Permissions Control",
        "BA20D2@Privilege_grouping": "Permission Group",
        "896D7E@Sub_user": "User Management",
        "65D701@Operation_record": "Usage Record",
        "2C231F@store_management": "Branch Management",
        "41AF69@Commodity_classification": "Product Categories",
        "FA62B7@supplier": "Supplier",
        "0F2640@supplier_list": "Supplier List",
        "8F6D4D@supplier_products": "Supplier Products",
        "BFB6EB@commodity_attr": "Product Specification",
        "D74010@procurement": "Procurement",
        "396A54@general_order": "Order Management",
        "D6B9A2@Optional_products": "Create Order",
        "2B68B6@b2b_product": "B2B Product",
        "E6A81C@Inventory": "Inventory",
        "C58F2C@accounting": "Accounting",
        "59F88B@Integral_record": "Integral Record",
        "8F506E@company_info": "Company Infomation",
        "446088@setting": "Settings",
        "5409DA@add_supplier": "Edit Supplier",
        "6B6530@add_supplier_editSupProduct": "Edit Supplier Product",
        "90D6D1@order_det": "Order Detail",
    }, supplier_supplier_goodsAttribute: {
        "49D281@commodity_property": "Product Specification",
        "E416AD@attribute_name": "Product Specification",
        "D0456F@attribute_value": "Type",
        "3FB998@Attribute_name_hk": "Specification Name(Chinese)",
        "809D25@Attribute_name_en": "Specification Name(English)",
    }, restaurant_supplier_supplierList: {
        "8C238E@name": "Name",
        "E318B6@desc": "Description",
        "E84BAF@address": "Address",
        "43B05F@email": "Email",
        "28AF0E@phone": "Phone",
        "0666FD@pay_type": "Payment Terms",
        "5CA70A@min_order": "Minimum Order Amount",
        "8FC08B@reduction_conditions": "Free Delivery Amount",
        "2E31F9@fee": "Delivery Fee",
        "4A7048@supplier_id": "Supplier ID",
    }, supplier_partner: {
        "34C475@name": "Name",
        "249A92@payment_method": "Payment Terms",
        "AADE98@Late_payment_day": "Credit Term",
        "A73300@Designated_monthly_statement_day": "Monthly Settlement Date",
        "475422@partner": "Restaurant Partners",
        "C6DED8@partner_ID": "Partner ID",
    }, restaurant_supplier_products: {
        "3B1C04@product_id": "Product ID",
        "DC5311@Product_classification": "Category",
        "CE8240@product_name": "Product",
        "7E39D8@img": "Image",
        "C04851@price": "Price",
        "FEF412@supplier_name": "Supplier",
        "B3EF6A@status": "Active",
        "5398AC@unit_number": "Unit Number",
        "CDC4F4@unit": "Unit",
    }, restaurant_store: {
        "6D46FE@store_id": "Branch ID",
        "1DC508@contact": "Contact Person",
        "938F71@HongKong_SAR": "District",
        "E99B03@name": "Branch",
        "763B74@address": "Branch Address",
        "0C4048@phone": "Contact Number",
        "BA05EA@store_name_hk": "Branch(Chinese)",
        "238DE5@store_name_en": "Branch(English)",
    }, supplier_order: {
        "E0C2CB@order_management": "Order Management",
        "E5F50A@order_status": "Order Status",
        "46C89C@order_reference": "Order No.",
        "CB13F3@type": "Type",
        "066E3C@shop": "Branch",
    }, supplier_company_information: {
        "A5E131@whatsapp_group": "WhatsApp Group",
        "773745@contact_number": "Contact Number",
        "65F49B@company_description": "Company Introduction",
        "9B4616@company_address": "Company Address (Chinese)",
        "539097@company_information": "Company Information",
    }, supplier_TransactionSettings: {
        "CD4A38@delivery_fee": "Free Delivery Amount", "2435B2@Transaction_settings": "Transaction Settings",
    }, restaurant_supplier_attr: {
        "14816B@attr_id": "Specification ID",
        "F17085@attr_name": "Specification",
        "72DFF1@attr_val": "Type",
        "D76F17@sort": "Sort",
        "CF4A03@attr_name_hk": "Specification Name(Chinese)",
        "50B6CC@attr_name_en": "Specification Name(English)",
        "DBE743@more_val": "Please enter a property value, multiple property values please use English semicolon separation",
        "B6A194@default": "Default Optional",
    }, restaurant_buy_genner: {
        "30D594@order_id": "Order No.",
        "FE6497@store": "Branch",
        "7B0C54@type": "Type",
        "586E4F@supplier_name": "Supplier Name",
        "E0A3B6@order_price": "Total Order Amount",
        "759F57@pay_status": "Payment Status",
        "17E296@order_status": "Order Status",
        "97F49A@created_order": "Create Purchase Order",
        "99D144@type": "Choose Type",
        "13B04D@Optional_supplier": "Optional Supplier",
        "59739F@choose_supplier": "Choose Supplier",
        "4C75AE@tips": "Note: Supplier cannot be changed after pressing Create",
        "452B30@created": "Create",
        "E3B214@place_order_user": "User",
        "47DC09@created_general": "Purchase Order",
        "557AE0@select_store": "Select Branch",
        "D6582C@add_goods": "Add Goods",
        "D78E87@place_order": "Place Order",
    }, restaurant_buy_optionalOrder: {
        "283B63@product_id": "Product ID",
        "FFD749@product_img": "Image",
        "7AB313@classify": "Category",
        "64F0A6@product_name": "Product",
        "1C9E17@supplier_name": "Supplier",
        "9D943A@price": "Price",
        "118294@shopcart": "Add to Shopping List",
        "F1FD94@Choose_specifications": "Choose Specifications",
        "2744C9@number": "Quantity",
        "C5B93F@color": "Color",
        "861FA0@number_unit": "Unit/Number",
    }, restaurant_buy_B2BProduct: {
        "B3EB47@product_id": "B2B Product ID",
        "6C52C8@product_name": "Product",
        "DCD279@sku_id": "B2B Product Specification ID",
        "8BF399@sku_name": "Product Specification",
        "EF1083@unit_price": "Price",
        "52D795@supplier_name": "Supplier Name",
        "2D3DAB@buy_time": "Buy Time",
    }, restaurant_inventory: {
        "227D37@inventory_id": "Inventory ID",
        "CF7A68@store": "Branch",
        "0009CF@product_name": "Product",
        "198560@sku_name": "Product Specification",
        "E5BE78@quantity_purchased": "Quantity of Goods Purchased",
        "812240@buy_quantity": "Purchase Unit",
        "05A3D5@calculate_quantity": "Conversion Rate",
        "98D94E@Inventory_quantity": "Inventory",
        "307A4B@Inventory_unit": "Inventory Unit",
        "8E8BAB@calculate_unit": "Inventory Unit",
    }, restaurant_accouting_record: {
        "22C5BC@record_id": "Record ID",
        "1B48BB@order_code": "Order No.",
        "228DB4@integral_type": "Integral Operation Type",
        "7597E7@integral": "Integral",
        "23DF28@record_time": "Record Time",
        "C59B8B@increase": "Increase",
        "E94CF8@reduce": "Reduce",
    }, restaurant_setting_companyInfo: {
        "B056EF@email": "Email", "D20198@telephone": "Contact Number",
    }, restaurant_dashboard: {
        "E3BBED@order_request": "Payables", "27D6A5@unfinish": "Outstanding Orders", "11D38A@store": "Suppliers",
    }, "supplier-setting-DistributionSet": {
        "0823DB@DistributionSet": "Distribution Settings",
        "6198C8@delivery_date": "Delivery Date",
        "EB504B@Distribution_area": "Distribution Area",
        "F1E87C@Select_date": "Select Date",
        "1DB5AF@Select_region": "Select Region",
        "F988E1@Select_region": "Select Region",
    }, restaurant_supplier_addsuppluer: {
        "6BA9D8@name_hk": "Supplier (Chinese)",
        "4A6809@name_en": "Supplier (English)",
        "0C63A5@linkman": "Contact Person",
        "AB65B0@cash_delivery": "C.O.D",
        "2FDEF9@Deferred_payment": "Credit Term",
        "4AF4CD@specify_date": "Monthly Settlement",
        "BF08D0@overdue_days": "Credit Term",
        "610635@specify_date": "Monthly Settlement",
    }, restaurant_supplier_addSupProduct: {
        "BAA03D@basic_tab": "Basic",
        "BF0A77@product_attr": "Product Specification",
        "AF8EC7@name_hk": "Product Name(Chinese)",
        "EA9E65@name_en": "Product Name(English)",
        "E9513A@product_name": "Product Name",
        "015CB0@key": "Keywords",
        "183798@space": "Distinguish keywords by Spaces",
        "DC9557@sort": "Sort",
        "3E1559@clear_attr": "Clear Attribute",
        "43EF6B@attr_name": "Attribute Name",
        "16EBEC@price": "Price",
        "5A7BCB@number_unit": "unit number / Commodity unit",
        "AAB37F@no_data": "No Data",
    }, shop_cart: {
        "23490B@shopcart": "Shopping List",
        "E65DF9@goods_img": "Image",
        "4C8583@name": "Name",
        "86F6A0@unit_price": "Price",
        "D3B150@number": "Quantity",
        "CB55C1@specifications": "Product Specification",
        "936345@total_price": "Total Amount",
        "883AA6@send_date": "Availability",
        "7E9DE5@check_all": "Select All",
        "C0D4AB@choosed": "Selected Products",
        "A91ABF@order_price": "Order Amount",
        "1ED05B@rate": "Free Delivery Amount",
        "1AE43D@total": "Total",
        "C83C45@settlement": "Settlement",
    }, "supplier-produceManage-publishProduct": {
        "2072FB@classify_id": "Category ID",
        "BD6C55@Name_hk": "Name(Chinese)",
        "36F75F@name_en": "Name(English)",
        "3B83F2@keyword": "Keywords",
        "226509@Distinguish_keywords_by_Spaces": "Distinguish keywords by Spaces",
        "785991@Introduction_en": "Description(English)",
        "14719A@Introduction_hk": "Description(Chinese)",
        "92C10D@Shelf": "Active",
        "DBCC4F@Number_of_Units": "Number of Units",
        "4E6A29@Commodity_unit": "Unit",
        "97DB98@Minimum_purchase_quantity": "Minimum Order Quantity",
        "EE6D61@Sort": "Sort",
        "E400F7@Product_Image": "Product Image",
        "B7148B@Basic_Settings": "Basic Settings",
        "98875D@Product_specifications": "Specification",
        "E9BF3B@Number_of_Units_Commodity_unit": "Number of Units/Commodity unit",
        "D4430E@New_specifications": "New Specification",
        "799C97@Clear_specification": "Clear Specification",
    }, orderDetail: {
        "C0ED69@print": "Print",
        "F854E2@be_processed": "Awaiting Confirmation",
        "87CE30@In_processing": "Processing",
        "7879F8@have_received": "Received",
        "A119AD@has_payment": "Paid",
        "35ED62@order_closed": "Order Closed",
        "AB7C91@invoice_number": "Invoice No.",
        "845D3E@buyer": "Buyers",
        "082549@order_time": "Order Created",
        "2D64A4@store_name": "Branch",
        "2775BD@store_tele": "Phone",
        "6F5A1A@delivery_adderss": "Address",
        "30DB36@Payment_status": "Payment Status",
        "FF66B0@supplier": "Supplier",
        "7FC644@supplier_tele": "Contact Number",
        "F6B332@Supplier_contact": "Contact Person",
        "7CA68E@total_price": "Total Amount",
        "CAF2C7@number": "Purchase Quantity",
        "3BB7C2@Accept_number": "Receive Quantity",
        "04B67B@Credits_deducted": "ChefPoints Used",
        "98FF8B@next": "Back",
        "EB17E9@Refuse_order": "Refuse Order",
        "73FE8F@Cancel_order": "Cancel Order",
        "7917E0@Accepted_quantity": "Accepted Quantity",
    }, "shop-index": {
        "E96A88@index": "Home",
        "9FD647@store": "Features",
        "ADD4F1@Please_enter_key_words": "Please enter key words",
        "6449D6@product_category": "Category",
        "C8B82E@company": "Company",
        "BA8EF4@about_us": "About Us",
        "6D3E8C@Privacy_Policy": "Privacy Policy",
        "F113A5@contac_us": "Contact Us",
        "5D4405@Terms_and_conditions": "Terms and Conditions",
        "3B0294@contact": "Contact",
        "12AD58@phone": "Phone",
        "D32B7A@time": "Time",
        "D72298@Monday_to_Friday": "Monday to Friday",
        "EEA554@Saturday_to_Sunday": "Saturday to Sunday",
        "382FFB@confirm_order": "Confirm Order",
        "EACECB@Return_to_the_mall": "Return to the mall",
    }, "shop-goods": {
        "BED8D2@goods": "Marketplace",
        "88E57F@Minimum_order_amount": "Minimum Order Amount",
        "E6DC79@Full_reduction": "Free Delivery Amount",
        "A29F44@search": "Search",
        "D4F96B@kind": "Category",
        "5C23E3@Switch_language": "Switch Language",
        "740AD3@login": "Login",
        "486EA7@More": "More",
        "CFD43B@No_specifications": "No specifications",
        "D33083@Remaining_points": "ChefPoints Balance",
        "5F5919@Minimum_order_quantity_ratio": "Minimum Order Quantity Ratio",
        "EA3800@supplier_LOGO": "Supplier LOGO",
        "731456@Supplier_profile": "Supplier Description",
        "1D4230@Fill_in_the_form": "Fill in the form",
        "299596@Remarks": "Remark",
        "B5507E@submit": "Submit",
        "43461C@Receiving_part": "Receiving part",
        "1988CD@Receiving_completed": "Receiving Completed",
        "3D33DE@Please_tick_the_category": "Please Tick the Category！",
        "1213D8@Please_select_supplier": "Please Select Supplier",
        "1213D7@Please_select_supplier": "Please Select Supplier！",
        "FC4B28@Please_improve_the_product_specification_information": "Please Improve the Product Specification Information!",
        "919A0F@Please_fill_in_the_quantity_of_the_product": "Please Fill in the Quantity of the Product！",
        "8264A9@Add_to_Cart_successful": "Add to Shopping List Successful！",
        "91B7D6@please_log_in_first": "Please Log in First！",
        "8BF492@Unchecked_items_cannot_be_placed": "Unchecked Items Cannot be Placed！",
        "9DD40C@Please_choose_a_store": "Please Choose a Branches！",
        "9A0301@Please_complete_the_account_password": "Please Complete the Account and Password！",
        "C8E0FC@Please_fill_in_the_group_name": "Please Fill in the Group Name！",
        "A16978@Please_select_a_role": "Please Select a Role！",
        "2ECFD0@Confirm_delete": "Confirm Delete？",
        "032EEE@Confirm_reset_password": "Confirm Reset Password?",
        "768789@Confirm_receipt": "Confirm Receive?",
        "ABFDC5@Confirm_the_change": "Confirm  Change?",
        "16E8D3@Successful_operation": "Successful Operation！",
        "A906CE@Network_error_please_try_again_later": "Network Error, Please Try Again Later！",
        "5E8023@Please_complete_the_form": "Please Complete the Form！",
    }, restaurant_buy_print: {
        "9C5FBF@supplier_info": "Supplier Infomation",
        "59DDF6@order_info": "Order Infomation",
        "2DC8EA@product_info": "Commodify Infomation",
        "945C82@Print_immediately": "Print Immediately",
        "470F44@Latest_date": "Payment Due",
        "B06A44@Branches_stores": "Branch Stores",
        "2886D5@Order_store_phone": "Order Branch Phone",
        "54AB78@Order_delivery_address": "Order Delivery Address",
    }, aboutus: {
        "06D38E@online": "B2B ONLINE",
        "9C7F56@food": "FOOD INGREDIENTS",
        "FE6F4C@ingredients": "MARKET PLACE",
        "BE3023@join": "Join Us！",
        "A52D91@solve1": "Difficult to find suitable suppliers - Restaurateurs always need to rely on referrals or limited information on internet",
        "1D4E1A@solve2": "Lack of transparency in prices and quality of food ingredients",
        "E1E269@solve3": "Waste time and labour resources on data entry for each procurement order",
        "458BFD@solve4": "Wrong orders of purchase due to human error",
        "4C7AB7@feature": "FEATURES",
        "23DAB9@feature_title1": "PROCUREMENT MANAGEMENT",
        "6D1C81@feature1": "A systematized way to send purchase order and manage invoices in order to avoid any human error.   No more missing or late orders.",
        "AD5C20@feature_title2": "BACK-END COST ANALYSIS",
        "893305@feature2": "An efficient way of monitoring your daily cost with minimal hassle.  Detailed analysis shown  with just one click.",
        "9ACA2B@feature_title3": "ONLINE FOOD INGREDIENTS MARKETPLACE",
        "7BDA32@feature_title4": "MEMBERSHIP REWARD SYSTEM",
        "CEA6B3@feature3": "For you to find new suppliers, compare prices and directly order through our integrated online marketplace.  We are here to help you find the most suitable ingredients.",
        "B49CB8@feature4": "Earn ChefPoints by purchasing on marketplace, then use your Chefpoints as store credit –  Buy more earn more!",
        "1E0AA1@fee1-1": "Place Order On the Internet",
        "FD8464@fee1-4": "Loyalty Reward Programme",
        "F44CA0@fee2-1": "Procurement Analysis Report",
        "DB85C3@fee2-3": "Product Price and Quantity Variance",
        "C41E8F@fee5": "Purchase Order Management",
        "3A74F7@fee3-3": "Supplier Product Catalogue",
        "36F318@fee7": "Repeat Regular Orders",
        "4D7ADA@fee8": "Issue Tender",
        "C03B4A@partner": "OUR  PARTNERS",
        "DD9192@fee_plan": "Price",
        "907247@contact_us": "CONTACT US！",
        "57B601@info": "Message",
        "23CC4E@name": "Name",
        "E928CD@issue": "Chefstation",
        "C5B9F6@solve": "Solves",
        "72F5FF@Minimum_order_quantity": "M.O.Q",
        "B0A0B1@radio": "Proportion",
        "BE94C4@fee3-4": "Monitor  Payment Status",
        "72AB93@Product_Details": "Product Info",
        "3DB928@Supplier_Details": "Supplier Info",
        "935D28@ChefStation": "THE ISSUE",
        "1446FC@no_fee": "NO FEE",
        "1020E4@no_fee1": "We charge no fee for restaurants",
        "6AD290@Current_Inventory": "Stock Count",
        "CE2CE2@Historical-stock": "Historical Inventory",
        "BAABFA@Confirm_the_modification": "Confirm the Modification?",
        "CCBA90@Confirm_cancellation": "Confirm Cancellation?",
        "098C3F@all_products": "All Products",
        "351BB4@All_suppliers": "Supplier",
        "49CA13@personal": "Personal",
        "9C51BD@E-mail_order": "E-Mail Order",
        "D4D3E7@chat": "Chat",
        "AF9C99@month_order_money": "Daily Sales Amount (Current Month)",
        "CD5BE2@month_money_top10": "Top 10 Products by Sales(Current Month)",
        "405894@month_revice_top10": "Top 10 Products by Quantity(Current Month)",
        "15D285@chart": "Charts",
        "374FC5@month_order_all": "Current Month Orders",
        "A01056@order_change": "Purchase Variance",
        "09AE4A@primary_account": "Primary Account",
        "6A7E47@initial_unit": "Order Price",
        "2DF5D9@pay_unit": "Receive Price",
        "03863E@last_date": "The Latest Payment Date",
        "B0F833@order_time": "Date",
        "0390DF@difference": "Difference",
        "58431A@chatroom": "Chat",
        "9E51D1@Order_item_amount_difference": "Purchase Price Variance",
        "412295@Order_quantity_difference": "Purchase Quantity Variance",
        "C44096@Historical_inventory_ID": "Historical Inventory ID",
        "3482AB@Recording_time": "Date",
        "13C31B@modification_time": "Last Edited",
        "C3BE54@admin": "Admin",
        "662D36@staff": "Staff",
        "CEECB1@is_edit": "Editing Allowed",
        "A94527@spec_id": "Specification ID",
        "A97E8F@Description": "Description",
        "3A6FE5@product_num": "Product Quantity (can edit)",
        "96F4F8@product_price": "Price",
        "CBD374@total_price": "Total Amount",
        "482D27@commodity_attr": "Company Address (Chinese)",
        "9E0005@order_min": "Free Delivery Amount",
        "1886CC@fee": "Delivery Fee",
        "F31309@fee_ways": "Payment Terms",
        "E56154@user_name": "Username",
        "1DFF41@Send_PO": "Send P.O.",
        "87F6C1@Balance": "ChefPoints Balance",
        "E820CB@Estimated_delivery_time": "Availability",
        "CB7991@Wrong_estimated_delivery_time": "Wrong Estimated Delivery Time",
        "B69FB2@order_time": "Order Time",
        "6DFA47@Delivery_status": "Delivery Status",
        "E37C0F@common_problem": "FAQ",
        "DE7FD1@restaurant_contact": "Restaurant Person-In-Charge",
        "37A57E@restaurant_phone": "Restaurant Phone Number",
        "29B8DC@top10_restaurant": "Top 10 Restaurants by Sales",
        "D4D5D3@day": "day(s)",
        "F32A9F@company_addr": "Company Address (English)",
        "8F4A3C@company_logo": "Company Logo",
        "A8C409@Terms_of_use": "Terms of Use",
        "99A454@monday": "Monday",
        "6FC140@Tuesday": "Tuesday",
        "7FE14B@Wednesday": "Wednesday",
        "1C3563@Thursday": "Thursday",
        "AA11D7@Friday": "Friday",
        "78EC72@Saturday": "Saturday",
        "CA70D5@sunday": "Sunday",
        "7F2D75@Cash_on_delivery": "Cash on Delivery",
        "782FFE@Deferred_payment": "Deferred Payment",
        "D0F1D6@Set_statement": "Set date monthly statement",
        "C7977E@Payment_Receieved": "Paid",
        "323AC0@Awaiting_Payment": "Pending Payment",
        "3008EA@Add_to_the_optional_classification": "Add to Category",
        "3AA7E7@top10_category": "The category of the top ten purchase amount of the month",
        "45DF7E@Standard_stock": "Par Level",
        "94396A@standard_value": "Par Level",
        "C77370@Login": "Log in",
        "6A20EF@forget_password": "Forget Password",
        "4E7C2A@Please_add_items_first": "Please add items first",
        "F0A61A@Marketplace": "Online Marketplace",
        "EDC382@fee1-2": "Get Food Samples",
        "870877@fee1-3": "Get a Special Offer",
        "E16045@fee2": "Data Analysis",
        "D808AF@fee2-2": "Inventory Analysis Report",
        "47AA8C@fee3": "Procurement  Management System",
        "4B9FED@fee3-1": "Online Order Management",
        "EE182D@fee3-2": "General Order Management (via WhatsApp or Email)",
        "B4F293@Request_sample": "Request Sample",
        "375CE0@Request_a_special_offer": "Request Special Price",
        "915ECD@General_order": "General Order",
        "0CBCA9@normal": "Normal",
        "E0C2B9@lock": "Lock",
        "980E0D@yes": "Yes",
        "A03514@no": "No",
        "14F845@chefpoints": "ChefPoints",
        "26D960@chefPoints_change": "ChefPoints Change",
        "245620@Overdue": "Overdue",
        "572BF7@Order_Type": "Order Type",
        "50BC07@forgot_pass": "Forget Password？",
        "D453CE@Time_of_receipt": "Receiving Times",
        "57925F@register": "Register",
        "66D050@delivery_time": "Delivery Time",
        "48C14D@supplier_platform": "Supplier Platform",
        "09C1E0@Total_amount_of_goods": "Total Product Amount",
        "E3A644@Order_quantity": "Purchase Quantity",
        "AEB42C@Use_ChefPoints": "Use ChefPoints",
        "E8221A@restaurant_name": "Restaurant Name",
        "BAF614@restaurant_chart1": "Daily Order Amount (Current Month )",
        "3B33BC@restaurant_chart2": "Top 10 Products by Purchase Amount(Current Month)",
        "E701FA@resaurant_chart3": "Top 10 Products by Purchase Quantity(Current Month)",
        "327C00@resaurant_chart4": "Top 10 Categories by Purchase Amount (Current Month)",
        "6B3562@Marketplace_Order": "Marketplace Order",
        "2AD773@Restaurant_Details": "Restaurant Info",
        "41856B@supplier_block1": "Unpaid Orders",
        "871D88@Count_date": "Count Date",
        "C9B86B@daily": "Daily",
        "445AA1@Weekly": "Weekly",
        "B8F622@Opening_times": "Opening Times",
        "C28C46@delivery_time": "Delivery Time",
        "06BB26@original_password": "Original Password",
        "3F9881@new_password": "New Password",
        "D904D5@repeat_password": "Repeat Password",
        "3F05B9@equit": "Withdraw  Account",
        "01F5E5@login_success": "Login Successfully！",
        "2E2D92@Quantity_of_Order": "Order Quantity",
        "035886@Repeat_Orders": "Recurring Order",
        "606AE7@login_repeated": "Long time no operation, please login again!",
        "9301F0@Quotation_Information": "Quotation Information",
        "7C0CF5@Upload_failed": "Upload failed, image size cannot exceed 6MB！",
        "7C0CF6@Upload_failed": "Upload failed, document size cannot exceed 30MB!",
        "0DBD9D@Failed_to_load": "Failed to load",
        "8DA31F@New_news": "New news",
        "0CE600@Send_a_message": "Send a message",
        "88E89A@Return": "Return",
        "2FB613@Send": "Send",
        "9397BF@Click_OK_to_Send_the_Picture": "Click OK to Send the Picture",
        "9397B8@Click_OK_to_Send_the_Doc": "Click confirm to send out the document",
        "B36F4C@chat_record": "Chat Record",
        "882633@No_Notice": "No Notice",
        "2F9ADA@Mark_as_read": "Mark As Read",
        "3DC252@new_message": "New Message",
        "25DB6F@account_number": "Account Number",
        "273505@Refresh_permissions_manually": "Refresh permissions manually",
        "DF0FCE@In_Stock": "In Stock",
        "18EF78@Book": "Book",
        "A5B775@Quantity_Status": "Quantity Status",
        "D11489@Order_Deadline": "Cut-Off Time",
        "24F080@Bank_Name": "Bank",
        "1A4DE1@Account_Name": "Payee",
        "C232EA@Account_Number": "Account No.",
        "2F1443@Bank_Info": "Bank Info",
        "F98ACA@Constant": "Constant",
        "BCC0D3@my_favourite": "Favourites",
        "D38FDE@Inoperable": "Inoperable",
        "4E9CB1@Order_Combination": "Order Combination",
        "BBBC82@Group_ID": "Full/Part Time",
        "06951D@Group_Name": "Group Name",
        "0119CF@Number_of_Product_Groups": "Products in Combination",
        "A7A890@Produc_ID": "Product ID",
        "900298@Please_Enter_a_Product_Name_or_Keyword": "Please Enter a Product Name or Keyword",
        "742833@There_are_No_Products_in_This_Combination_Cannot_be_Added_to_Cart": "There are No Products in This Combination, Cannot be Added to Cart",
        "5454F8@Marketplace_Product": "Marketplace Product",
        "D278B5@please_login_first": "Failed to access the page, please login first!",
        "6505D3@Plan_Subscription": "Subscription Plan",
        "ED5B08@Plan_Name": "Plan Name",
        "B4FA87@Number_of_planned_stores": "Number_of_planned_stores",
        "C4970F@Basic_Cost": "Basic Cost",
        "A32813@Exceeding_Planned_Store_Costs": "Exceeding Planned Store Costs",
        "8F1D59@Shelves": "Shelves",
        "7FF405@Plan_Name_English": "Plan Name(English)",
        "1F998B@Plan_Name_Chinese": "Plan Name(Chinese)",
        "2F8186@Upload_failed": "Upload failed, image size cannot exceed 5MB!",
        "23D2D3@Upload_failed": "Upload failed, pictures can only be uploaded in jpg format!",
        "8F2EAB@slide_photo": "Banner",
        "1C3EE0@log": "Log",
        "C0DD27@regular_order": "Regular Order",
        "47CFC2@timerId": "Timer Id",
        "88B1A4@timer_name": "Timer Name",
        "33EAEC@date_type": "Repeat Order Type",
        "E0F102@range": "Set  time",
        "225A90@created_time": "Create Time",
        "6B52ED@monthly": "Month",
        "A85781@result": "Execution Result",
        "DCF209@execution_time": "Set time",
        "CAF889@day": "Day",
        "C7C495@info": "Infomation",
        "075530@massUp": "Enable/Disable",
        "B76508@showProduct": "Shelv",
        "02B038@hidePRoduct": "Removal",
        "981221@ecex_time": "Execution time",
        "0B8401@set_user": "Set User",
        "21AFC6@Product_No": "Product No",
        "CEA76C@Product_unit": "Product Unit",
        "47C789@Product_Unit_Name": "Product Unit Name(English)",
        "840BD3@Product_Unit_Name": "Product Unit Name(Chinese)",
        "C9B5FF@Product_Unit_ID": "Product Unit ID",
        "C676AA@The_operation_will": "The operation will modify the unit information of all products. Are you sure to modify it?",
        "FFCC64@Are_you_sure_to_add": "Are you sure to add?",
        "8FD945@please_log_in_again": "User information is missing, please log in again！",
        "CAAF92@Download_bulk_edit_Excel": "Mass Edit",
        "DBF560@Upload_Bulk_edit_Excel": "Upload Mass Edit",
        "033E98@Please_Select_Restaurant": "Please Select Restaurant",
        "5A698D@rights_reserved": "© Celestial Stars Strategy Limited. All rights reserved",
        "F2C0FA@Partner_Product_ID": "Partner Product ID",
        "111BDD@Original_Price": "Original Price",
        "932932@special_price": "Special Price",
        "664E7E@Invalid": "Invalid",
        "0B6945@Cooperation_Products": "Cooperation Products",
        "3AEBA8@My_Supplier": "My Supplier",
        "E9C731@ChefStation_Supplier": "The Market",
        "0467CD@WhatsApp_Order": "WhatsApp Order",
        "66A5FF@Order_Notification_Type": "Order Notification Type",
        "123991@Order_Type_First": "Please Select An Order Type First",
        "0FCD62@Inventory": "Inventory (b.f.)",
        "CC731D@Orders": "Purchases",
        "71DE08@Sales": "Sales",
        "512DA9@Write_Off": "Wastage",
        "3F0589@Theoretical_Inventory": "Theoretical Inventory",
        "DDC645@Actual_Inventory": "Inventory (c.f.)",
        "62183E@Variance": "Variance",
        "EDBC7C@Wastage_Summary": "Wastage Summary",
        "44863D@Report": "Report",
        "6AB704@Write_Off": "Write Off",
        "4849F7@Stock_date": "Stock Date",
        "C9ECA3@Issue_PO": "Create P.O.",
        "1B8D6E@Order_by_Product": "Order by Product",
        "736349@Branch_First": "Please Select A Branch First",
        "144975@Accounting_Entry": "Accounting Entry",
        "9B9285@Bill_Classification": "Bill Classification",
        "AFA01A@sub-categories": "Whether to Allow sub-categories",
        "3C1FAD@Revenue": "Revenue",
        "B4B0FE@Total_revenue": "Total Revenue",
        "C70D90@Cost_of_Sales": "Cost of Sales",
        "733F4F@Total_Cost_of_Sales": "Total Cost of Sales",
        "F16C06@Gross_Profit": "Gross Profit",
        "21F1F5@GP_Margin": "GP Margin",
        "5C09FA@Expenses": "Expenses",
        "29AA04@Total_Expenses": "Total Expenses",
        "183E01@Profit_Before_Tax": "Profit Before Tax",
        "ADC948@Profit_Margin": "Before Tax Profit Magin",
        "DE6DD7@Add_Record": "Add Record",
        "7CD35D@New_Category": "Edit Category",
        "B9DAAB@Export_Bill": "Income Statement",
        "381E69@Date": "Date",
        "FE0DEB@Amount": "Amount",
        "D2772D@Cannot_be_Modified": "Cannot be Edited",
        "60E2D6@On_Timing": "On Timing",
        "A9AC5D@Please_Select": "Please Select a Time",
        "A446F9@Product_changes": "Purchase Variance",
        "EEC3D3@Substandard_Inventory_Products": "Par Level",
        "95A642@Products_In_Total_Amount": "By Purchase Value",
        "C991DB@Receive_Top_Ten_Products": "By Purchase Quantity",
        "0026F6@Purchase_Amount": "By Product Category",
        "62F376@Top_Ten_Vendors_Purchased": "By Supplier",
        "6B0C8F@Loss_Percentage": "Wastage",
        "59395F@Inventory_value": "Inventory Amount",
        "5E837C@Export_report": "Export Report",
        "2AECD0@Export_Order": "Export to Excel",
        "5F8C9F@Purchasing_Price": "Purchase Amount",
        "DD54D2@Product_Chart": "Purchase Analysis",
        "12AAC8@profit": "Gross Profit",
        "F12209@analysis": "Analysis",
        "7EF062@Default": "Default",
        "AB78A8@Day": "Day",
        "420F01@month": "Month",
        "480440@season": "Season",
        "B52CFC@year": "Year",
        "14C616@the_first_season": "First Quarter",
        "5AD174@Second_quarter": "Second Quarter",
        "49F8FC@Third_Quater": "Third Quater",
        "F0305E@Fourth_quarter": "Fourth Quarter",
        "74A5F4@sum": "Purchase amount restaurant ranking",
        "F5021B@Target_Price": "Target Price",
        "1F9F16@Staff": "Staff",
        "41421F@Staff_Type": "Full/Part Time",
        "8AE12E@Income_breakdown": "Income Breakdown",
        "447E60@Employee_ID": "Employee ID",
        "8620F5@Username": "Username",
        "25FC31@Title": "Title",
        "DD713B@Name": "Name",
        "BAB644@Date_of_Birth": "Date of Birth",
        "B1BDBB@Address": "Address",
        "687DD4@Employee_title": "Employee title",
        "5BAB8D@Full": "Full Time",
        "47D798@part_time": "Part Time",
        "A22EBE@Monthly": "Monthly",
        "357941@hourly_rate": "hourly rate",
        "F8647E@Hire_date": "Hire Date",
        "3F5BBF@Leave_date": "Leave Date",
        "E9690C@Salary_type": "Salary Type",
        "E0CB0A@Salary": "Salary",
        "C56440@Open_area": "Open Area",
        "4D4C0B@shut_down": "Shut Down",
        "B58C96@Place_an_order": "Repeat Order",
        "5429E6@Supplier_Type": "Supplier Type",
        "71E150@Search_combination_name": "Search Combination Name",
        "578A62@Search_Supplier_Name": "Search Supplier Name",
        "F9CC44@Total_amount_of_goods": "Total amount of goods of Goods",
        "22B84B@reference_number": "Reference No.",
        "AB134B@Payee": "Payee",
        "C97519@supplier_no": "Supplier ID",
        "5C6796@Food_cost": "Food Cost",
        "7D8E82@Schedule": "Weekly Schedule",
        "4712AC@Overtime_pay": "Overtime Pay (Hourly)",
        "76605B@hour": "hour",
        "BADE55@Job_Type": "Work Type",
        "1C32A1@Work": "Work",
        "FC1FB9@Official_Holiday": "Day Off",
        "8DAD0F@sick_leave": "Sick Leave",
        "CC1840@Annual_Leave": "Annual Leave",
        "FFECA8@Total_arrangement_hours": "Work Hours (Current Month)",
        "327289@Overtime": "Overtime",
        "48E7FC@cost": "Cost of Sales",
        "476677@Employee_working_hours_per_month": "Employee Working Hours Per Month",
        "832B7C@Not_due": "Not Due",
        "7B072D@Overdue": "Overdue",
        "ADE56E@Employee_salary": "Payroll",
        "EE7271@Select_month": "Select Month",
        "2A0D3F@Working_hours": "Working Hours",
        "E96BBF@Normal_time_salary": "Normal Time Salary",
        "3F765C@Overtime_pay": "Overtime Pay",
        "D7E9FD@Additional_charges": "Additional Adjustment",
        "84242F@Total_salary": "Total Salary",
        "C70759@Employee_MPF": "Employee MPF",
        "A20A57@Employer_MPF": "Employer MPF",
        "467CD2@one_fix": "Modify",
        "BF70BE@Break_time": "Break Time",
        "A9A7F4@Open_the_schedule": "Open The Edit Table",
        "CF6BE6@mon": "/Month",
        "C7FD6E@hours": "/Hour",
        "31B467@Arrange_time": "Arrange Time",
        "696FE4@Start": "Start",
        "44C071@End": "End",
        "E82D58@Pleas_selected_type": "Please Fill In the Time Of the Selected Type",
        "A627ED@Arrangement_type": "Arrangement Type",
        "01D9C2@batch_modify": "Batch Modify",
        "A6801E@Change_time": "Edit Table",
        "BA6AE5@Unpaid_order_amount": "Unpaid Amount",
        "BA6AE6@paid_order_amount": "Paid Amount",
        "2CF1A8@Total_Purchase_Amount": "Purchase Amount",
        "7BE40B@Top_five_purchases": "Top Five Purchases",
        "6F41DA@Accept_top_five_products": "Accept Top Five Products",
        "24F369@Top_5_categories_of_purchase_amount": "Top 5 Categories of Purchase Amount",
        "C16F26@Top_5_suppliers_with_total_purchase_amount": "Top 5 Suppliers With Total purchase Amount",
        "BDBC1F@Loss": "Wastage",
        "8B9C12@no_receive": "Orders to be Received",
        "355446@Monthly_salary_hourly_salary": "Monthly salary / hourly salary",
        "2EFE79@30_profit": "Last 30 days profit",
        "8CBEFD@my_restaurant": "My Restaurant",
        "B60983@topfive_30": "Top 5 by Purchase Amount (Past 30 Days)",
        "F89D2B@top5_30": "Top 5 by Purchase Quantity (Past 30 Days)",
        "E558D5@top5_30": "Top 5 by Product Category (Past 30 Days)",
        "9703BB@top5_30": "Top 5 by Supplier (Past 30 Days)",
        "C32928@edit": "Edit Permission",
        "9E728C@salary": "Salary (Monthly/Hourly)",
        "AB0929@side_bar": "Product Category",
        "119D08@side_bar": "Staff Type",
        "F3F8D5@sup_addr": "Supplier Address (Chinese)",
        "CB7169@sup_addr": "Supplier Address (English)",
        "D1BE99@bank2": "Bank 2",
        "9BE18D@bank2": "Payee 2",
        "F75A75@bank2": "Account No. 2",
        "B003A6@This_is_required": "This is required",
        "B7722D@Supplier_Address": "Supplier Address",
        "27721E@type": "Order Type",
        "0AB255@Inventory": "Inventory",
        "80E0A4@branch_addr": "Branch Address (English)",
        "CF24C8@branch_addr": "Branch Address (Chinese)",
        "6A5B3D@Daily_break": "Daily Break Time (Hours)",
        "59C826@time": "Time Created",
        "4AF32C@repeat_time": "Repeat Order Timer",
        "DC552B@Awaiting": "Pending Confirmation",
        "7A52EA@order_date": "Order Date",
        "95BD63@receive_date": "Receive Date",
        "3697EB@Blog": "Blog",
        "E7F9ED@Blog_type": "Blog Type",
        "B99A84@Blog_list": "Blog List",
        "0176C5@Author": "Author",
        "07C2A1@Whether_to_publish": "Whether to publish",
        "A57A84@grade": "grade",
        "43A2F7@Level_one": "Level one",
        "EE1777@Level_2": "Level 2",
        "F86F61@Level_3": "Level 3",
        "259FAD@blog": "Blog",
        "D1060F@read_more": "Read More",
        "E2B943@blog1": "Enjoy Taiwanese food together",
        "C20495@blog2": "Gather all the local cuisine and favorite restaurants for you",
        "BCCCBD@Content_Chinese": "Content (Chinese)",
        "D6AC22@Content_English": "Content (English)",
        "FFAA91@addInve": "Add Inventory",
        "88FB2C@supplier_no": "Supplier No.",
        "62FAC0@client_id": "Client ID",
        "B80F98@Client_name": "Client Name",
        "BB3448@client": "Client",
        "6DBD66@client_no": "Client No.",
        "C64B9E@client_no": "Client No.",
        "944A6F@cline_name_hk": "Client(Chinese)",
        "128746@cline_name_en": "Client(English)",
        "329B27@sale": "Sale",
        "198D18@client_name": "Client List",
        "FBB25C@client_pro": "Client Products",
        "C3247D@sale": "Sale",
        "74584D@create_sale": "Create Sale Order",
        "78FDD0@bill_manage": "Bill Management",
        "93BE8A@pro_no": "Product No.",
        "0E6C0E@is_shop": "Is merchant",
        "098CC0@Seller_name": "Seller Name",
        "0EC6CD@Seller_Information": "Seller Information",
        "AA764A@Seller_company_phone": "Seller Company Phone",
        "AA5D50@Seller_company_name": "Seller Company Name",
        "34848B@Seller_Store": "Seller Branch",
        "497B6E@Branch_phone": "Branch Phone",
        "8B5D50@Customer_Information": "Customer Information",
        "F2429D@Customer_delivery_address": "Customer Delivery Address",
        "2AD4B0@A_whole_new_way": "A whole new way to procure food ingredients for your restaurant",
        "A11641@Everything_about_your": "Find all the food ingredients you need all at once, you’re on top of your food cost anytime anywhere.",
        "3C4EF5@Customer_testimonials": "Customer testimonials",
        "244DC1@General_Manager": "General Manager",
        "5BB3B6@Real_Legendary_Pot": "Real Legendary Pot",
        "E6BE33@CHEFSTATION_helped_me": "“CHEFSTATION helped me search for ingredients and contact suppliers directly, so I don’t need to rely on referrals anymore”",
        "3587D3@Ken_Head_Chef": "Head Chef",
        "F56B05@Online_Ingredients": "The Market - Online Ingredients Marketplace",
    }, index: {
        "13A6DF@On_CHEFSTATION": "“On CHEFSTATION, ingredient prices are clear and it’s easy to compare products, saved me lots of time reading piles of quotations!”",
        "9CEFA3@Owner": "Owner",
        "CD4FCB@Director": "Director",
        "50F637@Fung_Shing_1954": "Fung Shing 1954",
        "9D9183@Order_details_are": "“Order details are automatically saved into backend data analysis, saves time and manpower in data entry. Our management team can access and control these data anytime, anywhere, which is the most effective way to monitor daily ingredient cost”",
        "97775F@All-in-one": "All-in-one",
        "DCFB04@CHEFSTATION_is_an": "CHEFSTATION is an online mobile and web application that allows businesses to manage their restaurants at their fingertips.",
        "853A2F@Key_features": "Key features",
        "06AAE8@CHEFSTATION_offers": "CHEFSTATION offers a lot of features, below are a few key ones that can help you start managing your restaurant better.",
        "0F72E4@Ingredients_Marketplace": "Find New Suppliers",
        "D8EFFC@For_you_to_find_new_suppliers": "For you to find new suppliers, compare prices, get samples and directly order through our integrated online marketplace. We are here to help you find the most suitable ingredients.",
        "83DCCA@ChefPoint_Rewards": "ChefPoint Rewards",
        "6203EE@Earn_ChefPoints": "Earn ChefPoints by purchasing on the Market, then use your ChefPoints as store credit - Buy more earn more!",
        "BB48B1@Online_Chat_with_Suppliers": "Online Chat with Suppliers",
        "5D079C@Chat_with_our": "Chat with our suppliers to get product info, latest news and recommendations for your restaurant.",
        "71FB61@My_Restaurant": "My Restaurant - Restaurant Management System",
        "3D6AEF@Procurement_Management": "Procurement Management",
        "DF064B@A_quick": "A quick, systematized way to send purchase orders and manage invoices. No more missing or late orders due to human error.",
        "CF7DF1@Inventory_Management": "Inventory Management",
        "1BFD5E@Completed_purchases": "Completed purchases automatically stored as inventory. Perform stock count and find out reorder points just got easier.",
        "D25FEA@Staff_Management": "Staff Management",
        "5D25DF@Manage_weekly": "Manage weekly staff schedule and calculate payroll electronically, minimize time cost and improve your restaurant’s efficiency.",
        "57474E@Performance_analytics": "Performance analytics",
        "D25A7C@An_efficient_way": "An efficient way of monitoring your daily cost and restaurant operation with minimal hassle. Detailed analysis shown with just one click.",
        "72DC4D@Accounting": "Accounting",
        "32CBA5@Keep_track": "Keep track of all your expenses and see real-time profit & loss reports. Say goodbye to tedious data entry.",
        "CC2163@Branch_Management": "Branch Management",
        "1F2C61@Manage_multiple": "Manage multiple branches in one account, and set permissions to allow different employees to handle their related roles, manage your restaurant in one place.",
        "6E62C7@Ready_for_CHEFSTATION": "Ready for CHEFSTATION?",
        "4BB965@Start_managing": "Start managing your restaurant in one place.",
        "042056@Get_inspired": "Get inspired and stay up-to-date",
        "C2AAF2@MORE_FROM_OUR_BLOG": "MORE FROM OUR BLOG",
        "5F97F7@Suppliers_are": "Suppliers are welcome to join the CHEFSTATION platform to reach more potential restaurant clients and expand your business.",
        "EB28A6@Are_you_a_food": "Are you a food supplier?",
        "E067C0@CHEFSTATION_is_an": "CHEFSTATION is an all-in-one food ingredients procurement solution built for restaurant businesses.",
        "86BC36@CONTACT_US": "CONTACT US",
        "CBD2F0@CHEFSTATION_Procurement": "“CHEFSTATION’s Procurement Management is systemised and simple to use, my procurement orders are now organised and can match with supplier’s invoice”",
        "E7A5A0@All_right_reserved": "All right reserved.",
        "CA31BF@mail": "mail",
        "ABA598@Marketing_email": "Marketing email",
        "B6DB10@release_time": "release time",
    }, HowItWorks: {
        "F7FEB1@Online_restaurant": "Online restaurant supplies market",
        "4F5465@Search_for_ingredients": "Search for ingredients on your computer, tablet or mobile phone. For you to find new suppliers, compare prices, get samples and directly order through our integrated online marketplace. We are here to help you find the most suitable ingredients.",
        "2339A7@ChefPoints_Rewards": "ChefPoints Rewards",
        "CAED12@Earn_ChefPoints": "Earn ChefPoints by purchasing on Marketplace, then use your ChefPoints as store credit - The more you buy the more you earn!",
        "9E6A1C@Manage_your": "Manage your restaurant at fingertips",
        "2AD916@Discover_Product_Prices": "Discover Product Prices",
        "92271D@Request_Sample": "Request Sample",
        "CF7DC1@Request_Special_Price": "Request Special Price",
        "770A8D@Place_Purchase_Orders": "Place Purchase Orders",
        "889D52@View_Orders": "View Orders",
        "BC2E92@Supplier_Product_Catalogue": "Supplier Product Catalogue",
        "3D6CE9@Place_Purchase": "Place Purchase Orders with Your Own Suppliers (WhatsApp or Email)",
        "40347B@Repeat_Regular_Orders": "Repeat Regular Orders",
        "EFEE42@Manage_Purchase_Orders": "Manage Purchase Orders",
        "606C5C@Manage_Product_Categories": "Manage Product Categories",
        "23A696@Auto_Record": "Auto Record Purchases into Inventory",
        "D616C8@Perform_Stock_Count": "Perform Stock Count",
        "842351@Set_Reorder_Point": "Set Reorder Point",
        "A4850C@Set_Staff_Permissions": "Set Staff Permissions",
        "EF9DAD@Create_Staff_Profiles": "Create Staff Profiles",
        "7BDA52@Manage_Weekly_Schedule": "Manage Weekly Schedule",
        "7B9064@Manage_Payroll": "Manage Payroll",
        "FA11DB@Auto_Record_Purchases": "Auto Record Purchases",
        "F033ED@Auto_Record_Payroll": "Auto Record Payroll",
        "9E9C97@Sales_and": "Sales and Expense Entries",
        "930C92@Real-Time_Profit": "Real-Time Profit & Loss Report",
        "4BCDF8@Auto_Record_Payroll": "Auto Record Payroll",
        "07F4DE@Sales_and_Expense_Entries": "Sales and Expense Entries",
        "C65183@Sales_COGS": "Sales, COGS and Expense Ledger",
        "539650@Inventory_Report": "Inventory Report",
        "26438F@Monitor_Payables": "Monitor Payables",
        "FD463C@Procurement_Analysis": "Procurement Analysis",
        "C59091@Track_Price_Changes": "Track Price Changes",
        "ABED8F@Wastage_Analysis": "Wastage Analysis",
        "BFFF2A@Food_distribution_and": "Food distribution and F&B are major industry sectors of every economy. As we enter the digital era where the world is connected by innovative technology, effective use of online tools can accelerate the development of various traditional industries, reduce costs and promote the scale of sales. Being a part of platformization is undoubtedly an important step for the new era.",
        "81B2F3@Our_offerings": "Our offerings",
        "DA7ED9@ChefStation_is_Hong_Kong": "ChefStation is Hong Kong's first integrated ingredients platform, which connects food suppliers and various types of restaurants. Food suppliers can increase exposure of their company's products through the platform, reach more potential customers and stay up to date to market information. Restaurants can search for ingredients around the world, compare prices and quality of similar ingredients in the fastest and most convenient way, and use our backend data analysis to effectively manage their procurement process.",
        "EBF838@Our_team": "Our team",
        "DB42EE@Chefstation_team": "Chefstation's team consists of overseas professionals, technology teams and F&B management experts. We firmly believe that the future trading of goods will be led by IT and hence began development in this field, aiming to bring companies to the path of platformization with our innovation. The team focuses on the development direction and strategy, and has a strict operation system to ensure continuous development and improvement of our services.",
        "B200B7@Whether_it_is": "Whether it is technical support on ChefStation, or any other problems you encountered during use, we are happy to answer your questions. You can first check out the FAQ and if your issues are not answered, we are here to help!",
        "780084@FAQ": "FAQ",
        "8E68A4@Contact_us_for_support": "Contact us for support",
    }, Contact: {
        "70E47A@ChefStation_is_more": "ChefStation is more than happy to listen to your question or business requirements. Our professional team will get back to you as soon as possible!",
        "42FDF8@Email": "E-mail",
        "476B88@Company_name": "Company name",
        "156127@Enquiry_type": "Enquiry type (Restaurant, Supplier, Tech Support)",
        "95050B@Message": "Message",
        "D3F802@By_ticking": "By ticking this box you agree ChefStation to contact you for promotional material in the future.",
        "7CB5F1@SUBMIT": "SUBMIT",
        "5C0AEF@Address_Room": "Room 301, 3/F, Kai Wong Commercial Building, 222 Queen's Road Central, Hong Kong",
        "5ABD35@Connect_with_us": "Connect with us",
        "E3C4E4@PRODUCT": "PRODUCT",
        "8AF1D8@How_it_works": "How it works",
        "236572@COMPANY": "COMPANY",
        "129684@BLOG": "BLOG",
        "F7D2F0@Restaurant": "Restaurant",
        "7A9192@Tech_Support": "Tech Support",
        "9EC622@Whether_to_Deal_With": "Whether to Deal With",
        "515150@Home_page_display": "Home page display",
        "615150@Home_page_display": "Leading Product",
    },
};
