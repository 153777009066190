<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane :label="$t('register_user.restaurant_user')">
        <section class="flex-end">
          <el-input prefix-icon="el-icon-search" type="text" v-model="search"
                    style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
        </section>
        <el-table
            :data="restaurantList"
            style="width: 100%">
          <!--     公司名稱     -->
          <el-table-column prop="company_name" align="center"
                           :label="$t('backend_shop_supplierList.995038@company_name')"></el-table-column>
          <!--     聯絡人     -->
          <el-table-column prop="contact" align="center"
                           :label="$t('restaurant_store.1DC508@contact')">

          </el-table-column>
          <!--     email     -->
          <el-table-column prop="email" align="center"
                           :label="$t('backend_config_platformInfo.837359@email')"></el-table-column>
          <!--     連絡電話     -->
          <el-table-column prop="telephone" align="center"
                           :label="$t('backend_config_platformInfo.936621@phone')"></el-table-column>
          <!--     公司地址     -->
          <el-table-column prop="address" align="center" :label="$t('register_user.company_address')">
            <template slot-scope="scope">
              <span>{{ scope.row.addr }}</span>
            </template>
          </el-table-column>
          <!--     商業登記證     -->
          <el-table-column prop="telephone" align="center" :label="$t('register_user.brc')">
            <template slot-scope="scope">
              <div v-if="scope.row.has_br==0">
                <div>{{ `${$t('register_user.br_no')}：${scope.row.br_no}` }}</div>
                <div>{{ `${$t('register_user.br_address')}：${scope.row.br_address}` }}</div>
                <div v-if="scope.row.br_pic">
                  <section>{{ `${$t('register_user.br_pic')}` }}</section>
                  <el-image style="width: 240px;height: 160px" :src="scope.row.br_pic"
                            :preview-src-list="[scope.row.br_pic]" fit="contain"></el-image>
                </div>
              </div>
              <div v-else>
                <span>N/A</span>
              </div>
            </template>
          </el-table-column>
          <!--     操作     -->
          <el-table-column prop="operation" align="center" :label="$t('public.03EA09@operation')">
            <template slot-scope="scope">
              <!--       批准       -->
              <kt-button
                  type="success"
                  :perm="536"
                  :label="$t('register_user.approve')"
                  @click="approveUser(scope.row.id)">
              </kt-button>
              <!--       拒絕       -->
              <!--              <kt-button-->
              <!--                  type="danger"-->
              <!--                  :perm="536"-->
              <!--                  :label="$t('register_user.reject')"-->
              <!--                  @click="alterUserStatus(scope.row.uid,1)">-->
              <!--              </kt-button>-->
            </template>
          </el-table-column>
        </el-table>
        <section class="pagination-container" style="float: right">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[15,25,50,100]"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </section>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import KtButton from "../../components/ktButton/KtButton";

export default {
  name: "",
  components: {KtButton},
  watch: {
    //搜尋文字
    search() {
      this.getRestaurantRegister()
    }
  },
  data() {
    return {
      page: 1,
      size: 15,
      total: 0,
      //搜尋文字
      search: "",
      //餐廳註冊用戶
      restaurantList: [],
    }
  },
  created() {
    this.getRestaurantRegister()
  },
  methods: {
    //拒絕使用者
    rejectUser() {

    },
    //批准使用者通過
    approveUser(companyID) {
      this.$http.approveUser({
        companyID: companyID
      }).then(res => {
        if (res.errno == 0) {
          this.getRestaurantRegister()
        }
      })

    },
    handleSizeChange(val) {
      this.size = val;
      this.getRestaurantRegister();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRestaurantRegister();
    },
    //取得餐廳註冊使用者
    getRestaurantRegister() {
      this.$http.getRestaurantRegister({
        size: this.size, page: this.page, search: this.search
      }).then(res => {
        if (res.errno == 0) {
          let data = res.data
          this.restaurantList = data.list
          this.total = data.total
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
