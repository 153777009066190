<!-- PagesComponents -->
<template>
  <section class="PagesComponents">
    <section class="justify-between mb20">
      <section class="flex-items">
        <el-date-picker
            v-model="datetime"
            type="daterange"
            value-format="timestamp"
            @change="changeDatetime"
            range-separator="~"
            :start-placeholder="$t('placeholder.524620@start_time')"
            :end-placeholder="$t('placeholder.ABB7CF@end_time')"
            :default-time="['00:00:00', '23:59:59']"
            style="width: 220px"
            class="mr10"
        ></el-date-picker>
        <el-select
            v-if="type == 2"
            v-model="is_b2b"
            class="mr10"
            @change="changeFilter"
            clearable
            filterable
            style="width: 150px"
        >
          <el-option
              v-for="item in orderList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <el-cascader
            v-if="type == 2"
            v-model="idArr"
            :options="CompanyList"
            clearable
            filterable
            @clear="clear"
            :props="{
            children: 'shop_list',
            label: 'name',
            value: 'id',
            checkStrictly: true,
          }"
            @change="getCheckedNodes"
            :placeholder="
            $t('AverageOrderPrice.AverageOrderPrice_content.ChooseARestaurant')
          "
        ></el-cascader>
        <el-select
            v-else
            v-model="company_id"
            class="mr10"
            @change="changeFilter"
            clearable
            :placeholder="
            $t('AverageOrderPrice.AverageOrderPrice_content.ChooseSupplier')
          "
            style="width: 250px"
        >
          <template v-if="type == 2">
            <el-option
                v-for="item in CompanyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </template>
          <template v-else>
            <el-option
                v-for="item in CompanyList"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id"
            ></el-option>
          </template>
        </el-select>
      </section>
      <el-button
          type="primary"
          class="table-btn btn-gray"
          size="default"
          @click="downloadexcel"
      >{{ $t("aboutus.2AECD0@Export_Order") }}
      </el-button
      >
    </section>
    <section>
      <el-table
          show-summary
          :summary-method="getDataSummariesTotal"
          border
          :data="tableData"
          v-loading="loading"
          :row-class-name="$tableRowClassName"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :highlight-current-row="true"
          :default-sort="{ prop: 'total_sales', order: 'descending' }"
          @sort-change="sortChange">
        <!--   第二層     -->
        <el-table-column type="expand" v-if="type==2">
          <template slot-scope="data">
            <el-table
                show-summary
                :summary-method="getDataSummaries"
                border
                :data="data.row.data"
                v-loading="loading"
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }"
                :highlight-current-row="true">
              <!--     第三層        -->
              <el-table-column type="expand" v-if="type==2">
                <template slot-scope="goods">
                  <el-table
                      border
                      :data="goods.row.goods"
                      v-loading="loading"
                      :header-cell-style="{ 'text-align': 'center' }"
                      :cell-style="{ 'text-align': 'center' }"
                      :highlight-current-row="true">
                    <el-table-column
                        align="center"
                        prop="goods_name"
                        :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="unit"
                        :label="$t('restaurant_supplier_addSupProduct.5A7BCB@number_unit')">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="receive_num"
                        :label="$t('orderDetail.3BB7C2@Accept_number')">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="receive_unit_price"
                        :label="$t('aboutus.96F4F8@product_price')">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="final_goods_price"
                        :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="order_no"
                  :label="$t('ActiveUser.ActiveUser_content.OrderNumber')">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="goods_sum"
                  :label="$t('restaurant_inventory.E5BE78@quantity_purchased')">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="goods_price"
                  :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="receive_deliver_fee"
                  :label="$t('aboutus.1886CC@fee')">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="final_price"
                  :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="create_time"
                  :label="$t('orderDetail.082549@order_time')">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')">
        </el-table-column>
        <el-table-column
            v-if="type==2"
            prop="shop_name"
            :label="$t('backend_authrity_grouping.28C052@store')">
        </el-table-column>
        <el-table-column
            prop="total_sales"
            sortable="custom"
            :label="$t('CumulativeSalesVolume.CumulativeSalesVolume_content.TotalSales')">
          <template slot-scope="scope">
            <div>
              {{ scope.row.total_sales ? "$" + formatMoney(Number(scope.row.total_sales).toFixed(2)) : "$0" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="total_num" sortable="custom"
                         :label="$t('CumulativeSalesVolume.CumulativeSalesVolume_content.SalesAmount')">
        </el-table-column>
      </el-table>
      <section
          style="margin-top: 20px; display: flex; justify-content: flex-end"
      >
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </section>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "PagesComponents",
  components: {},
  data() {
    //这里存放数据
    return {
      company_id: "",
      datetime: "",
      tableData: [],
      CompanyList: [],
      idArr: "",
      loading: false,
      currentPage: 1,
      pageSize: 10,
      shop_id: "",
      total: 0,
      is_b2b: "",
      // 1=>按订单数量排序 2=>按金额排序
      sort_type: 2,
      // 1=>正序 2=>倒序
      sort: 2,
      orderList: [
        {
          label: this.$t("AverageOrderPrice.AverageOrderPrice_content.all"),
          value: "",
        },
        {
          label: this.$t("AverageOrderPrice.AverageOrderPrice_content.general"),
          value: 0,
        },
        {
          label: 'b2b',
          value: 1,
        },
      ],
    };
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  //方法集合
  methods: {
    //format money
    formatMoney(v) {
      return v.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
    },
    //取得最外部的統計
    getDataSummariesTotal(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '統計'
          return
        }

        if (this.type != 1) {
          if (index == 1 || index == 2) {
            sums[index] = ''
            return
          }
        }

        const values = data.map(item => column.property === 'total_sales' ? Number(item[column.property]) : Number(item[column.property].replace(",", "").replace("$", "")))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + Number(curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = column.property === 'total_sales' ? `$${this.formatMoney(sums[index].toFixed(2))}元` : `${this.formatMoney(sums[index].toFixed(2))}`
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getDataSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '統計'
          return
        }
        const values = data.map(item => Number(item[column.property].replace("$", "").replace(",", "")))
        if (!values.every(value => isNaN(value))) {
          let data = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          switch (column.property) {
            case 'goods_sum':
              sums[index] = this.formatMoney(data.toFixed(3))
              break;
            case 'goods_price':
              sums[index] = `$${this.formatMoney(data.toFixed(2))}`
              break;
            case 'receive_deliver_fee':
              sums[index] = `$${this.formatMoney(data.toFixed(2))}`
              break;
            case 'final_price':
              sums[index] = `$${this.formatMoney(data.toFixed(2))}`
              break;
          }
        }
      })
      return sums
    },
    getAddUpSales() {
      let params = {
        sort_type: this.sort_type,
        sort: this.sort,
        project: this.type,
        company_id: this.company_id || "",
        shop_id: this.shop_id,
        start_time: this.datetime ? this.datetime[0] / 1000 : "",
        end_time: this.datetime ? this.datetime[1] / 1000 : "",
        is_b2b: this.is_b2b,
        size: this.pageSize,
        page: this.currentPage,
      };
      this.loading = true;
      this.$http.AddUpSales(params).then((res) => {
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    changeDatetime(val) {
      this.datetime = val || "";
      this.currentPage = 1;
      this.getAddUpSales();
    },
    changeFilter() {
      this.currentPage = 1;
      this.getAddUpSales();
    },
    downloadexcel() {
      this.$http.AddUpSalesExcel({
        sort_type: this.sort_type,
        sort: this.sort,
        project: this.type,
        company_id: this.company_id || "",
        shop_id: this.shop_id,
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
        is_b2b: this.is_b2b,
        size: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        this.downloadFile(res, this.type == 2 ? "CumulativeSalesVolume_Restaurant.xlsx" : "CumulativeSalesVolume_Supplier.xlsx");
      });
    },
    // 排序
    sortChange({column, prop, order}) {
      this.sort_type = prop == "total_num" ? 1 : 2
      this.sort = order == "ascending" ? 1 : 2
      this.getAddUpSales()
    },
    // 获取选择器
    getCompanyList() {
      if (this.type == 2) {
        this.$http.restaurantShopSelector().then((res) => {
          this.CompanyList = res.data.filter(s => {
            return s.id != 41 && s.id != 45 && s.id != 47 && s.id != 68 &&
                s.id != 74 && s.id != 423 && s.id != 428 && s.id != 432 &&
                s.id != 596 && s.id != 597 && s.id != 598 && s.id != 599 &&
                s.id != 600 && s.id != 601 && s.id != 718 && s.id != 807 &&
                s.id != 825
          })
        })
      } else {
        this.$http.b2bSupplierSelector().then((res) => {
          this.CompanyList = res.data.filter(s => {
            return s.company_id != 43 && s.company_id != 44 && s.company_id != 46 && s.company_id != 429 &&
                s.company_id != 587 && s.company_id != 588
          })
        })
      }
    },
    clear() {
      this.getAddUpSales();
    },
    getCheckedNodes(idArr) {
      this.company_id = idArr[0];
      this.shop_id = idArr[1] || "";
      this.currentPage = 1;
      this.getAddUpSales();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAddUpSales();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAddUpSales();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAddUpSales();
    this.getCompanyList();
  },
};
</script>
<style src='./PagesComponents.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
