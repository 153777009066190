<template>
  <div class="no-authrity" style="margin-top:100px;">
    <el-row style="display:flex;align-item:center;">
      <el-col :span="12">
        <div style="width:50%;margin:0 auto;">
          <img class="img-auto" src="./img/authrity.jpg" />
        </div>
      </el-col>
      <el-col :span="12">
        <div style="display:flex;flex-direction: column;align-content: center">
          <h2 style="display:flex;flex-direction: column;;color:#409EFF;font-weight:900;">
            Sorry,
            <p>您沒有訪問權限！</p>
          </h2>
          <el-button style="width:100px" size="small" type="primary" @click="toIndex">返回首頁</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  methods: {
    toIndex () {
      this.$router.go(-1);
    }
  }
}
</script>
<style scoped>
.h {
  display: flex;
  flex-direction: column;
  align-content: center;
}
</style>