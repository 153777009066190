<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.EF42B5@email_list')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <el-table
        :data="tableData"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column prop="id" label="ID" align="center" width="50"></el-table-column>
        <el-table-column
          prop="type"
          :label="$t('backend_emailList.1892CE@email_type')"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="title"
          :label="$t('backend_emailList.A062DF@email_title')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="from_email"
          :label="$t('backend_emailList.D7675B@send_address')"
          align="center"
          min-width="180"
        ></el-table-column>
        <el-table-column
          prop="to_email"
          :label="$t('backend_emailList.28EE7F@receive_address')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="mark_read"
          :label="$t('backend_emailList.C31D79@isReaded')"
          align="center"
        >
          <template scope="scope">
            <span v-if="scope.row.mark_read">Yes</span>
            <span v-else>No</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('backend_emailList.0C2F58@send_time')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="read_time"
          :label="$t('backend_emailList.B1D7F4@read_time')"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.read_time">{{$t('public.4E63B1@unread')}}</span>
            <span v-else>{{scope.row.read_time}}</span>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";
import api from "../../api/index";
export default {
  data() {
    return {
      loading: true,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
  },

  methods: {
    getTableData() {
      api
        .getEmailList({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.tableData = res.data.list;
          this.total = res.data.sum;
          this.loading = false;
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-left {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>