<template>
  <div class="favorite-container">
    <el-card>
      <!--   卡片標題   -->
      <div slot="header">
        <h4 style="font-weight: 500">{{ $t('public.9AF601@unit_converter') }}</h4>
      </div>
      <section style="display: flex;justify-content: flex-end">
        <el-button size="small" @click="showDialog" type="primary">{{ $t('public.DC9752@btn_add') }}</el-button>
      </section>
      <section class="justify-between">
      </section>
      <el-table
          :data="tableData"
          border
          class="mt20"
          :highlight-current-row="true"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
        <el-table-column
            show-overflow-tooltip
            prop="name"
            :label="$t('aboutus.06951D@Group_Name')">
        </el-table-column>
        <!--    備註    -->
        <el-table-column
            prop="remark"
            show-overflow-tooltip
            :label="$t('restaurant_supplier_supplierList.E318B6@desc')">
          <template slot-scope="scope">
            <div v-html="scope.row.unit_str"></div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')">
          <template slot-scope="scope">
            <!--    修改      -->
<!--            <el-button size="small" type="primary" @click="showModifyDialog(scope.row)">-->
<!--              {{ $t('public.B6D57F@btn_modify') }}-->
<!--            </el-button>-->
            <!--    刪除   -->
            <el-button size="small" type="danger" @click="del(scope.row.id)">
              {{ $t('public.1FBB5E@btn_delete') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[20,50,100,250]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <!--    對話視窗 -->
    <el-dialog
        :title="isModify ? $t('public.B6D57F@btn_modify') : $t('public.DC9752@btn_add')"
        center
        width="80%"
        :visible.sync="dialogShow">
      <el-form ref="form" :model="dialogForm" :rules="rules" label-width="150px">
        <el-row>
          <el-col :span="18" :offset="2">
            <!--    群組中文名稱  -->
            <el-form-item :label="$t('public.98E123@group_name_hk')" prop="name_hk">
              <el-input style="width:100%;" v-model="dialogForm.name_hk"></el-input>
            </el-form-item>
            <!--      群組英文名稱  -->
            <el-form-item :label="$t('public.A5E465@group_name_en')" prop="name_en">
              <el-input style="width:100%;" v-model="dialogForm.name_en"></el-input>
            </el-form-item>
            <!--         啟用狀態   -->
            <el-form-item :label="$t('backend_authrity_user.07D89C@status')" prop="is_use">
              <!--      狀態開關        -->
              <el-switch
                  v-model="dialogForm.is_use"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
            <el-form-item label="轉換清單">
              <el-select v-model="dialogForm.unitGroup" filterable multiple>
                <el-option v-for="item in unitList" :key="item.unit_id" :value="item.unit_id"
                           :label="item.unit_name">
                </el-option>
              </el-select>
              <el-table v-if="dialogForm.unitGroup.length"
                        :data="dialogForm.unitList"
                        border
                        style="margin-top: 10px; width: 100%">
                <el-table-column align="center" width="80" type="index"
                                 :label="$t('supplier_supplier_produceManage.4D7AF5@Quantity_unit')" :index="index2Str">
                </el-table-column>
                <el-table-column align="center" width="160" v-for="(item,index) in dialogForm.unitList"
                                 :label="item.unit_name" :key="index">
                  <template slot-scope="scope">
                    <el-input-number size="small" :precision="4" v-if="scope.$index!=index"
                                     v-model="dialogForm.unitList[scope.$index].list[index]"></el-input-number>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button @click="addOrModify" type="primary">
          {{ isModify ? $t('public.B6D57F@btn_modify') : $t('public.DC9752@btn_add') }}
        </el-button>
        <el-button @click="dialogShow = false" type="info">
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import KtButton from "components/ktButton/KtButton"

export default {
  name: "",
  components: {KtButton},
  comments: {
    KtButton
  },
  watch: {
    "dialogForm.unitGroup"() {
      this.dialogForm.unitList = []
      this.dialogForm.unitGroup.forEach(s => {
        let unit = this.unitList.find(u => s == u.unit_id)
        if (unit) {
          unit.list = []
          this.dialogForm.unitList.push(unit)
        }
      })
    }
  },
  data() {
    return {
      //表單驗證規則
      rules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
      },
      //表單資料
      dialogForm: {
        //中文名稱
        name_hk: "",
        //英文名稱
        name_en: "",
        //是否使用
        is_use: true,
        //單位換算群組
        unitGroup: [],
        //單位清單
        unitList: [],
        //id
        id: "",
      },
      //資料
      tableData: [],
      //總筆數
      total: 0,
      //當前分頁
      currentPage: 1,
      //尺寸
      pageSize: 20,
      //當前語言
      lang: this.$i18n.locale == 'hk',
      //顯示表單
      dialogShow: false,
      //新增或修改
      isModify: false,
      //單位清單
      unitList: [],
    }
  },
  methods: {
    //自定義Index方法
    index2Str(index) {
      return this.dialogForm.unitList[index].unit_name
    },
    //刪除
    del(id) {
      this.$http.deleteUnitConverter({
        id: id
      }).then((res) => {
        if (res.errno == 0) {
          this.getTableData()
        }
      })
    },
    //強制更新UI
    refresh() {
      this.$forceUpdate()
    },
    //清理表單參數
    clean() {
      //中文名稱
      this.dialogForm.name_hk = ""
      //英文名稱
      this.dialogForm.name_en = ""
      //是否使用
      this.dialogForm.is_use = true
      //清除預設陣列
      this.dialogForm.unitGroup = []
      //清除陣列
      this.dialogForm.unitList = []
    },
    //新增或修改
    addOrModify(id) {
      let that = this
      function getUnitData(){
        let unitGroup = that.dialogForm.unitGroup
        let unitList = that.dialogForm.unitList
        let data = []
        let ugLength = unitGroup.length
        unitGroup.forEach((ug, i) => {
          let temp = {unit_id: ug, list: []}
          for (let j = 0; j < ugLength; j++) {
            let v = unitList[i].list[j]
            if (!v) {
              temp.list.push({
                unit_id: ug,
                eq: 1
              })
            } else {
              temp.list.push({
                unit_id: unitGroup[j],
                eq: v
              })
            }
          }
          data.push(temp)
        })
        return data
      }

      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.isModify) {
            //修改
            this.$http.modifyUnitConverter({
              id: this.dialogForm.id,
              name_hk: this.dialogForm.name_hk,
              name_en: this.dialogForm.name_en,
              is_use: this.dialogForm.is_use,
              unit_group: JSON.stringify(this.dialogForm.unitGroup),
            }).then((res) => {
              if (res.errno == 0) {
                this.clean()
                this.dialogShow = false
                this.getTableData()
                this.id = ""
              }
            })
          } else {
            //新增
            this.$http.addUnitConverter({
              name_hk: this.dialogForm.name_hk,
              name_en: this.dialogForm.name_en,
              is_use: this.dialogForm.is_use,
              unit_group: JSON.stringify(getUnitData()),
            }).then((res) => {
              if (res.errno == 0) {
                this.clean()
                this.dialogShow = false
                this.getTableData()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    //取得單位轉換清單
    getUnitConverterList() {
      this.$http.getUnitConverterList({
        page: this.currentPage,
        size: this.pageSize,
      }).then((res) => {
        let data = res.data
        this.total = data.total
        this.tableData = data.list
      })
    },
    getAllUnitList() {
      this.$http.getAllUnitList().then(res => {
        this.unitList = res.data
      })
    },
    //取得單位轉換列表
    getTableData() {
      this.getAllUnitList()
      this.getUnitConverterList()
    },
    //顯示修改視窗
    showModifyDialog(data) {
      this.clean()
      this.dialogForm.is_use = data.is_use ? true : false
      this.dialogForm.name_en = data.name_en
      this.dialogForm.name_hk = data.name_hk
      this.dialogForm.unitGroup = []
      this.dialogForm.id = data.id
      let ugArray = JSON.parse(data.unit_group)
      ugArray.forEach(item => {
        let uc = new Unit
        uc.number = item.number
        uc.unit = item.unit
        this.dialogForm.unitGroup.push(uc)
      })
      this.isModify = true
      this.dialogShow = true
    },
    //顯示新增視窗
    showDialog() {
      this.clean()
      this.isModify = false
      this.dialogShow = true
    },
    //尺寸改變時
    handleSizeChange(val) {
      this.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
  },
  created() {
    this.getTableData()
  },
}
</script>

<style scoped lang="scss">
.top-pagination-container {
  margin-top: 0px;
  margin-bottom: 20px;
  overflow: hidden;

  .addButton {
    float: right;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}


.btn-add {
  background: transparent;
}

.btn-add:focus,
.btn-add:hover {
  color: green;
}

.btn-sub {
  background: transparent;
}

.btn-sub:focus,
.btn-sub:hover {
  color: red;
}

.btn-up-down {
  background: transparent;
}

.btn-up-down:focus,
.btn-up-down:hover {
  color: deepskyblue;
}
</style>
