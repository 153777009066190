<template>
  <div class="company">
    <page-top :title="$t('backend_submenu.157A9D@platform_Info')">
      <span style="font-size:26px;" class="fa fa-cog"></span>
    </page-top>
    <el-card>
      <section slot="header">
        <div slot="header" class="header">
          <i class="fa fa-info-circle"></i>
          <span class="title">{{$t('backend_submenu.157A9D@platform_Info')}}</span>
        </div>
      </section>
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-row>
          <el-col :span="16" :offset="3">
            <el-form-item :label="$t('backend_config_platformInfo.837359@email')" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_config_platformInfo.936621@phone')" prop="phone">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_config_platformInfo.607689@Copyright')" prop="copy">
              <el-input v-model="form.copy"></el-input>
            </el-form-item>
            <el-form-item :label="`Invoice ${$t('backend_emailList.0C2F58@send_time')}`" prop="invoice_config">
              每個月第
              <el-select filterable clearable v-model="form.day">
              <el-option v-for="item in 31" :key="item" :value="item" :label="item"></el-option>
              </el-select> 天<el-time-select clearable v-model="form.time" :picker-options="{start: '00:00',step: '00:30',end: '23:59'}"></el-time-select>發送給供應商
            </el-form-item>
            <el-form-item :label="$t('public.unconfirmed_order')" prop="unconfirmed_config">
              超過
              <el-select filterable clearable v-model="form.day2">
                <el-option v-for="item in 31" :key="item" :value="item" :label="item"></el-option>
              </el-select> 天未確認，將每天 <el-time-select clearable v-model="form.time2" :picker-options="{start: '00:00',step: '00:30',end: '23:59'}"></el-time-select>發送E-mail提醒供應商
            </el-form-item>
            <el-form-item label="LOGO" prop="logo">
              <el-upload
                ref="upload"
                class="avatar-uploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
                action="no"
                :http-request="(param)=>uploadFile(param,0)"
              >
                <img v-if="form.logo" :src="form.logo" class="img-auto" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item
              :label="$t('backend_config_platformInfo.D6C85F@bottomLogo')"
              prop="bottom_logo"
            >
              <el-upload
                ref="upload"
                class="avatar-uploader"
                :before-upload="beforeAvatarUpload"
                :show-file-list="false"
                action="no"
                :http-request="(param)=>uploadFile(param,1)"
              >
                <img v-if="form.bottom_logo" :src="form.bottom_logo" class="img-auto" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>

            <el-form-item>
              <section class="btn-section">
                <!-- <el-button
                  class="form-btn btn-yellow"
                  @click="updatePlatform('form')"
                >{{$t('btn.gx')}}</el-button>-->
                <KtButton
                  :label="$t('public.A738BA@update')"
                  class="form-btn btn-yellow"
                  :perm="6938"
                  @click="updatePlatform('form')"
                ></KtButton>
              </section>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import api from "../../api/index";
export default {
  data() {
    return {
      form: {
        time2:"",
        day2:"",
        time:"",
        day:"",
        bottom_logo: "",
        copy: "",
        email: "",
        logo: "",
        phone: ""
      },
      rules: {
        bottom_logo: { required: true, message: "Please enter" },
        copy: { required: true, message: "Please enter" },
        email: { required: true, message: "Please enter" },
        logo: { required: true, message: "Please enter" },
        phone: { required: true, message: "Please enter" },
      }
    };
  },
  components: {
    pageTop,
    KtButton
  },
  created() {
    this.getPlatformInfo();
  },
  methods: {
    getPlatformInfo() {
      api.getPlatformInfo().then(res => {
        console.log(res);
        Object.assign(this.form, res.data);
      });
    },
    // 自定义上传图片
    uploadFile(param, index) {
      console.log(param);
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(param["file"]);
      reader.onload = function(event) {
        if (index) {
          that.form.bottom_logo = this.result;
        } else {
          that.form.logo = this.result;
        }
        // that.diaomaozhukang(this.result);
      };
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".");
      let type = fileNameArr[fileNameArr.length - 1].toLowerCase()

      const isJPG = Boolean(type == "jpg");
      const isLt2M = Boolean(file.size / 1024 / 1024 < 5);

      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"));
      }
      return isJPG && isLt2M;
    },
    updatePlatform(formName) {
      this.$refs[formName].validate(valid => {
        if(!this.form.time || !this.form.day){
          this.$message.warning(this.$t('shop-goods.5E8023@Please_complete_the_form'))
          return
        }

        if (valid) {
          api.updatePlatform({
            email:this.form.email,
            phone:this.form.phone,
            copy:this.form.copy,
            logo:this.form.logo,
            bottom_logo:this.form.bottom_logo,
            invoice_config:JSON.stringify({
              day:this.form.day,
              time:this.form.time,
            }),
            unconfirmed_config:JSON.stringify({
              days:this.form.day2,
              time:this.form.time2,
            })
          }).then(res => {
            if(res.errno==0){
              this.getPlatformInfo()
            }
          })
        } else {
          // this.$message.warning("请将表单填写完整！");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.company {
  .header {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 900;
    span {
      margin-left: 10px;
    }
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
  .btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
