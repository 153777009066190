<template>
  <div>
    <page-top :title="$t('invoice.AE64AD@menu')">
      <span class="fa fa-money fa-2x"></span>
    </page-top>
    <el-card class="box-card">
      <section>
        <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="width:25vw;padding-bottom: 1vh;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
      </section>
      <el-table border
                :data="search"
                :highlight-current-row="true"
                v-loading="loading"
                :element-loading-text="$t('public.4ABAB1@loading')"
                :header-cell-style="{'text-align':'center',}">
        <!--    公司ID    -->
        <el-table-column :label="$t('backend_shop_supplierList.8F71F5@company_id')" align="center" prop="company_id"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    公司名稱    -->
        <el-table-column :label="$t('backend_shop_supplierList.995038@company_name')" align="center" prop="company_name"
                         min-width="3vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    發票日期    -->
        <el-table-column :label="$t('invoice.F9100D@invoice_date')" align="center" prop="invoice_date"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    發票號碼    -->
        <el-table-column :label="$t('invoice.0DE312@invoice_number')" align="center" prop="invoice_number"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    總銷售金額    -->
        <el-table-column :label="$t('invoice.A886987@gmv')" prop="gmv" min-width="1.5vw"
                         align="right" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    抽成率    -->
        <el-table-column :label="$t('invoice.EA5264@cost_ratio')" prop="cost_ratio" min-width="1vw"
                         align="center">
          <template slot-scope="scope">
            {{ scope.row.cost_ratio }}%
          </template>
        </el-table-column>
        <!--    發票金額    -->
        <el-table-column :label="$t('invoice.9E1612@invoice_amount')" prop="amount" min-width="1vw"
                         align="right" :sort-orders="['ascending', 'descending']" sortable></el-table-column>
        <!--    付款狀態    -->
        <el-table-column :label="$t('invoice.84D521@pay_status')" prop="pay_status" min-width="2vw"
                         align="center" :sort-orders="['ascending', 'descending']" sortable>
          <template slot-scope="score">
            <el-switch v-model="score.row.payment_status" @change="updateInvoicePaymentStatus(score.row)"
                       :active-value="1" :active-text="$t('invoice.A8312E@paid')" active-color="darkgreen"
                       :inactive-value="0" :inactive-text="$t('invoice.F5D21D@unpaid')" inactive-color="red">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="btn" min-width="3vw" align="center">
          <template slot-scope="scope">
            <el-row :gutter="24">
              <!--      管理發票      -->
              <el-button type="success" size="small" @click="showDialog(scope.row)">
                {{ $t('invoice.AE5D11@manage_invoice') }}
              </el-button>
              <!--      查看發票      -->
              <el-button type="primary" size="small" @click="goPrintDetail(scope.row)">
                {{ $t('invoice.ED8512@click_to_see_invoice') }}
              </el-button>
            </el-row>
            <el-row :gutter="24" style="margin-top: calc(1vh)">
              <!--      發送給用戶      -->
              <el-button type="info" size="small" @click="sendInvoiceMail(scope.row)">
                {{ $t('invoice.85EA12@click_to_email_client') }}
              </el-button>
              <!--      下載PDF      -->
              <el-button type="danger" size="small" @click="downloadInvoicePDF(scope.row)">
                {{ $t('invoice.FD5EA1@download_pdf') }}
              </el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <section class="mt20" style="display: flex;justify-content: start">
        <el-button type="primary" size="small" @click="downloadMonthExcel">
          {{ $t('invoice.E5712A@export_current_month_btn') }}
        </el-button>
        <el-button type="success" size="small" @click="downloadYearExcel">
          {{ $t('invoice.E86FF82@export_current_year_btn') }}
        </el-button>
      </section>
      <section class="flex-end mt20">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10,25,50,100]"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="page"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog :visible.sync="show" width="90%" :title="info.invoice_number" center>
      <el-table border
                :data="info.list"
                :highlight-current-row="true"
                v-loading="loadingModify"
                :element-loading-text="$t('public.4ABAB1@loading')"
                :header-cell-style="{'text-align':'center',}">
        <el-table-column label="採購訂單 PO" align="center" prop="order_no"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable>
        </el-table-column>
        <el-table-column label="金額 Amount (HK$)" align="center" prop="amount"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable>
        </el-table-column>
        <el-table-column label="收費 Fee (HK$)" align="center" prop="invoice_fee"
                         min-width="1vw" :sort-orders="['ascending', 'descending']" sortable>
          <template slot-scope="scope">
            <el-input-number
                v-model="scope.row.invoice_fee"
                size="small"
                :min="0"
                :precision="2"
                :value="0.5"
                style="max-width: 125px">
            </el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <section style="padding-top: 10px;text-align: center">
        <el-button type="primary" size="small" @click="modifyInvoiceFee">
          {{ $t('public.3B45A2@confirm') }}
        </el-button>
        <el-button type="danger" size="small" @click="show=false">
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import JSZipUtils from "jszip-utils";
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import {Message} from "element-ui";

export default {
  name: "",
  components: {
    pageTop,
  },
  computed: {
    search() {
      if (this.searchText == "") {
        return this.invoiceList
      } else {
        return this.invoiceList.filter(i => {
          return i.company_name.indexOf(this.searchText) > -1 ||
              i.invoice_number.indexOf(this.searchText) > -1 ||
              i.invoice_date.indexOf(this.searchText) > -1
        })
      }
    }
  },
  data() {
    return {
      info: {},
      //show dialog
      show: false,
      //search Text
      searchText: "",
      //Invoice List
      invoiceList: [],
      //total
      total: 0,
      //size
      size: 10,
      //current page
      page: 1,
      //loading
      loading: true,
      //loadingModify
      loadingModify: true,
    }
  },
  methods: {
    modifyInvoiceFee() {
      this.$http.modifyInvoiceFee({
        orderList: JSON.stringify(this.info.list)
      }).then(res => {
        if (res.errno == 0) {
          this.show = false
          this.info = {}
          this.getInvoiceList()
        }
      })
    },
    showDialog(d) {
      this.$http.getInvoiceInfo({
        cid: d.company_id,
        invoice_date: d.invoice_date,
        invoice_number: d.invoice_number
      }).then(res => {
        if (res.errno == 0) {
          this.info = res.data
          this.info.list.forEach((i, k) => {
            let invoice_fee = parseFloat(i.invoice_fee.replace(',', ''))
            let fee = parseFloat(parseFloat(i.amount.replace(',', '')) / 100 * parseFloat(i.cost_ratio.replace(',', '')))
            if (!i.is_invoice_fee) {
              this.info.list[k].invoice_fee = invoice_fee
            } else {
              this.info.list[k].invoice_fee = fee
            }
          })
          this.show = true
          this.loadingModify = false
        }
      })
    },
    //發送發票郵件
    sendInvoiceMail(data) {
      const monthUpperArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const monthFullArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      let invoice_date = data.invoice_date
      let year = invoice_date.substring(0, invoice_date.indexOf('-'))
      let month = parseInt(invoice_date.substring(invoice_date.indexOf('-') + 1, invoice_date.lastIndexOf('-')))

      let info = {
        invoice_number: data.invoice_number,
        invoice_date: data.invoice_date,
        cid: data.company_id,
      }

      this.$http.getInvoiceInfo(info).then(res => {
        let result = res.data
        for (let i = 0; i < result.list.length; i++) {
          result.list[i].invoice_fee = parseFloat(result.list[i].invoice_fee.replace(',', '')) == 0 && !result.list[i].is_invoice_fee ? '免費' : (result.list[i].is_invoice_fee ? result.list[i].fee : result.list[i].is_invoice_fee)
        }

        JSZipUtils.getBinaryContent("/Invoice_Template.docx", (error, content) => {
          if (error) {
            Message.warning('轉檔失敗，請聯繫工程師進行修復!')
            return;
          }
          //set content
          let zip = new PizZip(content);
          //doc
          let doc = new docxtemplater().loadZip(zip);
          //mapping template data
          doc.setData(result)
          try {
            //產生
            doc.render()
            //URI
            let word = doc.getZip().generate()
            this.$http.sendInvoiceMail({
              year: year,
              month: month,
              monthUpper: monthUpperArray[month - 1],
              monthFull: monthFullArray[month - 1],
              invoice_date: data.invoice_date,
              invoice_number: data.invoice_number,
              cid: data.company_id,
              file: word,
            }).then(res => {

            })
          } catch (error) {
          }
        })
      })
    },
    //下載發票pdf
    downloadInvoicePDF(data) {
      const monthUpperArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const monthFullArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

      let invoice_date = data.invoice_date
      let info = {
        invoice_number: data.invoice_number,
        invoice_date: data.invoice_date,
        cid: data.company_id,
      }
      this.$http.getInvoiceInfo(info).then(res => {

        let result = res.data
        for (let i = 0; i < result.list.length; i++) {
          result.list[i].invoice_fee = parseFloat(result.list[i].invoice_fee.replace(',', '')) == 0 && !result.list[i].is_invoice_fee ? '免費' : (result.list[i].is_invoice_fee ? result.list[i].fee : result.list[i].is_invoice_fee)
        }

        JSZipUtils.getBinaryContent("/Invoice_Template.docx", (error, content) => {
          if (error) {
            Message.warning('轉檔失敗，請聯繫工程師進行修復!')
            return;
          }
          //set content
          let zip = new PizZip(content);
          //doc
          let doc = new docxtemplater().loadZip(zip);
          //mapping template data
          doc.setData(result)
          try {
            //產生
            doc.render()
            //URI
            let word = doc.getZip().generate()
            this.$http.exportInvoiceToPDF({
              file: word,
            }).then(res => {
              this.downloadPDF(res, data.invoice_number.replace('/', '_') + '.pdf')
            })
          } catch (error) {
          }
        })
      })
    },
//匯出本月發票Excel
    downloadMonthExcel() {
      this.$http.exportMonthInvoiceExcel().then(res => {
        this.downloadFile(res)
      })
    },
//匯出本年發票Excel
    downloadYearExcel() {
      this.$http.exportYearInvoiceExcel().then(res => {
        this.downloadFile(res)
      })
    },
//轉跳發票明細
    goPrintDetail(data) {
      const {href} = this.$router.resolve({
        name: "printInvoice",
        query: {
          date: data.invoice_date,
          cid: data.company_id,
          invoice_number: data.invoice_number,
        },
      });
      window.open(href, "_blank");
    },
//更新付款狀態
    updateInvoicePaymentStatus(data) {
      this.$http.updateInvoicePaymentStatus({
        invoice_number: data.invoice_number,
        cid: data.company_id,
        payment_status: data.payment_status
      })
    },
    getInvoiceList() {
      this.$http.getInvoiceList({
        size: this.size,
        page: this.page
      }).then(res => {
        this.invoiceList = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.size = val;
      this.getInvoiceList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getInvoiceList();
    }
  },
  created() {
    this.getInvoiceList()
  }
}
</script>

<style lang="scss" scoped>

</style>
