<!--  -->
<template>
  <div class="BillClassification">
    <page-top :title="$t('aboutus.9B9285@Bill_Classification')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <kt-button
          class="table-btn btn-yellow"
          :perm="15834015903274"
          :label="$t('public.DC9752@btn_add')"
          @click="add"
        ></kt-button>
      </section>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :data="tableData"
        row-key="bill_cid"
        :tree-props="{children: 'sub_category'}"
        :row-style="{'height':'80px'}"
      >
        <el-table-column
          prop="bill_cid"
          :label="$t('backend_productClassify.CFB3A7@classify_id')"
          align="left"
          header-align="left"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('backend_productClassify.3CAC83@classify_name')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('aboutus.225A90@created_time')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="modify_time"
          :label="$t('aboutus.13C31B@modification_time')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('public.03EA09@operation')"
          fixed="right"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <kt-button
              type="info"
              :perm="15834016746911"
              :label="$t('public.B6D57F@btn_modify')"
              @click="modify(scope.row.bill_cid)"
            ></kt-button>
            <kt-button
              :perm="15834017231347"
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              @click="delCategory(scope.row.bill_cid)"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
      :title="type==1?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')"
      center
      width="50%"
      :visible.sync="dialogFormVisible"
      top="8vh"
    >
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form ref="dataForm" :model="dialogForm" :rules="rules" label-width="160px">
            <el-form-item prop="bill_pid" :label="$t('backend_productClassify.CFB3A7@classify_id')">
              <el-select
                v-model="dialogForm.bill_pid"
                :placeholder="$t('backend_productClassify.F93B5D@default')"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.bill_cid"
                  :label="item.name"
                  :value="item.bill_cid"
                ></el-option>
              </el-select>
              <!-- <el-cascader
                v-model="dialogForm.bill_pid"
                :options="options"
                clearable
                :props="{children:'sub_category',label:'name',value:'bill_cid',emitPath:false,checkStrictly:true }"
                :placeholder="$t('backend_productClassify.F93B5D@default')"
              ></el-cascader>-->
            </el-form-item>
            <el-form-item
              prop="name_hk"
              :label="$t('backend_productClassify.1CDE5B@classify_name_hk')"
            >
              <el-input style="width:100%;" v-model="dialogForm.name_hk"></el-input>
            </el-form-item>
            <el-form-item
              prop="name_en"
              :label="$t('backend_productClassify.667CB5@classify_name_en')"
            >
              <el-input style="width:100%;" v-model="dialogForm.name_en"></el-input>
            </el-form-item>
            <el-form-item
              prop="allow_extend"
              v-show="!Boolean(dialogForm.bill_pid)"
              :label="$t('aboutus.AFA01A@sub-categories')"
            >
              <el-switch
                style="line-height: 40px"
                :active-value="1"
                :inactive-value="0"
                v-model="dialogForm.allow_extend"
                active-color="#13ce66"
              ></el-switch>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <section slot="footer">
        <el-button
          class="table-btn btn-gray"
          @click="dialogFormVisible=false"
        >{{$t('public.836FD4@cancel')}}</el-button>
        <el-button class="table-btn btn-yellow" @click="addclassify">{{$t('public.3B45A2@confirm')}}</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";
export default {
  //import引入的组件需要注入到对象中才能使用
  name: "BillClassification",
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      tableData: [],
      options: [],
      dialogFormVisible: false,
      dialogForm: {
        bill_cid: "",
        bill_pid: "",
        name_hk: "",
        name_en: "",
        allow_extend: ""
      },
      type: "",
      rules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  components: {
    pageTop,
    ktButton
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getTableData() {
      this.$http.getBillCategory().then(res => {
        this.tableData = res.data;
        this.loading = false;
      });
    },
    add() {
      this.type = 2;
      if (this.dialogForm.bill_pid || this.dialogForm.bill_cid) {
        this.$refs["dataForm"].resetFields();
      }
      this.dialogFormVisible = true;
    },
    //弹框内确认按钮
    addclassify() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            //  修改
            this.$http
              .updateBillCategory({
                bill_pid: this.dialogForm.bill_pid,
                bill_cid: this.dialogForm.bill_cid,
                name_en: this.dialogForm.name_en,
                name_hk: this.dialogForm.name_hk,
                allow_extend: this.dialogForm.allow_extend
              })
              .then(res => {
                this.getTableData();
                this.dialogFormVisible = false;
              });
          } else {
            // 新增
            this.$http
              .addBillCategory({
                bill_pid: this.dialogForm.bill_pid,
                name_en: this.dialogForm.name_en,
                name_hk: this.dialogForm.name_hk,
                allow_extend: this.dialogForm.allow_extend
              })
              .then(res => {
                this.getTableData();
                this.dialogFormVisible = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    // 账单分类选择器
    getCategorySelector() {
      this.$http.topBillCategorySelector().then(res => {
        this.options = res.data;
      });
    },
    // 点击修改按钮
    modify(bill_cid) {
      this.type = 1;
      this.$http.getBillCategoryInfo({ bill_cid }).then(res => {
        Object.assign(this.dialogForm, res.data);
        this.dialogForm.bill_pid = res.data.bill_pid
          ? this.dialogForm.bill_pid
          : "";
      });
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    //删除
    delCategory(bill_cid) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delBillCategory({
            bill_cid
          })
          .then(res => {
            this.getTableData();
          });
      });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
    this.getCategorySelector();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss'>
.BillClassification {
  .form-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    section {
      display: flex;
      align-items: center;
    }
  }

  .pagination-container {
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;

    .pagination {
      float: right;
    }
  }
}
</style>