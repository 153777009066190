<!-- supplier -->
<template>
  <section class="supplier">
    <section class="justify-between" style="margin-bottom: 50px">
      <section class="flex-items">
        <el-select
            v-model="company_id"
            class="mr10"
            @change="
            () => {
              this.getOrderAverage();
            }
          "
            filterable
            clearable
            :placeholder="$t('AverageOrderPrice.AverageOrderPrice_content.ChooseSupplier')"
            style="width: 250px"
        >
          <el-option
              v-for="item in supplierList"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
          ></el-option>
        </el-select>
        <datetimepicker
            @datetimeChange="datetimeChange"
            @dateTypeSelectChange="dateTypeSelectChange"
            :isYear="true"
        ></datetimepicker>
      </section>
      <el-button
          type="primary"
          class="table-btn btn-gray"
          size="default"
          @click="downloadexcel"
      >{{ $t("aboutus.2AECD0@Export_Order") }}
      </el-button
      >
    </section>
    <line-chart :xAxis="lineX" :chart-data="lineData"></line-chart>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import datetimepicker from "../../../components/datetimepicker";
import LineChart from "../../../components/LineChart";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "supplier",
  components: {datetimepicker, LineChart},
  data() {
    //这里存放数据
    return {
      type: "",
      dateTime: "",
      supplierList: [],
      company_id: "",
      lineX: [],
      lineData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getOrderAverage() {
      this.$http.orderAverage({
        type: this.type || 0,
        project: 1,
        is_b2b: "",
        company_id: this.company_id,
        start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
        end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
      }).then((res) => {
        this.lineX = res.data.date;
        this.lineData = res.data.amount;
      });
    },
    // 获取类型
    dateTypeSelectChange(val) {
      if (val) {
        this.type = val
      } else {
        this.type = 0
        this.dateTime = ""
        this.getOrderAverage()
      }
    },
    downloadexcel() {
      this.$http.orderAverageExcel({
        type: this.type || 0,
        project: 1,
        is_b2b: "",
        company_id: this.company_id,
        start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
        end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
      }).then((res) => {
        this.downloadFile(res, "AverageOrderPrice_Supplier.xlsx")
      })
    },
    // 获取时间
    datetimeChange(val) {
      if (val) {
        this.dateTime = val;
        this.getOrderAverage();
      }
    },
    getSupplierList() {
      this.$http.b2bSupplierSelector().then((res) => {
        this.supplierList = res.data.filter(s => {
          return s.company_id != 43 && s.company_id != 44 && s.company_id != 46 && s.company_id != 429
        })
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getOrderAverage();
    this.getSupplierList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {
  }, //生命周期 - 创建之前
  beforeMount() {
  }, //生命周期 - 挂载之前
  beforeUpdate() {
  }, //生命周期 - 更新之前
  updated() {
  }, //生命周期 - 更新之后
  beforeDestroy() {
  }, //生命周期 - 销毁之前
  destroyed() {
  }, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src='./supplier.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
