/*
 * @Author: your name
 * @Date: 2019-11-09 10:12:24
 * @LastEditTime: 2019-11-14 16:12:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\main.js
 */

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import ElementUI from 'element-ui'

import locale from 'element-ui/lib/locale'
import 'element-ui/lib/theme-chalk/index.css'
import VueTouch from 'vue-touch';

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import store from './store';
import router from './Routes';
import App from './App';
import VueI18n from 'vue-i18n'

import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.use(CKEditor);

import downLoadFile from './utils/downLoadFile'
Vue.use(downLoadFile);

import { getlocalStorage, setlocalStorage, remlocalStorage } from './utils/localStorage';
Vue.prototype.$getlocalStorage = getlocalStorage
Vue.prototype.$setlocalStorage = setlocalStorage
Vue.prototype.$remlocalStorage = remlocalStorage

// 请求
import http from './api/index'
Vue.prototype.$http = http;

Vue.use(VueTouch);
Vue.use(VueI18n)
Vue.use(BootstrapVue);
Vue.use(ElementUI);

import Print from './plugs/print'
Vue.use(Print)

import websocket from './utils/websocket';
Vue.prototype.global = websocket

import en from './i18n/lang/en.js'
import hk from './i18n/lang/zh_hk.js'
Vue.prototype.$inputLenght = function (e) {
  e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
}
Vue.prototype.$tableRowClassName = ({ row, rowIndex }) => {
  if (rowIndex % 2 == 0) {
    return 'gray';
  } else {
    return 'white';
  }
  return '';
}
const i18n = new VueI18n({
  locale: Vue.prototype.$getlocalStorage('lang') || "hk",  // 语言标识
  messages: {
    "en": en,
    "hk": hk,
  }
})
locale.i18n((key, value) => i18n.t(key, value))
Vue.config.productionTip = false;

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: h => h(App),
});



