<template>
  <section class="OrderUser">
    <page-top :title="$t('OrderUser.title')">
      <span style="font-size: 26px; padding-bottom: 10px" class="fa fa-shopping-bag"></span>
    </page-top>
    <el-card>
      <section>
        <section class="justify-between" style="padding-bottom: 10px">
          <section class="flex-items">
            <el-date-picker
                v-model="datetime"
                type="daterange"
                value-format="timestamp"
                @change="changeDatetime"
                range-separator="~"
                :start-placeholder="$t('placeholder.524620@start_time')"
                :end-placeholder="$t('placeholder.ABB7CF@end_time')"
                :default-time="['00:00:00', '23:59:59']"
                style="width: 220px"
                class="mr10">
            </el-date-picker>
          </section>
          <el-button
              type="primary"
              class="table-btn btn-gray"
              size="default"
              @click="downloadExcel">
            {{ $t("aboutus.2AECD0@Export_Order") }}
          </el-button>
        </section>
        <el-table
            border
            :data="tableData"
            v-loading="loading"
            :element-loading-text="$t('public.4ABAB1@loading')"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            :highlight-current-row="true"
        >
          <el-table-column prop="company_name"
                           :label="$t('ActiveUser.ActiveUser_content.RestaurantName')"></el-table-column>
          <el-table-column prop="unpaid"
                           :label="$t('aboutus.BA6AE5@Unpaid_order_amount')"></el-table-column>
          <el-table-column prop="paid"
                           :label="$t('aboutus.BA6AE6@paid_order_amount')"></el-table-column>
          <el-table-column prop="final_price"
                           :label="$t('aboutus.2CF1A8@Total_Purchase_Amount')"></el-table-column>
        </el-table>
        <section style="margin-top: 20px; display: flex; justify-content: flex-end">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="pageSize"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          ></el-pagination>
        </section>
      </section>
    </el-card>
  </section>
</template>

<script>
import pageTop from "components/PageTop/PageTop";

export default {
  name: "OrderUser",
  components: {pageTop},
  data() {
    return {
      datetime: "",
      loading: true,
      pageSize: 50,
      currentPage: 1,
      total: 0,
      tableData: [],
    }
  },
  methods: {
    getData() {
      this.$http.OrderUserList({
        size: this.pageSize,
        page: this.currentPage,
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
      }).then(res => {
        if (res.errno == 0) {
          this.loading = false
          this.tableData = res.data.list
          this.total = res.data.sum
        }
      })
    },
    downloadExcel() {
      this.$http.exportOrderUser({
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
      }).then((res) => {
        this.downloadFile(res, "OrderUser.xlsx")
      })
    },
    changeDatetime(val) {
      this.datetime = val || ""
      this.currentPage = 1
      this.getData()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getData()
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
