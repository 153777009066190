/*
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-01 16:31:53
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\store\index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout';
import websocket from './websocket';
import app from './modules/app'
import tab from './modules/tab'
import iframe from './modules/iframe'
import user from './modules/user'
import menu from './modules/menu'

Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    notReadCount:0,
  },
  mutations:{
    notReadCount(state, count) {
      state.notReadCount = count
    },
  },
  modules: {
    layout,
    websocket
    // app: app,
    // tab: tab,
    // iframe: iframe,
    // user: user,
    // menu: menu
  },
});
