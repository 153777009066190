<!-- FunctionUsageRecord -->
<template>
  <section class="FunctionUsageRecord">
    <page-top :title="$t('FunctionUsageRecord.FunctionUsageRecord_tit')">
      <span
        style="font-size: 26px; padding-bottom: 10px"
        class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-card>
      <section class="justify-between" style="padding-bottom: 10px">
        <el-date-picker
          v-model="datetime"
          type="daterange"
          value-format="timestamp"
          @change="changeDatetime"
          range-separator="~"
          :start-placeholder="$t('placeholder.524620@start_time')"
          :end-placeholder="$t('placeholder.ABB7CF@end_time')"
          :default-time="['00:00:00', '23:59:59']"
          style="width: 220px"
          class="mr10"
        ></el-date-picker>
        <el-button
          type="primary"
          class="table-btn btn-gray"
          size="default"
          @click="downloadexcel"
          >{{ $t("aboutus.2AECD0@Export_Order") }}</el-button
        >
      </section>
      <section>
        <el-table
            border
          :data="tableData"
          v-loading="loading"
          :row-class-name="$tableRowClassName"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }"
          :highlight-current-row="true"
        >
          <el-table-column
            prop="comments"
            :label="
              $t('FunctionUsageRecord.FunctionUsageRecord_content.FunctionName')
            "
          ></el-table-column>
          <el-table-column
            prop="number"
            :label="
              $t('FunctionUsageRecord.FunctionUsageRecord_content.UsageCount')
            "
          ></el-table-column>
          <el-table-column
            prop="r_number"
            :label="
              $t(
                'FunctionUsageRecord.FunctionUsageRecord_content.NumberOfRestaurants'
              )
            "
          ></el-table-column>
        </el-table>
        <section
          style="margin-top: 20px; display: flex; justify-content: flex-end"
        >
          <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </section>
      </section>
    </el-card>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "@/components/PageTop/PageTop";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "FunctionUsageRecord",
  components: { pageTop },
  data() {
    //这里存放数据
    return {
      datetime: "",
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getUsageLog() {
      let params = {
        size: this.pageSize,
        page: this.currentPage,
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
      };
      this.loading = true;
      this.$http.getUsageLog(params).then((res) => {
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    downloadexcel() {
      this.$http
        .getUsageLogExcel({
          size: this.pageSize,
          page: this.currentPage,
          start_time: this.datetime[0] / 1000 || "",
          end_time: this.datetime[1] / 1000 || "",
        })
        .then((res) => {
          this.downloadFile(res);
        });
    },
    changeDatetime(val) {
      this.datetime = val || "";
      this.currentPage = 1;
      this.getUsageLog();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getUsageLog();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUsageLog();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUsageLog();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src='./FunctionUsageRecord.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
