<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.526167@restaurant_user_man')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
      </section>
      <section class="flex-start">
        <el-select size="small" v-model="langOption" @change="langChange">
          <el-option :key="2" :value="2" :label="$t('rating.E2DF56@all')"></el-option>
          <el-option :key="1" :value="1"
                     :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"></el-option>
          <el-option :key="0" :value="0" :label="$t('backend_authrity_authrityList.B25958@english')"></el-option>
        </el-select>
      </section>
      <el-table
          border
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          :data="tableData"
          style="width: 100%;margin-top:20px;">
        <el-table-column
            prop="company_name"
            :label="$t('backend_shopUser_supplierUser.E916BD@company_name')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="user_name"
            :label="$t('backend_shopUser_supplierUser.26E957@username')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="nick_name"
            :label="$t('backend_shopUser_supplierUser.062848@nickname')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="status"
            :label="$t('backend_shopUser_supplierUser.9CD9E0@status')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="is_superuser"
            :label="$t('backend_shopUser_supplierUser.B3BDE4@isSuper')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="last_login_time"
            :label="$t('backend_shopUser_supplierUser.82F60F@last_login_time')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="create_time"
            :label="$t('public.D17BCA@created_time')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="opration"
            :label="$t('public.03EA09@operation')"
            align="center"
            fixed="right"
        >
          <template slot-scope="scope">
            <kt-button
                :perm="536"
                :label="$t('public.DBC110@locked')"
                @click="alterUserStatus(scope.row.uid,1)"
                v-if="scope.row.status=='NORMAL'"
            ></kt-button>
            <kt-button
                :perm="536"
                :label="$t('public.564C21@unlock')"
                @click="alterUserStatus(scope.row.uid,0)"
                v-else
            ></kt-button>
            <kt-button
                type="info"
                :label="$t('public.A1E5C5@reset_password')"
                :perm="16347127974680"
                @click="resetPassword(scope.row.uid)">
            </kt-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="sum"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import api from "../../api/index";
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";

export default {
  watch: {
    searchText() {
      this.getTableData()
    }
  },
  data() {
    return {
      langOption: 2,
      loading: true,
      select: {
        search: ""
      },
      searchText: null,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      sum: 0
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData()
  },
  methods: {
    //中英文改變
    langChange() {
      if (this.currentPage > 1) {
        this.currentPage = 1
      }
      this.getTableData()
    },
    //重置密碼
    resetPassword(uid) {
      api.resetPassword({
        uid: uid,
      })
    },
    getTableData() {
      api.getRestaurantUser({
        size: this.pageSize,
        page: this.currentPage,
        search: this.searchText,
        langOption: this.langOption,
      }).then(res => {
        this.tableData = res.data.list;
        this.sum = res.data.sum;
        this.loading = false;
      });
    },
    // 餐厅用户状态更改
    alterUserStatus(uid, e) {
      api
          .lockRestaurantUser({
            uid,
            type: e
          })
          .then(res => {
            if (res.errno == 0) {
              this.getTableData();
            }
          });
    },

    toAdd() {
      this.$router.push("/app/addFavorite");
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    fixed(row) {
      this.dialogFormVisible = true;
      Object.assign(this.dialogForm, row);
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>
