<!-- GMV -->
<template>
  <section class="GMV">
    <page-top title="GMV">
      <span
          style="font-size: 26px; padding-bottom: 10px"
          class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-card shadow="always" :body-style="{ padding: '20px' }">
      <section class="justify-between" :style="mbStyle">
        <section class="flex-items">
          <el-select v-model="gmvwhere" class="mr10" @change="() =>this.getGMVOrderAverage()"
                     :placeholder="$t('backend_authrity_authrityList.12349D@type')" style="width: 250px">
            <el-option v-for="item in gmvwhereList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <datetimepicker
              v-if="gmvwhere<6"
              @datetimeChange="datetimeChange"
              @dateTypeSelectChange="dateTypeSelectChange"
              :isYear="true"
          ></datetimepicker>
          <el-date-picker
              v-else
              v-model="datetime2"
              type="daterange"
              value-format="timestamp"
              @change="changeDatetime"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              :default-time="['00:00:00', '23:59:59']"
              style="width:220px"
          ></el-date-picker>
        </section>
        <el-button
            v-if="gmvwhere<6"
            type="primary"
            class="table-btn btn-gray"
            size="default"
            @click="downloadexcel"
        >{{ $t("aboutus.2AECD0@Export_Order") }}
        </el-button
        >
      </section>
      <div>
        <!--    第一層表格    -->
        <el-table
            v-if="gmvwhere<6"
            show-summary
            :summary-method="getSummaries"
            border
            :data="data"
            v-loading="loading"
            tooltip-effect="dark"
            style="width: 100%; margin-bottom: 30px"
            :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
            :cell-style="{ 'text-align': 'center' }">
          <!--    第二層表格    -->
          <el-table-column type="expand">
            <template slot-scope="order">
              <el-table
                  show-summary
                  :summary-method="getSummaries2"
                  v-if="order.row.order.length"
                  border
                  :data="order.row.order"
                  v-loading="loading"
                  tooltip-effect="dark"
                  style="width: 100%; margin-bottom: 30px"
                  :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
                  :cell-style="{ 'text-align': 'center' }">
                <!--    第三層表格    -->
                <el-table-column v-if="gmvwhere!=5" type="expand">
                  <template slot-scope="goods">
                    <el-table
                        border
                        :data="goods.row.goods"
                        v-loading="loading"
                        tooltip-effect="dark"
                        style="width: 100%; margin-bottom: 30px"
                        :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
                        :cell-style="{ 'text-align': 'center' }">
                      <!--    產品名稱    -->
                      <el-table-column prop="goods_name"
                                       :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')">
                      </el-table-column>
                      <!--    接收價格    -->
                      <el-table-column prop="receive_unit_price" :label="$t('aboutus.2DF5D9@pay_unit')">
                        <template slot-scope="scope">
                          <span>
                            ${{ scope.row.receive_unit_price }}
                          </span>
                        </template>
                      </el-table-column>
                      <!--    接收數量    -->
                      <el-table-column prop="receive_num" :label="$t('orderDetail.3BB7C2@Accept_number')">
                      </el-table-column>
                      <!--    產品總金額    -->
                      <el-table-column prop="final_goods_price" :label="$t('shop_cart.936345@total_price')">
                        <template slot-scope="scope">
                          <span>
                            ${{ scope.row.final_goods_price }}
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </template>
                </el-table-column>
                <!--    訂單編號    -->
                <el-table-column prop="order_no" :label="$t('placeholder.5C894A@order_code')">
                </el-table-column>
                <!--    餐廳名稱    -->
                <el-table-column prop="buyer_shop_name" :label="$t('aboutus.E8221A@restaurant_name')">
                </el-table-column>
                <!--    餐廳名稱    -->
                <el-table-column prop="seller_company_name" :label="$t('restaurant_buy_genner.586E4F@supplier_name')">
                </el-table-column>
                <!--    產品價格    -->
                <el-table-column prop="receive_goods_price" :label="$t('aboutus.96F4F8@product_price')" align="center">
                  <template slot-scope="scope">
                    <span>
                      ${{ scope.row.receive_goods_price }}
                    </span>
                  </template>
                </el-table-column>
                <!--    運費    -->
                <el-table-column prop="receive_deliver_fee" :label="$t('aboutus.1886CC@fee')" align="center">
                  <template slot-scope="scope">
                    <span>
                      ${{ scope.row.receive_deliver_fee }}
                    </span>
                  </template>
                </el-table-column>
                <!--    訂單總金額    -->
                <el-table-column prop="final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')"
                                 align="center">
                  <template slot-scope="scope">
                    <span>
                      ${{ scope.row.final_price }}
                    </span>
                  </template>
                </el-table-column>
                <!--    稅額    -->
                <el-table-column v-if="gmvwhere==1 || gmvwhere==3 || gmvwhere==5" prop="cost_ratio"
                                 :label="$t('invoice.EA5264@cost_ratio')">
                  <template slot-scope="scope">
                    {{ scope.row.cost_ratio }}%
                  </template>
                </el-table-column>
                <!--    收入    -->
                <el-table-column v-if="gmvwhere==1 || gmvwhere==3 || gmvwhere==5" prop="revenue"
                                 :label="$t('invoice.A886987@gmv')" align="center">
                  <template slot-scope="scope">
                    ${{ scope.row.revenue }}
                  </template>
                </el-table-column>
                <!--    購入商品數量    -->
                <el-table-column v-if="gmvwhere==3 || gmvwhere==4" prop="count" align="center"
                                 :label="$t('restaurant_inventory.E5BE78@quantity_purchased')">
                  <template slot-scope="scope">
                    {{ scope.row.count }}
                  </template>
                </el-table-column>
              </el-table>
              <!--    無數據    -->
              <div v-else style="text-align: center;font-weight: bold;font-size: 1em">
                {{ $t('restaurant_supplier_addSupProduct.AAB37F@no_data') }}
              </div>
            </template>
          </el-table-column>
          <!--     訂單日期     -->
          <el-table-column prop="date" :label="$t('aboutus.B0F833@order_time')">
          </el-table-column>
          <!--     訂單總金額     -->
          <el-table-column v-if="gmvwhere==1||gmvwhere==2" prop="amount" align="center"
                           :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
            <template slot-scope="scope">
              ${{ scope.row.amount }}
            </template>
          </el-table-column>
          <!--     訂單總數量     -->
          <el-table-column v-if="gmvwhere==3||gmvwhere==4" prop="order_num"
                           :label="$t('backend_dashboard.E2556D@total_order_count')">
            <template slot-scope="scope">
              {{ scope.row.order_num }}
            </template>
          </el-table-column>
          <!--     訂單收入     -->
          <el-table-column v-if="gmvwhere==5" prop="revenue" :label="$t('aboutus.3C1FAD@Revenue')">
            <template slot-scope="scope">
              ${{ scope.row.revenue }}
            </template>
          </el-table-column>
        </el-table>
        <!--    餐廳    -->
        <el-table
            v-if="gmvwhere==6"
            show-summary
            :summary-method="getSummariesRestaurant"
            border
            :data="data"
            v-loading="loading"
            tooltip-effect="dark"
            style="width: 100%; margin-bottom: 30px"
            :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
            :cell-style="{ 'text-align': 'center' }">
          <el-table-column type="expand">
            <template slot-scope="order">
              <el-table
                  border
                  show-summary
                  :summary-method="getSummariesRestaurant2"
                  :data="order.row.list"
                  v-loading="loading"
                  tooltip-effect="dark"
                  style="width: 100%; margin-bottom: 30px"
                  :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
                  :cell-style="{ 'text-align': 'center' }">
                <el-table-column prop="order_no" :label="$t('placeholder.5C894A@order_code')">
                </el-table-column>
                <el-table-column prop="buyer_shop_name" :label="$t('restaurant_buy_print.B06A44@Branches_stores')">
                </el-table-column>
                <el-table-column prop="seller_company_name"
                                 :label="$t('backend_authrity_authrityList.85EDE9@supplier_tab')">
                </el-table-column>
                <el-table-column prop="receive_goods_price" :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
                </el-table-column>
                <el-table-column prop="receive_deliver_fee" :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
                </el-table-column>
                <el-table-column prop="final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
                </el-table-column>
                <el-table-column prop="cost_ratio" :label="$t('invoice.EA5264@cost_ratio')">
                </el-table-column>
                <el-table-column prop="revenue" :label="$t('aboutus.3C1FAD@Revenue')">
                </el-table-column>
                <el-table-column prop="create_time" :label="$t('orderDetail.082549@order_time')">
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="company_name" :label="$t('aboutus.E8221A@restaurant_name')">
          </el-table-column>
          <el-table-column prop="total_receive_goods_price" :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
            <template slot-scope="scope">
              {{ scope.row.total_receive_goods_price }}
            </template>
          </el-table-column>
          <el-table-column prop="total_receive_deliver_fee" :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
            <template slot-scope="scope">
              {{ scope.row.total_receive_deliver_fee }}
            </template>
          </el-table-column>
          <el-table-column prop="total_final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
          </el-table-column>
          <el-table-column prop="total_revenue" :label="$t('aboutus.3C1FAD@Revenue')"></el-table-column>
        </el-table>
        <!--    供應商    -->
        <el-table
            v-if="gmvwhere==7"
            show-summary
            :summary-method="getSummariesSupplier"
            border
            :data="data"
            v-loading="loading"
            tooltip-effect="dark"
            style="width: 100%; margin-bottom: 30px"
            :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
            :cell-style="{ 'text-align': 'center' }">
          <el-table-column type="expand">
            <template slot-scope="order">
              <el-table
                  show-summary
                  :summary-method="getSummariesSupplier2"
                  border
                  :data="order.row.list"
                  v-loading="loading"
                  tooltip-effect="dark"
                  style="width: 100%; margin-bottom: 30px"
                  :header-cell-style="{ 'text-align': 'center','font-weight': '1000' }"
                  :cell-style="{ 'text-align': 'center' }">
                <el-table-column prop="order_no" :label="$t('placeholder.5C894A@order_code')">
                </el-table-column>
                <el-table-column prop="buyer_shop_name" :label="$t('restaurant_buy_print.B06A44@Branches_stores')">
                </el-table-column>
                <el-table-column prop="seller_company_name"
                                 :label="$t('backend_authrity_authrityList.85EDE9@supplier_tab')">
                </el-table-column>
                <el-table-column prop="receive_goods_price" :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
                </el-table-column>
                <el-table-column prop="receive_deliver_fee" :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
                </el-table-column>
                <el-table-column prop="final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
                </el-table-column>
                <el-table-column prop="cost_ratio" :label="$t('invoice.EA5264@cost_ratio')">
                </el-table-column>
                <el-table-column prop="revenue" :label="$t('aboutus.3C1FAD@Revenue')">
                </el-table-column>
                <el-table-column prop="create_time" :label="$t('orderDetail.082549@order_time')">
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="company_name" :label="$t('restaurant_buy_genner.586E4F@supplier_name')">
          </el-table-column>
          <el-table-column prop="total_receive_goods_price" :label="$t('aboutus.09C1E0@Total_amount_of_goods')">
            <template slot-scope="scope">
              {{ scope.row.total_receive_goods_price }}
            </template>
          </el-table-column>
          <el-table-column prop="total_receive_deliver_fee" :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
            <template slot-scope="scope">
              {{ scope.row.total_receive_deliver_fee }}
            </template>
          </el-table-column>
          <el-table-column prop="total_final_price" :label="$t('restaurant_buy_genner.E0A3B6@order_price')">
          </el-table-column>
          <el-table-column prop="total_revenue" :label="$t('aboutus.3C1FAD@Revenue')"></el-table-column>
        </el-table>
      </div>
      <section style="margin-top:20px;display:flex;justify-content:flex-end;">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[15, 25, 50, 100]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>

      </section>
    </el-card>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "@/components/PageTop/PageTop";
import LineChart from "../components/LineChart";
import datetimepicker from "../components/datetimepicker";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "GMV",
  components: {
    pageTop,
    LineChart,
    datetimepicker,
  },
  data() {
    //这里存放数据
    return {
      //資料筆數
      total: 0,
      //當前頁數
      currentPage: 1,
      //頁面長度
      pageSize: 15,
      //給餐廳或供應商用的時間
      datetime2: "",
      mbStyle: 'margin-bottom: 50px',
      lang: this.$i18n.locale == 'hk',
      //gmv種類
      gmvwhere: 1,
      //給非餐廳或供應商用的時間
      dateTime: "",
      gmvwhereList: [
        {label: "Market訂單GMV", value: 1},
        {label: "一般訂單GMV", value: 2},
        {label: "Market訂單數量", value: 3},
        {label: "一般訂單數量", value: 4},
        {label: "收入", value: 5},
        {label: "餐廳", value: 6},
        {label: "供應商", value: 7},
      ],
      type: "",
      //資料
      data: [],
      //載入
      loading: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //餐廳尾部統計
    getSummariesRestaurant2(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '總計'
          return
        }

        if (index == 1 || index == 2 || index == 6 || index == 8) {
          sums[index] = ''
          return
        }

        const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + Number(curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${this.currencyFormat(sums[index])} 元`
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    //餐廳尾部統計
    getSummariesRestaurant(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '總計'
          return
        }

        if (index == 1 || index == 7) {
          sums[index] = ''
          return
        }

        const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + Number(curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${this.currencyFormat(sums[index])} 元`
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    //供應商尾部統計
    getSummariesSupplier2(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '總計'
          return
        }

        if (index == 1 || index == 2 || index == 6 || index == 8) {
          sums[index] = ''
          return
        }

        const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + Number(curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${this.currencyFormat(sums[index])} 元`
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    //供應商尾部統計
    getSummariesSupplier(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '總計'
          return
        }

        if (index == 1 || index == 7) {
          sums[index] = ''
          return
        }

        const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + Number(curr)
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${this.currencyFormat(sums[index])} 元`
        } else {
          sums[index] = ''
        }
      })

      return sums
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.companyGMV()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.companyGMV()
    },
    //提供給餐廳或供應商的GMV
    companyGMV() {
      this.loading = true
      this.$http.companyGMV({
        page: this.currentPage,
        size: this.pageSize,
        start_time: this.datetime2 ? this.datetime2[0] / 1000 : 0,
        end_time: this.datetime2 ? this.datetime2[1] / 1000 : 0,
        type: this.gmvwhere == 6 ? 0 : 1,
      }).then(res => {
        this.data = res.data.list
        this.total = res.data.sum
        this.loading = false
      })
    },
    //餐廳或供應商更改時間時
    changeDatetime() {
      this.companyGMV()
    },
    //取得金額第三位加上,
    currencyFormat(num) {
      return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    //第二層統計
    getSummaries2(param) {
      const {columns, data} = param
      const sums = []

      //訂單數量
      if (this.gmvwhere == 3 || this.gmvwhere == 4) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '總計'
            return
          }

          if (index == 1 || index == 2 || index == 3 || index == 7) {
            sums[index] = ''
            return
          }

          const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + Number(curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = index == 9 ? `${this.$t("backend_dashboard.E2556D@total_order_count")}：${this.currencyFormat(sums[index]).replace("$", "")}` : `${this.currencyFormat(sums[index])} 元`
          } else {
            sums[index] = ''
          }
        })
      } else if (this.gmvwhere == 1 || this.gmvwhere == 2) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '總計'
            return
          }

          if (index == 1 || index == 2 || index == 3 || index == 7) {
            sums[index] = ''
            return
          }

          const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + Number(curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = `${this.currencyFormat(sums[index])} 元`
          } else {
            sums[index] = ''
          }
        })
      } else if (this.gmvwhere == 5) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '總計'
            return
          }

          if (index == 1 || index == 2 || index == 3 || index == 6) {
            sums[index] = ''
            return
          }

          const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + Number(curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = `${this.currencyFormat(sums[index])} 元`
          } else {
            sums[index] = ''
          }
        })
      }

      return sums
    },
    //第一層統計
    getSummaries(param) {
      const {columns, data} = param
      const sums = []

      //訂單數量
      if (this.gmvwhere == 3 || this.gmvwhere == 4) {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = ''
            return
          }
          const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + Number(curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = `${this.$t("backend_dashboard.E2556D@total_order_count")}：${this.currencyFormat(sums[index]).replace("$", "")}`
          } else {
            sums[index] = ''
          }
        })
      } else {
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = ''
            return
          }

          const values = data.map(item => Number(item[column.property].replace(",", "").replace("$", "")))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + Number(curr)
              } else {
                return prev
              }
            }, 0)
            sums[index] = `${this.$t("restaurant_buy_genner.E0A3B6@order_price")}：${this.currencyFormat(sums[index])} 元`
          } else {
            sums[index] = ''
          }
        })
      }

      return sums
    },
    getGMVOrderAverage() {
      this.currentPage = 1
      if (this.gmvwhere < 6) {
        this.mbStyle = 'margin-bottom: 50px'
        this.$http.GMVOrderAverage({
          type: this.type || 0,
          gmvwhere: this.gmvwhere,
          start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
          end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
        }).then((res) => {
          this.data = res.data.data
          this.loading = false
        })
      } else {
        this.mbStyle = 'margin-bottom: 20px'
        this.companyGMV()
      }

    },
    // 获取类型
    dateTypeSelectChange(val) {
      if (val) {
        this.type = val;
      } else {
        this.type = 0;
        this.dateTime = "";
        this.getGMVOrderAverage();
      }
    },
    downloadexcel() {
      this.$http.GMVOrderAverageExcel({
        type: this.type || 0,
        gmvwhere: this.gmvwhere,
        start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
        end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
      }).then((res) => {
        this.downloadFile(res);
      });
    },
    // 获取时间
    datetimeChange(val) {
      if (val) {
        this.dateTime = val;
        this.getGMVOrderAverage();
      }
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getGMVOrderAverage();
  },
};
</script>
<style src='./GMV.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
