<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.21BF37@grouping')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <section style="display:flex;justify-content:flex-end;">
        <kt-button
          class="table-btn btn-yellow"
          :perm="893"
          :label="$t('public.DC9752@btn_add')"
          @click="add"
        ></kt-button>
      </section>
      <el-table
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        :data="tableData"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <el-table-column
          prop="group_id"
          :label="$t('backend_authrity_grouping.31BA13@grouping_id')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="group_name"
          :label="$t('backend_authrity_grouping.5EDDBF@grouping_name')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="is_system"
          :label="$t('backend_authrity_grouping.4A9FA6@isSuper')"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('public.03EA09@operation')"
          width="200"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.allow_modify == 1">
              <kt-button
                type="info"
                :perm="683"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
              ></kt-button>
              <kt-button
                type="danger"
                :perm="781"
                :label="$t('public.1FBB5E@btn_delete')"
                @click="deleteRow(scope.row.group_id)"
              ></kt-button>
            </div>
            <span v-else>{{$t('public.25F6B0@tips')}}</span>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <!--树形复选框 -->
    <el-dialog center width="50%" :visible.sync="dialogVisible">
      <el-form ref="form" :rules="rules" :model="dialogForm" label-width="110px">
        <el-form-item
          style="width:65%;"
          :label="$t('backend_authrity_grouping.5EDDBF@grouping_name')"
          prop="name"
        >
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
        <div>
          <section v-for="parent in menuData" :key="parent.id" class="module-container">
            <el-checkbox-group v-model="checkedTreeId">
              <div class="module-title">
                <section>
                  <el-checkbox
                    :disabled="parent.children|isDisabled"
                    :value="parent.id"
                    :label="parent.id"
                  >{{parent.name_hk}}</el-checkbox>
                </section>
                <!-- @change="((val) => {topChange(val, parent)})" -->
              </div>
              <div class="menu-container" v-for="menu in parent.children" :key="menu.id">
                <span class="menu-title">
                  <el-checkbox
                    :value="menu.id"
                    :label="menu.id"
                    @change="((val) => {menuChange(val, menu,parent)})"
                  >{{menu.name_hk}}</el-checkbox>
                  <el-tag v-if="menu.type == 'btn'" style="display:inline-block;" size="mini">btn</el-tag>
                </span>
                <div>
                  <span class="btn-name" v-for="btn in menu.children" :key="btn.id">
                    <el-checkbox
                      style="display:inline-block;"
                      :value="btn.id"
                      :label="btn.id"
                      @change="((val) => {btnChange(val, menu,btn)})"
                    >{{btn.name_hk}}</el-checkbox>
                    <el-tag style="display:inline-block;" size="mini">btn</el-tag>
                  </span>
                </div>
              </div>
            </el-checkbox-group>
          </section>
        </div>
      </el-form>
      <span style="display:flex;justify-content:flex-end;margin-top:10px;">
        <el-button size="small" @click="dialogVisible = false">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button
          @click="confirmDialogForm"
          type="primary"
          size="small"
        >{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
    </el-dialog>

    <!-- test -->
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";
import api from "../../api/index";
export default {
  data() {
    return {
      loading: true,
      select: {
        search: ""
      },

      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,

      addModify: 1,
      dialogVisible: false,
      dialogForm: {
        name: ""
      },
      row: [],
      menuData: [],
      checkedTreeId: [],
      btn: [],
      sub_menu: [],
      click: 0,
      rules: {
        name: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  filters: {
    isDisabled: function(value) {
      if (value.length && value[0].type != "btn") {
        return true;
      }
    }
  },
  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
    this.getTree();
  },
  methods: {
    // 实现了二级全选的结果
    btnChange(val, menu, btn) {
      // 勾选了btn，就push其父menu
      if (this.checkedTreeId.indexOf(menu.id) == -1) {
        this.checkedTreeId.push(menu.id);
      }
      // 取消勾选
      if (!val) {
        console.log("val==>", val);

        let btnIds = [];
        menu.children.forEach(btn => {
          btnIds.push(btn.id);
        });
        let result = btnIds.filter(
          item => this.checkedTreeId.indexOf(item) != -1
        );
        console.log("result==>", result);

        // if (!result.length) {
        //   let index = this.checkedTreeId.indexOf(btn.parentId);
        //   this.checkedTreeId.splice(index, 1);
        // }
      }
    },

    // 一級下面的btn的相關操作
    menuChange(val, self, top) {
      if (self.type == "btn") {
        // 一級
        let btns = [];
        top.children.forEach(btn => {
          btns.push(btn.id);
        });
        if (val) {
          //自己選擇後，父級也要選擇
          if (this.checkedTreeId.indexOf(self.parentId) == -1) {
            this.checkedTreeId.push(self.parentId);
          }
        } else {
          // 自己以及兄弟都取消後，父級也取消
          // let result = btns.filter(item => this.checkedTreeId.indexOf(item) != -1)
          // if (!result.length) {
          //   // 沒有兄弟了了，取消父級
          //   let index = this.checkedTreeId.indexOf(self.parentId);
          //   this.checkedTreeId.splice(index, 1);
          // }
        }
      } else {
        // 二級
        let btnsId = [];
        self.children.forEach(btn => {
          btnsId.push(btn.id);
        });
        if (!val) {
          //取消
          btnsId.forEach(btn => {
            let index2 = this.checkedTreeId.indexOf(btn);
            if (index2 >= 0) {
              this.checkedTreeId.splice(index2, 1);
            }
          });
          console.log("erjiquxiao==>", this.checkedTreeId);
        } else {
          // 選擇
          btnsId.forEach(btn => {
            this.checkedTreeId.push(btn);
          });
          console.log("erji==>", this.checkedTreeId);
        }
      }
    },

    // 一級下面的sub_menu的操作
    // topChange (val, self) {
    //   console.log(top);

    //   let childrenId = [];
    //   self.children.forEach((btn) => {
    //     childrenId.push(btn.id)
    //   })
    //   if (val) {
    //     childrenId.forEach((id) => {
    //       this.checkedTreeId.push(id)
    //     })
    //   }

    //   else {
    //     childrenId.forEach((id, idIndex) => {
    //       this.checkedTreeId.splice(idIndex, 1);
    //     })
    //   }
    // },

    getTableData() {
      api
        .getGrouping({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.tableData = res.data.list;
          this.total = res.data.sum;
          this.loading = false;
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },

    add() {
      this.dialogVisible = true;
      this.checkedTreeId = [];
      this.addModify = 0;
      if (this.dialogForm.name) {
        this.$refs["form"].resetFields();
        // this.$refs.tree.setCheckedKeys([]);
      }
    },

    modify(row) {
      this.addModify = 1;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.row = row;
      api
        .queryGrouping({
          group_id: row.group_id
        })
        .then(res => {
          this.checkedTreeId = res.data.route_id_obj.concat(
            res.data.btn_id_obj
          );
          this.dialogForm.name = res.data.group_name;
        });
    },

    recursion(data, idArr) {
      let that = this;
      data.forEach(v => {
        idArr.forEach(j => {
          if (j == v.id) {
            if (v.type == "btn") {
              that.btn.push(j);
            } else {
              that.sub_menu.push(j);
            }
          }
        });
        if (v.children.length) {
          that.recursion(v.children, idArr);
        }
      });
    },
    // 生成btn_json和
    confirmDialogForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.btn = [];
          this.sub_menu = [];
          this.recursion(this.menuData, this.checkedTreeId);

          if (this.addModify) {
            api
              .modifyGrouping({
                group_id: this.row.group_id,
                group_name: this.dialogForm.name,
                route_id_json: JSON.stringify(this.sub_menu) || "",
                btn_id_json: JSON.stringify(this.btn) || "",
                shop_id: 0
              })
              .then(res => {
                this.dialogVisible = false;
                this.getTableData();
              });
          } else {
            api
              .addGrouping({
                group_name: this.dialogForm.name,
                route_id_json: JSON.stringify(this.sub_menu) || "",
                btn_id_json: JSON.stringify(this.btn) || "",
                shop_id: 0
              })
              .then(res => {
                this.dialogVisible = false;
                this.getTableData();
              });
          }
        } else {
          return false;
        }
      });
    },

    deleteRow(id) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        api
          .deleteGrouping({
            group_id: id
          })
          .then(res => {
            this.getTableData();
          });
      });
    },

    // 树形
    getTree() {
      api.getTree().then(res => {
        this.menuData = res.data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  section {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}

// tree
.module-container {
  background-color: #f5f5f5;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  .module-title {
    display: inline-block;
    margin-bottom: 0;
  }
  .menu-container {
    display: flex;
    padding-left: 30px;
    .menu-title {
      display: inline-block;
      width: 150px;
    }

    .btn-name {
      display: inline-block;
      width: 150px;
    }
  }
}
</style>

   