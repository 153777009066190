<template>
  <div id="PermissionManage">
    <page-top :title="$t('backend_submenu.5815A5@restaurant_permission_management')">
      <span style="font-size:26px;" class="fa fa-cog"></span>
    </page-top>
    <el-card>
      <el-row>
        <el-col style="width: 35%">
          <el-card class="box-card" style="max-height: calc(140vh);overflow-y: auto;overflow-x: auto">
            <div slot="header">
              <el-input v-model="searchText"
                        :placeholder="`${$t('shop-goods.A29F44@search')}${$t('Contact.F7D2F0@Restaurant')}`"
                        clearable></el-input>
            </div>
            <div class="text item">
              <el-radio @change="getAuthGroups" v-model="formData.rid" v-for="v in formData.restaurantList"
                        :key="v.company_id"
                        :label="v.company_id"
                        style="width: 100%">
                {{ v.company_name }}
              </el-radio>
            </div>
          </el-card>
        </el-col>
        <el-col style="width: 60%">
          <!--        群組清單    -->
          <section v-for="parent in groupNameList" :key="parent.id" class="module-container">
            <el-checkbox-group v-model="selectList">
              <div class="module-title">
                <section>
                  <!--                    主要群組-->
                  <el-checkbox :value="parent.id" :label="parent.id"
                               @change="parentChange($event, parent)">
                    {{ parent.name }}
                  </el-checkbox>
                </section>
              </div>
              <!--             次群組   -->
              <div class="menu-container" v-for="menu in parent.children" :key="menu.id">
                  <span class="menu-title">
                    <el-checkbox :value="menu.id" :label="menu.id" @change="childChange($event, menu)">
                      {{ menu.name }}
                    </el-checkbox>
                  </span>
                <div style="display: none">
                    <span class="btn-name" v-for="btn in menu.children" :key="btn.id">
                      <el-checkbox :value="btn.id" :label="btn.id">
                        {{ btn.name_hk }}
                      </el-checkbox>
                    </span>
                </div>
              </div>
            </el-checkbox-group>
          </section>
          <section style="text-align: center;padding-top: calc(1vh)">
            <el-button :disabled="formData.rid==0" type="primary" @click="updateAuth">{{
                $t('public.A738BA@update')
              }}
            </el-button>
            <el-button :disabled="formData.rid==0" type="danger" @click="resetAuth">{{
                $t('public.836FD4@cancel')
              }}
            </el-button>
          </section>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  components: {
    pageTop,
  },
  watch: {
    searchText() {
      if (this.searchText) {
        this.getRestaurant()
      }
    }
  },
  name: "",
  data() {
    return {
      //搜尋文字
      searchText: "",
      //當前語言
      lang: this.$i18n.locale == 'hk',
      //種類
      project: 'restaurant',
      //群組清單名稱
      groupNameList: [],
      //選取權限的清單
      selectList: [],
      //btn_id_json
      btnSelectList: [],
      //群組清單
      restaurantGroupList: "",
      //表單資料
      formData: {
        //data
        data: [],
        //餐廳id
        rid: 0,
        //餐廳清單
        restaurantList: "",
      },
    }
  },
  methods: {
    //重置授權
    resetAuth() {
      this.restaurantChange()
    },
    //更新授權
    updateAuth() {
      //reset data array
      if (this.formData.data.length > 0) {
        this.formData.data = []
      }

      for (let index = 0; index < this.restaurantGroupList.length; index++) {
        //admin
        if (parseInt(this.restaurantGroupList[index].is_system) == 1) {
          // 更新btn_id_json
          this.restaurantGroupList[index].btn_id_json = JSON.stringify(this.btnSelectList)
          // 更新route_id_json
          this.restaurantGroupList[index].route_id_json = JSON.stringify(this.selectList)
        } else {
          // user
          let routerArray = JSON.parse(this.restaurantGroupList[index].route_id_json)
          //將原本使用者與現有的權限匹配 還在的留下
          this.restaurantGroupList[index].route_id_json = JSON.stringify(routerArray.filter(function (e) {
            return this.indexOf(e) > -1
          }, this.selectList))
          //同上 更新按鈕權限
          let btnArray = JSON.parse(this.restaurantGroupList[index].btn_id_json)
          this.restaurantGroupList[index].btn_id_json = JSON.stringify(btnArray.filter(function (e) {
            return this.indexOf(e) > -1
          }, this.btnSelectList))
        }
        // add object to array
        this.formData.data.push({
          // 群組id
          group_id: this.restaurantGroupList[index].group_id,
          // btn_id_json
          btn_id_json: this.restaurantGroupList[index].btn_id_json,
          // route_id_json
          route_id_json: this.restaurantGroupList[index].route_id_json,
        })
      }
      let params = Object.assign({}, this.formData)
      this.$http.updateRestaurantAuthList(params).then((res) => {
        if (res.errno == 0) {
          //清空
          this.formData.rid = ""
          this.selectList = []
          this.btnSelectList = []
          this.restaurantGroupList = ""
        }
      })
    },
    //父類別改變時
    parentChange(status, parent) {
      let childArray = parent.children.map((i) => i.id)
      //勾選時
      if (status) {
        //merge array
        this.selectList.push.apply(this.selectList, childArray)
        //取得child Array
        let child = parent.children.map((i) => i)
        //在取得btn Array
        child.map((c) => {
          let btnArray = c.children.map((btn) => btn.id)
          //再將btn array merge
          this.btnSelectList.push.apply(this.btnSelectList, btnArray)
        })
      } else {
        //回傳刪除後的勾選清單
        this.selectList = this.selectList.filter(function (e) {
          return this.indexOf(e) < 0
        }, childArray)

        //取得child Array
        let child = parent.children.map((i) => i)
        child.map((c) => {
          let btnArray = c.children.map((btn) => btn.id)
          //再將btn array merge
          this.btnSelectList = this.btnSelectList.filter(function (e) {
            return this.indexOf(e) < 0
          }, btnArray)
        })
      }
    },
    //子類別被改變時
    childChange(status, child) {
      let childArray = child.children.map((i) => i.id)
      //勾選時
      if (status) {
        //merge array
        this.btnSelectList.push.apply(this.btnSelectList, childArray)
      } else {
        //回傳刪除後的勾選清單
        this.btnSelectList = this.btnSelectList.filter(function (e) {
          return this.indexOf(e) < 0
        }, childArray)
      }
    },
    //取得授權名稱清單
    getAuthNameList() {
      this.$http.getAuthNameList({
        project: this.project,
      }).then((res) => {
        //設定群組名稱清單
        this.groupNameList = res.data
        //加入checked
        this.groupNameList.map((item) => {
          //添加屬性給主要類別checked
          this.$set(item, 'checked', false)
          //添加主要名稱
          this.$set(item, 'name', this.lang ? item.name_hk : item.name_en)
          if (item.children.length > 0) {
            item.children.map((child) => {
              //添加次要名稱
              this.$set(child, 'name', this.lang ? child.name_hk : child.name_en)
              //添加屬性給次要類別checked
              this.$set(child, 'checked', false)
            })
          }
        })
      })
    },
    //取得餐廳群組授權清單
    getAuthGroups() {
      this.selectList = []
      this.btnSelectList = []
      this.restaurantGroupList = ""
      this.$http.getAuthGroups({
        //餐廳id
        rid: this.formData.rid,
        //專案名稱
        project: this.project,
      }).then((res) => {
        this.restaurantGroupList = res.data
        let admin = this.restaurantGroupList.filter((info) => info.is_system == 1)
        if (admin.length > 0) {
          //將其admin數據直接加入選取選單中
          this.selectList.push.apply(this.selectList, JSON.parse(admin[0].route_id_json))
          this.btnSelectList.push.apply(this.btnSelectList, JSON.parse(admin[0].btn_id_json))
        }
      })
    },
    //取得餐廳列表
    getRestaurant() {
      this.$http.getRestaurant({
        size: 100000,
        search: this.searchText,
      }).then((res) => {
        this.formData.restaurantList = res.data.list
      })
    },
    //餐廳變化時
    restaurantChange() {
      //清空選取選單
      this.selectList = []
      this.btnSelectList = []
      this.restaurantGroupList = ""
      this.getAuthGroups()
    },
  },
  //生命週期-創建時
  created() {
    this.getAuthNameList()
    this.getRestaurant()
  },
}
</script>

<style lang="scss" scoped>
#PermissionManage {
  .header {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 900;

    span {
      margin-left: 10px;
    }
  }

  .tips {
    font-size: 12px;
    color: #999;
  }

  .btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .restaurant {
    width: 100%;
    text-align: center;
  }

  // tree
  .module-container {
    margin-left: 15%;
    padding-top: 5px;
    border-bottom: 1px solid #f5f5f5;

    .module-title {
      margin-bottom: 0;
    }

    .menu-container {
      display: flex;
      padding-left: 30px;

      .menu-title {
        > label {
          width: 130px;
        }
      }

      .btn-name {
        > label {
          width: 110px;
        }

        > span {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>