<!-- 教學文章分類 -->
<template>
  <section class="BlogCategory">
    <page-top :title="$t('AppGuideline.AppGuidelineCategory')">
      <span style="font-size:26px;" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-yellow"
            :perm="16636836662505"
            @click="addCategory"
        ></KtButton>
      </section>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          style="width:100%;margin-top:20px;"
          :header-cell-style="{'text-align':'center',}"
          :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="name" :label="$t('shop-index.6449D6@product_category')"></el-table-column>
        <el-table-column
            prop="description"
            :label="$t('restaurant_supplier_supplierList.E318B6@desc')"
        ></el-table-column>
        <el-table-column
            prop="created_at"
            :label="$t('public.D17BCA@created_time')"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="220" fixed="right">
          <template slot-scope="scope">
            <KtButton
                type="info"
                :label="$t('public.B6D57F@btn_modify')"
                plain
                :perm="15894509712111"
                @click="modify(scope.row)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                plain
                :perm="15894510398119"
                @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="40%" :visible.sync="dialogVisible" top="8vh">
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item
            :label="$t('supplier-produceManage-publishProduct.BD6C55@Name_hk')"
            prop="name_hk"
        >
          <el-input v-model="form.name_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('public.4BF093@desc_hk')" prop="description_hk">
          <el-input v-model="form.description_hk"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{ $t('public.836FD4@cancel') }}</el-button>
        <el-button
            size="small"
            type="primary"
            @click="confirmDialog"
        >{{ $t('public.3B45A2@confirm') }}</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "",
  components: {pageTop, KtButton},
  data() {
    //这里存放数据
    return {
      form: {
        name_hk: "",
        description_hk: ""
      },
      dialogVisible: false,
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      modifyIndex: false,
      category_id: ""
    };
  },
  //方法集合
  methods: {
    //取得分類清單
    getAppGuidelineCategoryList() {
      this.$http.getAppGuidelineCategoryList().then(res => {
        if (res.errno == 0) {
          this.tableData = res.data
        }
      })
    },
    //   新增
    addCategory() {
      this.dialogVisible = true
      Object.keys(this.form).forEach(key => (this.form[key] = ""))
      this.modifyIndex = false
    },
    //   弹框确认
    confirmDialog() {
      if (this.modifyIndex) {
        // 修改
        let params = {category_id: this.category_id, ...this.form}
        this.$http.editAppGuidelineCategory(params).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineCategoryList()
            this.dialogVisible = false
          }
        })
      } else {
        //新增
        let params = this.form
        this.$http.addAppGuidelineCategory(params).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineCategoryList()
            this.dialogVisible = false
          }
        })
      }
    },
    // 修改
    modify(row) {
      this.category_id = row.id
      this.modifyIndex = true
      this.$http.showAppGuidelineCategory({category_id: row.id}).then(res => {
        Object.keys(this.form).forEach(
            key => (this.form[key] = JSON.parse(JSON.stringify(res.data[key])))
        );
        this.dialogVisible = true
      })
    },
    // 删除
    deleteUser(row) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delAppGuidelineCategory({category_id: row.id}).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineCategoryList();
          }
        });
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAppGuidelineCategoryList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAppGuidelineCategoryList();
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAppGuidelineCategoryList();
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>