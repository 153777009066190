<template>
  <div class="favorite-container">
    <page-top :title="$t('aboutus.3C4EF5@Customer_testimonials')">
      <span style="font-size: 26px" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-yellow"
            :perm="16571034896485"
            @click="showModifyOrAddDialog(true)"
        ></KtButton>
      </section>
      <el-table
          :data="data"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          style="width: 100%; margin-top: 20px"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
        <el-table-column
            width="80px"
            prop="id"
            label="ID"></el-table-column>
        <!--    標題    -->
        <el-table-column
            prop="title"
            :label="$t('backend_config_Advertisement.88A5B8@ad_title')">
        </el-table-column>
        <!--    縮圖    -->
        <el-table-column
            prop="cover"
            :label="$t('public.cover')">
          <template slot-scope="scope">
            <el-image v-if="scope.row.cover" fit="contain" :src="scope.row.cover" style="width: 240px"></el-image>
          </template>
        </el-table-column>
        <!--    影片    -->
        <el-table-column
            prop="title"
            :label="$t('public.video')">
          <template slot-scope="scope">
            <iframe class="video" :src="scope.row.video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </template>
        </el-table-column>
        <!--    操作    -->
        <el-table-column
            :label="$t('public.03EA09@operation')"
            fixed="right">
          <template slot-scope="scope">
            <KtButton
                type="info"
                :label="$t('public.B6D57F@btn_modify')"
                plain
                :perm="16571042403693"
                @click="showModifyOrAddDialog(false, scope.row.id)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                plain
                :perm="16571042712597"
                @click="delHomeVideo(scope.row.id)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10,25,50,100]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <!--  對話視窗  -->
    <el-dialog width="80%" :visible.sync="showDialog" :center="true" @close='clean'
               :title="isAdd ? $t('public.DC9752@btn_add') : $t('public.B6D57F@btn_modify')">
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form :model="form" :rules="rules" ref="form" label-width="150px">
            <!--      標題      -->
            <el-form-item :label="$t('backend_config_Advertisement.88A5B8@ad_title')" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <!--     顯示狀態       -->
            <el-form-item :label="$t('backend_authrity_user.07D89C@status')" prop="status">
              <el-switch
                  v-model="form.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-text="$t('public.B3669C@show')"
                  :inactive-text="$t('public.8B9314@hide')">
              </el-switch>
            </el-form-item>
            <!--縮圖-->
            <el-form-item
                :label="$t('backend_config_Advertisement.8A317E@photo')"
                prop="cover">
              <el-upload
                  accept=".jpeg, .jpg, .png"
                  ref="upload"
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="no"
                  :before-upload="(file)=>beforeAvatarUpload(file)"
                  :http-request="(file)=>uploadFile(file)">
                <el-image class="img-auto" v-if="form.cover" :src="form.cover" style="width: 320px"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <!--影片-->
            <el-form-item :label="$t('public.video')" prop="video">
              <el-input v-model="form.video"></el-input>
            </el-form-item>
            <!--     描述       -->
            <el-form-item :label="$t('restaurant_supplier_supplierList.E318B6@desc')" prop="desc">
              <el-input type="textarea" v-model="form.desc" :autosize="{ minRows: 5, maxRows: 10}">
              </el-input>
            </el-form-item>
            <!--     排序       -->
            <el-form-item :label="$t('backend_config_Advertisement.1E4172@sort')" prop="sort">
              <el-input-number v-model="form.sort" :min="0"></el-input-number>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="addOrModify">
          {{ $t("public.3B45A2@confirm") }}
        </el-button>
        <el-button size="small" @click="showDialog = false">
          {{ $t("public.836FD4@cancel") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import 'video.js/dist/video-js.css'
import {videoPlayer} from 'vue-video-player'

export default {
  data() {
    return {
      //表單
      form: {
        //排序
        sort: 0,
        //狀態
        status: 1,
        //id
        id: 0,
        title: '',
        desc: '',
        video: '',
        cover: null,
      },
      //是否為新增
      isAdd: false,
      //總數量
      total: 0,
      showDialog: false,
      data: [],
      loading: false,
      rules: {
        title: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
        cover: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
        video: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
      },
      //頁面數量
      pageSize: 10,
      //頁面
      currentPage: 1,
    };
  },
  components: {
    videoPlayer,
    pageTop,
    KtButton,
  },
  created() {
    this.getHomeVideoList();
  },
  methods: {
    showModifyOrAddDialog(isAdd, id = null) {
      this.isAdd = isAdd

      if (!this.isAdd && id) {
        this.getHomeVideoInfo(id);
      }
      this.showDialog = true
    },
    //修改
    modifyHomeVideo() {
      //判斷是否已經是正確的網址
      let isEmbed = this.form.video.indexOf('embed') > -1
      let position, videoID
      if (isEmbed) {
        //取得影片ID位置
        position = this.form.video.lastIndexOf('/')
        //取得影片ID
        videoID = this.form.video.substring(position + 1, position + 12)
      } else {
        //取得影片ID位置
        position = this.form.video.indexOf('=')
        //取得影片ID
        videoID = this.form.video.substring(position + 1, position + 12)
      }

      this.$http.modifyHomeVideo({
        id: this.form.id,
        title: this.form.title,
        desc: this.form.desc,
        cover: this.form.cover,
        video: videoID,
        status: this.form.status,
        sort: this.form.sort
      }).then(res => {
        if (res.errno == 0) {
          this.clean()
          this.getHomeVideoInfo(this.form.id)
          this.getHomeVideoList()
        }
      })
    },
    //新增或修改
    addOrModify() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.addHomeVideo()
          } else {
            this.modifyHomeVideo()
          }
        } else {
          return false;
        }
      })
    },
    //清空表單
    clean() {
      this.$refs.file.value = null
      this.showDialog = false
      this.$refs['form'].resetFields()
      this.form.id = 0
      this.form.sort = 0
      this.form.status = 1
      this.form.desc = ''
      this.form.cover = null
      this.form.video = ''
      this.form.title = ''
      this.isAdd = false
    },
    // 上傳圖片和影片
    uploadFile(param) {
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(param["file"])
      reader.onload = function (event) {
        that.form.cover = this.result
      }
      this.dialogVisible = true
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".");
      let typeName = fileNameArr[fileNameArr.length - 1].toLowerCase()
      //圖片
      const isJPG = Boolean(typeName == "jpg" || typeName == 'png')
      const isLt2M = Boolean(file.size / 1024 / 1024 < 6)
      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"))
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"))
      }

      return isJPG && isLt2M
    },
    //刪除首頁影片
    delHomeVideo(id) {
      this.$http.delHomeVideo({
        id: id
      }).then(res => {
        if (res.errno == 0) {
          this.getHomeVideoList()
        }
      })
    },
    //新增首頁影片
    addHomeVideo() {
      //判斷是否已經是正確的網址
      let isEmbed = this.form.video.indexOf('embed') > -1
      let position, videoID
      if (isEmbed) {
        //取得影片ID位置
        position = this.form.video.lastIndexOf('/')
        //取得影片ID
        videoID = this.form.video.substring(position + 1, position + 12)
      } else {
        //取得影片ID位置
        position = this.form.video.indexOf('=')
        //取得影片ID
        videoID = this.form.video.substring(position + 1, position + 12)
      }
      this.$http.addHomeVideo({
        title: this.form.title,
        desc: this.form.desc,
        cover: this.form.cover,
        video: videoID,
        sort: this.form.sort,
        status: this.form.status,
      }).then(res => {
        if (res.errno == 0) {
          this.clean()
          this.getHomeVideoList()
        }
      })
    },
    getHomeVideoInfo(id) {
      this.$http.getHomeVideoInfo({
        id: id
      }).then(res => {
        if (res.errno == 0) {
          let data = res.data
          this.form.id = data.id
          this.form.cover = data.cover
          this.form.video = data.video
          this.form.desc = data.desc
          this.form.sort = data.sort
          this.form.title = data.title
        }
      })
    },
    //取得影片清單
    getHomeVideoList() {
      this.$http.getHomeVideoList({page: this.currentPage, size: this.pageSize}).then(res => {
        if (res.errno == 0) {
          console.log(res)
          this.total = res.total
          this.data = res.data.list
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getHomeVideoList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getHomeVideoList();
    },
  },
};
</script>
<style lang="scss" scoped>
.form-select {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
}


.video {
  padding-top: pxToper(10);
  width: pxToper(480);
  height: pxToper(320);
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}

.tabs {
  width: 170px;
  margin-bottom: -20px;

  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}

.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  // width: 400px;
  height: 150px;
  display: block;
}
</style>
