<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-25 19:32:21
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="order-detail">
    <page-top :title="$t('restaurant_submenu.90D6D1@order_det')"></page-top>
    <el-card>
      <section class="justify-between">
        <el-button class="table-btn btn-green" @click="goPrint">{{ $t('orderDetail.C0ED69@print') }}</el-button>
      </section>
      <el-steps
          style="width:65%;margin:50px auto;"
          :active="detailData.order_course"
          :align-center="true"
      >
        <template v-if="detailData.order_course!=5">
          <el-step :title="$t('orderDetail.F854E2@be_processed')"></el-step>
          <el-step :title="$t('orderDetail.87CE30@In_processing')"></el-step>
          <el-step :title="$t('orderDetail.7879F8@have_received')"></el-step>
          <el-step :title="$t('orderDetail.A119AD@has_payment')"></el-step>
        </template>
        <template v-else>
          <el-step :title="$t('orderDetail.35ED62@order_closed')"></el-step>
        </template>
      </el-steps>
      <section class="order-detail">
        <header class="detail-header">
          <div style="margin-right:50px">
            <h3>
              {{ $t('placeholder.5C894A@order_code') }}&nbsp;:&nbsp;
              <span>{{ detailData.order_no }}</span>
            </h3>
          </div>
          <div>
            <h3 v-if="detailData.invoice_no">
              <span>{{ detailData.invoice_no }}</span>
            </h3>
          </div>
          <el-select
              v-model="detailData.order_status_no"
              :placeholder="$t('placeholder.D4E968@order_status')"
              class="mr20"
              @change="orderStatusChange"
          >
            <el-option :label="$t('public.64203E@to_be_determined')" :value="1"></el-option>
            <el-option :label="$t('orderDetail.EB17E9@Refuse_order')" :value="2"></el-option>
            <el-option :label="$t('orderDetail.73FE8F@Cancel_order')" :value="3"></el-option>
            <el-option :label="$t('public.471669@Confirmed_order')" :value="4"></el-option>
            <el-option :label="$t('public.8E9DAD@Some_accept')" :value="5"></el-option>
            <el-option :label="$t('orderDetail.7879F8@have_received')" :value="6"></el-option>
            <el-option :label="$t('public.0E51AE@changeProduct')" :value="7"></el-option>
            <el-option :label="$t('public.5E1AAE@changeDeliverFee')" :value="8"></el-option>
          </el-select>
          <el-select
              v-model="detailData.pay_status_no"
              :placeholder="$t('public.180B5F@Payment_status')"
              :disabled="detailData.order_status_no==2||detailData.order_status_no==3 || detailData.order_status_no==7"
              clearable
          >
            <el-option :label="$t('aboutus.323AC0@Awaiting_Payment')" :value="1"></el-option>
            <el-option :label="$t('aboutus.C7977E@Payment_Receieved')" :value="2"></el-option>
            <!-- <el-option :label="$t('aboutus.245620@Overdue')" :value="3"></el-option> -->
          </el-select>
        </header>
        <div style="display:flex;padding:30px">
          <ul class="info">
            <h4 style="font-weight:bold;color:#333333;">{{ $t('aboutus.2AD773@Restaurant_Details') }}：</h4>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('backend_submenu.61A3ED@user') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_user_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('backend_authrity_authrityList.C90CD7@restaurant_tab') }}</span>
              &nbsp;:&nbsp;{{ detailData.receiver_company_name }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('orderDetail.082549@order_time') }}</span>
              &nbsp;:&nbsp;{{ detailData.create_time }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('product_delivery.F5EA13@product_delivery') }}</span>
              &nbsp;:&nbsp;{{ detailData.product_delivery_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_buy_genner.FE6497@store') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_shop_name }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('orderDetail.2775BD@store_tele') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_shop_phone }}
            </li>
            <li v-if="detailData.product_delivery==0">
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_supplierList.E84BAF@address') }}</span>
              &nbsp;:&nbsp;{{ detailData.receiver_address }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.B8F622@Opening_times') }}</span>
              &nbsp;:&nbsp;{{ detailData.delivery_time }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>
              &nbsp;:&nbsp;{{ detailData.buyer_contact }}
            </li>
            <!--      送貨日期      -->
            <li v-if="detailData.order_status_no==1">
              <span style="font-weight:bold;color:#333333;">{{ $t('supplier-setting-DistributionSet.6198C8@delivery_date') }}</span>
              &nbsp;:&nbsp;
              <el-date-picker
                  v-model="detailData.delivery_date_time"
                  value-format="timestamp"
                  :picker-options="pickerDisabled"
                  style="width: 200px"
                  type="date"
                  :placeholder="$t('supplier-setting-DistributionSet.F1E87C@Select_date')">
              </el-date-picker>
            </li>
            <li v-else>
              <span style="font-weight:bold;color:#333333;">{{ $t('supplier-setting-DistributionSet.6198C8@delivery_date') }}</span>
              &nbsp;:&nbsp;{{ detailData.delivery_date }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.66D050@delivery_time') }}</span>
              &nbsp;:&nbsp;{{ detailData.business_time }}
            </li>
            <li v-if="detailData.order_status_no!=1 && detailData.order_status_no!=4">
              <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.F31309@fee_ways') }}</span>
              &nbsp;:&nbsp;{{ detailData.pay_method }}
            </li>
            <li v-else>
              <span style="font-weight:bold;color:#333333;margin-right: 5px">{{ $t('aboutus.F31309@fee_ways') }}</span>&nbsp;:&nbsp;
              <el-select v-model="detailData.pay_method_num" size="small" filterable>
                  <el-option :label="$t('public.2E733D@Cash_on_delivery')" :value="1"></el-option>
                  <el-option :label="$t('public.5D8689@Delayable_payment')" :value="2"></el-option>
                  <el-option :label="$t('public.CC56D2@Designated_monthly_date')" :value="3"></el-option>
                  <el-option :label="$t('public.6458EF@credit_card')" :value="4"></el-option>
              </el-select>
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('orderDetail.30DB36@Payment_status') }}</span>
              &nbsp;:&nbsp;{{ detailData.pay_status }}
            </li>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('shop-goods.299596@Remarks') }}</span>
              <div v-html="detailData.remark?detailData.remark.replace(/\n/g, '<br>'):''"></div>
            </li>
          </ul>
          <ul class="info">
            <h4 style="font-weight:bold;color:#333333;">{{ $t('aboutus.3DB928@Supplier_Details') }}：</h4>
            <li>
              <span style="font-weight:bold;color:#333333;">{{ $t('orderDetail.FF66B0@supplier') }}</span>
              &nbsp;:&nbsp;{{ detailData.seller_company_name }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_setting_companyInfo.D20198@telephone') }}</span>
              &nbsp;:&nbsp;{{ detailData.seller_company_phone }}
            </li>
            <li>
              <span
                  style="font-weight:bold;color:#333333;"
              >{{ $t('restaurant_supplier_addsuppluer.0C63A5@linkman') }}</span>
              &nbsp;:&nbsp;{{ detailData.contact }}
            </li>
          </ul>
          <ul class="info">
            <section v-if="detailData.bank_name1 && detailData.account_name1 && detailData.account_number1">
              <h4 style="font-weight:bold;color:#333333;">{{ $t('aboutus.2F1443@Bank_Info') }}1：</h4>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.24F080@Bank_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.bank_name1 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.1A4DE1@Account_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_name1 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.C232EA@Account_Number') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_number1 }}
              </li>
            </section>
            <section v-if="detailData.bank_name2 && detailData.account_name2 && detailData.account_number2">
              <h4
                  class="mt10"
                  style="font-weight:bold;color:#333333;"
              >{{ $t('aboutus.2F1443@Bank_Info') }}2：</h4>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.24F080@Bank_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.bank_name2 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.1A4DE1@Account_Name') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_name2 }}
              </li>
              <li>
                <span style="font-weight:bold;color:#333333;">{{ $t('aboutus.C232EA@Account_Number') }}</span>
                &nbsp;:&nbsp;{{ detailData.account_number2 }}
              </li>
            </section>
          </ul>
        </div>
        <div class="goods">
          <el-button v-if="detailData.order_status_no==1 && canAddProductList.length" size="small" type="success"
                     style="margin-bottom: 5px" @click="addNewRow">
            {{ $t('public.FE11E4@add_goods') }}
          </el-button>
          <el-table
              border
              :data="detailData.goods_list"
              class="mt30"
              style="width: 100%"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align': 'center',}"
          >
            <el-table-column
                prop="gid"
                header-align="center"
                width="70"
                :label="$t('restaurant_supplier_products.3B1C04@product_id')"
            ></el-table-column>
            <el-table-column
                prop="goods_name"
                header-align="center"
                :label="$t('restaurant_supplier_products.CE8240@product_name')">
              <template slot-scope="scope">
                <span v-if="detailData.order_status_no!=7 && !scope.row.is_new">
                  {{ scope.row.goods_name }}
                </span>
                <span v-else-if="detailData.order_status_no==1 && scope.row.is_new">
                  <el-select v-model="scope.row.gid" @change="selectAddGoods(scope.$index)" filterable>
                    <el-option v-for="item in canAddProductList" :key="item.gid" :value="item.gid"
                               :label="item.name"></el-option>
                  </el-select>
                </span>
                <span v-else>
                  <!--      更換產品用      -->
                  <el-select v-model="scope.row.gid" @change="selectProductChange(scope.row, scope.$index)">
                    <el-option v-for="(item, index) in goodsList" :key="item.gid" :value="item.gid"
                               :label="item.goods_name">
                    </el-option>
                  </el-select>
                </span>
              </template>
            </el-table-column>
            <!--            <el-table-column-->
            <!--              prop="sku_name"-->
            <!--              header-align="center"-->
            <!--              :label="$t('supplier-produceManage-publishProduct.98875D@Product_specifications')"-->
            <!--            ></el-table-column>-->
            <el-table-column
                prop="unit_name"
                header-align="center"
                :label="$t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit')"
            >
              <template scope="scope">
                <div v-if="!scope.row.is_new">
                  {{ scope.row.unit_num }}/{{ scope.row.unit_name }}
                </div>
                <div v-else>
                  {{ scope.row.unit_name }}
                </div>
              </template>
            </el-table-column>
            <!--      產品價格      -->
            <el-table-column
                prop="receive_unit_price"
                header-align="center"
                :label="$t('restaurant_buy_B2BProduct.EF1083@unit_price')"
                width="150"
            >
              <template slot-scope="scope">
                <div class="justify-around">
                  <span>{{ symbol }}</span>
                  <el-input
                      @keyup.native="inputPrice(scope.$index)"
                      v-model="detailData.goods_list[scope.$index].receive_unit_price"
                      size="small"
                      :min="0"
                      :label="$t('restaurant_supplier_supplierList.E318B6@desc')"
                      style="width:100%;max-width:90px;"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <!--      總金額      -->
            <el-table-column
                prop="final_goods_price"
                header-align="center"
                :label="$t('orderDetail.7CA68E@total_price')"
            ></el-table-column>
            <el-table-column
                prop="buy_num"
                header-align="center"
                :label="$t('orderDetail.CAF2C7@number')"
                width="150">
              <template slot-scope="scope">
                <span
                    v-if="detailData.order_status_no!=7 && detailData.order_status_no!=1">{{ scope.row.buy_num }}</span>
                <!--      用於產品改變時的購買數量      -->
                <span v-else>
                  <el-input-number
                      @change="buyNumberChange(scope.$index)"
                      v-model="scope.row.buy_num"
                      size="small"
                      :min="0"
                      style="max-width:125px;">
                  </el-input-number>
                </span>
              </template>
            </el-table-column>
            <!--      接收數量      -->
            <el-table-column
                prop="receive_num"
                header-align="center"
                :label="$t('orderDetail.3BB7C2@Accept_number')">
              <template slot-scope="scope">
                <span
                    v-if="detailData.order_status_no!=5 && detailData.order_status_no!=6 && detailData.order_status_no!=1">{{
                    scope.row.receive_num
                  }}</span>
                <el-input-number
                    v-else
                    v-model="scope.row.receive_num"
                    size="small"
                    :min="0"
                    style="max-width:125px;"
                    @change="receiveNumberChange(scope.$index)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
                v-if="detailData.order_status_no<=4"
                prop="opt"
                header-align="center"
                :label="$t('public.03EA09@operation')">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.is_new" type="danger" @click="delOrderGoods(scope.row.order_gid)"
                           size="small">
                  {{ $t('public.1FBB5E@btn_delete') }}
                </el-button>
                <el-button v-else type="danger" @click="delNewGoods(scope.$index)" size="small">
                  {{ $t('public.1FBB5E@btn_delete') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <section class="statistics">
          <ul>
            <li>
              <span>{{ $t('aboutus.09C1E0@Total_amount_of_goods') }} :</span>
              <span>{{ detailData.receive_goods_price }}</span>
            </li>
            <li v-if="detailData.order_status_no!=8">
              <span>{{ $t('restaurant_supplier_supplierList.2E31F9@fee') }} :</span>
              <span v-if="detailData.receive_deliver_fee=='$0.00'">{{ detailData.receive_deliver_fee }}</span>
              <span v-else style="color: #f24040;font-weight: bold">{{ detailData.receive_deliver_fee }}</span>
            </li>
            <li v-else>
              <!--      用於產品改變時的購買數量      -->
              <span>
                {{ $t('restaurant_supplier_supplierList.2E31F9@fee') }} :
              </span>
              <span>
                $
                <el-input @keyup.native="inputDigital" class="receive_deliver_fee" type="text" size="mini"
                          v-model="receive_deliver_fee" style="max-width: 80px;padding: 0">
                </el-input>
              </span>
            </li>
            <li>
              <span>{{ $t('shop_cart.A91ABF@order_price') }} :</span>
              <span>{{ detailData.final_price }}</span>
            </li>
          </ul>
        </section>

        <!-- 备注部分 -->
        <!-- <section class="note">
          <span>{{$t('orderDetail.t10')}}</span>
          <el-input type="textarea" :rows="4" v-model="note"></el-input>
        </section>-->
        <section class="btn-group">
          <div>
            <b-btn class="table-btn btn-gray" @click="goBack">{{ $t('orderDetail.98FF8B@next') }}</b-btn>
          </div>
          <el-button type="primary" size="small" @click="changeOrder">{{ $t('public.A5864E@changeOrder') }}</el-button>
        </section>
      </section>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  data() {
    return {
      //可以新增產品清單
      canAddProductList: [],
      //運費
      receive_deliver_fee: "",
      //供應商
      supplier: {},
      //原本的產品清單
      originGoodList: [],
      //該供應商商品清單
      goodsList: [],
      note: "",
      centerDialogVisible: false,
      detailData: {},
      order_no: "",
      //type为1是b2b 0为自选
      type: "",
      symbol: "",
      //驗證時間
      pickerDisabled: {
        disabledDate: (time) => {
            let date = new Date();
            let str =
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate();
            return (
                Date.parse(time) / 1000 <
                Date.parse(str) / 1000
            );
        },
      },
    };
  },
  components: {
    pageTop
  },
  methods: {
    //選擇新產品
    selectAddGoods(index) {
      let goods = this.canAddProductList.find(g => {
        return g.gid == this.detailData.goods_list[index].gid
      })

      if (goods) {
        this.detailData.goods_list[index].receive_unit_price = goods.price
        this.detailData.goods_list[index].goods_no = goods.goods_no
        this.detailData.goods_list[index].unit_name = goods.unit_name
        this.buyNumberChange(index)
      }
    },
    //刪除新產品
    delNewGoods(index) {
      this.detailData.goods_list = this.detailData.goods_list.slice(0, index)
    },
    //新增一行
    addNewRow() {
      this.detailData.goods_list.push({
        is_new: true,
        buy_num: 0.000,
        receive_num: 0.000
      })
    },
    //取得該筆訂單可以新增的產品清單
    getCanAddProductList() {
      this.$http.getCanAddProductList({
        oid: this.detailData.oid
      }).then(res => {
        this.canAddProductList = res.data
      })
    },
    //刪除訂單產品
    delOrderGoods(order_gid) {
      this.$http.delOrderGoods({
        order_gid: order_gid,
        order_no: this.detailData.order_no,
      }).then(res => {
        if (res.errno == 0) {
          this.getData()
        }
      })
    },
    //價格只能輸入數值
    inputPrice(index) {
      if (this.detailData.goods_list[index].receive_unit_price) {
        this.detailData.goods_list[index].receive_unit_price = this.detailData.goods_list[index].receive_unit_price
            //清除"数字"和"."以外的字符
            .replace(/[^\.\d]/g, '')
            //验证第一个字符是数字而不是.
            .replace(/^\./g, '')
            //只保留第一个. 清除多余的.
            .replace(/\.{2,}/g, ".")
            //只允许输入一个小数点
            .replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
            //只能输入两个小数
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      } else {
        this.detailData.goods_list[index].receive_unit_price = 0.00
      }
      this.buyNumberChange(index)
    },
    //只能輸入數字
    inputDigital() {
      if (this.receive_deliver_fee != '') {
        this.receive_deliver_fee = this.receive_deliver_fee
            //清除"数字"和"."以外的字符
            .replace(/[^\.\d]/g, '')
            //验证第一个字符是数字而不是.
            .replace(/^\./g, '')
            //只保留第一个. 清除多余的.
            .replace(/\.{2,}/g, ".")
            //只允许输入一个小数点
            .replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
            //只能输入两个小数
            .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      } else {
        this.receive_deliver_fee = '0.00'
      }
      this.detailData.final_price = '$' +
          (parseFloat(this.detailData.receive_goods_price.substring(this.detailData.receive_goods_price.indexOf('$') + 1).replace(',', ''))
              + parseFloat(this.receive_deliver_fee)).toFixed(2)
    },
    //改變下單數量改變
    buyNumberChange(index) {
      let data = this.detailData.goods_list[index]
      this.detailData.goods_list[index].final_goods_price = '$' + parseFloat(parseFloat(data.receive_unit_price) * parseFloat(data.buy_num)).toFixed(2)
      this.detailData.receive_goods_price = 0.0
      this.detailData.final_price = 0.0

      //重新計算價格
      this.detailData.goods_list.forEach(i => {
        let price = i.final_goods_price.replace('$', '')
        this.detailData.final_price += parseFloat(price)
        this.detailData.receive_goods_price += parseFloat(price)
        i.receive_num = parseFloat(i.buy_num).toFixed(2)
      })

      //達到運費標準
      if (parseFloat(this.detailData.full_slicing) > parseFloat(this.detailData.receive_goods_price)) {
        this.detailData.final_price += parseFloat(this.detailData.deliver_fee)
        this.detailData.receive_deliver_fee = '$' + parseFloat(this.detailData.deliver_fee).toFixed(2)
      } else {
        if (this.detailData.receive_deliver_fee == '$0.00') {
          this.detailData.receive_deliver_fee = "$0.00"
        }else{
          this.detailData.receive_deliver_fee = "$0.00"
        }
      }

      this.detailData.receive_goods_price = '$' + parseFloat(this.detailData.receive_goods_price).toFixed(2)
      this.detailData.final_price = '$' + parseFloat(this.detailData.final_price).toFixed(2)
      this.$forceUpdate()
      if (parseFloat(data.buy_num) == 0 && !data.is_new) {
        this.$http.delOrderGood({
          oid: this.detailData.oid,
          order_gid: data.order_gid,
        }).then(res => {
          if (res.errno == 0) {
            this.getData()
          }
        })
      }
    },
    //接收數量改變
    receiveNumberChange(index) {
      if (this.detailData.order_status_no != 1) {
        let data = this.detailData.goods_list[index]
        this.detailData.goods_list[index].final_goods_price = '$' + parseFloat(parseFloat(data.receive_unit_price) * parseFloat(data.receive_num)).toFixed(2)
        this.detailData.receive_goods_price = 0.0
        this.detailData.final_price = 0.0

        //重新計算價格
        this.detailData.goods_list.forEach(i => {
          let price = i.final_goods_price.replace('$', '')
          this.detailData.final_price += parseFloat(price)
          this.detailData.receive_goods_price += parseFloat(price)
        })
        //達到運費標準
        if (parseFloat(this.detailData.full_slicing) > parseFloat(this.detailData.receive_goods_price)) {
          this.detailData.final_price += parseFloat(this.detailData.deliver_fee)
          this.detailData.receive_deliver_fee = '$' + parseFloat(this.detailData.deliver_fee).toFixed(2)
        } else {
          if (this.detailData.receive_deliver_fee == '$0.00') {
            this.detailData.receive_deliver_fee = "$0.00"
          }else{
            this.detailData.receive_deliver_fee = "$0.00"
          }
        }

        this.detailData.receive_goods_price = '$' + parseFloat(this.detailData.receive_goods_price).toFixed(2)
        this.detailData.final_price = '$' + parseFloat(this.detailData.final_price).toFixed(2)
        this.$forceUpdate()

        if (parseFloat(data.receive_num) == 0) {
          this.$http.delOrderGood({
            oid: this.detailData.oid,
            order_gid: data.order_gid,
          }).then(res => {
            if (res.errno == 0) {
              this.getData()
            }
          })
        }
      }
    },
    //產品被改變
    selectProductChange(data, index) {
      let info = this.goodsList.find((item, index) => {
        return item.gid == data.gid
      })
      this.detailData.goods_list[index] = {
        order_gid: data.order_gid,
        gid: info.gid,
        goods_name: info.goods_name,
        sku_name: info.sku_name,
        receive_unit_price: parseFloat(info.price).toFixed(2),
        buy_num: data.buy_num,
        final_goods_price: (parseFloat(info.price) * parseFloat(data.receive_num)).toFixed(2),
        receive_num: data.receive_num,
        unit_num: parseFloat(info.unit_num).toFixed(2),
        unit_name: info.unit,
      }
      this.buyNumberChange(index)
    },
    //取得供應商商品清單
    showGoodsListBySupplier(supplier_id) {
      this.$http.showGoodsListBySupplier({
        is_b2b: 1,
        supplier_id: supplier_id,
      }).then(res => {
        this.goodsList = res.data;
      })
    },
    //訂單狀態改變
    orderStatusChange(e) {
      if (e == 8) {
        this.receive_deliver_fee = this.detailData.receive_deliver_fee ?
            parseFloat(this.detailData.receive_deliver_fee.replace('$', '')).toFixed(2) : "0.00"
      } else if (e == 7) {
        this.receive_deliver_fee = 0
      } else {
        this.receive_deliver_fee = 0
        this.getData(e)
        this.originGoodList = []
        this.$forceUpdate()
      }
    },
    //获取数据
    getData(status) {
      let order_no = this.order_no;
      let that = this;

      this.$http.getOrderDetail({order_no}).then(res => {
        function getData(data) {
          data.goods_list.forEach(item => {
            that.symbol = item.receive_unit_price.substring(0, 1);
            item.receive_unit_price = item.receive_unit_price.substring(1);
          });
          return data;
        }

        this.detailData = getData(res.data);
        if (status) {
          this.detailData.order_status_no = status
        }
        this.showGoodsListBySupplier(this.detailData.company_id)
        this.originGoodList = []
        this.detailData.goods_list.forEach(item => {
          this.originGoodList.push({
            buy_num: item.buy_num,
            final_goods_price: item.final_goods_price,
            gid: item.gid,
            goods_name: item.goods_name,
            order_gid: item.order_gid,
            receive_num: item.receive_num,
            receive_unit_price: item.receive_unit_price,
            sku_name: item.sku_name,
            unit_name: item.unit_name,
            unit_num: item.unit_num
          })
        })
        //原先送貨時間不存在
        if(this.detailData.delivery_date_time==0){
          this.detailData.delivery_date_time = new Date().getTime()
        }else{
          this.detailData.delivery_date_time = this.detailData.delivery_date_time * 1000
        }

        this.getCanAddProductList()
      });
    },
    goPrint() {
      const {href} = this.$router.resolve({
        name: "print",
        query: {order_no: this.detailData.order_no, type: this.type}
      });
      window.open(href, "_blank");
    },
    goBack() {
      this.$router.push("purchase");
    },
    changeOrder() {
      //原先的訂單修改
      if (this.detailData.order_status_no <= 6) {
        let goods_json = [];
        this.detailData.goods_list.forEach((v, i) => {
          let params = {
            order_gid: v.order_gid,
            gid: v.gid,
            receive_unit_price: v.receive_unit_price,
            receive_num: v.receive_num,
            buy_num: v.buy_num,
          }
          goods_json.push(params)
        })
        let params = {
          order_no: this.detailData.order_no,
          status_no: this.detailData.order_status_no,
          pay_status: this.detailData.pay_status_no,
          pay_method:this.detailData.pay_method_num,
          goods_json: JSON.stringify(goods_json),
          delivery_date_time:this.detailData.delivery_date_time/1000,
        }
        this.$http.editOrder(params).then(res => {
          if (res.errno == 0) {
            this.getData()
          }
        })
      } else if (this.detailData.order_status_no == 7) {
        let newOrderArray = []
        this.originGoodList.filter(x => this.detailData.goods_list.some(y => {
          if (x.order_gid === y.order_gid && x.gid !== y.gid) {
            newOrderArray.push({
              oldGid: x.gid,
              newGid: y.gid,
              old_buy_num: x.buy_num,
              new_buy_num: y.buy_num,
              new_receive_num: y.receive_num,
              order_gid: y.order_gid,
              receive_unit_price: parseFloat(y.receive_unit_price)
            })
            return true
          }
          return false
        }))

        if (newOrderArray.length > 0) {
          this.originGoodList.filter(x => this.detailData.goods_list.some(y => {
            if (x.order_gid === y.order_gid && x.gid === y.gid) {
              newOrderArray.push({
                oldGid: x.gid,
                newGid: y.gid,
                old_buy_num: x.buy_num,
                new_buy_num: y.buy_num,
                new_receive_num: y.receive_num,
                order_gid: y.order_gid,
                receive_unit_price: parseFloat(y.receive_unit_price)
              })
              return true
            }
            return false
          }))
          this.$http.changeOrderProduct({
            order_no: this.detailData.order_no,
            status_no: this.detailData.order_status_no,
            pay_status: this.detailData.pay_status_no,
            goods_json: JSON.stringify(newOrderArray),
            delivery_date_time:this.detailData.delivery_date_time/1000,
            final_goods_price: this.detailData.receive_goods_price.replace('$', '').replace(',', ''),
            final_price: this.detailData.final_price.replace('$', '').replace(',', ''),
          }).then(res => {
            this.originGoodList = []
            this.getData(7)
          })
        } else {
          newOrderArray = []
          this.detailData.goods_list.forEach(item => {
            newOrderArray.push({
              oldGid: item.gid,
              newGid: item.gid,
              old_buy_num: item.buy_num,
              new_buy_num: item.buy_num,
              new_receive_num: item.receive_num,
              order_gid: item.order_gid,
              receive_unit_price: parseFloat(item.receive_unit_price)
            })
          })
          this.$http.changeOrderProduct({
            order_no: this.detailData.order_no,
            status_no: this.detailData.order_status_no,
            pay_status: this.detailData.pay_status_no,
            goods_json: JSON.stringify(newOrderArray),
            delivery_date_time:this.detailData.delivery_date_time/1000,
            final_goods_price: this.detailData.receive_goods_price.replace('$', '').replace(',', ''),
            final_price: this.detailData.final_price.replace('$', '').replace(',', ''),
          }).then(res => {
            this.originGoodList = []
            this.getData(7)
          })
        }
      } else if (this.detailData.order_status_no == 8) {
        //更改運費
        this.$http.editOrderDeliverFee({
          oid: this.detailData.order_no,
          deliver_fee: this.receive_deliver_fee.substring(this.receive_deliver_fee.indexOf('$') + 1),
        })
      }
    }
  },
  created() {
    this.order_no = this.$route.query.order_no;
    this.type = +this.$route.query.type;
    this.getData();
  }
};
</script>
<style lang="scss">
@import "../../styles/app.scss";

.order-detail {
  display: flex;
  flex-direction: column;

  .detail-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #e6e6e6;

    div {
      h3 {
        font-size: 16px;
        font-weight: bold;

        > span {
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          color: #333333;
        }
      }

      span {
        font-size: 12px;
        font-stretch: normal;
      }
    }

    section {
      span {
        font-size: 14px;
        color: #666666;
        padding-right: 10px;
      }
    }
  }

  .info {
    margin-right: 120px;

    li {
      font-size: 14px;
      padding: 10px 0;
      color: #666;
    }
  }

  .statistics {
    background-color: #f0f0f0;
    margin: 20px 0;
    padding: 5px 15px;

    > ul {
      float: right;

      > li {
        padding-top: 5px;
        display: flex;
        justify-content: space-between;

        span {
          display: block;
          min-width: pxToper(110);

          &:nth-child(2n) {
            text-align: right;
          }

          .receive_deliver_fee {
            input {
              text-align: right;
            }
          }
        }

        b {
          font-weight: 900;
        }
      }
    }
  }

  .note {
    span {
      font-weight: 900;
    }
  }

  .btn-group {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
