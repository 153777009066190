<template>
  <div class="page-top">
    <div class="img-box">
      <slot></slot>
    </div>
    <h4>{{ title }}</h4>
  </div>
</template>

<script>
export default {
  props: ['title', 'icon'],
  data () {
    return {
    };
  },


  components: {},

  methods: {}
}

</script>
<style lang="scss" scoped>
.page-top {
  padding: 20px 0;
  display: flex;
  align-items: center;
  .img-box {
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>