<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-20 10:55:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\pages\shopManage\restaurantList.vue
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.411879@restaurant_list')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <el-input size="small" prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"
                  @change="changeSearchText"></el-input>
        <kt-button
            type="primary"
            :label="$t('public.DC9752@btn_add')"
            :perm="651"
            @click="add">
        </kt-button>
        <kt-button
            type="success"
            :label="$t('backend_submenu.BCC157@export_@restaurant_list')"
            :perm="16259145892682"
            @click="export2Excel"
        ></kt-button>
      </section>
      <section class="flex-start">
        <el-select size="small" v-model="show" @change="statusChange">
          <el-option :key="2" :value="2" :label="$t('hidden_or_show.EAD5E1@display_all_restaurant')"></el-option>
          <el-option :key="1" :value="1" :label="$t('hidden_or_show.DFE51A@display_hide_restaurant')"></el-option>
          <el-option :key="0" :value="0"
                     :label="$t('hidden_or_show.DE2D1E@display_none_hidden_restaurant')"></el-option>
        </el-select>
        <el-select size="small" v-model="locale" @change="statusChange">
          <el-option :key="'all'" :value="'all'"
                     :label="`${$t('rating.E2DF56@all')}${$t('public.locale')}`"></el-option>
          <el-option :key="'hk'" :value="'hk'" :label="$t('public.51D01B@traditional_chinese')"></el-option>
          <el-option :key="'en'" :value="'en'" :label="$t('public.154BAB@english')"></el-option>
        </el-select>
      </section>
      <section class="flex-end">
      </section>
      <el-table
          border
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          :data="tableData"
          style="width: 100%;margin-top:20px;"
      >
        <el-table-column
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="admin"
            :label="$t('backend_shop_supplierList.E919F3@super_user')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="contact"
            :label="$t('backend_shop_supplierList.5A75EE@Contact_person')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="email"
            :label="$t('backend_shop_supplierList.F9618A@company_email')"
            show-overflow-tooltip
            align="center"
        ></el-table-column>
        <el-table-column
            prop="telephone"
            :label="$t('backend_shop_supplierList.65868F@company_phone')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="status"
            :label="$t('backend_shop_supplierList.760B82@company_status')"
            align="center"
        >
          <template slot-scope="scope">{{ status(scope.row.status) }}</template>
        </el-table-column>
        <el-table-column
            prop="create_time"
            :label="$t('backend_shop_supplierList.D4ED09@registration_time')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="opration"
            :label="$t('public.03EA09@operation')"
            width="250"
            align="center"
            fixed="right"
        >
          <template slot-scope="scope">
            <kt-button
                :perm="879"
                :label="$t('public.DBC110@locked')"
                @click="lock(scope.row,1)"
                v-if="scope.row.status == 'NORMAL'"
            ></kt-button>
            <kt-button
                :perm="879"
                :label="$t('public.564C21@unlock')"
                @click="lock(scope.row,0)"
                v-else
            ></kt-button>
            <!--     隱藏       -->
            <kt-button
                v-if="scope.row.is_hidden==0"
                :perm="16259182518196"
                :label="$t('rating.A2575E@Hide')"
                type="danger"
                @click="showOrHiddenCompany(scope.row.company_id, 1)"
            >
            </kt-button>
            <!--     顯示       -->
            <kt-button
                v-else
                :perm="16259182518196"
                :label="$t('public.B3669C@show')"
                type="success"
                @click="showOrHiddenCompany(scope.row.company_id, 0)"
            >
            </kt-button>
            <kt-button
                type="info"
                :perm="854"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[50,100,250,500]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
        :title="addModify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
        center
        width="60%"
        :visible.sync="dialogFormVisible"
    >
      <el-form ref="form" :rules="rules" :model="dialogForm" label-width="150px">
        <el-row>
          <el-col :span="18" :offset="2">
            <el-form-item
                :label="$t('public.A82133@username')"
                prop="user_name"
                v-if="addModify==0"
            >
              <el-input style="width:100%;" v-model="dialogForm.user_name"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.F703D1@company_name_hk')"
                prop="company_name_hk"
            >
              <el-input style="width:100%;" v-model="dialogForm.company_name_hk"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.394FBB@company_name_en')"
                prop="company_name_en"
            >
              <el-input style="width:100%;" v-model="dialogForm.company_name_en"></el-input>
            </el-form-item>
            <!--      香港行政區      -->
            <el-form-item
                :label="$t('restaurant_store.938F71@HongKong_SAR')"
                prop="district_id"
                v-if="addModify==0">
              <el-select v-model="dialogForm.district_id">
                <el-option v-for="item in HKDistrictList" :key="item.district_id" :value="item.district_id"
                           :label="item.district">
                </el-option>
              </el-select>
            </el-form-item>
            <!--      語系      -->
            <el-form-item
                :label="$t('public.locale')"
                prop="locale">
              <el-select v-model="dialogForm.locale">
                <el-option :label="$t('public.51D01B@traditional_chinese')" :key="0" :value="'hk'"></el-option>
                <el-option :label="$t('public.154BAB@english')" :key="1" :value="'en'"></el-option>
              </el-select>
            </el-form-item>
            <!--      分店名稱      -->
            <el-form-item
                :label="$t('restaurant_store.BA05EA@store_name_hk')"
                prop="shop_name_hk"
                v-if="addModify==0"
            >
              <el-input style="width:100%;" v-model="dialogForm.shop_name_hk"></el-input>
            </el-form-item>
            <!--      分店名稱      -->
            <el-form-item
                :label="$t('restaurant_store.238DE5@store_name_en')"
                prop="shop_name_en"
                v-if="addModify==0"
            >
              <el-input style="width:100%;" v-model="dialogForm.shop_name_en"></el-input>
            </el-form-item>
            <!--     收貨時間       -->
            <el-form-item :label="$t('aboutus.D453CE@Time_of_receipt')" prop="receive_time_start" v-if="addModify==0">
              <el-time-picker
                  is-range
                  format="HH:mm"
                  value-format="HH:mm"
                  v-model="time"
                  range-separator="~"
                  :start-placeholder="$t('placeholder.524620@start_time')"
                  :end-placeholder="$t('placeholder.ABB7CF@end_time')"
                  :placeholder="$t('aboutus.D453CE@Time_of_receipt')"
                  @change="changeTime"
                  style="width:200px"
              ></el-time-picker>
            </el-form-item>
            <!--     上班時間       -->
            <el-form-item :label="$t('aboutus.2A0D3F@Working_hours')" prop="work_start" v-if="addModify==0">
              <el-time-picker
                  is-range
                  format="HH:mm"
                  value-format="HH:mm"
                  v-model="workTime"
                  range-separator="~"
                  :start-placeholder="$t('placeholder.524620@start_time')"
                  :end-placeholder="$t('placeholder.ABB7CF@end_time')"
                  :placeholder="$t('aboutus.D453CE@Time_of_receipt')"
                  @change="changeWorkTime"
                  style="width:200px"
              ></el-time-picker>
            </el-form-item>

            <el-form-item
                :label="$t('backend_shop_supplierList.5A75EE@Contact_person')"
                prop="contact"
            >
              <el-input style="width:100%;" v-model="dialogForm.contact"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.F9618A@company_email')"
                prop="email"
            >
              <el-input style="width:100%;" v-model="dialogForm.email"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.65868F@company_phone')"
                prop="telephone"
            >
              <el-input style="width:100%;" v-model="dialogForm.telephone"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.B08D37@addr_hk')" prop="addr_hk">
              <el-input style="width:100%;" v-model="dialogForm.addr_hk"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.EFBD4B@addr_en')" prop="addr_en">
              <el-input style="width:100%;" v-model="dialogForm.addr_en"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_submenu.21BF37@grouping')" prop="gid">
              <el-select v-model="dialogForm.gid" style="width: 100%"
                         clearable filterable remote>
                <el-option v-for="v in customAuthGroupList" :key="v.group_id" :value="v.group_id"
                           :label="lang?v.name_hk : v.name_en">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('public.asia_miles_membership_no')" prop="asia_miles_account">
              <el-input style="width:100%;" v-model="dialogForm.asia_miles_account"></el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('backend_shop_supplierList.5AEC72@remark_hk')" prop="desc_hk">
              <el-input style="width:100%;" v-model="dialogForm.desc_hk"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.EF978E@remark_en')" prop="desc_en">
              <el-input style="width:100%;" v-model="dialogForm.desc_en"></el-input>
            </el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button
            @click="dialogFormVisible = false"
            class="table-btn btn-gray"
        >{{ $t('public.836FD4@cancel') }}
        </el-button>
        <el-button
            @click="confirmDialog"
            class="table-btn btn-yellow"
        >{{ $t('public.3B45A2@confirm') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/index";
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";

export default {
  watch: {
    searchText() {
      this.getTableData()
    }
  },
  data() {
    return {
      //語系
      locale: "all",
      time: "",
      workTime: "",
      //2=顯示所有餐廳, 1=已隱藏的餐廳, 0=未隱藏的餐廳
      show: 0,
      //搜尋文字
      searchText: "",
      //當前語言
      lang: this.$i18n.locale == 'hk',
      loading: true,
      select: {
        search: ""
      },
      //自訂授權群組
      customAuthGroupList: [],
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 100,
      HKDistrictList: [],
      dialogFormVisible: false,
      dialogForm: {
        user_name: "",
        company_name_en: "",
        company_name_hk: "",
        shop_name_hk: "",
        shop_name_en: "",
        email: "",
        telephone: "",
        addr_en: "",
        addr_hk: "",
        desc_en: "",
        desc_hk: "",
        contact: "",
        cost_ratio: "",
        //自定義餐廳授權群組id
        gid: "",
        //區域
        district_id: "",
        work_start: "",
        work_end: "",
        receive_time_start: "",
        receive_time_end: "",
        //語系
        locale: "",
        //亞洲飛行里程
        asia_miles_account: "",
      },
      //新增=0, 修改=1
      addModify: 0,
      row: {},
      rules: {
        user_name: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        company_name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        company_name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        email: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        telephone: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        addr_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        addr_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        contact: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        cost_ratio: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.GetHKDistrictList()
    this.getCustomAuthGroupList()
    this.getTableData();
  },
  methods: {
    changeSearchText() {
      this.currentPage = 1
      this.getTableData()
    },
    changeRules() {
      //新增
      if (this.addModify == 0) {
        this.rules = {
          receive_time_start: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          work_start: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          district_id: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          locale: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
          shop_name_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          shop_name_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          user_name: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          company_name_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          company_name_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          email: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          telephone: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          addr_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          addr_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          contact: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          cost_ratio: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          }
        }
      } else {
        this.rules = {
          user_name: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          company_name_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          company_name_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          email: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          telephone: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          addr_en: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          addr_hk: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          contact: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          cost_ratio: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "blur"
          },
          locale: {
            required: true,
            message: this.$t("aboutus.B003A6@This_is_required"),
            trigger: "change"
          },
        }
      }
    },
    changeTime(e) {
      if (e) {
        this.dialogForm.receive_time_start = e[0];
        this.dialogForm.receive_time_end = e[1];
      } else {
        this.dialogForm.receive_time_start = "";
        this.dialogForm.receive_time_end = "";
      }
    },
    changeWorkTime(e) {
      if (e) {
        this.dialogForm.work_start = e[0];
        this.dialogForm.work_end = e[1];
      } else {
        this.dialogForm.work_start = "";
        this.dialogForm.work_end = "";
      }
    },
    //取得香港行政分區
    GetHKDistrictList() {
      this.$http.HKDistrictList().then(res => {
        this.HKDistrictList = res.data
      })
    },
    //狀態改變
    statusChange() {
      this.currentPage = 1
      this.getTableData()
    },
    //隱藏公司
    showOrHiddenCompany(company_id, status) {
      this.$http.showOrHiddenCompany({
        company_id: company_id,
        project: 'restaurant',
        status: status
      }).then(res => {
        if (res.errno == 0) {
          this.getTableData()
        }
      })
    },
    status(data) {
      if (this.$i18n.locale == 'hk') {
        if (data == "NORMAL") {
          return '正常'
        } else {
          return '鎖定'
        }
      } else {
        if (data == "NORMAL") {
          return 'Normal'
        } else {
          return 'Locked'
        }
      }
    },
    export2Excel() {
      this.$http.exportRestaurant2Excel({
        status: this.show,
      }).then(res => {
        this.downloadFile(res)
      })
    },
    checkboxChange(checked) {
      if (!checked)
        this.dialogForm.pos_id = ""
    },
    getCustomAuthGroupList() {
      this.$http.getCustomAuthGroupList({
        project: 'restaurant',
      }).then((res) => {
        let defaultArray = [{
          name_en: 'Custom',
          name_hk: 'Custom',
          group_id: -1
        }]
        this.customAuthGroupList = defaultArray.concat(res.data)
      })
    },
    getTableData() {
      api.getRestaurant({
        status: this.show,
        size: this.pageSize,
        page: this.currentPage,
        search: this.searchText,
        loc: this.locale,
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.sum;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    add() {
      this.addModify = 0;
      this.changeRules()
      this.dialogFormVisible = true;
      if (this.dialogForm.email) {
        this.$refs["form"].resetFields();
      }
      this.dialogForm.enable_pos = false
      this.dialogForm.pos_id = ""
    },
    modify(row) {
      this.addModify = 1;
      this.changeRules()
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.row = row;
      api.queryRestaurant({
        company_id: row.company_id
      }).then(res => {
        Object.assign(this.dialogForm, res.data);
      });
    },

    confirmDialog() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.addModify) {
            //修改
            let params = Object.assign({}, this.dialogForm);
            api.modifyRestaurant({
              company_id: this.row.company_id,
              ...params,
              cost_ratio: +params.cost_ratio
            }).then(res => {
              if (res.errno == 0) {
                this.dialogFormVisible = false;
                this.getTableData();
                this.dialogForm.gid = ""
                this.dialogForm.asia_miles_account = ""
              }
            });
          } else {
            let params = Object.assign({}, this.dialogForm);
            api.addRestaurant({
              ...params
            }).then(res => {
              if (res.errno == 0) {
                this.getTableData();
                this.dialogFormVisible = false;
                this.dialogForm.gid = ""
                this.dialogForm.district_id = ""
                this.workTime = ""
                this.time = ""
                this.dialogForm.user_name = ""
                this.dialogForm.asia_miles_account = ""
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    lock(row, type) {
      api.lockRestaurant({
        company_id: row.company_id,
        type: type
      }).then(res => {
        this.getTableData();
      });
    },

    download() {
      api.download().then(res => {
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>
