<template>
  <div>
    <page-top :title="$t('specialArea.title')">
    </page-top>
    <el-tabs type="border-card" v-model="tabName" @tab-click="tabClick">
      <!--  折扣    -->
      <el-tab-pane :label="$t('specialArea.discount')" name="discount">
        <section class="flex-end" style="padding-bottom: 10px;">
          <kt-button class="table-btn btn-yellow" :label="$t('public.DC9752@btn_add')" :perm="16136622262760"
                     @click="clickAddDiscount">
          </kt-button>
        </section>
        <!--   表單     -->
        <el-table border v-loading="loading" :data="data">
          <!--     公司名稱     -->
          <el-table-column
              :label="$t('restaurant_buy_genner.586E4F@supplier_name')"
              prop="company_name"
              align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.display_name ? scope.row.display_name : scope.row.company_name }}</span>
            </template>
          </el-table-column>
          <!--     產品名稱     -->
          <el-table-column :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')" prop="goodsName"
                           align="center">
          </el-table-column>
          <!--     產品照片     -->
          <el-table-column :label="$t('specialArea.goods_picture')" prop="pic"
                           align="center">
            <template slot-scope="scope">
              <img style="height:60px" v-if="scope.row.picture" :src="scope.row.picture" alt/>
              <span v-else>{{ $t('public.84FA27@no_pictures') }}</span>
            </template>
          </el-table-column>
          <!--     產品分類     -->
          <el-table-column :label="$t('aboutus.AB0929@side_bar')" prop="category"
                           align="center">
            <template slot-scope="scope">
              <span>{{ `${scope.row.category1Name} / ${scope.row.category2Name}` }}</span>
            </template>
          </el-table-column>
          <!--     價格     -->
          <el-table-column :label="$t('aboutus.96F4F8@product_price')" prop="price"
                           align="center">
            <template slot-scope="scope">
              <span style="text-decoration: line-through;">{{ scope.row.price }}</span>
              <span> {{ scope.row.discount_price }}</span>
            </template>
          </el-table-column>

          <!--      操作    -->
          <el-table-column
              prop="btn"
              align="center">
            <template slot-scope="scope">
              <!--       刪除       -->
              <kt-button
                  :perm="16136761389596"
                  type="danger"
                  :label="$t('public.1FBB5E@btn_delete')"
                  @click="delDiscountGoods(scope.row.gid)">
              </kt-button>
            </template>
          </el-table-column>
        </el-table>
        <section class="pagination-container">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          ></el-pagination>
        </section>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="showDiscountDialog" :title="$t('public.DC9752@btn_add')"
               width="50%" center>
      <el-form :model="discountForm" ref="form" label-width="150px">
        <el-row>
          <el-col :span="20" :offset="2">
            <!--    供應商        -->
            <el-form-item
                :label="`${$t('specialArea.discount')}${$t('supplier_supplier_produceManage.B8BD33@product_name')}`">
              <el-select v-model="discountForm.gid" style="width:80%"
                         :placeholder="`${$t('placeholder.C209ED@select')}${$t('specialArea.discount')}`"
                         filterable remote multiple automatic-dropdown>
                <el-option v-for="item in discountList" :key="item.gid" :label="item.goodsName" :value="item.gid">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button type="primary" size="small" @click="addDiscountGoods2SpecialArea">
          {{ $t('public.3B45A2@confirm') }}
        </el-button>
        <el-button type="danger" @click="show=false" size="small">
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import ktButton from "../../components/ktButton/KtButton";

export default {
  components: {
    pageTop,
    ktButton,
  },
  name: "",
  data() {
    return {
      //折扣表單
      discountForm: {
        //gid
        gid: [],
      },
      ///搜尋關鍵字
      keywords: "",
      //折扣產品
      discountList: [],
      ///是否顯示折扣
      showDiscountDialog: false,
      loading: false,
      //total
      total: 0,
      //data
      data: [],
      //show
      show: false,
      //add=0, modify=1
      status: 0,
      //tab name
      tabName: "discount",
      //type, 0=discount, 1=groupBuy
      type: 0,
      //每頁顯示數量
      size: 10,
      //當前分頁
      page: 1,
    }
  },
  created() {
    this.init(true)
  },
  methods: {
    //移除團購產品
    delDiscountGoods(gid) {
      this.modifyDiscountGoods2SpecialArea(0, gid)
    },
    ///點擊新增折扣產品按鈕
    clickAddDiscount() {
      this.showDiscountDialog = true
      this.discountForm.gid = []
    },
    //新增折扣產品至優惠專區
    addDiscountGoods2SpecialArea() {
      if (this.discountForm.gid.length > 0) {
        this.modifyDiscountGoods2SpecialArea(1)
      } else {
        this.$message.error(`${this.$t('placeholder.C209ED@select')}${this.$t('specialArea.discount')}`)
      }
    },
    //修改優惠專區的折扣產品
    modifyDiscountGoods2SpecialArea(status, gid) {
      this.$http.modifyDiscountGoods2SpecialArea({
        gid: gid ? JSON.stringify([gid]) : JSON.stringify(this.discountForm.gid),
        status: status,
      }).then(res => {
        if (res.errno == 0) {
          this.discountForm.gid = gid ? this.discountForm.gid : []
          this.showDiscountDialog = false
          this.init()
        }
      })
    },
    //初始化
    init(isChangeTab = false) {
      //是否為點擊Tab
      if (!isChangeTab) {
        this.page = 1
        this.keywords = ''
      }
      if (this.type == 0) {
        this.getDiscountGoodsList()
        this.getSpecialAreaGoodsList()
      } else {

      }
    },
    //取得優惠專區產品清單
    getSpecialAreaGoodsList() {
      this.$http.getSpecialAreaGoodsList({
        keywords: this.keywords,
        size: this.size,
        page: this.page,
        type: this.type,
      }).then(res => {
        if (res.errno == 0) {
          let data = res.data
          this.total = data.total
          this.data = data.list
        }
      })
    },
    //取得尚未加入折扣產品優惠產品
    getDiscountGoodsList() {
      this.$http.getDiscountGoodsList({
        keywords: '',
      }).then(res => {
        if (res.errno == 0) {
          this.discountList = res.data
        }
      })
    },
    //tab click
    tabClick(tab, e) {
      if (tab.name == "discount") {
        //折扣
        this.type = 0
      } else if (tab.name == "groupBuy") {
        //團購
        this.type = 1
      }
      this.init(true)
    },
    handleSizeChange(val) {
      this.size = val
    },
    handleCurrentChange(val) {
      this.page = val
    },
  }
}
</script>
