<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-15 16:52:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\components\Sidebar\Sidebar.vue
 -->
<template>
  <nav
    :class="{ sidebar: true, sidebarStatic, sidebarOpened }"
    @mouseenter="sidebarMouseEnter"
    @mouseleave="sidebarMouseLeave"
  >
    <header style="text-align:center;padding:10px 0 ;">
      <div class="item-logo">
        <img style="width:90%;" src="../../assets/headLogo.png" />
      </div>
    </header>
    <div style="margin-top:30px;">
      <div style="width:50px;height:50px;margin:0 auto;border-radius:50%;overflow:hidden;">
        <img style="display:block;width:100%;height:auto;" :src="userInfo.avatar" />
      </div>
      <p style="text-align:center;margin:10px 0;font-size: 16px;">{{userInfo.user_name}}</p>
      <p
        style="text-align:center;font-size: 16px;"
        v-if="userInfo.is_superuser"
      >{{$t('aboutus.09AE4A@primary_account')}}</p>
    </div>
    <ul class="nav">
      <NavLink
        :header="$t('backend_submenu.ABAAF2@dashboard')"
        link="/app/dashboard"
        iconName="fa-dashboard"
        index="dashboard"
        isHeader
        :perm="888"
      />
      <NavLink
       :activeItem="activeItem"
        :header="$t('statistics')"
        link="/Statistics"
        iconName="fa-bar-chart-o"
        index="Statistics"
        isHeader
        :perm="hasRoutePerms(16171940481727)?1:hasRoutePerms(16171945565643)?1:hasRoutePerms(16171943527328)?1:hasRoutePerms(16171948681727)?1:hasRoutePerms(16171949501071)?1:hasRoutePerms(16171950282578)?1:hasRoutePerms(16171951957887)?1:hasRoutePerms(16171953041822)?1:hasRoutePerms(16171956147084)?1:hasRoutePerms(16171958068080)?1:hasRoutePerms(16303957249994)?1:0"
          :childrenLinks="[
          {perm:16171945565643, header: 'GMV', link: '/app/Statistics/GMV' },
          {perm:16171943527328, header: $t('AverageOrderPrice.AverageOrderPrice_tit'), link: '/app/Statistics/AverageOrderPrice' },
          {perm:16171948681727, header: $t('KeyWords.KeyWords_tit'), link: '/app/Statistics/KeyWords' },
          {perm:16171949501071, header: $t('ActiveUser.ActiveUser_tit'), link: '/app/Statistics/ActiveUser' },
          {perm:16171950282578, header: $t('CumulativeSalesVolume.CumulativeSalesVolume_tit'), link: '/app/Statistics/CumulativeSalesVolume' },
          {perm:16171951957887, header: $t('LoginStatistics.LoginStatistics_tit'), link: '/app/Statistics/LoginStatistics' },
          {perm:16171953041822, header: $t('GoodsSales.GoodsSales_tit'), link: '/app/Statistics/GoodsSales' },
          {perm:16171956147084, header: $t('CommodityAnalysis.CommodityAnalysis_tit'), link: '/app/Statistics/CommodityAnalysis' },
          {perm:16171958068080, header: $t('FunctionUsageRecord.FunctionUsageRecord_tit'), link: '/app/Statistics/FunctionUsageRecord' },
          {perm: 16303957249994, header: $t('OrderUser.title'),  link: '/app/Statistics/OrderUser'}
        ]"
      />
      <NavLink
        :activeItem="activeItem"
        :header="$t('backend_submenu.ED96C7@authrity')"
        link="/app/authority"
        iconName="fa-american-sign-language-interpreting"
        index="authority"
        isHeader
        :perm="hasRoutePerms(850)?1:hasRoutePerms(636)?1:hasRoutePerms(845)?1:hasRoutePerms(990)?1:0"
        :childrenLinks="[
          {perm:850, header: $t('backend_submenu.4A302E@authrityList'), link: '/app/authority/authority' },
          {perm:636, header: $t('backend_submenu.21BF37@grouping'), link: '/app/authority/grouping' },
          {perm:845, header: $t('backend_submenu.61A3ED@user'), link: '/app/authority/user' },
          {perm:990, header: $t('backend_submenu.6A0B0E@Operation_record'), link: '/app/authority/log' },
        ]"
      />
      <NavLink
          :activeItem="activeItem"
          :header="$t('backend_submenu.0FED78@config')"
          link="/app/configItem"
          iconName="fa-cog"
          index="configItem"
          isHeader
          :perm="hasRoutePerms(1257)?1:hasRoutePerms(6820)?1:hasRoutePerms(2869)?1:hasRoutePerms(752)?1:hasRoutePerms(3563)?1:0"
          :childrenLinks="[

          {perm:1257,header: $t('backend_web_adv.Z25E52@web_adv'), link: '/app/configItem/webadv' },
          {perm:1257,header: $t('backend_submenu.0265E5@Advertisement'), link: '/app/configItem/advertising' },
          {perm:6820,header: $t('backend_submenu.157A9D@platform_Info'), link: '/app/configItem/platform' },
          {perm:2869,header: $t('backend_submenu.4F09AA@index'), link: '/app/configItem/homeClassify' },
          {perm:752,header: $t('backend_submenu.49DFF3@integral'), link: '/app/configItem/integral' },
          {perm:3563,header: $t('aboutus.6505D3@Plan_Subscription'), link: '/app/configItem/plan' },
          {perm:4122,header: $t('aboutus.CEA76C@Product_unit'), link: '/app/configItem/ProductUnit' },
          {perm:16257529458819,header: $t('public.9AF601@unit_converter') , link: '/app/configItem/unitConverter' },
          {perm:16570954479775,header: $t('aboutus.3C4EF5@Customer_testimonials') , link: '/app/configItem/homeVideo' },
          {perm:16627316323651, header: $t('specialArea.title'), link: '/app/configItem/specialArea' },
        ]"
      />

      <NavLink
          :activeItem="activeItem"
          :header="$t('backend_submenu.AD8E7D@Merchant_man')"
          link="/app/shopManage"
          iconName="fa-diamond"
          index="shopManage"
          isHeader
          :perm="hasRoutePerms(686)?1:hasRoutePerms(948)?1:hasRoutePerms(16128655484976)?1:hasRoutePerms(16136614302385)?1:hasRoutePerms(16185741672988)?1:hasRoutePerms(16588324268534)?1:0"
          :childrenLinks="[
          {perm:686, header: $t('backend_submenu.411879@restaurant_list'), link: '/app/shopManage/restaurantList' },
          {perm:948, header: $t('backend_submenu.BE2794@supplier_list'), link: '/app/shopManage/supplierList' },
          {perm:16128655484976, header: $t('backend_submenu.DD811F@payment_list'), link: '/app/shopManage/paymentList' },
          {perm: 16136614302385, header: $t('MarketRank.E3564A@name'), link: '/app/shopManage/marketRank' },
          {perm:16185741672988, header: $t('pos.DD6478@title'), link: '/app/shopManage/ManagePos' },
      ]"
      />
      <NavLink
        :activeItem="activeItem"
        :header="$t('backend_submenu.100AE5@Merchant_user_management')"
        link="/app/userManage"
        iconName="fa-user"
        index="userManage"
        isHeader
        :perm="hasRoutePerms(911)?1:hasRoutePerms(908)?1:0"
        :childrenLinks="[
          { perm:911, header: $t('backend_submenu.526167@restaurant_user_man'), link: '/app/userManage/restaurantUser' ,},
          { perm : 911, header : $t('backend_submenu.5815A5@restaurant_permission_management'), link : '/Restaurant/PermissionManage' },
          { perm : 16073265229291, header : $t('backend_submenu.C89D43@custom_restaurant_auth_group'), link : '/app/Restaurant/CustomPermissionGroup' },
          { perm : 16113583886897, header : $t('whatsapp_group.9EFF12@name'), link : '/app/userManage/groupConfig' },
          { perm:908, header: $t('backend_submenu.92671D@supplier_user_man'), link: '/app/userManage/supplierUser' },
        ]"
      />
      <NavLink
          :header="$t('backend_submenu.96B8E2@Product_classification')"
          link="/app/productCategory"
          iconName="fa-braille"
          index="productCategory"
          isHeader
          :perm="723"
      />
      <NavLink
        :header="$t('aboutus.9B9285@Bill_Classification')"
        link="/app/BillClassification"
        iconName="fa-university"
        index="BillClassification"
        isHeader
        :perm="15834014934913"
      />

      <NavLink
          :header="$t('invoice.AE64AD@menu')"
          link="/app/invoice"
          iconName="fa-money"
          index="BillClassification"
          isHeader
          :perm="16144170744786">
      </NavLink>
      <NavLink
        :header="$t('supplier_order.E0C2CB@order_management')"
        link="/app/order"
        iconName="fa-list-alt"
        index="productCategory"
        isHeader
        :perm="hasRoutePerms(15792558088380)?1:hasRoutePerms(15792558088380)?1:0"
        :childrenLinks="[
          { perm:15792558088380, header: $t('supplier_order.E0C2CB@order_management'), link: '/app/order/purchase' ,},
          { perm:15792558088380, header: $t('backend_submenu.A15265@add_order'), link: '/app/order/addGoodsOrder' },
        ]"
      />
      <!--   評價管理   -->
      <NavLink
          :header="$t('rating.AED585@rating_manage')"
          link="/app/RateManage"
          iconName="fa-diamond"
          index="RateManage"
          isHeader
          :perm="16167868752230"
      />
      <NavLink
        :activeItem="activeItem"
        :header="$t('aboutus.3697EB@Blog')"
        link="/app/Blog"
        iconName="fa-user"
        index="Blog"
        isHeader
        :perm="hasRoutePerms(15894504589622)?1:hasRoutePerms(15894505672627)?1:0"
        :childrenLinks="[
          { perm:15894504589622, header: $t('aboutus.E7F9ED@Blog_type'), link: '/app/Blog/BlogCategory' ,},
          { perm:15894505672627, header: $t('aboutus.B99A84@Blog_list'), link: '/app/Blog/BlogList' },
      ]"
      />
      <!--   手機教學   -->
      <NavLink
          :activeItem="activeItem"
          :header="$t('AppGuideline.title')"
          link="/app/AppGuideline"
          iconName="fa-user"
          index="AppGuideline"
          isHeader
          :perm="hasRoutePerms(16636649816586)?1:hasRoutePerms(16636652192938)?hasRoutePerms(16636652601330):0"
          :childrenLinks="[
          { perm:16636652192938, header: $t('AppGuideline.AppGuidelineCategory'), link: '/app/AppGuideline/AppGuidelineCategory' ,},
          { perm:16636652601330, header: $t('AppGuideline.AppGuidelineList'), link: '/app/AppGuideline/AppGuidelineList' },
      ]"
      />
      <NavLink
        :activeItem="activeItem"
        :header="$t('index.CA31BF@mail')"
        link="/app/Email"
        iconName="fa fa-envelope"
        index="Email"
        isHeader
        :perm="hasRoutePerms(15942063038883)?1:hasRoutePerms(15942064631361)?1:0"
        :childrenLinks="[
          { perm:15942063038883, header: $t('backend_submenu.EF42B5@email_list'), link: '/app/Email' ,},
          { perm:15942064631361, header: $t('index.ABA598@Marketing_email'), link: '/app/email-marketing' },
      ]"
      />
      <!-- <NavLink
        :header="$t('backend_submenu.EF42B5@email_list')"
        link="/app/Email"
        iconName="fa-list-alt"
        index="productCategory"
        isHeader
        :perm="9123"
      />-->
        <NavLink
            :badge="`${notReadCount}`"
          :header="$t('aboutus.B36F4C@chat_record')"
          link="/app/chatrecord"
          iconName="fa-file-text-o"
          index="productCategory"
          isHeader
          :perm="3249"
        />
    </ul>
  </nav>
</template>

<script>
import { hasRoutePermission } from "../../permission/index.js";
import { mapState, mapActions } from "vuex";
import isScreen from "@/core/screenHelper";
import NavLink from "./NavLink/NavLink";
export default {
  name: "Sidebar",
  components: { NavLink },
  data() {
    return {
      notReadCount: '0'
    };
  },
  props: {
    userInfo: ""
  },
  methods: {
    ...mapActions("layout", ["changeSidebarActive", "switchSidebar"]),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split("/");
      paths.pop();
      this.changeSidebarActive(paths.join("/"));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },

    hasRoutePerms: function(perm) {
      return hasRoutePermission(perm);
    },
    getNotReadCount(){
      this.$http.getNotReadCount().then(res=>{
        this.notReadCount = res.data.count
        this.$setlocalStorage("notReadCount", this.notReadCount)
        this.$store.commit("notReadCount")

      })
    }
  },
  created() {
    this.getNotReadCount()
    this.setActiveByRoute();
  },
  computed: {
    ...mapState("layout", {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement
    }),
  }
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped  />
