<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.6A0B0E@Operation_record')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <section class="select-container">
        <section class="select-left">
          <section style="margin-right:10px;">
            <el-date-picker
              v-model="select.datetime"
              type="daterange"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              @change="changeDatetime"
              @clear="clearTable"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </section>
          <section style="margin-right:10px;">
            <el-input
              @blur="searchLog"
              @keyup.enter.native="searchLog"
              @clear="clearTable"
              v-model="select.username"
              :placeholder="$t('placeholder.9A92DD@username')"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </section>
        </section>
        <section>
          <kt-button
            type="danger"
            @click="deleteRow(scope.row.id)"
            :perm="640"
            :label="$t('public.2C74E6@btn_delete_more')"
          ></kt-button>
        </section>
      </section>
      <el-table
        :data="tableData"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column type="index" width="60" align="center"></el-table-column>
        <!-- <el-table-column prop="id" label="ID" align="center"></el-table-column> -->
        <el-table-column
          prop="user_name"
          :label="$t('backend_authrity_operationLog.8CF925@operationUser')"
          align="center"
        ></el-table-column>
        <!-- <el-table-column show-overflow-tooltip prop="uri" :label="uri" align="center"></el-table-column>
        <el-table-column
          prop="controller"
          :label="$t('backend_authrity_operationLog.3531D8@Controller')"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="method"
          :label="$t('backend_authrity_operationLog.F5AD79@methods')"
          align="center"
        ></el-table-column>-->
        <el-table-column
          show-overflow-tooltip
          prop="data"
          :label="$t('backend_authrity_operationLog.AC377D@operation_info')"
          align="center"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="ip" label="IP" align="center"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="time"
          :label="$t('backend_authrity_operationLog.E3B71C@operation_time')"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="$t('public.03EA09@operation')"
          width="120"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <kt-button
              :perm="640"
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              @click="deleteRow(scope.row.id)"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";
import api from "../../api/index";
export default {
  data() {
    return {
      loading: true,
      select: {
        username: "",
        datetime: 0
      },

      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,

      deleteIds: []
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
  },

  methods: {
    getTableData() {
      api
        .getLog({
          size: this.pageSize,
          page: this.currentPage,
          user_name: this.select.username || "",
          start_time: this.select.start ? this.select.start.getTime()/1000 : 0,
          end_time: this.select.end ? this.select.end.getTime()/1000 : 0
        })
        .then(res => {
          this.tableData = res.data.list;
          this.total = res.data.sum;
          this.loading = false;
        });
    },

    searchLog() {
      this.getTableData();
    },

    changeDatetime(val) {
        this.select.start = val? val[0]:0;
        this.select.end = val? val[1]:0;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },

    deleteRow(id) {
      let id_json;
      let ids = [];
      ids.push(id);

      if (id) {
        id_json = JSON.stringify(ids);
      } else {
        id_json = JSON.stringify(this.deleteIds);
      }

      console.log("id_json==>", id_json);

      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        api
          .deleteLog({
            id_json: id_json
          })
          .then(res => {
            this.getTableData();
          });
      });
    },

    handleSelectionChange(val) {
      let ids = [];
      this.deleteIds = [];
      val.forEach(i => {
        ids.push(i.id);
      });
      this.deleteIds.push(ids);
      console.log("select==>", this.deleteIds);
    },

    clearTable() {
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-left {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>