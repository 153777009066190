<template>
  <div>
    <page-top :title="$t('MarketRank.E3564A@name')">
      <span style="font-size:26px;" class="fa fa-sort-amount-asc"></span>
    </page-top>
    <el-tabs type="border-card" v-model="tabName" @tab-click="handleClick">
      <!--  產品    -->
      <el-tab-pane :label="$t('restaurant_submenu.8F6D4D@supplier_products')" name="product">
        <section class="flex-end" style="padding-bottom: 10px;">
          <!--          <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"-->
          <!--                    style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>-->
          <kt-button
              class="table-btn btn-yellow"
              :label="$t('public.DC9752@btn_add')"
              :perm="16136622262760"
              @click="dialog(0)">
          </kt-button>
        </section>
        <!--   表單     -->
        <el-table border
                  v-loading="loading"
                  :data="rankOrderList">
          <!--     公司名稱     -->
          <el-table-column prop="company_name"
                           :label="$t('restaurant_buy_genner.586E4F@supplier_name')"
                           align="center"
                           min-width="80">
          </el-table-column>
          <!--     產品名稱     -->
          <el-table-column prop="product_name"
                           :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')"
                           align="center"
                           min-width="80">
          </el-table-column>
          <!--     開始時間     -->
          <el-table-column prop="start_time"
                           :label="$t('placeholder.524620@start_time')"
                           align="center"
                           min-width="50">
          </el-table-column>
          <!--     結束時間     -->
          <el-table-column prop="end_time"
                           :label="$t('placeholder.ABB7CF@end_time')"
                           align="center"
                           min-width="50">
          </el-table-column>
          <!--     排序     -->
          <el-table-column prop="order_no"
                           :label="$t('backend_config_home.47380E@sort')"
                           align="center"
                           min-width="80">
          </el-table-column>
<!--          <el-table-column prop="status"-->
<!--                           :label="$t('backend_shop_supplierList.760B82@company_status')"-->
<!--                           align="center"-->
<!--                           min-width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <span>-->
<!--                {{-->
<!--                  Date.parse(scope.row.start_time) < Date.now() && Date.now() > Date.parse(scope.row.end_time) ? $t('MarketRank.FA6851@start') :-->
<!--                      Date.parse(scope.row.start_time) < Date.now() && Date.now() > Date.parse(scope.row.end_time) ? $t('MarketRank.B1122F@end') : $t('MarketRank.AE8188@unstarted')-->
<!--                }}-->
<!--              </span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              prop="btn"
              align="center"
              min-width="80">
            <template slot-scope="scope">
              <!--       修改       -->
              <kt-button
                  :perm="16136734906613"
                  type="info"
                  :label="$t('public.B6D57F@btn_modify')"
                  @click="dialog(1, scope.row.mid)">
              </kt-button>
              <!--       刪除       -->
              <kt-button
                  :perm="16136761389596"
                  type="danger"
                  :label="$t('public.1FBB5E@btn_delete')"
                  @click="delRankOrder(scope.row.mid)"
              >
              </kt-button>
            </template>
          </el-table-column>
        </el-table>
        <section class="pagination-container">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="rankOrderLength"
          ></el-pagination>
        </section>
      </el-tab-pane>
      <!--  供應商    -->
      <el-tab-pane :label="$t('restaurant_submenu.FA62B7@supplier')" name="supplier">
        <section class="flex-end" style="padding-bottom: 10px;">
          <!--          <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"-->
          <!--                    style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>-->
          <kt-button
              class="table-btn btn-yellow"
              :label="$t('public.DC9752@btn_add')"
              :perm="16136622262760"
              @click="dialog(0)">
          </kt-button>
        </section>
        <!--   表單     -->
        <el-table border
                  v-loading="loading"
                  :data="rankOrderList">
          <!--     公司名稱     -->
          <el-table-column prop="company_name"
                           :label="$t('restaurant_buy_genner.586E4F@supplier_name')"
                           align="center"
                           min-width="80">
          </el-table-column>
          <!--     開始時間     -->
          <el-table-column prop="start_time"
                           :label="$t('placeholder.524620@start_time')"
                           align="center"
                           min-width="50">
          </el-table-column>
          <!--     結束時間     -->
          <el-table-column prop="end_time"
                           :label="$t('placeholder.ABB7CF@end_time')"
                           align="center"
                           min-width="50">
          </el-table-column>
          <!--     排序     -->
          <el-table-column prop="order_no"
                           :label="$t('backend_config_home.47380E@sort')"
                           align="center"
                           min-width="80">
          </el-table-column>
<!--          <el-table-column prop="status"-->
<!--                           :label="$t('backend_shop_supplierList.760B82@company_status')"-->
<!--                           align="center"-->
<!--                           min-width="80">-->
<!--            <template slot-scope="scope">-->
<!--              <span>-->
<!--                {{-->
<!--                  Date.parse(scope.row.start_time) <= Date.now() ? $t('MarketRank.FA6851@start') :-->
<!--                      Date.now() > Date.parse(scope.row.end_time) ? $t('MarketRank.B1122F@end') :-->
<!--                          $t('MarketRank.AE8188@unstarted')-->
<!--                }}-->
<!--              </span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
              prop="btn"
              align="center"
              min-width="80">
            <template slot-scope="scope">
              <!--       修改       -->
              <kt-button
                  :perm="16136734906613"
                  type="info"
                  :label="$t('public.B6D57F@btn_modify')"
                  @click="dialog(1, scope.row.mid)">
              </kt-button>
              <!--       刪除       -->
              <kt-button
                  :perm="16136761389596"
                  type="danger"
                  :label="$t('public.1FBB5E@btn_delete')"
                  @click="delRankOrder(scope.row.mid)"
              >
              </kt-button>
            </template>
          </el-table-column>
        </el-table>
        <section class="pagination-container">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[10, 25, 50, 100]"
              :page-size="size"
              @current-change="handleCurrentChange"
              :current-page="page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="rankOrderLength"
          ></el-pagination>
        </section>
      </el-tab-pane>
    </el-tabs>
    <el-dialog :visible.sync="show" :title="status==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
               width="50%" center>
      <el-form :model="dialogForm" ref="form" :rules="rules" label-width="250px">
        <el-row>
          <el-col :span="20" :offset="2">
            <!--      產品用供應商清單      -->
            <el-form-item v-if="type==0" :label="$t('restaurant_submenu.FA62B7@supplier')">
              <el-select v-model="dialogForm.sid" remote filterable @change="supplierChange"
                         style="width: 43%" :placeholder="$t('placeholder.C209ED@select')">
                <el-option
                    v-for="item in supplierList"
                    :label="item.company_name"
                    :key="item.company_id"
                    :value="item.company_id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--      供應商-供應商清單      -->
            <el-form-item v-else :label="$t('restaurant_submenu.FA62B7@supplier')" prop="cid">
              <el-select v-model="dialogForm.cid" remote filterable @change="supplierChange"
                         style="width: 43%" :placeholder="$t('placeholder.C209ED@select')">
                <el-option
                    v-for="item in supplierList"
                    :label="item.company_name"
                    :key="item.company_id"
                    :value="item.company_id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--      產品清單      -->
            <el-form-item v-if="type==0" v-show="dialogForm.sid"
                          :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')"
                          prop="cid">
              <el-select v-model="dialogForm.cid" remote filterable
                         style="width: 43%" :placeholder="$t('placeholder.C209ED@select')" @change="productChange">
                <el-option
                    v-for="item in productList"
                    :label="item.goods_name"
                    :key="item.gid"
                    :value="item.gid">
                </el-option>
              </el-select>
            </el-form-item>
            <!--      開始時間      -->
            <el-form-item :label="$t('placeholder.524620@start_time')" prop="start_time">
              <el-date-picker v-model="dialogForm.start_time" value-format="timestamp" :picker-options="pickerDisabled"
                              type="date" :placeholder="$t('supplier-setting-DistributionSet.F1E87C@Select_date')"
                              class="mt10 input">
              </el-date-picker>
            </el-form-item>
            <!--      結束時間      -->
            <el-form-item :label="$t('placeholder.ABB7CF@end_time')" prop="end_time">
              <el-date-picker v-model="dialogForm.end_time" value-format="timestamp" :picker-options="pickerDisabledEnd"
                              type="date" :placeholder="$t('supplier-setting-DistributionSet.F1E87C@Select_date')"
                              class="mt10 input">
              </el-date-picker>
            </el-form-item>
            <!--      排序      -->
            <el-form-item>
              <template slot="label">
                {{ $t('backend_config_home.47380E@sort') }}
                <el-tooltip placement="left">
                  <div slot="content">{{ $t('MarketRank.A85E67@soft_content') }}</div>
                  <i class="el-icon-info"/>
                </el-tooltip>
              </template>
              <el-input-number :min="0" :step="1" v-model="dialogForm.order_no"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--      確認取消按鈕      -->
      <section slot="footer">
        <kt-button
            :perm="16136622262760"
            :label="status==0 ? $t('public.DC9752@btn_add') : $t('public.B6D57F@btn_modify')"
            type="primary"
            size="small"
            @click="addOrModify">
        </kt-button>
        <el-button
            @click="show = false"
            type="danger"
            size="small"
            plain>
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import ktButton from "../../components/ktButton/KtButton";

export default {
  components: {
    pageTop,
    ktButton,
  },
  name: "",
  data() {
    return {
      //search text
      searchText: "",
      //product list
      productList: [],
      //loading
      loading: true,
      //supplier list
      supplierList: [],
      //show
      show: false,
      //dialog form
      dialogForm: {
        //supplier
        sid: "",
        //mid
        mid: "",
        //start_time
        start_time: "",
        //end_time
        end_time: "",
        //cid
        cid: "",
        //order_no
        order_no: 0,
      },
      //add=0, modify=1
      status: 0,
      //rank order length
      rankOrderLength: 0,
      //rank order list
      rankOrderList: [],
      //tab name
      tabName: "product",
      //type, 0=product, 1=supplier
      type: 0,
      //每頁顯示數量
      size: 10,
      //當前分頁
      page: 1,
      //date filter
      pickerDisabled: {
        disabledDate: time => {
          let date = new Date();
          let str =
              date.getFullYear() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getDate();
          return (Date.parse(time) / 1000 < Date.parse(str) / 1000)
        }
      },
      pickerDisabledEnd: {
        disabledDate: time => {
          //起始時間存在時
          if (this.dialogForm.start_time) {
            let date = new Date(this.dialogForm.start_time)
            let str =
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate();
            return (Date.parse(time) / 1000 <= Date.parse(str) / 1000)
          } else {
            let date = new Date();
            let str =
                date.getFullYear() +
                "/" +
                (date.getMonth() + 1) +
                "/" +
                date.getDate();
            return (Date.parse(time) / 1000 < Date.parse(str) / 1000)
          }
        }
      },
      rules: {
        cid: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: 'blur'
        },
        start_time: {
          type: 'date',
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: 'change'
        },
        end_time: {
          type: 'date',
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
      }
    }
  },
  created() {
    this.getSupplierList()
    this.getRankOrderList()
  },
  computed: {
    search() {
      if (this.searchText == "") {
        return this.rankOrderList
      } else {
        return this.rankOrderList.filter(i => {
          return i.company_name.indexOf(this.searchText) > -1 ||
              i.product_name.indexOf(this.searchText) > -1
        })
      }
    }
  },
  methods: {
    //product change
    productChange() {
      this.$http.searchRankOrder({
        cid: this.dialogForm.cid
      }).then(res => {
        if (res.data.result != null) {
          //keep supplier id
          let sid = this.dialogForm.sid
          this.dialogForm = res.data.result
          this.dialogForm.sid = sid
          //revert timestamp
          this.dialogForm.start_time *= 1000
          this.dialogForm.end_time *= 1000
          this.status = 1
        }
      })
    },
    //get product list
    getGoodsList() {
      this.$http.showGoodsListBySupplier({
        is_b2b: 1,
        supplier_id: this.dialogForm.sid,
      }).then(res => {
        this.dialogForm.cid = ""
        this.productList = res.data
      })
    },
    modifyRankOrder() {
      this.$http.modifyRankOrder({
        mid: this.dialogForm.mid,
        start_time: this.dialogForm.start_time / 1000,
        end_time: this.dialogForm.end_time / 1000,
        order_no: this.dialogForm.order_no,
        cid: this.dialogForm.cid,
      }).then(res => {
        if (res.errno == 0) {
          this.getRankOrderList()
          this.show = false
          this.clean()
        }
      })
    },
    //get RankOrder
    getRankOrder(id) {
      this.$http.getRankOrder({
        mid: id
      }).then(res => {
        this.dialogForm = res.data.result
        this.dialogForm.start_time *= 1000
        this.dialogForm.end_time *= 1000
      })
    },
    //Delete RankOrder
    delRankOrder(id) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delRankOrder({
          mid: id
        }).then(() => {
          this.getRankOrderList()
        })
      })
    },
    //clean
    clean() {
      this.mid = ""
      this.dialogForm.cid = ""
      this.dialogForm.start_time = ""
      this.dialogForm.end_time = ""
      this.order_no = 0
    },
    //add or modify
    addOrModify() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.status == 0) {
            this.addRankOrder()
          } else if (this.status == 1) {
            this.modifyRankOrder()
          }
        } else {
          return false
        }
      })
    },
    //supplier change
    supplierChange() {
      if (this.type == 0) {
        this.getGoodsList()
      }else if(this.type==1){
        this.$http.searchRankOrder({
          cid: this.dialogForm.cid
        }).then(res => {
          if (res.data.result != null) {
            this.dialogForm = res.data.result
            //revert timestamp
            this.dialogForm.start_time *= 1000
            this.dialogForm.end_time *= 1000
            this.status = 1
          }
        })
      }
    },
    //get all supplier
    getSupplierList() {
      this.$http.getAllSupplierList().then(res => {
        this.supplierList = res.data.list
      })
    },
    //show dialog
    dialog(status, id) {
      this.clean()
      this.status = status
      this.show = true
      this.$nextTick(() => {
        this.$refs["form"].clearValidate()
      })
      if (status == 1) {
        this.getRankOrder(id)
      }
    },
    //add rankOrder
    addRankOrder() {
      this.$http.addRankOrder({
        type: this.type,
        start_time: this.dialogForm.start_time / 1000,
        end_time: this.dialogForm.end_time / 1000,
        order_no: this.dialogForm.order_no,
        cid: this.dialogForm.cid,
      }).then(res => {
        if (res.errno == 0) {
          this.show = false
          this.getRankOrderList()
        }
      })
    },
    //get RankOrderList
    getRankOrderList() {
      this.$http.getRankOrderList({
        size: this.size,
        page: this.page,
        type: this.type,
      }).then(res => {
        this.rankOrderLength = res.data.sum
        this.rankOrderList = res.data.list
        this.loading = false
      })
    },
    //tab click
    handleClick(tab, e) {
      if (tab.name == "supplier") {
        //供應商
        this.type = 1
      } else if (tab.name == "product") {
        //產品
        this.type = 0
      }
      this.getRankOrderList()
    },
    handleSizeChange(val) {
      this.size = val
      this.getRankOrderList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getRankOrderList()
    },
  }
}
</script>

<style scoped>

</style>