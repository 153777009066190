<!-- AverageOrderPrice -->
<template>
  <section class="AverageOrderPrice">
    <page-top :title="$t('AverageOrderPrice.AverageOrderPrice_tit')">
      <span
        style="font-size: 26px; padding-bottom: 10px"
        class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-tabs v-model="tabValue" type="border-card">
      <el-tab-pane :label="$t('AverageOrderPrice.AverageOrderPrice_content.restaurant')" name="restaurant">
        <restaurant v-if="tabValue == 'restaurant'"></restaurant>
      </el-tab-pane>
      <el-tab-pane :label="$t('AverageOrderPrice.AverageOrderPrice_content.supplier')" name="supplier">
        <supplier v-if="tabValue == 'supplier'"></supplier>
      </el-tab-pane>
    </el-tabs>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import restaurant from "./components/restaurant/restaurant";
import supplier from "./components/supplier/supplier";
import pageTop from "@/components/PageTop/PageTop";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "AverageOrderPrice",
  components: { pageTop, restaurant, supplier },
  data() {
    //这里存放数据
    return {
      tabValue: "restaurant",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style src='./AverageOrderPrice.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>