<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-15 12:02:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\pages\Login\Login.vue
 -->
<template>
  <div class="login-page">
    <b-container>
      <h5 class="logo">
        <i class="fa fa-circle text-gray" />
        sing
        <i class="fa fa-circle text-warning" />
      </h5>
      <el-card style="width:50%;margin:0 auto;padding-top:30px;">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="用户名">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="form.password" @keyup.enter.native="login"></el-input>
          </el-form-item>
          <el-form-item style="overflow:hidden;">
            <el-button
              style="display:flex;float:right;"
              type="primary"
              @click="login"
              size="small"
              plain
            >Login</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </b-container>
    <footer class="footer">2017 &copy; Sing. Admin Dashboard Template.</footer>
  </div>
</template>

<script>
import api from "../../api/index";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    ...mapMutations("websocket", [
      "updataChatMsg",
      "updataChatImg",
      "updataChatList",
      "updataChatInform",
      "updataReqSample",
      "updataReqPrice"
    ]),
    onMessage(msg) {
      // let token = this.$getlocalStorage("token");
      let token = this.$getlocalStorage("token");
      let lang = this.$getlocalStorage("lang") || "hk";
      const data = JSON.parse(msg.data).data;
      if (JSON.parse(msg.data).errno === 1107) {
        console.log("长连接长时间未操作重新登录", data);
        this.$remlocalStorage("token");
        this.$message.warning({
          message:
            this.$getlocalStorage("lang") == "en"
              ? "Long time no operation, please login again!"
              : this.$getlocalStorage("lang") == "cn"
              ? "长时间未操作，请重新登录！"
              : "長時間未操作，請重新登錄！"
        });
        this.$router.push("/login");
        return;
      } else if (JSON.parse(msg.data).errno === 2122) {
        localStorage.clear();
        this.$router.push("/login");

        this.$message.warning(
          `${JSON.parse(msg.data).msg}[errno: ${JSON.parse(msg.data).errno}]`
        );
        return;
      }
      if (data.push_type == "ping") {
        // console.log(data);
        if (this.global.websocket && this.global.websocket.readyState == 1) {
          let params = {
            push_type: "pong",
            room_id: "",
            content: "",
            code: ""
          };
          this.global.websocketsend(params);
          console.log("心跳包");
        }
      } else if (data.push_type == "chat_msg") {
        // console.log("消息", data);
        this.updataChatMsg(data);
      } else if (data.push_type == "chat_img") {
        // console.log("图片", data);
        this.updataChatImg(data);
      } else if (data.push_type == "chat_list") {
        // console.log("列表", data);
        this.updataChatList(data);
      } else if (data.push_type == "chat_inform") {
        // console.log("通知", data);
        this.updataChatInform(data);
      } else if (data.push_type == "req_sample") {
        // console.log("样本", data);
        this.updataReqSample(data);
      } else if (data.push_type == "req_price") {
        // console.log("报价", data);
        this.updataReqPrice(data);
      } else if (data.push_type == "refresh_token") {
        // console.log("刷新token", data);
        // this.$setlocalStorage("token", data.push_data);
        this.$setlocalStorage("token", data.push_data);
      }
    },
    login() {
      if (!Boolean(this.form.username && this.form.password)) {
        this.$message.warning(
          this.$t("shop-goods.9A0301@Please_complete_the_account_password")
        );
        return;
      }
      api
        .login({
          user_name: this.form.username,
          password: this.form.password
        })
        .then(res => {
          console.log(res);

          if (res.errno == 0) {
            let userInfo = {
              avatar: res.data.avatar,
              user_name: res.data.user_name,
              nick_name: res.data.nick_name,
              is_superuser: res.data.is_superuser,
              shop_id: res.data.shop_id
            };
            this.$setlocalStorage("backstageToken", res.data.token);
            this.$setlocalStorage("backstageRoute_id", res.data.route_id_json);
            this.$setlocalStorage("backstageBtn_id", res.data.btn_id_json);
            this.$setlocalStorage("backstageTableData", res.data.auth_json);
            this.$setlocalStorage(
              "backstageAuth_id_json",
              res.data.auth_id_json
            );
            this.$setlocalStorage(
              "BackstageUserInfo",
              JSON.stringify(userInfo)
            );

            this.$router.push("/app/dashboard");
            this.global.initWebSocket();
            this.global.websocketonmessage(this.onMessage);
          }
        });
    }
  },
  created() {}
};
</script>

<style src="./Login.scss" lang="scss" scoped />

