<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-20 18:46:13
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div>
    <page-top :title="$t('backend_submenu.96B8E2@Product_classification')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-tabs v-model="activeTab" type="border-card" @tab-click="clickTab">
      <!--   一般分類   -->
      <el-tab-pane :label="$t('backend_submenu.96B8E2@Product_classification')" name="category">
        <el-card>
          <section class="flex-end">
            <kt-button
                class="table-btn btn-yellow"
                :perm="834"
                :label="$t('public.DC9752@btn_add')"
                @click="add()"
            ></kt-button>
          </section>
          <el-table
              ref="multipleTable"
              v-loading="loading"
              :element-loading-text="$t('public.4ABAB1@loading')"
              :data="tableData"
              row-key="cid"
              :tree-props="{children: 'sub_category', hasChildren: 'hasChildren'}"
              :row-style="{'height':'80px'}"
          >
            <el-table-column
                prop="cid"
                :label="$t('backend_productClassify.CFB3A7@classify_id')"
                align="left"
                header-align="left"
            ></el-table-column>
            <el-table-column
                prop="name"
                :label="$t('backend_productClassify.3CAC83@classify_name')"
                align="center"
                header-align="center"
            ></el-table-column>
            <el-table-column
                prop="status"
                :label="$t('backend_productClassify.ED8AF1@status')"
                align="center"
                header-align="center"
            ></el-table-column>
            <el-table-column
                prop="picture"
                :label="$t('backend_productClassify.B39EE1@classify_photo')"
                align="center"
                header-align="center"
            >
              <template slot-scope="scope">
                <img style="height:60px" v-if="scope.row.picture" :src="scope.row.picture" alt/>
                <span v-else>{{ $t('public.84FA27@no_pictures') }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="create_time"
                :label="$t('public.D17BCA@created_time')"
                align="center"
                header-align="center"
            ></el-table-column>
            <el-table-column
                prop="sort"
                :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
                align="center"
                header-align="center"
            ></el-table-column>
            <el-table-column
                prop="opration"
                :label="$t('public.03EA09@operation')"
                fixed="right"
                align="center"
                header-align="center"
            >
              <template slot-scope="scope">
                <kt-button
                    :perm="635"
                    :label="$t('public.B3669C@show')"
                    @click="isShow(scope.row.cid,1)"
                    v-if="scope.row.status=='HIDE'"
                ></kt-button>
                <kt-button
                    :perm="635"
                    :label="$t('public.8B9314@hide')"
                    @click="isShow(scope.row.cid,0)"
                    v-else
                ></kt-button>
                <kt-button
                    type="info"
                    :perm="519"
                    :label="$t('public.B6D57F@btn_modify')"
                    @click="modify(scope.row.cid)"
                ></kt-button>
                <kt-button
                    :perm="955"
                    :label="$t('public.1FBB5E@btn_delete')"
                    type="danger"
                    @click="delCategory(scope.row.cid)"
                ></kt-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-dialog
            :title="type==1?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')"
            center
            width="50%"
            @close="resetForm"
            :visible.sync="dialogFormVisible"
            top="8vh"
        >
          <el-row>
            <el-col :span="16" :offset="4">
              <el-form ref="dataForm" :rules="rules" :model="dialogForm" label-width="160px">
                <el-form-item prop="pid" :label="$t('backend_productClassify.CFB3A7@classify_id')">
                  <el-cascader
                      v-model="dialogForm.pid"
                      :options="options"
                      :props="{children:'sub_category',label:'name',value:'cid',emitPath:false,checkStrictly:true }"
                      @change="getCheckedNodes"
                      :placeholder="$t('backend_productClassify.F93B5D@default')"
                  ></el-cascader>
                </el-form-item>
                <el-form-item
                    prop="name_hk"
                    :label="$t('backend_productClassify.1CDE5B@classify_name_hk')"
                >
                  <el-input style="width:100%;" v-model="dialogForm.name_hk"></el-input>
                </el-form-item>
                <el-form-item
                    prop="name_en"
                    :label="$t('backend_productClassify.667CB5@classify_name_en')"
                >
                  <el-input style="width:100%;" v-model="dialogForm.name_en"></el-input>
                </el-form-item>
                <el-form-item prop="display" :label="$t('backend_productClassify.ED8AF1@status')">
                  <el-switch
                      style="line-height: 40px"
                      v-model="dialogForm.display"
                      active-color="#13ce66"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                    prop="sort"
                    :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
                >
                  <el-input v-model="dialogForm.sort"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('backend_productClassify.B39EE1@classify_photo')"
                    prop="picture"
                >
                  <el-upload
                      ref="upload"
                      class="avatar-uploader"
                      :show-file-list="false"
                      action="no"
                      :before-upload="beforeAvatarUpload"
                      :http-request="uploadFile"
                  >
                    <img v-if="dialogForm.picture" :src="dialogForm.picture" class="avatar"/>
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <section slot="footer">
            <el-button
                class="table-btn btn-gray"
                @click="dialogFormVisible=false"
            >{{ $t('public.836FD4@cancel') }}
            </el-button>
            <el-button class="table-btn btn-yellow" @click="addclassify">{{ $t('public.3B45A2@confirm') }}</el-button>
          </section>
        </el-dialog>
      </el-tab-pane>
      <!--   菜色分類   -->
      <el-tab-pane :label="$t('backend_submenu.96B8E3@Product_classification_dish')" name="category_dish">
        <el-card>
          <section class="flex-end">
            <kt-button
                class="table-btn btn-yellow"
                :perm="16553667275623"
                :label="$t('public.DC9752@btn_add')"
                @click="modifyCategoryDish(null, 1)">
            </kt-button>
          </section>
          <el-table
              ref="multipleTable"
              v-loading="loading"
              :element-loading-text="$t('public.4ABAB1@loading')"
              :data="categoryDishList"
              row-key="id"
              :tree-props="{children: 'childData', hasChildren: 'hasChildren'}"
              :row-style="{'height':'80px'}">
            <el-table-column
                prop="id"
                :label="$t('backend_productClassify.CFB3A7@classify_id')"
                align="left"
                header-align="left">
            </el-table-column>
            <el-table-column
                prop="name"
                :label="$t('backend_submenu.96B8E4@classification_dish_name')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="create_time"
                :label="$t('public.D17BCA@created_time')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="sort"
                :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="opration"
                :label="$t('public.03EA09@operation')"
                fixed="right"
                align="center"
                header-align="center">
              <template slot-scope="scope">
                <kt-button
                    type="info"
                    :perm="16553678527176"
                    :label="$t('public.B6D57F@btn_modify')"
                    @click="modifyCategoryDish(scope.row, 0)"
                ></kt-button>
                <kt-button
                    :perm="16553679387622"
                    :label="$t('public.1FBB5E@btn_delete')"
                    type="danger"
                    @click="delCategoryDish(scope.row.id)"
                ></kt-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-dialog :title="isModify==0?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')" center width="50%"
                   @close="closeCategoryDishDialog" :visible.sync="categoryDishDialog" top="8vh">
          <el-row>
            <el-col :span="16" :offset="4">
              <el-form ref="categoryDishForm" :rules="dishRules" :model="dialogForm" label-width="160px">
                <el-form-item prop="type" :label="$t('supplier_supplier_produceManage.8D36FA@classification_of_goods')"
                              v-if="isModify==1">
                  <el-radio-group v-model="categoryDishForm.type">
                    <el-radio :label="0">{{ $t('backend_productClassify.add_top_classify') }}</el-radio>
                    <el-radio :label="1">{{ $t('backend_productClassify.add_second_classify') }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="pid" :label="$t('backend_productClassify.CFB3A7@classify_id')"
                              v-if="categoryDishForm.type==1">
                  <el-select v-model="categoryDishForm.pid" :placeholder="$t('placeholder.C209ED@select')">
                    <el-option v-for="item in categoryDishList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="name_hk" :label="$t('backend_productClassify.dish_classify_name_hk')">
                  <el-input style="width:100%;" v-model="categoryDishForm.title_hk"></el-input>
                </el-form-item>
                <el-form-item prop="name_en" :label="$t('backend_productClassify.dish_classify_name_en')">
                  <el-input style="width:100%;" v-model="categoryDishForm.title_en"></el-input>
                </el-form-item>
                <el-form-item prop="sort" :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')">
                  <el-input v-model="categoryDishForm.sort" type="number" :min="0"></el-input>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <section slot="footer">
            <el-button class="table-btn" type="danger" @click="cancelCategoryDishDialog">
              {{ $t('public.836FD4@cancel') }}
            </el-button>
            <el-button class="table-btn" type="success" @click="addOrModifyCategoryDish">
              {{ $t('public.3B45A2@confirm') }}
            </el-button>
          </section>
        </el-dialog>
      </el-tab-pane>
      <!--   餐廳分類   -->
      <el-tab-pane :label="$t('restaurant_classify.title')" name="restaurant_classify">
        <el-card>
          <section class="flex-end">
            <kt-button
                class="table-btn btn-yellow"
                :perm="16584119485889"
                :label="$t('public.DC9752@btn_add')"
                @click="addRestaurantClassify">
            </kt-button>
          </section>
          <el-table
              ref="multipleTable"
              v-loading="loading"
              :element-loading-text="$t('public.4ABAB1@loading')"
              :data="restaurantClassifyList"
              row-key="id"
              :tree-props="{children: 'childData', hasChildren: 'hasChildren'}"
              :row-style="{'height':'80px'}">
            <el-table-column
                prop="id"
                :label="$t('backend_productClassify.CFB3A7@classify_id')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="name"
                :label="$t('backend_submenu.96B8E4@classification_dish_name')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="sort"
                :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="is_show"
                :label="$t('backend_productClassify.ED8AF1@status')"
                align="center"
                header-align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.is_show" style="color: green">
                  {{ $t('public.B3669C@show') }}
                </span>
                <span v-else style="color: red">
                  {{ $t('public.8B9314@hide') }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="create_time"
                :label="$t('public.D17BCA@created_time')"
                align="center"
                header-align="center">
            </el-table-column>
            <el-table-column
                prop="operation"
                :label="$t('public.03EA09@operation')"
                fixed="right"
                align="center"
                header-align="center">
              <template slot-scope="scope">
                <kt-button
                    type="info"
                    :perm="16584120178321"
                    :label="$t('public.B6D57F@btn_modify')"
                    @click="modifyRestaurantClassify(scope.row)"
                ></kt-button>
                <kt-button
                    :perm="16584120716163"
                    :label="$t('public.1FBB5E@btn_delete')"
                    type="danger"
                    @click="delRestaurantClassify(scope.row.id)"
                ></kt-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <section class="pagination-container">
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[15,25,50,100]"
              :page-size="restaurantSize"
              @current-change="handleCurrentChange"
              :current-page="restaurantPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="restaurantTotal"
          ></el-pagination>
        </section>
        <el-dialog :title="isModify==0?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')" center width="50%"
                   @close="restaurantClassifyCloseDialog" :visible.sync="restaurantClassifyDialog" top="8vh">
          <el-row>
            <el-col :span="16" :offset="4">
              <el-form ref="restaurantClassifyForm" :rules="restaurantClassifyRules" :model="restaurantClassifyForm"
                       label-width="160px">
                <el-form-item prop="name_hk" :label="$t('backend_productClassify.dish_classify_name_hk')">
                  <el-input style="width:100%;" v-model="restaurantClassifyForm.name_hk"></el-input>
                </el-form-item>
                <el-form-item prop="name_en" :label="$t('backend_productClassify.dish_classify_name_en')">
                  <el-input style="width:100%;" v-model="restaurantClassifyForm.name_en"></el-input>
                </el-form-item>
                <el-form-item prop="sort" :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')">
                  <el-input v-model="restaurantClassifyForm.sort" type="number" :min="0"></el-input>
                </el-form-item>
                <el-form-item prop="is_show" :label="$t('backend_shop_supplierList.760B82@company_status')">
                  <el-switch v-model="restaurantClassifyForm.is_show" :active-text="$t('public.B3669C@show')"
                             :inactive-text="$t('public.8B9314@hide')" :active-value="1" :inactive-value="0"
                             active-color="#13ce66" inactive-color="#ff4949">
                  </el-switch>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <section slot="footer">
            <el-button class="table-btn" type="danger" @click="restaurantClassifyCloseDialog">
              {{ $t('public.836FD4@cancel') }}
            </el-button>
            <el-button class="table-btn" type="success" @click="addOrModifyRestaurantClassify">
              {{ $t('public.3B45A2@confirm') }}
            </el-button>
          </section>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import api from "../../api/index";
import pageTop from "../../components/PageTop/PageTop";
import axios from "axios";
import LoginVue from "../Login/Login.vue";
import {log} from "util";

export default {
  data() {
    return {
      //餐廳規則
      restaurantClassifyRules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },
      //餐廳分類表單
      restaurantClassifyDialog: false,
      //餐廳分類表單
      restaurantClassifyForm: {
        id: 0,
        name_hk: '',
        name_en: '',
        is_show: 0,
        sort: 0,
      },
      //餐廳分類數量
      restaurantTotal: 0,
      //餐廳分頁
      restaurantPage: 1,
      //餐廳數量
      restaurantSize: 15,
      //餐廳分類清單
      restaurantClassifyList: [],
      //修改=0;新增=1
      isModify: 0,
      dishRules: {
        title_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        title_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },
      //顯示菜色分類dialog
      categoryDishDialog: false,
      //菜色分類form
      categoryDishForm: {
        //0=大分類;1=第二層分類
        type: 0,
        //父id
        pid: '',
        //標題中文
        title_hk: '',
        //標題英文
        title_en: '',
        //排序
        sort: 0,
        //id
        id: 0,
      },
      //菜色分類資料
      categoryDishList: [],
      //分頁名稱
      activeTab: 'category',
      //載入進度
      loading: true,
      //分類資料
      tableData: [],
      options: [],
      dialogFormVisible: false,
      dialogForm: {
        pid: "",
        cid: "",
        name_hk: "",
        name_en: "",
        sort: "",
        picture: "",
        display: true
      },
      type: "",
      rules: {
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
  },
  methods: {
    //刪除餐廳分類
    delRestaurantClassify(id) {
      api.delRestaurantClassify({
        id: id
      }).then(res => {
        if (res.errno == 0) {
          this.restaurantPage = 1
          this.getRestaurantClassifyList()
        }
      })
    },
    //新增餐廳分類
    addRestaurantClassify() {
      this.isModify = 1
      this.restaurantClassifyDialog = true
    },
    //修改餐廳分類
    modifyRestaurantClassify(row) {
      this.isModify = 0
      this.restaurantClassifyDialog = true
      if (row) {
        api.getRestaurantClassifyInfo({
          id: row.id
        }).then(res => {
          if (res.errno == 0) {
            let data = res.data
            this.restaurantClassifyForm.id = row.id
            this.restaurantClassifyForm.name_en = data.name_en
            this.restaurantClassifyForm.name_hk = data.name_hk
            this.restaurantClassifyForm.is_show = data.is_show
            this.restaurantClassifyForm.sort = data.sort
          }
        })
      }
    },
    //新增餐廳分類表單
    addOrModifyRestaurantClassify() {
      this.$refs["restaurantClassifyForm"].validate(valid => {
        if (valid) {
          if (this.isModify == 0) {
            //修改
            api.modifyRestaurantClassify({
              id: this.restaurantClassifyForm.id,
              name_hk: this.restaurantClassifyForm.name_hk,
              name_en: this.restaurantClassifyForm.name_en,
              is_show: this.restaurantClassifyForm.is_show,
              sort: this.restaurantClassifyForm.sort,
            }).then(res => {
              if (res.errno == 0) {
                this.restaurantPage = 1
                this.restaurantClassifyCloseDialog()
                this.getRestaurantClassifyList()
              }
            })
          } else {
            //新增
            api.addRestaurantClassify({
              name_hk: this.restaurantClassifyForm.name_hk,
              name_en: this.restaurantClassifyForm.name_en,
              is_show: this.restaurantClassifyForm.is_show,
              sort: this.restaurantClassifyForm.sort,
            }).then(res => {
              if (res.errno == 0) {
                this.restaurantPage = 1
                this.restaurantClassifyCloseDialog()
                this.getRestaurantClassifyList()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    //關閉餐廳分類表單
    restaurantClassifyCloseDialog() {
      this.restaurantClassifyDialog = false
      this.restaurantClassifyForm.id = 0
      this.restaurantClassifyForm.name_hk = ''
      this.restaurantClassifyForm.name_en = ''
      this.restaurantClassifyForm.is_show = 0
      this.restaurantClassifyForm.sort = 0
    },
    //取得餐廳分類清單
    getRestaurantClassifyList() {
      this.$http.getRestaurantClassifyList({size: this.restaurantSize, page: this.restaurantPage}).then(res => {
        if (res.errno == 0) {
          this.loading = false
          this.restaurantTotal = res.data.total
          this.restaurantClassifyList = res.data.list
        }
      })
    },
    handleSizeChange(val) {
      this.restaurantPage = val;
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.restaurantPage = val;
      this.getTableData()
    },
    //關閉菜色分類視窗
    closeCategoryDishDialog() {
      this.cancelCategoryDishDialog()
      this.$refs["categoryDishForm"].resetFields()
    },
    //取消菜色分類
    cancelCategoryDishDialog() {
      this.categoryDishForm.pid = ''
      this.categoryDishForm.type = 0
      this.categoryDishForm.title_hk = ''
      this.categoryDishForm.title_en = ''
      this.categoryDishForm.sort = 0
      this.categoryDishForm.id = 0
      this.categoryDishDialog = false
    },
    //修改菜色分類
    modifyCategoryDish(row, status) {
      this.isModify = status
      this.categoryDishDialog = true
      if (row) {
        api.getCategoryDish({
          id: row.id
        }).then(res => {
          if (res.errno == 0) {
            this.categoryDishForm.sort = res.data.sort
            this.categoryDishForm.id = row.id
            this.categoryDishForm.title_hk = res.data.title_hk
            this.categoryDishForm.title_en = res.data.title_en
          }
        })
      }
    },
    //刪除菜色分類
    delCategoryDish(id) {
      api.delCategoryDish({
        id: id
      }).then(res => {
        if (res.errno == 0) {
          this.getCategoryDishList()
        }
      })
    },
    //新增菜色分類
    addOrModifyCategoryDish() {
      this.$refs["categoryDishForm"].validate(valid => {
        if (valid) {
          if (this.isModify == 0) {
            //修改
            api.modifyCategoryDish({
              id: this.categoryDishForm.id,
              title_en: this.categoryDishForm.title_en,
              title_hk: this.categoryDishForm.title_hk,
              sort: this.categoryDishForm.sort,
            }).then(res => {
              if (res.errno == 0) {
                this.getCategoryDishList()
                this.closeCategoryDishDialog()
              }
            })
          } else {
            //新增
            api.addCategoryDish({
              pid: this.categoryDishForm.pid,
              title_en: this.categoryDishForm.title_en,
              title_hk: this.categoryDishForm.title_hk,
              sort: this.categoryDishForm.sort,
              type: this.categoryDishForm.type,
            }).then(res => {
              if (res.errno == 0) {
                this.getCategoryDishList()
                this.cancelCategoryDishDialog()
              }
            })
          }
        } else {
          return false
        }
      })
    },
    //點擊tabs
    clickTab(tab) {
      this.loading = true

      if (tab.name == 'category') {
        this.getTableData()
      } else if (tab.name == 'category_dish') {
        this.getCategoryDishList()
      } else if (tab.name == 'restaurant_classify') {
        this.getRestaurantClassifyList()
      }
    },
    getCategoryDishList() {
      api.getCategoryDishList().then(res => {
        this.categoryDishList = res.data.parentData
        this.loading = false
      })
    },
    getTableData() {
      api.getClassify().then(res => {
        this.tableData = res.data;
        this.loading = false;
      });
    },
    add() {
      this.type = 2;
      this.getSelector(0);
      this.dialogForm.sort = 1;
      this.dialogFormVisible = true;
    },

    // 点击修改按钮
    modify: async function (cid) {
      this.type = 1;
      this.getSelector(cid);
      this.getinfo(cid);
      this.dialogFormVisible = true;
    },

    //获取选择器数据
    getSelector(cid) {
      api.classifyDialog({
        cid
      }).then(res => {
        function dele_sub(data) {
          data.forEach((v, i) => {
            if (v.current_level == 3) {
              v.disabled = true;
            }
            if (v.sub_category.length) {
              dele_sub(v.sub_category);
            } else {
              delete v.sub_category;
            }
          });
        }

        dele_sub(res.data);
        this.options = res.data;
      });
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".");
      let type = fileNameArr[fileNameArr.length - 1].toLowerCase()
      const isJPG = Boolean(type == "jpg");
      const isLt2M = Boolean(file.size / 1024 / 1024 < 5);

      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"));
      }
      return isJPG && isLt2M;
    },
    // 获取点击的分类信息
    getinfo(cid) {
      api.queryClassify({
        cid
      }).then(res => {
        Object.assign(this.dialogForm, res.data);
        this.dialogForm.sort = res.data.sort ? this.dialogForm.sort : 1;
      });
    },
    //弹框内确认按钮
    addclassify() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            //  修改
            api.modifyClassify({
              pid: this.dialogForm.pid || 0,
              cid: this.dialogForm.cid,
              name_en: this.dialogForm.name_en,
              name_hk: this.dialogForm.name_hk,
              sort: this.dialogForm.sort,
              picture: this.dialogForm.picture,
              display: this.dialogForm.display == true ? 1 : 0
            }).then(res => {
              if (res.errno == 0) {
                this.dialogFormVisible = false
                this.getTableData()
              }
            })
          } else {
            // 新增
            api.addClassify({
              pid: this.dialogForm.pid || 0,
              name_en: this.dialogForm.name_en,
              name_hk: this.dialogForm.name_hk,
              sort: this.dialogForm.sort,
              picture: this.dialogForm.picture,
              display: this.dialogForm.display == true ? 1 : 0
            }).then(res => {
              if (res.errno == 0) {
                this.dialogFormVisible = false;
                this.getTableData();
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 关闭弹框时清空表单内容和图片
    resetForm() {
      this.$refs["dataForm"].resetFields()
      this.$refs["upload"].clearFiles()
    },
    // 自定义上传图片
    uploadFile(param) {
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(param["file"]);
      reader.onload = function (event) {
        that.dialogForm.picture = this.result;
        // that.diaomaozhukang(this.result);
      };
      this.dialogVisible = true;
    },
    // 选择器选中
    getCheckedNodes(leafOnly) {
      // this.getSelector();
      this.dialogForm.pid = leafOnly;
    },
    //删除
    delCategory(cid) {
      api.deleteClassify({
        cid
      }).then(res => {
        if (res.errno == 0) {
          this.getTableData()
        }
      })
    },
    // 显示隐藏
    isShow(cid, type) {
      api.isShowClassify({
        cid,
        type
      }).then(res => {
        if (res.errno == 0) {
          this.getTableData();
        }
      })
    }
  }
}
;
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
