/*
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-21 10:32:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\api\index.js
 */
// 封装每个请求
import request from "./http";
import {Message, Loading} from "element-ui";

export default {
    /*教學系列*/
    //添加手機教學分類
    addAppGuidelineCategory(data) {
        return request({
            url: '/admin/v1/addAppGuidelineCategory', method: 'post', data,
        })
    }, //獲取手機教學清單
    getAppGuidelineCategoryList(params) {
        return request({
            url: '/admin/v1/getAppGuidelineCategoryList', method: 'GET', params,
        })
    }, //獲取手機教學詳情
    showAppGuidelineCategory(data) {
        return request({
            url: '/admin/v1/showAppGuidelineCategory', method: 'post', data,
        })
    }, //修改手機教學分類
    editAppGuidelineCategory(data) {
        return request({
            url: '/admin/v1/editAppGuidelineCategory', method: 'post', data,
        })
    }, //刪除手機教學分類
    delAppGuidelineCategory(data) {
        return request({
            url: '/admin/v1/delAppGuidelineCategory', method: 'post', data,
        })
    },

    //添加手機教學
    addAppGuideline(data) {
        return request({
            url: '/admin/v1/addAppGuideline', method: 'post', data,
        })
    }, //獲取手機教學清單
    getAppGuidelineList(params) {
        return request({
            url: '/admin/v1/getAppGuidelineList', method: 'GET', params,
        })
    }, //獲取手機教學資訊
    showAppGuideline(data) {
        return request({
            url: '/admin/v1/showAppGuideline', method: 'post', data,
        })
    }, //修改手機教學
    editAppGuideline(data) {
        return request({
            url: '/admin/v1/editAppGuideline', method: 'post', data,
        })
    }, //上傳手機教學圖片
    uploadAppGuidelineImg(data) {
        return request({
            url: '/admin/v1/uploadAppGuidelineImg', method: 'post', data,
        })
    }, //刪除手機教學文章
    delAppGuideline(data) {
        return request({
            url: '/admin/v1/delAppGuideline', method: 'post', data,
        })
    },
    /*教學系列*/
    /**取得折扣產品清單*/
    getDiscountGoodsList(params) {
        return request({
            url: '/admin/v1/getDiscountGoodsList', method: 'get', params,
        })
    }, /**修改優惠專區的折扣產品*/
    modifyDiscountGoods2SpecialArea(data) {
        return request({
            url: '/admin/v1/modifyDiscountGoods2SpecialArea', method: 'post', data,
        })
    }, /**取得優惠專區產品清單*/
    getSpecialAreaGoodsList(params) {
        return request({
            url: '/admin/v1/getSpecialAreaGoodsList', method: 'get', params,
        })
    }, //批准使用者
    approveUser(data) {
        return request({
            url: '/admin/v1/approveUser', method: 'post', data,
        })
    }, /**取得餐廳註冊使用者*/
    getRestaurantRegister(params) {
        return request({
            url: '/admin/v1/getRestaurantRegister', method: 'get', params,
        })
    }, //修改餐廳分類
    modifyRestaurantClassify(data) {
        return request({
            url: '/admin/v1/modifyRestaurantClassify', method: 'post', data,
        })
    }, //刪除餐廳分類
    delRestaurantClassify(data) {
        return request({
            url: '/admin/v1/delRestaurantClassify', method: 'post', data,
        })
    }, //新增餐廳分類
    addRestaurantClassify(data) {
        return request({
            url: '/admin/v1/addRestaurantClassify', method: 'post', data,
        })
    }, //取得餐廳分類資訊
    getRestaurantClassifyInfo(params) {
        return request({
            url: '/admin/v1/getRestaurantClassifyInfo', method: 'get', params,
        })
    }, //取得餐廳分類清單
    getRestaurantClassifyList(params) {
        return request({
            url: '/admin/v1/getRestaurantClassifyList', method: 'get', params,
        })
    }, //新增首頁影片
    addHomeVideo(data) {
        return request({
            url: '/admin/v1/addHomeVideo', method: 'post', data,
        })
    }, //刪除首頁影片
    delHomeVideo(data) {
        return request({
            url: '/admin/v1/delHomeVideo', method: 'post', data,
        })
    }, //修改首頁影片
    modifyHomeVideo(data) {
        return request({
            url: '/admin/v1/modifyHomeVideo', method: 'post', data,
        })
    }, //取得首頁影片
    getHomeVideoInfo(params) {
        return request({
            url: '/admin/v1/getHomeVideoInfo', method: 'GET', params,
        })
    }, //取得首頁影片清單
    getHomeVideoList(params) {
        return request({
            url: '/admin/v1/getHomeVideoList', method: 'GET', params,
        })
    }, //修改菜色分類
    modifyCategoryDish(data) {
        return request({
            url: '/admin/v1/modifyCategoryDish', method: 'post', data,
        })
    }, //取得菜色分類資訊
    getCategoryDish(params) {
        return request({
            url: '/admin/v1/getCategoryDish', method: 'GET', params,
        })
    }, //刪除菜色分類
    delCategoryDish(data) {
        return request({
            url: '/admin/v1/delCategoryDish', method: 'post', data,
        })
    }, //新增菜色分類
    addCategoryDish(data) {
        return request({
            url: '/admin/v1/addCategoryDish', method: 'post', data,
        })
    }, //取得菜色分類
    getCategoryDishList(params) {
        return request({
            url: '/admin/v1/getCategoryDishList', method: 'GET', params,
        })
    }, //提供給餐廳或供應商的GMV
    companyGMV(params) {
        return request({
            url: '/admin/v1/companyGMV', method: 'GET', params,
        })
    }, //暫時替代WS發送訊息
    sendMessage(data) {
        return request({
            url: '/admin/v1/sendMessage', method: 'post', data
        })
    }, //取得Order PDF用Info
    getOrderInfoForPDF(data) {
        return request({
            url: '/admin/v1/getOrderInfoForPDF', method: 'post', data
        })
    }, //重置密碼
    resetPassword(data) {
        return request({
            url: '/admin/v1/resetPassword', method: 'post', data
        })
    }, //輸出當前訂單Excel
    exportOrderExcel(params) {
        return request({
            url: '/admin/v1/exportOrderExcel', method: 'GET', params, responseType: 'blob'
        })
    }, //輸出30天內曾下單用戶Excel
    exportOrderUser(params) {
        return request({
            url: '/admin/v1/exportOrderUser', method: 'GET', params, responseType: 'blob'
        })
    }, //取得30天內曾下單用戶清單
    OrderUserList(params) {
        return request({
            url: '/admin/v1/OrderUserList', method: 'GET', params,
        })
    }, //輸出Invoice PDF
    exportInvoiceToPDF(data) {
        return request({
            url: '/admin/v1/exportInvoiceToPDF', method: 'post', data, responseType: 'blob'
        })
    }, //取得尚未讀取的聊天訊息數量
    getNotReadCount(params) {
        return request({
            url: '/admin/v1/getNotReadCount', method: 'GET', params,
        })
    }, //取得所有單位清單
    getAllUnitList(params) {
        return request({
            url: '/admin/v1/getAllUnitList', method: 'GET', params,
        })
    }, //取得該筆訂單可以新增的產品清單
    getCanAddProductList(params) {
        return request({
            url: '/admin/v1/getCanAddProductList', method: 'GET', params,
        })
    }, //刪除訂單產品
    delOrderGoods(data) {
        return request({
            url: '/admin/v1/delOrderGoods', method: 'post', data
        })
    }, //大量刪除訂單
    multiOrderDelete(data) {
        return request({
            url: '/admin/v1/multiOrderDelete', method: 'post', data
        })
    }, //大量修改訂單狀態
    multiChangeOrderStatus(data) {
        return request({
            url: '/admin/v1/multiChangeOrderStatus', method: 'post', data
        })
    }, //香港行政分區
    HKDistrictList(params) {
        return request({
            url: '/admin/v1/HKDistrictList', method: 'GET', params,
        })
    }, //隱藏公司
    showOrHiddenCompany(data) {
        return request({
            url: '/admin/v1/showOrHiddenCompany', method: 'post', data
        })
    }, //輸出餐廳清單至Excel
    exportRestaurant2Excel(params) {
        return request({
            url: '/admin/v1/exportRestaurant2Excel', method: 'GET', params, responseType: 'blob'
        })
    }, //取得全部餐廳清單, 不論狀態如何
    getAllStatusRestaurant(params) {
        return request({
            url: '/admin/v1/getAllStatusRestaurant', method: 'GET', params,
        })
    }, //取得有訂單的B2B餐廳
    getB2bOrderRestaurant(params) {
        return request({
            url: '/admin/v1/getB2bOrderRestaurant', method: 'GET', params,
        })
    }, //取得有訂單的B2B供應商
    getB2bOrderSupplier(params) {
        return request({
            url: '/admin/v1/getB2bOrderSupplier', method: 'GET', params,
        })
    }, //輸出供應商清單
    exportSupplierList(params) {
        return request({
            url: '/admin/v1/exportSupplierList', method: 'GET', params, responseType: 'blob'
        })
    }, //重新派送訂單郵件
    resendOrderEmail(params) {
        return request({
            url: '/admin/v1/resendOrderEmail', method: 'get', params
        })
    }, //重新派送訂單WhatsApp
    resendOrderWhatsApp(params) {
        return request({
            url: '/admin/v1/resendOrderWhatsApp', method: 'get', params
        })
    }, //刪除訂單產品
    delOrderGood(data) {
        return request({
            url: '/admin/v1/delOrderGood', method: 'post', data
        })
    }, top10SaleGood(params) {
        return request({
            url: '/admin/v1/top10SaleGood', method: 'get', params
        })
    }, top10PurchaseRestaurant(params) {
        return request({
            url: '/admin/v1/top10PurchaseRestaurant', method: 'get', params
        })
    }, //前十關鍵詞
    top10Keywords(params) {
        return request({
            url: '/admin/v1/top10Keywords', method: 'get', params
        })
    }, //Dashboard
    dashboard(params) {
        return request({
            url: '/admin/v1/dashboard', method: 'get', params
        })
    }, //更新 Pos
    modifyPos(data) {
        return request({
            url: '/admin/v1/modifyPos', method: 'post', data
        })
    }, //取得 POS info
    getPosInfo(params) {
        return request({
            url: '/admin/v1/getPosInfo', method: 'get', params
        })
    }, //刪除POS
    delPos(data) {
        return request({
            url: '/admin/v1/delPos', method: 'post', data
        })
    }, //新增POS
    addPos(data) {
        return request({
            url: '/admin/v1/addPos', method: 'post', data
        })
    }, //取得POS Name list
    getPosNameList(params) {
        return request({
            url: '/admin/v1/getPosNameList', method: 'get', params
        })
    }, //取得評價List
    getPosList(params) {
        return request({
            url: '/admin/v1/getPosList', method: 'get', params
        })
    }, //刪除訂單
    deleteOrder(data) {
        return request({
            url: '/admin/v1/deleteOrder', method: 'post', data
        })
    }, //修改評分狀態
    modifyRatingStatus(data) {
        return request({
            url: '/admin/v1/modifyRatingStatus', method: 'post', data
        })
    }, //取得評論清單
    getRatingList(params) {
        return request({
            url: '/admin/v1/getRatingList', method: 'get', params
        })
    }, //更改訂單運費
    editOrderDeliverFee(data) {
        return request({
            url: '/admin/v1/editOrderDeliverFee', method: 'post', data
        })
    }, //訂單產品更換
    changeOrderProduct(data) {
        return request({
            url: '/admin/v1/changeOrderProduct', method: 'post', data
        })
    }, //更新發票收費價格
    modifyInvoiceFee(data) {
        return request({
            url: '/admin/v1/modifyInvoiceFee', method: 'post', data
        })
    }, //發送發票郵件
    sendInvoiceMail(data) {
        return request({
            url: '/admin/v1/sendInvoiceMail', method: 'post', data
        })
    }, //匯出本月發票Excel
    exportMonthInvoiceExcel(params) {
        return request({
            url: '/admin/v1/exportMonthInvoiceExcel', method: 'get', params, responseType: 'blob'
        })
    }, //匯出本年發票Excel
    exportYearInvoiceExcel(params) {
        return request({
            url: '/admin/v1/exportYearInvoiceExcel', method: 'get', params, responseType: 'blob'
        })
    }, //取得發票資訊
    getInvoiceInfo(params) {
        return request({
            url: '/admin/v1/getInvoiceInfo', method: 'get', params
        })
    }, //更新發票付款狀態
    updateInvoicePaymentStatus(data) {
        return request({
            url: '/admin/v1/updateInvoicePaymentStatus', method: 'post', data
        })
    }, //取得發票清單
    getInvoiceList(params) {
        return request({
            url: '/admin/v1/getInvoiceList', method: 'get', params
        })
    }, //搜尋排序
    searchRankOrder(params) {
        return request({
            url: '/admin/v1/searchRankOrder', method: 'get', params
        })
    }, //刪除排序
    delRankOrder(data) {
        return request({
            url: '/admin/v1/delRankOrder', method: 'post', data
        })
    }, //修改排序
    modifyRankOrder(data) {
        return request({
            url: '/admin/v1/modifyRankOrder', method: 'post', data
        })
    }, //取得排序
    getRankOrder(params) {
        return request({
            url: '/admin/v1/getRankOrder', method: 'get', params
        })
    }, //新增排序
    addRankOrder(data) {
        return request({
            url: '/admin/v1/addRankOrder', method: 'post', data
        })
    }, //取得排序清單
    getRankOrderList(params) {
        return request({
            url: '/admin/v1/getRankOrderList', method: 'get', params
        })
    }, //新增支付
    addPayment(data) {
        return request({
            url: '/admin/v1/addPayment', method: 'POST', data
        })
    }, //更新支付
    modifyPayment(data) {
        return request({
            url: '/admin/v1/modifyPayment', method: 'POST', data
        })
    }, //取得Payment info
    getPaymentInfo(params) {
        return request({
            url: '/admin/v1/getPaymentInfo', method: 'get', params
        })
    }, //刪除支付
    delPayment(data) {
        return request({
            url: '/admin/v1/delPayment', method: 'POST', data
        })
    }, //取得支付清單
    getPaymentList(params) {
        return request({
            url: '/admin/v1/getPaymentList', method: 'get', params
        })
    }, //取得所有供應商
    getAllSupplierList(params) {
        return request({
            url: '/admin/v1/getAllSupplierList', method: 'get', params
        })
    }, //取得全部餐廳WhatsApp群組清單
    getEnableSupplierList(params) {
        return request({
            url: '/admin/v1/getEnableSupplierList', method: 'get', params
        })
    }, //更新餐廳WhatsApp群組
    updateWhatsAppGroupList(data) {
        return request({
            url: '/admin/v1/updateWhatsAppGroupList', method: 'POST', data
        })
    }, //取得全部餐廳WhatsApp群組清單
    getRestaurantWhatsAppGroup(params) {
        return request({
            url: '/admin/v1/getRestaurantWhatsAppGroup', method: 'get', params
        })
    }, //下載供應商產品
    exportSupplierProduct(params) {
        return request({
            url: '/admin/v1/exportSupplierProduct', method: 'GET', params, responseType: 'blob'
        })
    }, //取得單位轉換清單
    getUnitConverterList(params) {
        return request({
            url: '/admin/v1/getUnitConverterList', method: 'GET', params
        })
    }, //新增單位轉換
    addUnitConverter(data) {
        return request({
            url: '/admin/v1/addUnitConverter', method: 'POST', data
        })
    }, //修改單位轉換
    modifyUnitConverter(data) {
        return request({
            url: '/admin/v1/modifyUnitConverter', method: 'POST', data
        })
    }, //刪除單位轉換
    deleteUnitConverter(data) {
        return request({
            url: '/admin/v1/deleteUnitConverter', method: 'POST', data
        })
    }, //快速套用權限
    quicklyApplyAuth(data) {
        return request({
            url: '/admin/v1/quicklyApplyAuth', method: 'POST', data
        })
    }, //取得全部餐廳列表
    getAllRestaurantList(params) {
        return request({
            url: '/admin/v1/getAllRestaurantList', method: 'GET', params
        })
    }, //取得自訂授權群組清單
    getCustomAuthGroupList(params) {
        return request({
            url: "/admin/v1/getCustomAuthGroupList", method: "GET", params,
        });
    }, //新增自訂授權群組
    addCustomAuthGroup(data) {
        return request({
            url: "/admin/v1/addCustomAuthGroup", method: "POST", data,
        });
    }, //更新自訂授權群組
    updateCustomAuthGroup(data) {
        return request({
            url: "/admin/v1/updateCustomAuthGroup", method: "POST", data,
        });
    }, //刪除自訂授權群組
    delCustomAuthGroup(data) {
        return request({
            url: "/admin/v1/delCustomAuthGroup", method: "POST", data,
        });
    }, // 取得群組授權名稱清單
    updateRestaurantAuthList(data) {
        return request({
            url: "/admin/v1/updateRestaurantAuthList", method: "POST", data,
        });
    }, // 取得群組授權名稱清單
    getAuthNameList(params) {
        return request({
            url: "/admin/v1/getAuthNameList", method: "GET", params,
        });
    }, // 取得群組授權清單
    getAuthGroups(params) {
        return request({
            url: "/admin/v1/getAuthGroups", method: "GET", params,
        });
    }, //取得自訂供應商商品清單
    getCustomSupplierGoodsList(params) {
        return request({
            url: "/admin/v1/getCustomSupplierGoodsList", method: "GET", params,
        });
    }, //取得自訂的供應商清單
    getCustomSupplierList(params) {
        return request({
            url: "/admin/v1/getCustomSupplierList", method: "GET", params,
        });
    }, //新增訂單
    goodsPlaceOrder(data) {
        return request({
            url: "/admin/v1/goodsPlaceOrder", method: "POST", data,
        });
    }, // 取得送貨時間
    getSupplierDeliveryTime(params) {
        return request({
            url: "/admin/v1/getSupplierDeliveryTime", method: "GET", params,
        });
    }, //取得商品資訊
    getGoodsInfo(params) {
        return request({
            url: "/admin/v1/getGoodsInfo", method: "GET", params,
        });
    }, // 取得餐廳分店清單
    getBranchRestaurantList(params) {
        return request({
            url: "/admin/v1/getBranchRestaurantList", method: "GET", params,
        });
    }, // 配置項-網頁廣告
    getWebAdvList(params) {
        return request({
            url: "/admin/v1/getWebAdvList", method: "GET", params,
        });
    }, // 新增網頁廣告
    addWebAdv(data) {
        return request({
            url: "/admin/v1/addWebAdv", method: "POST", data,
        });
    }, // 網頁廣告詳情
    getWebAdvInfo(params) {
        return request({
            url: "/admin/v1/getWebAdvInfo", method: "GET", params,
        });
    }, // 更新廣告網頁
    updateWebAdv(data) {
        return request({
            url: "/admin/v1/updateWebAdv", method: "POST", data,
        });
    }, // 刪除網頁廣告
    delWebAdv(data) {
        return request({
            url: "/admin/v1/delWebAdv", method: "POST", data,
        });
    },

    // 前端权限列表
    getAuth(params) {
        return request({
            url: "/admin/v1/getAuthJson", method: "GET", params,
        });
    }, modifyAuth(data) {
        return request({
            url: "/admin/v1/updateAuthJson", method: "POST", data,
        });
    }, refreshAuth(data) {
        return request({
            url: "/admin/v1/refreshAuth", method: "POST", data,
        });
    },

    //权限分组
    getGrouping(params) {
        return request({
            url: "/admin/v1/getAuthGroupList", method: "GET", params,
        });
    },

    addGrouping(data) {
        return request({
            url: "/admin/v1/addAuthGroup", method: "POST", data,
        });
    }, queryGrouping(params) {
        return request({
            url: "/admin/v1/getAuthGroupInfo", method: "GET", params,
        });
    },

    modifyGrouping(data) {
        return request({
            url: "/admin/v1/updateAuthGroup", method: "POST", data,
        });
    }, deleteGrouping(data) {
        return request({
            url: "/admin/v1/delAuthGroup", method: "POST", data,
        });
    }, getTree(params) {
        return request({
            url: "/admin/v1/getAuthJsonList", method: "GET", params,
        });
    },

    // user
    login(data) {
        return request({
            url: "/admin/v1/login", method: "POST", data,
        });
        // .then(res => {
        //   if (!res.errno) {
        //     Message.success('登录成功')
        //   }
        // })
    }, logout(data) {
        return request({
            url: "/admin/v1/logout", method: "POST", data,
        });
    }, getUser(params) {
        return request({
            url: "/admin/v1/getUserList", method: "GET", params,
        });
    }, addUser(data) {
        return request({
            url: "/admin/v1/addSubUser", method: "POST", data,
        });
    }, queryUser(params) {
        return request({
            url: "/admin/v1/getUserInfo", method: "GET", params,
        });
    },

    modifyUser(data) {
        return request({
            url: "/admin/v1/updateUser", method: "POST", data,
        });
    }, deleteUser(data) {
        return request({
            url: "/admin/v1/delUser", method: "POST", data,
        });
    }, lockUser(data) {
        return request({
            url: "/admin/v1/updateUserStat", method: "Post", data,
        });
    }, resetPass(data) {
        return request({
            url: "/admin/v1/resetUserPwd", method: "POST", data,
        });
    },

    modifyPass(data) {
        return request({
            url: "/admin/v1/changePwd", method: "POST", data,
        });
    },

    dialogSelect(params) {
        return request({
            url: "/admin/v1/groupSelector", method: "GET", params,
        });
    }, // operationLog
    getLog(params) {
        return request({
            url: "/admin/v1/getOperatingList", method: "GET", params,
        });
    },

    queryLog(params) {
        return request({
            url: "/admin/v1/searchOperatingList", method: "GET", params,
        });
    },

    deleteLog(data) {
        return request({
            url: "/admin/v1/delOperating", method: "POST", data,
        });
    },

    // 商家管理--餐厅
    getRestaurant(params) {
        return request({
            url: "/admin/v1/getRestaurantList", method: "GET", params,
        });
    }, addRestaurant(data) {
        return request({
            url: "/admin/v1/addRestaurant", method: "POST", data,
        });
    }, queryRestaurant(params) {
        return request({
            url: "/admin/v1/getRestaurantInfo", method: "GET", params,
        });
    },

    modifyRestaurant(data) {
        return request({
            url: "/admin/v1/updateRestaurant", method: "POST", data,
        });
    },

    lockRestaurant(data) {
        return request({
            url: "/admin/v1/updateRestaurantStat", method: "Post", data,
        });
    },

    // 商家管理--供应商
    getSupplier(params) {
        return request({
            url: "/admin/v1/getSupplierList", method: "GET", params,
        });
    }, addSupplier(data) {
        return request({
            url: "/admin/v1/addSupplier", method: "POST", data,
        });
    }, querySupplier(params) {
        return request({
            url: "/admin/v1/getSupplierInfo", method: "GET", params,
        });
    },

    modifySupplier(data) {
        return request({
            url: "/admin/v1/updateSupplier", method: "POST", data,
        });
    },

    lockSupplier(data) {
        return request({
            url: "/admin/v1/updateSupplierStat", method: "Post", data,
        });
    }, // 商家用户
    getRestaurantUser(params) {
        return request({
            url: "/admin/v1/getRestaurantUserList", method: "GET", params,
        });
    }, lockRestaurantUser(data) {
        return request({
            url: "/admin/v1/updateRestaurantUserStat", method: "Post", data,
        });
    },

    getSupplierUser(params) {
        return request({
            url: "/admin/v1/getSupplierUserList", method: "GET", params,
        });
    }, lockSupplierUser(data) {
        return request({
            url: "/admin/v1/updateSupplierUserStat", method: "Post", data,
        });
    },

    // 产品分类
    getClassify(params) {
        return request({
            url: "/admin/v1/getCategoryList", method: "GET", params,
        });
    }, addClassify(data) {
        return request({
            url: "/admin/v1/addCategory", method: "POST", data,
        });
    }, queryClassify(params) {
        return request({
            url: "/admin/v1/getCategoryInfo", method: "GET", params,
        });
    },

    modifyClassify(data) {
        return request({
            url: "/admin/v1/updateCategory", method: "POST", data,
        });
    },

    isShowClassify(data) {
        return request({
            url: "/admin/v1/updateCategoryStat", method: "Post", data,
        });
    }, deleteClassify(data) {
        return request({
            url: "/admin/v1/delCategory", method: "Post", data,
        });
    },

    classifyDialog(params) {
        return request({
            url: "/admin/v1/categorySelector", method: "GET", params,
        });
    },

    // xiazai\
    download(params) {
        return request({
            url: "/restaurant/v1/downloadSupplierTemplate", method: "GET", params,
        });
    },

    // 配置项-广告
    //查
    getAdvList(params) {
        return request({
            url: "/admin/v1/getAdvList", method: "GET", params,
        });
    }, // 增
    addAdv(data) {
        return request({
            url: "/admin/v1/addAdv", method: "POST", data,
        });
    }, // 详细
    getAdvInfo(params) {
        return request({
            url: "/admin/v1/getAdvInfo", method: "GET", params,
        });
    }, // 改
    updateAdv(data) {
        return request({
            url: "/admin/v1/updateAdv", method: "POST", data,
        });
    }, // 删
    delAdv(data) {
        return request({
            url: "/admin/v1/delAdv", method: "POST", data,
        });
    }, // 配置项-平台信息
    //查
    getPlatformInfo(params) {
        return request({
            url: "/admin/v1/getPlatformInfo", method: "GET", params,
        });
    }, // 改
    updatePlatform(data) {
        return request({
            url: "/admin/v1/updatePlatform", method: "POST", data,
        });
    }, // 配置项-首頁分類
    //查
    getHomeCategoryList(params) {
        return request({
            url: "/admin/v1/getHomeCategoryList", method: "GET", params,
        });
    }, // 增
    addHomeCategory(data) {
        return request({
            url: "/admin/v1/addHomeCategory", method: "POST", data,
        });
    }, // 详细
    getHomeCategoryInfo(params) {
        return request({
            url: "/admin/v1/getHomeCategoryInfo", method: "GET", params,
        });
    }, // 改
    updateHomeCategory(data) {
        return request({
            url: "/admin/v1/updateHomeCategory", method: "POST", data,
        });
    }, // 删
    delHomeCategory(data) {
        return request({
            url: "/admin/v1/delHomeCategory", method: "POST", data,
        });
    }, // 积分
    // 查
    getCreditSetting(params) {
        return request({
            url: "/admin/v1/getCreditSetting", method: "get", params,
        });
    }, // 改
    updateCreditSetting(data) {
        return request({
            url: "/admin/v1/updateCreditSetting", method: "POST", data,
        });
    }, // 订阅计划
    // 获取计划列表
    getPlanList(params) {
        return request({
            url: "/admin/v1/getPlanList", method: "get", params,
        });
    }, // 添加计划
    addPlan(data) {
        return request({
            url: "/admin/v1/addPlan", method: "POST", data,
        });
    }, // 获取计划信息
    getPlanInfo(params) {
        return request({
            url: "/admin/v1/getPlanInfo", method: "get", params,
        });
    }, // 修改计划
    updatePlan(data) {
        return request({
            url: "/admin/v1/updatePlan", method: "POST", data,
        });
    }, // 产品单位

    // 获取产品单位列表
    getUnitList(params) {
        return request({
            url: "/admin/v1/getUnitList", method: "get", params,
        });
    }, // 添加产品单位
    addUnit(data) {
        return request({
            url: "/admin/v1/addUnit", method: "POST", data,
        });
    }, // 获取产品单位信息
    getUnitInfo(params) {
        return request({
            url: "/admin/v1/getUnitInfo", method: "get", params,
        });
    }, // 修改产品单位
    updateUnit(data) {
        return request({
            url: "/admin/v1/updateUnit", method: "POST", data,
        });
    },

    // 会计
    // 获取账单分类列表
    getBillCategory(params) {
        return request({
            url: "/admin/v1/getBillCategory", method: "GET", params,
        });
    }, // 添加账单分类
    addBillCategory(data) {
        return request({
            url: "/admin/v1/addBillCategory", method: "POST", data,
        });
    }, // 修改账单分类
    updateBillCategory(data) {
        return request({
            url: "/admin/v1/updateBillCategory", method: "POST", data,
        });
    }, // 删除账单分类
    delBillCategory(data) {
        return request({
            url: "/admin/v1/delBillCategory", method: "POST", data,
        });
    }, // 获取账单分类信息
    getBillCategoryInfo(params) {
        return request({
            url: "/admin/v1/getBillCategoryInfo", method: "GET", params,
        });
    }, // 账单分类选择器
    topBillCategorySelector(params) {
        return request({
            url: "/admin/v1/topBillCategorySelector", method: "GET", params,
        });
    },

    //邮箱
    getEmailList(params) {
        return request({
            url: "/admin/v1/getEmailList", method: "GET", params,
        });
    }, //已读
    markRead(params) {
        return request({
            url: "/admin/v1/markRead", method: "GET", params,
        });
    },

    //香港行政區id
    HKDistrictSelector(params) {
        return request({
            url: "/restaurant/v1/HKDistrictSelector", method: "GET", params,
        });
    },

    //商品分类选择器
    goodsCategorySelector(params) {
        return request({
            url: "/restaurant/v1/goodsCategorySelector", method: "GET", params,
        });
    }, //一级分类
    homeCategorySelector(params) {
        return request({
            url: "/admin/v1/homeCategorySelector", method: "GET", params,
        });
    }, //切换语言
    userChangeLang(data) {
        return request({
            url: "/admin/v1/userChangeLang", method: "post", data,
        });
    },

    //聊天记录
    //用户聊天列表
    getUserChatList(params) {
        return request({
            url: "/admin/v1/getUserChatList", method: "get", params,
        });
    }, //用户聊天记录
    getUserChatRecord(params) {
        return request({
            url: "/admin/v1/getUserChatRecord", method: "get", params,
        });
    },

    // 订单管理
    // 获取订单列表
    getOrderList(params) {
        return request({
            url: "/admin/v1/getOrderList", method: "get", params,
        });
    }, // 查看订单详情
    getOrderDetail(params) {
        return request({
            url: "/admin/v1/getOrderDetail", method: "get", params,
        });
    }, // 获取餐厅和门店选择器
    restaurantShopSelector(params) {
        return request({
            url: "/admin/v1/restaurantShopSelector", method: "get", params,
        });
    }, // B2B供应商选择器
    b2bSupplierSelector(params) {
        return request({
            url: "/admin/v1/b2bSupplierSelector", method: "get", params,
        });
    }, //打印发票页面
    printOrderPDF(params) {
        return request({
            url: "/restaurant/v1/printOrderPDF", method: "get", params,
        });
    }, // 更改订单接收
    editOrder(data) {
        return request({
            url: "/admin/v1/editOrder", method: "post", data,
        });
    },

    // 添加博客分类
    addBlogCategory(data) {
        return request({
            url: "/admin/v1/addBlogCategory", method: "post", data,
        });
    }, // 获取博客分类列表
    getBlogCategoryList(params) {
        return request({
            url: "/admin/v1/getBlogCategoryList", method: "get", params,
        });
    },

    // 获取博客分类详情
    showBlogCategory(data) {
        return request({
            url: "/admin/v1/showBlogCategory", method: "post", data,
        });
    }, // 修改博客分类
    editBlogCategory(data) {
        return request({
            url: "/admin/v1/editBlogCategory", method: "post", data,
        });
    }, // 删除博客分类
    delBlogCategory(data) {
        return request({
            url: "/admin/v1/delBlogCategory", method: "post", data,
        });
    }, // 添加博客
    addBlog(data) {
        return request({
            url: "/admin/v1/addBlog", method: "post", data,
        });
    }, // 获取博客列表
    getBlogList(params) {
        return request({
            url: "/admin/v1/getBlogList", method: "get", params,
        });
    }, // 获取博客详情
    showBlog(data) {
        return request({
            url: "/admin/v1/showBlog", method: "post", data,
        });
    }, // 修改博客
    editBlog(data) {
        return request({
            url: "/admin/v1/editBlog", method: "post", data,
        });
    }, // 上传博客内文图片
    uploadImg(data) {
        return request({
            url: "/admin/v1/uploadImg", method: "post", data,
        });
    }, // 删除博客
    delBlog(data) {
        return request({
            url: "/admin/v1/delBlog", method: "post", data,
        });
    },

    //营销邮件
    // 导出邮件
    newsletterExport(params) {
        return request({
            url: "/admin/v1/newsletterExport", method: "get", params, responseType: "blob",
        });
    }, // 营销邮件列表
    newsletterList(params) {
        return request({
            url: "/admin/v1/newsletterList", method: "get", params,
        });
    }, // 获取通知
    getInform(params) {
        return request({
            url: "/admin/v1/getInform", method: "get", params,
        });
    }, // 获取通知
    clearAllInform(data) {
        return request({
            url: "/admin/v1/clearAllInform", method: "post", data,
        });
    }, //供应商商品选择器
    showGoodsListBySupplier(params) {
        return request({
            url: "/admin/v1/showGoodsListBySupplier", method: "get", params,
        });
    }, // 供应商选择器
    supplierSelector(params) {
        return request({
            url: "/admin/v1/supplierSelector", method: "get", params,
        });
    }, // 获取总GMV
    GMVOrderAverage(params) {
        return request({
            url: "/admin/v1/GMVOrderAverage", method: "get", params,
        });
    }, // 订单均价
    orderAverage(params) {
        return request({
            url: "/admin/v1/orderAverage", method: "get", params,
        });
    }, // 关键词搜索频率
    keywordFrequency(params) {
        return request({
            url: "/admin/v1/keywordFrequency", method: "get", params,
        });
    }, // 活跃用户30内天有下单
    activeUser(params) {
        return request({
            url: "/admin/v1/activeUser", method: "get", params,
        });
    }, // 登录统计
    loginStatistics(params) {
        return request({
            url: "/admin/v1/loginStatistics", method: "get", params,
        });
    }, // 累计销售量、销售金额
    AddUpSales(params) {
        return request({
            url: "/admin/v1/AddUpSales", method: "get", params,
        });
    }, // 供应商累计销售量、销售金额
    goodsSales(params) {
        return request({
            url: "/admin/v1/goodsSales", method: "get", params,
        });
    }, // 订单均价
    goodsHits(params) {
        return request({
            url: "/admin/v1/goodsHits", method: "get", params,
        });
    }, // 获取总GMV
    GMVOrderAverageExcel(params) {
        return request({
            url: "/admin/v1/GMVOrderAverageExcel", method: "get", params, responseType: "blob",
        });
    }, // 商品点击量/购买数次 Excel
    goodsHitsExcel(params) {
        return request({
            url: "/admin/v1/goodsHitsExcel", method: "get", params, responseType: "blob",
        });
    }, // 订单均价Excel
    orderAverageExcel(params) {
        return request({
            url: "/admin/v1/orderAverageExcel", method: "get", params, responseType: "blob",
        });
    }, // 登录统计Excel
    loginStatisticsExcel(params) {
        return request({
            url: "/admin/v1/loginStatisticsExcel", method: "get", params, responseType: "blob",
        });
    }, // 关键词搜索频率Excel
    keywordFrequencyExcel(params) {
        return request({
            url: "/admin/v1/keywordFrequencyExcel", method: "get", params, responseType: "blob",
        });
    }, // 活跃用户30内天有下单Excel
    activeUserExcel(params) {
        return request({
            url: "/admin/v1/activeUserExcel", method: "get", params, responseType: "blob",
        });
    }, // 供应商餐厅订单数量、金额Excel
    AddUpSalesExcel(params) {
        return request({
            url: "/admin/v1/AddUpSalesExcel", method: "get", params, responseType: "blob",
        });
    }, // 商品累计销售量、销售金额Excel
    goodsSalesExcel(params) {
        return request({
            url: "/admin/v1/goodsSalesExcel", method: "get", params, responseType: "blob",
        });
    }, // 功能使用记录
    getUsageLog(params) {
        return request({
            url: "/admin/v1/getUsageLog", method: "get", params,
        });
    }, // 功能使用记录Excel
    getUsageLogExcel(params) {
        return request({
            url: "/admin/v1/getUsageLogExcel", method: "get", params, responseType: "blob",
        });
    },
};
