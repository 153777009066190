<template>
  <div class="AddOrder">
    <page-top :title="$t('backend_submenu.A15265@add_order')">
      <span style="font-size:26px;" class="fa fa-cog"></span>
    </page-top>
    <el-card>
      <div class="delivery">
        <el-row>
          <el-col :span="8">
            <!--     餐廳       -->
            {{ $t('aboutus.033E98@Please_Select_Restaurant') }}&nbsp;:&nbsp;&nbsp;
            <el-select v-model="order.cid" clearable filterable @change="restaurantChange"
                       style="padding-bottom: 10px;width: 250px">
              <el-option v-for="item in restaurantList" :label="item.company_name" :key="item.cid"
                         :value="item.cid"></el-option>
            </el-select>
            <br>
            <!--     分店       -->
            {{ $t('backend_authrity_grouping.422561@edit_store') }}&nbsp;:&nbsp;&nbsp;
            <el-select v-model="order.sid" clearable filterable style="width: 250px">
              <el-option v-for="item in shopList" :label="item.shop_name" :key="item.shop_name"
                         :value="item.shop_id"></el-option>
            </el-select>
            <!--     分店資料       -->
            <section v-if="shop" class="outlet">
              <!--     送貨地址       -->
              <span>{{ $t("restaurant_store.763B74@address") }}&nbsp;:&nbsp;{{ shop.address }}</span>
              <!--     連絡電話       -->
              <span>{{ $t("orderDetail.2775BD@store_tele") }}&nbsp;:&nbsp;{{ shop.telephone }}</span>
              <!--     聯絡人       -->
              <span>{{ $t("restaurant_supplier_addsuppluer.0C63A5@linkman") }}&nbsp;:&nbsp;{{
                  shop.linkman
                }}</span>
              <!--     收貨時間       -->
              <span v-if="shop.receive_time_start && shop.receive_time_end">{{
                  $t("aboutus.D453CE@Time_of_receipt")
                }}&nbsp;:&nbsp;{{ shop.receive_time_start + '-' + shop.receive_time_end }}</span>
            </section>
          </el-col>
          <!--     供應商和商品選擇       -->
          <el-col :span="8">
            <section v-if="order.sid">
              {{ $t("shop-goods.1213D8@Please_select_supplier") }}&nbsp;:&nbsp;&nbsp;
              <el-select class="mr10" v-model="supplierID" filterable clearable style="padding-bottom: 10px;">
                <el-option v-for="item in supplierList" :key="item.company_id" :label="item.company_name"
                           :value="item.company_id" v-if="item.show_restaurant.includes(order.cid)"></el-option>
              </el-select>
              <br>
              {{ $t("public.AE2731@select_product") }}&nbsp;:&nbsp;&nbsp;
              <el-select class="mr10" v-model="selectGoods" filterable multiple>
                <el-option
                    v-for="item in goodsList"
                    :key="item.gid"
                    :label="item.goods_name"
                    :value="item.gid"
                ></el-option>
              </el-select>
              <br>
              <el-button type="warning" size="small" @click="addGoods">
                {{ $t("public.3B45A2@confirm") }}
              </el-button>
            </section>
          </el-col>
        </el-row>
      </div>
      <!--   供應商和產品   -->
      <div class="goods">
        <div class="ShopCar">
          <div class="main">
            <div
                v-if="!order.data.length"
                style="font-size: 22px; margin: 20px auto; text-align: center">
              {{ $t("restaurant_supplier_addSupProduct.AAB37F@no_data") }}
            </div>
            <el-table
                border
                v-else
                :data="order.data"
                v-loading="loading"
                tooltip-effect="dark"
                style="width: 100%; margin-bottom: 30px"
                :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }"
                :default-expand-all="true">
              <!--       展開表單       -->
              <el-table-column type="expand">
                <template slot-scope="supplier">
                  <el-table
                      border
                      :data="supplier.row.goods"
                      v-loading="loading"
                      tooltip-effect="dark"
                      style="width: 100%; margin-bottom: 30px"
                      :header-cell-style="{ 'text-align': 'center' }"
                      :cell-style="{ 'text-align': 'center' }">
                    <!--         圖片           -->
                    <el-table-column prop="picture" :label="$t('shop_cart.E65DF9@goods_img')">
                      <template slot-scope="scope">
                        <div class="img">
                          <img v-if="scope.row.picture" :src="scope.row.picture" alt/>
                        </div>
                      </template>
                    </el-table-column>
                    <!--         產品名稱           -->
                    <el-table-column prop="goods_name"
                                     :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')">
                    </el-table-column>
                    <!--         單位           -->
                    <el-table-column prop="unit"
                                     :label="$t('supplier-produceManage-publishProduct.4E6A29@Commodity_unit')">
                      <template slot-scope="scope">
                        <span v-if="scope.row.unit_num">
                          {{ scope.row.unit_num + " / " + scope.row.unit }}
                        </span>
                        <span v-else>
                          -
                        </span>
                      </template>
                    </el-table-column>
                    <!--         價格           -->
                    <el-table-column prop="price" :label="$t('aboutus.96F4F8@product_price')">
                      <template slot-scope="scope">
                        <div>
                          <el-input-number
                              @change="priceChange(supplier.$index, scope.$index)"
                              v-model="scope.row.price"
                              size="small"
                              :min="0.01"
                              :label="$t('restaurant_supplier_supplierList.E318B6@desc')"
                          ></el-input-number>
                        </div>
                      </template>
                    </el-table-column>
                    <!--         數量           -->
                    <el-table-column prop="num" :label="$t('restaurant_buy_optionalOrder.2744C9@number')">
                      <template slot-scope="scope">
                        <div>
                          <el-input-number
                              @change="numberChange(supplier.$index, scope.$index)"
                              v-model="scope.row.num"
                              size="small"
                              :min="0.01"
                              :label="$t('restaurant_supplier_supplierList.E318B6@desc')"
                          ></el-input-number>
                          <br>
                          <span v-if="supplier.row.product_delivery==0">{{
                              $t('supplier-produceManage-publishProduct.97DB98@Minimum_purchase_quantity') + "：" + scope.row.min_quantity
                            }}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <!--         總金額           -->
                    <el-table-column
                        :label="$t('aboutus.09C1E0@Total_amount_of_goods')"
                        prop="total_goods_price">
                      <template slot-scope="scope">
                        <span style="color: #f24040;">
                          {{ "$" + scope.row.total_goods_price.toFixed(2).toLocaleString() }}
                        </span>
                      </template>
                    </el-table-column>
                    <!--         操作           -->
                    <el-table-column
                        prop="btn">
                      <template slot-scope="scope">
                        <span>
                          <el-button type="danger" size="small" @click="delGoodsBtn(supplier.$index, scope.$index)">
                            {{ $t('public.1FBB5E@btn_delete') }}
                          </el-button>
                        </span>
                      </template>
                    </el-table-column>
                    <div slot="append" class="calculate">
                      <section class="top">
                        <span style="width: 50px">{{ $t("shop-goods.299596@Remarks") }}:</span>
                        <el-input type="textarea" resize="none" :rows="3" v-model="supplier.row.remark"
                                  style="margin: 1vh 1vw"></el-input>
                      </section>
                      <div class="bott">
                        <!--          最低配送額              -->
                        <div class="sum" v-if="supplier.row.product_delivery!=1">
                          {{ $t('shop-goods.88E57F@Minimum_order_amount') }}
                          ：
                          {{
                            "$" + (supplier.row.delivery && supplier.row.delivery.amount_min > -1 ? supplier.row.delivery.amount_min : supplier.row.amount_min.toFixed(2).toLocaleString())
                          }}
                        </div>
                        <div class="sum" v-else>
                          {{ $t("shop-goods.E6DC79@Full_reduction") }} ：$0.00
                        </div>
                        <!--          免運門檻              -->
                        <div class="sum" v-if="supplier.row.product_delivery!=1">
                          {{ $t("shop-goods.E6DC79@Full_reduction") }}
                          ：
                          {{
                            "$" + (supplier.row.delivery && supplier.row.delivery.full_slicing > -1 ? supplier.row.delivery.full_slicing : supplier.row.full_slicing.toFixed(2).toLocaleString())
                          }}
                        </div>
                        <div class="sum" v-else>
                          {{ $t("shop-goods.E6DC79@Full_reduction") }} ：$0.00
                        </div>
                      </div>
                      <div class="bott">
                        <div class="sum">
                          {{ $t("aboutus.09C1E0@Total_amount_of_goods") }}
                          ：
                          {{ "$" + supplier.row.goods_price.toFixed(2).toLocaleString() }}
                        </div>
                        <!--          運費              -->
                        <div class="sum">
                          {{ $t("restaurant_supplier_supplierList.2E31F9@fee") }}：
                          <el-input-number
                              @change="feeChange(supplier.$index)"
                              v-model="supplier.row.fee"
                              size="small"
                              :min="0"
                              :label="$t('restaurant_supplier_supplierList.2E31F9@fee')">
                          </el-input-number>
                        </div>
                        {{ $t("restaurant_buy_genner.E0A3B6@order_price") }}：
                        <span style="color: #f24040;font-size: 18px">
                          {{ "$" + supplier.row.order_price.toFixed(2).toLocaleString() }}
                        </span>
                      </div>
                    </div>
                  </el-table>
                </template>
              </el-table-column>
              <!--      供應商名稱        -->
              <el-table-column prop="supplier_name" :label="$t('restaurant_buy_genner.586E4F@supplier_name')">
              </el-table-column>
              <!--      送貨時間        -->
              <el-table-column prop="delivery_time"
                               :label="$t('supplier-setting-DistributionSet.6198C8@delivery_date')">
                <template slot-scope="scope">
                  <el-date-picker
                      v-model="scope.row.delivery_time"
                      value-format="timestamp"
                      :picker-options="pickerDisabled"
                      style="width: 200px"
                      type="date"
                      :placeholder="$t('supplier-setting-DistributionSet.F1E87C@Select_date')">
                  </el-date-picker>
                </template>
              </el-table-column>
              <!--      取貨方式        -->
              <el-table-column prop="product_delivery" :label="$t('product_delivery.F5EA13@product_delivery')">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.product_delivery" filterable @change="updatePrice">
                    <el-option :value="0" :label="$t('product_delivery.AD58EF@supplier_direct_shipping')"></el-option>
                    <el-option :value="1" :label="$t('product_delivery.F5E1A3@in_store_pickup')"
                               v-if="scope.row.heloneself==1"></el-option>
                  </el-select>
                </template>
              </el-table-column>

              <!--      付款方式        -->
              <el-table-column prop="pay_method" :label="$t('aboutus.F31309@fee_ways')">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.pay_method" filterable>
                    <el-option :value="1" :key="1" :label="$t('aboutus.7F2D75@Cash_on_delivery')"></el-option>
                    <el-option :value="2" :key="2" :label="$t('aboutus.782FFE@Deferred_payment')"></el-option>
                    <el-option :value="3" :key="3" :label="$t('public.CC56D2@Designated_monthly_date')"></el-option>
                    <el-option :value="4" :key="4" :label="$t('public.6458EF@credit_card')" v-if="supplier.credit_card"></el-option>
                  </el-select>
                </template>
              </el-table-column>
              <!--      最後付款日期        -->
              <el-table-column prop="days" :label="$t('public.A1F551@last_payment_day')">
                <template slot-scope="scope">
                  <el-date-picker v-if="scope.row.pay_method==2 || scope.row.pay_method==3"
                                  v-model="scope.row.days"
                                  value-format="timestamp"
                                  :picker-options="pickerDisabled"
                                  style="width: 200px"
                                  type="date"
                                  :placeholder="$t('supplier-setting-DistributionSet.F1E87C@Select_date')">
                  </el-date-picker>
                </template>
              </el-table-column>
            </el-table>
            <div style=" text-align: center" v-show="order.data.length">
              <el-button type="success" @click="addOrder">{{ $t('public.DC9752@btn_add') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";

export default {
  components: {
    pageTop,
  },
  name: "",
  created() {
    this.getAllRestaurantList()
    this.getAllSupplierList()
  },
  data() {
    return {
      //驗證時間
      pickerDisabled: {
        disabledDate: (time) => {
          let date = new Date();
          let str =
              date.getFullYear() +
              "/" +
              (date.getMonth() + 1) +
              "/" +
              date.getDate();
          return (
              Date.parse(time) / 1000 <
              Date.parse(str) / 1000
          );
        },
      },
      loading: false,
      //選擇商品
      selectGoods: [],
      //供應商產品列表
      goodsList: [],
      //供應商列表
      supplierList: [],
      //分店列表
      shopList: [],
      //餐廳列表
      restaurantList: [],
      //供應商ID
      supplierID: "",
      //訂單
      order: {
        //data
        data: [],
        //分店ID
        sid: "",
        //餐廳ID
        cid: "",
      }
    }
  },
  computed: {
    //取得當前供應商資料
    supplier() {
      return this.supplierList.find(s => {
        return s.company_id === this.supplierID
      })
    },
    //取得商店資料
    shop() {
      return this.shopList.find(s => {
        return s.shop_id === this.order.sid
      })
    },
    //取得目前選取商品
    getSelectgoods() {
      return this.goodsList.filter(s => this.selectGoods.includes(s.gid))
    }
  },
  watch: {
    supplierID() {
      this.selectGoods = []

      if (!this.supplierID) {
        this.goodsList = []
        return
      }

      this.$http.showGoodsListBySupplier({
        is_b2b: 1,
        supplier_id: this.supplierID,
      }).then(res => {
        this.goodsList = res.data
      })
    }
  },
  methods: {
    //刪除產品按鈕
    delGoodsBtn(supplier, index) {
      this.order.data[supplier].goods.splice(index, 1)
      let goodsLength = this.order.data[supplier].goods.length
      if (!goodsLength) {
        //資料都被刪光時
        this.order.data -= this.order.data[supplier]
      }
    },
    //更新所有供應商價格
    updatePrice() {
      let length = this.order.data.length
      for (let i = 0; i < length; i++) {
        let data = this.order.data[i]
        //清空原先產品價格
        data.goods_price = 0
        let goodsLength = data.goods.length
        for (let g = 0; g < goodsLength; g++) {
          //取得產品目前價格
          data.goods[g].total_goods_price = data.goods[g].price * data.goods[g].num
          data.goods_price += data.goods[g].total_goods_price
        }
        let district_id = this.shop.district_id
        let delivery = data.supplier_delivery.find(sd => {
          return JSON.parse(sd.district_json).indexOf(district_id) > -1
        })

        //當前運費
        let currentFee = data.fee
        //計算目前運費如果是自訂則採用當前運費,反之則採用預設運費
        if (data.product_delivery != 1) {
          if (delivery && parseFloat(delivery.deliver_fee.replace('$', '').replace(',', '')) > -1 && parseFloat(delivery.full_slicing.replace('$', '').replace(',', '')) > -1 && parseFloat(delivery.amount_min.replace('$', '').replace(',', '')) > -1) {
            data.delivery = delivery
            let sdFee = parseFloat(delivery.deliver_fee.replace('$', '').replace(',', ''))
            let sdSlicing = parseFloat(delivery.full_slicing.replace('$', '').replace(',', ''))
            if (sdFee == currentFee) {
              if (data.goods_price < sdSlicing) {
                data.fee = sdFee
              } else {
                data.fee = 0
              }
            }
          } else {
            if (data.fee == data.deliver_fee) {
              if (data.goods_price < data.full_slicing) {
                data.fee = data.deliver_fee
              } else {
                data.fee = 0
              }
            }
          }
        } else {
          //依照使用者設定運費為主
          if (currentFee > 0) {
            data.fee = currentFee
          } else {
            data.fee = 0
          }
        }
        //該供應商訂單總金額
        data.order_price = data.goods_price + data.fee
        this.order.data[i] = data
      }
      this.$forceUpdate()
    },
    //運費改變
    feeChange(supplier) {
      if (!this.order.data[supplier].fee) {
        this.order.data[supplier].fee = 0
      }
      this.updatePrice()
    },
    //數值改變
    numberChange(supplier, index) {
      if (!this.order.data[supplier].goods[index].num) {
        this.order.data[supplier].goods[index].num = 0.01
      }
      this.updatePrice()
    },
    //價格改變
    priceChange(supplier, index) {
      if (!this.order.data[supplier].goods[index].price) {
        this.order.data[supplier].goods[index].price = 0.01
      }
      this.updatePrice()
    },
    //新增訂單
    addOrder() {
      let hasCheck = true
      this.order.data.forEach(order => {
        //檢查最後付款日期是否有遺漏
        if (order.pay_method == 2 || order.pay_method == 3) {
          if (!order.days) {
            this.$message.warning(this.$t('placeholder.C209ED@select') + this.$t('public.A1F551@last_payment_day'))
            hasCheck = false
            return
          }
        }

        //檢查送貨日期是否遺漏
        if (!order.delivery_time) {
          this.$message.warning(this.$t('placeholder.C209ED@select') + this.$t('supplier-setting-DistributionSet.6198C8@delivery_date'))
          hasCheck = false
          return
        }
      })
      if (hasCheck) {
        this.$http.goodsPlaceOrder({
          goods_json: JSON.stringify(this.order),
        }).then(res => {
          if (res.errno == 0) {
            this.order.data = []
            this.selectGoods = []
            this.goodsList = []
            this.supplierID = ""
            this.order.sid = ""
            this.order.cid = ""
            this.$forceUpdate()
          }
        })
      }
    },
    //加入選單
    addGoods() {
      //未選擇供應商
      if (!this.supplier) {
        this.$message.warning(this.$t("shop-goods.1213D7@Please_select_supplier"))
        return
      }
      //未選擇商品
      if (!this.getSelectgoods.length) {
        this.$message.warning(this.$t("aboutus.4E7C2A@Please_add_items_first"))
        return
      }

      let hasData = this.order.data.find(s => {
        return s.sid === this.supplierID
      })

      //尚未新增供應商到資料中
      if (!hasData) {
        let data = {
          credit_card:this.supplier.credit_card,
          days: "",
          //付款方式[1=>货到付款, 2=>可延迟付款, 3=>指定日期月结, 4=信用卡]
          pay_method: 1,
          //供應商id
          sid: this.supplier.company_id,
          //備註
          remark: "",
          //供應商名稱
          supplier_name: this.supplier.company_name,
          //產品價格
          goods_price: 0,
          //訂單總價格
          order_price: 0,
          //實際運費
          fee: 0,
          //商品
          goods: [],
          //最低配送金額
          amount_min: this.supplier.amount_min,
          //免運門檻
          full_slicing: this.supplier.full_slicing,
          //未達門檻運費
          deliver_fee: this.supplier.deliver_fee,
          //配送時間
          delivery_time: "",
          //區域配置
          supplier_delivery: this.supplier.supplier_delivery,
          //是否可以自取
          heloneself: this.supplier.heloneself,
          //取貨設定
          product_delivery: 0,
        }
        this.getSelectgoods.forEach(goods => {
          data.goods.push({
            gid: goods.gid,
            //名字
            goods_name: goods.goods_name,
            //圖片
            picture: goods.picture,
            //價格
            price: parseFloat(goods.price),
            //MOQ
            min_quantity: goods.min_quantity,
            //單位數量
            unit_num: goods.unit_num,
            //單位
            unit: goods.unit,
            //購買數量
            num: 1,
            //產品總金額
            total_goods_price: parseFloat(goods.price) * 1
          })
          data.goods_price += parseFloat(goods.price) * 1
          data.order_price += data.goods_price
        })
        let delivery_id = this.shop.district_id
        let delivery = data.supplier_delivery.find(sd => {
          return JSON.parse(sd.district_json).indexOf(delivery_id) > -1
        })
        if (delivery) {
          data.delivery = delivery
          let fee = parseFloat(delivery.deliver_fee)
          let full_slicing = parseFloat(delivery.full_slicing)
          if (data.goods_price < full_slicing) {
            data.fee = fee
          }
        } else {
          //低於免運門檻
          if (data.goods_price < data.full_slicing) {
            //運費等於預設運費
            data.fee = data.deliver_fee
            //訂單總金額則須加總
            data.order_price = data.goods_price + data.fee
          }
        }

        this.order.data.push(data)
      } else {
        let data = this.order.data.find(s => {
          return s.sid === this.supplier.company_id
        })
        //dataIndex位置
        let dataIndex = this.order.data.indexOf(data)

        //確保有資料
        if (data) {
          let selectGoods = this.getSelectgoods
          //已有產品
          let hasGoods = data.goods.filter(s => selectGoods.some(sg => s.gid === sg.gid))
          hasGoods.forEach(i => {
            let index = this.order.data[dataIndex].goods.indexOf(i)
            let goods = this.order.data[dataIndex].goods[index]
            goods.num += 1
            goods.total_goods_price = parseFloat(goods.price) * goods.num
            this.order.data[dataIndex].goods[index] = goods
          })

          //未有產品
          let newGoods = selectGoods.filter(s => !data.goods.some(sg => sg.gid === s.gid))
          newGoods.forEach(goods => {
            this.order.data[dataIndex].goods.push({
              gid: goods.gid,
              //名字
              goods_name: goods.goods_name,
              //圖片
              picture: goods.picture,
              //價格
              price: parseFloat(goods.price),
              //MOQ
              min_quantity: goods.min_quantity,
              //單位數量
              unit_num: goods.unit_num,
              //單位
              unit: goods.unit,
              //購買數量
              num: 1,
              //產品總金額
              total_goods_price: parseFloat(goods.price) * 1
            })
          })
        }
      }
      this.updatePrice()
    },
    //取得所有啟用供應商
    getAllSupplierList() {
      this.$http.getAllSupplierList().then(res => {
        this.supplierList = res.data.list
      })
    },
    //取得分店
    getBranchRestaurantList() {
      this.order.sid = ""
      this.$http.getBranchRestaurantList({
        cid: this.order.cid
      }).then(res => {
        this.shopList = res.data.list
      })
    },
    //餐廳下拉選單異動時
    restaurantChange() {
      this.order.sid = ""
      this.supplierID = ""
      this.selectGoods = []
      if (this.order.cid) {
        this.order.data = []
      }
      this.getBranchRestaurantList()
    },
    //取得所有餐廳
    getAllRestaurantList() {
      this.$http.getAllRestaurantList().then(res => {
        this.restaurantList = res.data
      })
    }
  },

}
</script>
<style lang="scss" scoped>
.AddOrder {
  .el-select__tags-text {
    word-break: break-all !important;
    max-width: 140px;
    white-space: normal;
  }

  .el-tag {
    height: auto !important;
  }

  .ShopCar {
    margin-top: 30px;

    .main {
      .img {

        img {
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
      }

      .calculate {
        width: 100%;

        .top {
          width: 100%;
          padding: pxToper(20) pxToper(30);
          box-sizing: border-box;
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left {
            display: flex;
            justify-content: center;
            font-size: pxToper(12);
            font-stretch: normal;
            letter-spacing: 0px;
            color: #666666;

            .dizhi {
              display: flex;
              justify-content: center;
              font-size: pxToper(12);
              font-stretch: normal;
              letter-spacing: 0px;
              color: #666666;

              .time {
                flex-wrap: nowrap;
              }

              .dizhi_area {
                display: flex;
                flex-direction: column;

                .area {
                  line-height: pxToper(22);
                  max-width: pxToper(640);
                }
              }
            }
          }

          .right,
          .sum {
            // width: pxToper(213);

            .min,
            .sum {
              font-size: pxToper(14);
              font-stretch: normal;
              line-height: pxToper(24);
              letter-spacing: 0px;
              color: #333333;
              text-align: right;
            }
          }
        }

        .bott {
          padding: 1vh 1vw;
          font-size: pxToper(14);
          font-weight: bold;
          font-stretch: normal;
          line-height: pxToper(24);
          letter-spacing: 0px;
          color: #333333;
          text-align: right;

          > span {
            font-size: pxToper(36);
            letter-spacing: 0px;
            color: #f24040;
          }
        }
      }

      .buy {
        width: 100%;
        height: pxToper(70);
        background-color: #fff;
        margin-top: pxToper(20);
        border: 1px solid #ebeef5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;

        .aggregate {
          width: pxToper(1170);
          padding: pxToper(20);
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .delete {
            margin-left: pxToper(50);
            cursor: pointer;
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;
          }

          .right {
            display: flex;
            align-items: center;
          }

          .selected {
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;

            > span {
              font-size: pxToper(24);
              line-height: pxToper(24);
              font-weight: 500;
              letter-spacing: 0px;
              color: #f24040;
            }
          }

          .aggregate-money {
            font-size: pxToper(14);
            line-height: pxToper(14);
            font-weight: 500;
            color: #606266;
            margin-left: pxToper(30);

            > span {
              font-size: pxToper(24);
              line-height: pxToper(24);
              font-weight: 600;
              letter-spacing: 0px;
              color: #f24040;
            }
          }
        }

        > button {
          width: pxToper(150);
          height: 100%;
          background-color: #f24040;
          border: none;
          outline: none;
          font-size: pxToper(20);
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .info {
      white-space: normal !important;
      height: 100%;
      font-size: pxToper(14);
      line-height: pxToper(30);
      color: #666666;
    }

    .unit_price {
      font-weight: bold;
      font-size: pxToper(18);
      line-height: pxToper(30);
      letter-spacing: 0px;
      color: #f24040;
    }

    .el-table--border td {
      border-right: none !important;
    }

    .el-table thead.is-group th {
      background-color: #fff !important;
    }

    .is-group > tr:nth-child(2) > th {
      border-right: none !important;
    }

    .el-table_1_column_2 > div {
      // width: pxToper(185) !important;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }

  .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;

    > span {
      font-size: 14px;
      line-height: 30px;
      color: #666666;
    }
  }

  .delivery {
    padding: 30px 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .integral {
    display: flex;
    flex-direction: column;
    position: relative;

    .update {
      position: absolute;
      top: 3px;
      right: 40%;
      font-size: 12px;
      line-height: 30px;
      color: #ff9906;
      z-index: 99;

      i {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }

  .time,
  .address,
  .roll,
  .integral {
    > span:first-child {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .btn-address {
    width: 120px;
    height: 40px;
    background-image: linear-gradient(90deg, #5c6f86 0%, #404d5e 100%),
    linear-gradient(#3e4b5b, #3e4b5b);
    background-blend-mode: normal, normal;
    border-radius: 5px;
    color: #ffffff;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .deli-time {
    > span {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .deli-address {
    > span {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #333333;
    }
  }

  .outlet {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    > span {
      margin: 10px 0;
    }
  }
}
</style>
