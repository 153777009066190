<template>
  <div class="printInvoice">
    <el-card body-style="padding-top:10px">
      <section class="head">
        <section class="logo">
          <img :src="info.logo">
        </section>
        <section class="company">
          <h3>瀚星策略有限公司</h3>
          <h3>Celestial Stars Strategy Limited</h3>
          <h5>香港中環皇后大道中 222 號啓煌商業大廈 3 樓 301 室</h5>
          <h5>Room 301, 3/F, Kai Wong Commercial Building</h5>
          <h5>222 Queen's Road Central, Hong Kong</h5>
          <h5>(852) 2882 1163</h5>
          <h6><a href="mailto:admin@chefstation.com.hk">admin@chefstation.com.hk</a></h6>
        </section>
      </section>
      <section class="restaurant">
        <section class="info">
          <h4>{{ info.company_name_hk }}</h4>
          <h4>{{ info.company_name_en }}</h4>
          <h5>{{ info.addr_hk }}</h5>
          <h5>{{ info.addr_en }}</h5>
          <h5>{{ info.contact }}</h5>
          <h5>{{info.payment_str}}</h5>
          <h5 v-if="info.area_num">({{ info.area_num }}){{ info.telephone }}</h5>
          <h5 v-else>{{ info.telephone }}</h5>
          <h6><a :href="'mailto:'+info.email">{{ info.email }}</a></h6>
        </section>
        <section class="invoice">
          <h3 style="padding-top: 3vh">INVOICE</h3>
          <h4 v-html="'Invoice Date:'+invoice_date"></h4>
          <h4 v-html="'Invoice #:'+invoice_number"></h4>
          <h4>Credit Tem:15 Days</h4>
        </section>
      </section>
      <section class="printTable">
        <table border="1">
          <thead>
          <tr style="border:1px black solid">
            <th style="color: #fff;font-weight: bold">No.</th>
            <th style="color: #fff;text-align: center;font-weight: bold">採購訂單 PO</th>
            <th style="color: #fff;text-align: right;font-weight: bold">金額 Amount (HK$)</th>
            <th style="color: #fff;text-align: right;font-weight: bold">收費 Fee (HK$)</th>
            <th style="color: #fff;text-align: right;font-weight: bold">付款方式</th>
            <th style="color: #fff;text-align: right;font-weight: bold">是否已付款</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in info.list" style="border:1px black solid;">
            <th>{{ i.no }}</th>
            <th>{{ i.order_no }}</th>
            <th style="text-align: right">{{ i.amount }}</th>
            <th style="text-align: right">{{
                i.invoice_fee == "0.00" && !i.is_invoice_fee ? $t('invoice.BA1FF5@free') : (i.is_invoice_fee ? i.fee : i.invoice_fee)
              }}
            </th>
            <th style="text-align: right">{{ i.pay_method }}</th>
            <th style="text-align: right">{{ i.pay_status }}</th>
          </tr>
          </tbody>
        </table>
        <div class="append">
          <div>
            <span>交易量 GMV(HK$)</span>
            <span>{{ info.GMV }}</span>
          </div>
          <div>
            <span>收費率 Fee Rate(%)</span>
            <span>{{ info.cost_ratio }}</span>
          </div>
          <div class="money">
            <span>應繳金額 Balance Due(HK$)</span>
            <span>{{ info.due }}</span>
          </div>
          <div class="money">
            <span>信用卡已付金額(HK$)</span>
            <span>{{ info.paid }}</span>
          </div>
          <div class="money">
            <span>未付金額(HK$)</span>
            <span>{{ info.unpaid }}</span>
          </div>
        </div>
      </section>
      <section class="notice">
        <div>
          請以過銀行轉帳或支票付款：<br>
          Please pay via bank transfer or cheque:<br><br>
          收款人 Payee: Celestial Stars Strategy Limited<br>
          帳戶號碼 Acc Number: 040-160-152-838<br>
          銀行 Bank: 匯豐銀行 HSBC Bank<br>
          * 請將付款紀錄（連帳單號碼）電郵到 <a href="mailto:admin@chefstation.com.hk">admin@chefstation.com.hk</a>，謝謝！<br>
          Please email the proof of payment (including invoice number) to <a href="mailto:admin@chefstation.com.hk">admin@chefstation.com.hk</a>,
          thank you!
        </div>
      </section>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      //Invoice date
      invoice_date: "",
      //cid
      cid: "",
      //Invoice Number
      invoice_number: "",
      info: {}
    }
  },
  methods: {
    //取得發票資訊
    getInvoiceInfo() {
      this.$http.getInvoiceInfo({
        cid: this.cid,
        invoice_date: this.invoice_date,
        invoice_number: this.invoice_number
      }).then(res => {
        this.info = res.data
      })
    }
  },
  created() {
    this.invoice_date = this.$route.query.date
    this.cid = this.$route.query.cid
    this.invoice_number = this.$route.query.invoice_number
    this.getInvoiceInfo()
  }
}
</script>


<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC&display=swap");

* {
  font-family: 'Noto Sans TC';
  color: black;
  background: #fff;
}

@media screen and (max-width: 1000px) {
  .printInvoice {
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      padding: 0px;
      margin: 0px;
    }

    h1, h2, h3, h4, h6 {
      color: #F24040;
    }

    .head {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        justify-content: space-between;

        > img {
          max-width: 30vw;
        }
      }

      .company {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;

        h1 {
          font-size: 1.0rem
        }

        h2 {
          font-size: 0.68rem;
        }

        h3 {
          font-size: 0.45rem;
        }

        h4 {
          font-size: 0.4rem;
        }

        h5 {
          font-size: 0.38rem;
        }

        h6 {
          font-size: 0.34rem;
        }
      }
    }

    .restaurant {
      display: flex;
      flex-direction: row;

      .info {
        width: 50%;

        h4 {
          font-size: 0.4rem;
        }

        h5 {
          font-size: 0.38rem;
        }

        h6 {
          font-size: 0.34rem;
        }
      }

      .invoice {
        margin-right: 5vw;
        width: 50%;
        text-align: right;
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 0.5rem;
        }

        h4 {
          color: black;
          font-size: 0.38rem;
        }

      }
    }

    .printTable {
      padding-top: 5px;

      table {
        width: 100%;
        margin-bottom: 10px;

        thead {
          tr {
            th {
              background: #F24040;
            }
          }
        }

        th {
          font-size: 0.48rem;
          text-align: center;
        }
      }

      .append {
        width: 100%;
        text-align: right;

        > div {
          width: 100%;
          font-size: 0.48rem;

          span:first-of-type {
            padding-right: 1.5vw;
          }

          span:last-of-type {
            padding-right: 1vw;
            text-align: left;

          }
        }

        .money {
          > span {
            color: rgb(242, 64, 64);
            font-weight: bold
          }
        }
      }
    }

    .notice {
      width: 100%;
      font-size: 0.3rem;
    }
  }
}

@media screen and (min-width: 1000px) {
  .printInvoice {
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      padding: 0px;
      margin: 0px;
    }

    h1, h2, h3, h4, h6 {
      color: #F24040;
    }

    .head {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        justify-content: space-between;

        > img {
          max-width: 30vw;
        }
      }

      .company {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;

        h1 {
          line-height: 28px;
          font-size: 2.4rem
        }

        h3 {
          line-height: 48px;
          font-size: 2.2rem;
        }

        h4 {
          line-height: 48px;
          font-size: 2.1rem;
        }

        h5 {
          line-height: 25px;
          font-size: 1.3rem;
        }

        h6 {
          line-height: 24px;
          font-size: 1.15rem;
        }
      }
    }

    .restaurant {
      display: flex;
      flex-direction: row;

      .info {
        width: 50%;

        h4 {
          font-size: 1.4rem;
        }

        h5 {
          font-size: 1.2rem;
        }

        h6 {
          font-size: 1.0rem;
        }
      }

      .invoice {
        width: 50%;
        text-align: right;
        display: flex;
        flex-direction: column;


        h3 {
          line-height: 30px;
          font-size: 1.3rem;
          padding-bottom: 25px;
        }

        h4 {
          color: black;
          line-height: 20px;
          font-size: 1.15rem;
        }
      }
    }

    .printTable {
      padding-top: 10px;

      table {
        width: 100%;
        margin-bottom: 10px;

        thead {
          tr {
            th {
              background: #F24040;
            }
          }
        }

        th {
          font-size: 1.15rem;
          text-align: center;
        }
      }

      .append {
        width: 100%;
        text-align: right;

        > div {
          width: 100%;
          font-size: 1.2rem;

          span:first-of-type {
            padding-right: 1.5vw;
          }

          span:last-of-type {
            padding-right: 1vw;
            text-align: left;

          }
        }

        .money {
          > span {
            color: rgb(242, 64, 64);
            font-weight: bold
          }
        }
      }
    }

    .notice {
      width: 100%;
      font-size: 1.19rem;
    }
  }
}

@media print{
  .printInvoice {
    h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      padding: 0px;
      margin: 0px;
    }

    h1, h2, h3, h4, h6 {
      color: #F24040;
    }

    .head {
      padding: 0px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        justify-content: space-between;

        > img {
          max-width: 30vw;
        }
      }

      .company {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;

        h1 {
          font-size: 1.0rem
        }

        h2 {
          font-size: 0.68rem;
        }

        h3 {
          font-size: 0.45rem;
        }

        h4 {
          font-size: 0.4rem;
        }

        h5 {
          font-size: 0.38rem;
        }

        h6 {
          font-size: 0.34rem;
        }
      }
    }

    .restaurant {
      display: flex;
      flex-direction: row;

      .info {
        width: 50%;

        h4 {
          font-size: 0.4rem;
        }

        h5 {
          font-size: 0.38rem;
        }

        h6 {
          font-size: 0.34rem;
        }
      }

      .invoice {
        margin-right: 5vw;
        width: 50%;
        text-align: right;
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 0.5rem;
        }

        h4 {
          color: black;
          font-size: 0.38rem;
        }

      }
    }

    .printTable {
      padding-top: 5px;

      table {
        width: 100%;
        margin-bottom: 10px;

        thead {
          tr {
            th {
              background: #F24040;
            }
          }
        }

        th {
          font-size: 0.48rem;
          text-align: center;
        }
      }

      .append {
        width: 100%;
        text-align: right;

        > div {
          width: 100%;
          font-size: 0.48rem;

          span:first-of-type {
            padding-right: 1.5vw;
          }

          span:last-of-type {
            padding-right: 1vw;
            text-align: left;

          }
        }

        .money {
          > span {
            color: rgb(242, 64, 64);
            font-weight: bold
          }
        }
      }
    }

    .notice {
      width: 100%;
      font-size: 0.3rem;
    }
  }
}

</style>
