<!-- 教學文章清單 -->
<template>
  <section class="BlogList">
    <page-top :title="$t('AppGuideline.AppGuidelineCategory')">
      <span style="font-size:26px;" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-yellow"
            :perm="15894510954930"
            @click="addBolg"
        ></KtButton>
      </section>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          style="width:100%;margin-top:20px;"
          :row-style="{'max-height':'80px'}"
          :header-cell-style="{'text-align':'center',}"
          :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="category_name" :label="$t('shop-index.6449D6@product_category')"></el-table-column>
        <el-table-column prop="title" :label="$t('backend_config_Advertisement.88A5B8@ad_title')"></el-table-column>
        <el-table-column prop="cover_img" :label="$t('shop_cart.E65DF9@goods_img')">
          <template slot-scope="scope">
            <img :src="scope.row.cover_img" alt style="max-height:80px;object-fit:cover"/>
          </template>
        </el-table-column>
        <el-table-column prop="author" :label="$t('aboutus.0176C5@Author')"></el-table-column>
        <el-table-column prop="status" :label="$t('aboutus.07C2A1@Whether_to_publish')">
          <template slot-scope="scope">
            <div v-if="scope.row.status==1">{{ $t('aboutus.980E0D@yes') }}</div>
            <div v-else>{{ $t('aboutus.A03514@no') }}</div>
          </template>
        </el-table-column>
        <el-table-column
            prop="order"
            :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')"
        ></el-table-column>
        <el-table-column
            prop="created_at"
            :label="$t('public.D17BCA@created_time')"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="220" fixed="right">
          <template slot-scope="scope">
            <KtButton
                type="info"
                :label="$t('public.B6D57F@btn_modify')"
                plain
                :perm="15894511249644"
                @click="modify(scope.row)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                plain
                :perm="15894511672397"
                @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section style="margin-top:20px;display:flex;justify-content:flex-end;">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <el-dialog width="70%" :visible.sync="dialogVisible" top="3vh">
      <el-form :model="form" ref="form" label-width="100px">
        <el-form-item :label="$t('shop-index.6449D6@product_category')" prop="category_id">
          <el-select v-model="form.category_id" placeholder>
            <el-option
                v-for="item in CategoryOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('supplier-produceManage-publishProduct.EE6D61@Sort')" prop="order">
          <!-- <el-select v-model="form.level" placeholder> -->
          <!-- <el-option :label="$t('aboutus.43A2F7@Level_one')" :value="1"></el-option>
            <el-option :label="$t('aboutus.EE1777@Level_2')" :value="2"></el-option>
          <el-option :label="$t('aboutus.F86F61@Level_3')" :value="3"></el-option>-->
          <!-- </el-select> -->
          <el-input type="number" v-model.number="form.order"></el-input>
        </el-form-item>
        <!-- <el-form-item
          :label="$t('supplier-produceManage-publishProduct.36F75F@name_en')"
          prop="title_en"
        >
          <el-input v-model="form.title_en"></el-input>
        </el-form-item>-->
        <el-form-item
            :label="$t('supplier-produceManage-publishProduct.BD6C55@Name_hk')"
            prop="title_hk"
        >
          <el-input v-model="form.title_hk"></el-input>
        </el-form-item>
        <el-form-item :label="$t('shop_cart.E65DF9@goods_img')" prop="cover_img">
          <el-upload
              ref="upload"
              class="avatar-uploader"
              :show-file-list="false"
              action="no"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadFile"
          >
            <img v-if="form.cover_img" :src="form.cover_img" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('aboutus.0176C5@Author')" prop="author">
          <el-input v-model="form.author"></el-input>
        </el-form-item>
        <el-form-item :label="$t('aboutus.BCCCBD@Content_Chinese')" prop="content_hk">
          <!-- 工具栏容器 -->
          <ckeditor
              :editor="editor"
              v-model="form.content_hk"
              @ready="onReady"
              :config="editorConfig"
          ></ckeditor>
        </el-form-item>
        <el-form-item :label="$t('index.B6DB10@release_time')" prop="pubdate">
          <el-date-picker v-model="form.pubdate" type="date"></el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('aboutus.07C2A1@Whether_to_publish')" prop="status">
          <el-switch
              v-model="form.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('Contact.515150@Home_page_display')" prop="stick">
          <el-switch
              v-model="form.stick"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{ $t('public.836FD4@cancel') }}</el-button>
        <el-button size="small" type="primary" @click="confirmDialog">
          {{ $t('public.3B45A2@confirm') }}
        </el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import diyCkeditor from "diyCkeditor/build/ckeditor.js";
import "@ckeditor/ckeditor5-build-classic/build/translations/zh.js";
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "",
  components: {pageTop, KtButton},
  data() {
    //这里存放数据
    return {
      form: {
        category_id: "",
        title_en: "",
        title_hk: "",
        cover_img: "",
        author: "",
        content_en: "",
        content_hk: "",
        status: "",
        order: "",
        stick: "",
        pubdate: ""
      },
      CategoryOptions: [],
      blog_id: "",
      dialogVisible: false,
      tableData: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      modifyIndex: false,
      editor: diyCkeditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "Highlight",
            "Underline",
            "FontFamily",
            "FontSize",
            "fontcolor",
            "fontbackgroundcolor",
            "codeblock",
            "SelectAll",
            "|",
            "blockQuote",
            "horizontalLine",
            "bulletedList",
            "numberedList",
            "alignment",
            "indent",
            "outdent",
            "|",
            "link",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "imageUpload",
            "mediaEmbed",
            "insertTable",
            "|",
            "Clipboard",
            "Enter",
            "ShiftEnter",
            "Typing",
            "undo", //撤销
            "redo" //重做
          ]
        },
        mediaEmbed: {
          previewsInData: true,
        },
        fontSize: {
          options: [12, 14, 16, "default", 20, 22, 24]
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative"
          ],
          styles: ["full", "side", "alignCenter", "alignLeft", "alignRight"]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        },
        language: "zh"
      }
    }
  },
  //方法集合
  methods: {
    onReady(editor) {
      let that = this;
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return {
          upload: async function () {
            return await loader.file.then(f => {
              const F = new FileReader();
              F.readAsDataURL(f);
              return new Promise(resolve => {
                F.onload = async function () {
                  const res = await that.$http.uploadAppGuidelineImg({
                    blog_content_img: F.result
                  })
                  resolve({default: res.data.content_img_url})
                }
              })
            })
          }
        }
      }
    },
    getAppGuidelineList() {
      this.$http.getAppGuidelineList({
        size: this.pageSize,
        page: this.currentPage
      }).then(res => {
        if (res.errno == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      })
    },
    //   新增
    addBolg() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      this.modifyIndex = false;
    },
    //   弹框确认
    confirmDialog() {
      if (this.modifyIndex) {
        // 修改
        let params = {blog_id: this.blog_id, ...this.form}
        params.pubdate = Date.parse(this.form.pubdate) / 1000
        this.$http.editAppGuideline(params).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineList()
            this.dialogVisible = false
          }
        })
      } else {
        //新增
        let params = this.form
        params.pubdate = Date.parse(this.form.pubdate) / 1000
        this.$http.addAppGuideline(params).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineList()
            this.dialogVisible = false
          }
        })
      }
    },
    // 修改
    modify(row) {
      this.blog_id = row.blog_id
      this.modifyIndex = true
      this.dialogVisible = true
      this.$http.showAppGuideline({blog_id: row.blog_id}).then(res => {
        this.form = res.data
        if (res.data.pubdate == 0) {
          this.form.pubdate = ""
        }
      })
    },
    // 删除
    deleteUser(row) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delAppGuideline({blog_id: row.blog_id}).then(res => {
          if (res.errno == 0) {
            this.getAppGuidelineList()
          }
        })
      })
    },
    // 分类选择器
    getAppGuidelineCategoryList() {
      this.$http.getAppGuidelineCategoryList().then(res => {
        if (res.errno == 0) {
          this.CategoryOptions = res.data
        }
      })
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".")
      let type = fileNameArr[fileNameArr.length - 1].toLowerCase()

      const isJPG = Boolean(type == "jpg" || type == "jpeg" || type == "png")
      const isLt2M = Boolean(file.size / 1024 / 1024 < 5)

      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"))
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"))
      }
      return isJPG && isLt2M
    },
    // 自定义上传图片
    uploadFile(param) {
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(param["file"])
      reader.onload = function (event) {
        that.form.cover_img = this.result
      }
      this.dialogVisible = true
    },
    // 富文本传图片
    quillUploadFile(param) {
      let that = this
      let reader = new FileReader()
      reader.readAsDataURL(param["file"])
      reader.onload = function (event) {
        that.$http.uploadAppGuidelineImg({blog_content_img: this.result}).then(res => {
          let quill = that.$refs.myQuillEditor.quill
          // 如果上传成功
          // 获取光标所在位置
          let length = quill.getSelection().index
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, "image", res.data.content_img_url)
          // 调整光标到最后
          quill.setSelection(length + 1)
        });
      };
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getAppGuidelineList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getAppGuidelineList()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAppGuidelineList()
    this.getAppGuidelineCategoryList()
  },
}
</script>
<style lang='scss' scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .ck-content {
  font-size: 18px;

  p {
    margin-bottom: 0;
    line-height: 2;
  }
}
</style>
