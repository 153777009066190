/*
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-21 10:45:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\api\http.js
 */
import axios from "axios";
import { Message } from "element-ui";
import router from "../Routes";
import md5 from "../utils/md5";
import {
  getlocalStorage,
  setlocalStorage,
  remlocalStorage,
} from "../utils/localStorage";
const devBaseUrl = "https://api.chefstation.com.hk/";
let proBaseUrl = "";
if (window.location.href.indexOf("https://admin.chefstation.com.hk/") > -1) {
  proBaseUrl = "https://api.chefstation.com.hk/";
} else {
  proBaseUrl = "https://api.chefstation.com.hk/";
}
let baseURL;
console.log(process.env.NODE_ENV);
process.env.NODE_ENV === "development"
  ? (baseURL = devBaseUrl)
  : (baseURL = proBaseUrl);

export default function $axios(options) {
  return new Promise((resolve, reject) => {
    const service = axios.create({
      baseURL: baseURL,
      timeout: 100000,
    });
    let token;
    let lang;
    let route_id;
    let btn_id;
    let auth_id_json;
    let BackstageUserInfo;
    // request 拦截器
    service.interceptors.request.use(
      (config) => {
        token = getlocalStorage("backstageToken");
        lang = getlocalStorage("lang") || "hk";
        route_id = getlocalStorage("backstageRoute_id");
        btn_id = getlocalStorage("backstageBtn_id");
        auth_id_json = getlocalStorage("backstageAuth_id_json");
        BackstageUserInfo = getlocalStorage("BackstageUserInfo");

        config.headers.lang = lang;
        function sortNumber(a, b) {
          return a - b;
        }
        // token
        if (token && route_id && btn_id && auth_id_json && BackstageUserInfo) {
          config.headers.token = token;
          config.headers.authmd5 = md5(
            JSON.stringify(JSON.parse(route_id).sort(sortNumber)) +
              JSON.stringify(JSON.parse(btn_id).sort(sortNumber)) +
              JSON.stringify(JSON.parse(auth_id_json).sort(sortNumber))
          );
        } else {
          // 重定向到登录页面
          router.push("/login");
        }
        return config;
      },

      (error) => {
        // 请求错误时
        // console.log('request:', error)
        // 1. 判断请求超时

        if (
          error.code === "ECONNABORTED" &&
          error.message.indexOf("timeout") !== -1
        ) {
          // console.log('timeout请求超时')
          Loading.service(options).close();
          // return service.request(originalRequest);// 再重复请求一次
        }
        // 2. 需要重定向到错误页面
        // const errorInfo = error.response
        // console.log(errorInfo)
        // if (errorInfo) {
        //   error = errorInfo.data  // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
        //   const errorStatus = errorInfo.status; // 404 403 500 ...
        //   router.push({
        //     path: `/error/${errorStatus}`
        //   })
        // }
        return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    );

    // response 拦截器
    service.interceptors.response.use(
      (response) => {
        // console.log(response);
        if (response.data.errno == 0) {
        }
        let data;
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (response.data == undefined) {
          data = JSON.parse(response.request.responseText);
        } else {
          data = response.data;
        }
        // 根据返回的code值来做不同的处理
        // switch (data.rc) {
        //   case 1:
        //     console.log(data.desc)
        //     break;
        //   case 0:
        //     store.commit('changeState')
        //     // console.log('登录成功')
        //   default:
        // }
        // 若不是正确的返回code，且已经登录，就抛出错误
        // const err = new Error(data.desc)
        // err.data = data
        // err.response = response
        // throw err

        // Loading.service(options).close()
        if (data.errno >= 2000 && data.errno <= 3004) {
          Message.warning(`${data.msg}[errno: ${data.errno}]`);
          if (data.errno == 2122) {
            localStorage.clear();
            router.push("/login");
          }
        }
        if (data.errno >= 1000 && data.errno < 2000) {
          Message.warning({
            message:
              lang == "en"
                ? `Network error, please try again later![errno: ${data.errno}]`
                : lang == "cn"
                ? `$网络错误，请稍后再试[errno: ${data.errno}]  `
                : `網絡錯誤請稍後重試！[errno: ${data.errno}]`,
          });
        }
        if (!data.data && data.errno == 0) {
          const arr = ["/userChangeLang"];
          let falg = true;

          arr.forEach((v, i) => {
            if (
              v ==
              response.config.url.substring(
                response.config.url.indexOf("v1/") + 2,
                response.config.url.length
              )
            ) {
              falg = false;
            }
          });
          if (falg) {
            Message.success({
              message: lang == "en" ? "Operation is successful！" : "操作成功",
            });
          }
        }
        return data;
      },
      (err) => {
        if (
          String(err).indexOf("Network Error") > 0 ||
          String(err).indexOf("timeout") > 0 ||
          err.response.status == 400 ||
          err.response.status == 404 ||
          err.response.status == 500
        ) {
          Message.warning({
            message:
              lang == "en"
                ? "Network error, please try again later!"
                : lang == "cn"
                ? "网络错误，请稍后再试"
                : "網絡錯誤請稍後重試!",
          });
        }

        return Promise.reject(err); // 返回接口返回的错误信息
      }
    );

    // 请求处理
    service(options)
      .then((res) => {
        resolve(res);
        return false;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 403) {
          setlocalStorage("backstageToken", error.response.headers.token);
          service(options).then((res) => {
            resolve(res);
          });
          return false;
        } else if (error.response.status == 401) {
          localStorage.clear();
          Message.warning({
            message:
              lang == "en"
                ? "Long time no operation, please login again!"
                : "長時間未操作，請重新登陸！",
          });
          router.push("/login");
        }
        reject(error);
      });
  });
}
