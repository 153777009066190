<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-01 15:40:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\pages\Authority\Authority.vue
 -->
<template>
  <div class="child-user">
    <page-top :title="$t('backend_submenu.4A302E@authrityList')">
      <span style="font-size:26px;" class="fa fa-cogs"></span>
    </page-top>

    <el-tabs type="border-card" @tab-click="handleClick">
      <el-tab-pane :label="$t('backend_authrity_authrityList.A7F8C9@backend_tab')">
        <section style="display:flex;align-items: center;justify-content:flex-end;">
          <!-- <span style="margin-right:10px" v-if="backendNum!=5&&backendNum!=0"></span> -->
          <kt-button
            class="table-btn btn-yellow"
            :label="backendText"
            :perm="651"
            :disabled="backendBtn"
            @click="Refresh('backend')"
          ></kt-button>
          <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="651"
            @click="add"
          ></kt-button>
        </section>
        <el-table
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :data="tableTreeData"
          style="width: 100%;margin-top:20px;"
          rowKey="id"
        >
          <el-table-column type="index" align="center"></el-table-column>
          <el-table-column
            prop="id"
            align="center"
            :label="$t('backend_authrity_authrityList.B2EBC3@authrity_id')"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="name_hk"
            align="center"
            :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"
          ></el-table-column>
          <el-table-column
            prop="route"
            align="center"
            :label="$t('backend_authrity_authrityList.D22040@router')"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="api"
            align="center"
            :label="$t('backend_authrity_authrityList.468002@api')"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="type"
            align="center"
            :label="$t('backend_authrity_authrityList.12349D@type')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.type == 'btn'?'success':scope.row.type == 'api'?'warning':scope.row.type == 'route'?'primary':scope.row.type == 'sub_menu'?'info':'danger'"
                plain
              >{{scope.row.type}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            :label="$t('public.03EA09@operation')"
            width="200"
          >
            <template slot-scope="scope">
              <kt-button
                type="info"
                :perm="737"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
              ></kt-button>
              <kt-button
                :perm="737"
                @click="deleteRow(tableTreeData,scope.row.id)"
                type="danger"
                size="mini"
                :label="$t('public.1FBB5E@btn_delete')"
                plain
              ></kt-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('backend_authrity_authrityList.C90CD7@restaurant_tab')">
        <section style="display:flex;align-items: center;justify-content:flex-end;">
          <kt-button
            class="table-btn btn-yellow"
            :label="resText"
            :perm="651"
            :disabled="resBtn"
            @click="Refresh('res')"
          ></kt-button>
          <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="651"
            @click="add"
          ></kt-button>
        </section>
        <el-table
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :data="tableTreeData"
          style="width: 100%;margin-top:20px;"
          rowKey="id"
        >
          <el-table-column type="index" align="center"></el-table-column>
          <el-table-column
            prop="id"
            align="center"
            :label="$t('backend_authrity_authrityList.B2EBC3@authrity_id')"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="name_hk"
            align="center"
            :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"
          ></el-table-column>
          <el-table-column
            prop="route"
            align="center"
            :label="$t('backend_authrity_authrityList.D22040@router')"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="api"
            align="center"
            :label="$t('backend_authrity_authrityList.468002@api')"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="type"
            align="center"
            :label="$t('backend_authrity_authrityList.12349D@type')"
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.type == 'btn'?'success':scope.row.type == 'api'?'warning':scope.row.type == 'route'?'primary':scope.row.type == 'sub_menu'?'info':'danger'"
                plain
              >{{scope.row.type}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            :label="$t('public.03EA09@operation')"
            width="200"
          >
            <template slot-scope="scope">
              <kt-button
                type="info"
                :perm="737"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
              ></kt-button>
              <kt-button
                :perm="737"
                @click="deleteRow(tableTreeData,scope.row.id)"
                type="danger"
                size="mini"
                :label="$t('public.1FBB5E@btn_delete')"
                plain
              ></kt-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="$t('backend_authrity_authrityList.85EDE9@supplier_tab')">
        <section style="display:flex;align-items: center;justify-content:flex-end;">
          <kt-button
            class="table-btn btn-yellow"
            :label="supplierText"
            :perm="651"
            :disabled="supplierBtn"
            @click="Refresh('supplier')"
          ></kt-button>
          <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="651"
            @click="add"
          ></kt-button>
        </section>
        <el-table
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :data="tableTreeData"
          style="width: 100%;margin-top:20px;"
          rowKey="id"
        >
          <el-table-column type="index" align="center"></el-table-column>
          <el-table-column
            prop="id"
            align="center"
            :label="$t('backend_authrity_authrityList.B2EBC3@authrity_id')"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="name_hk"
            align="center"
            :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"
          ></el-table-column>
          <el-table-column
            prop="route"
            align="center"
            :label="$t('backend_authrity_authrityList.D22040@router')"
          ></el-table-column>
          <el-table-column
            prop="api"
            align="center"
            :label="$t('backend_authrity_authrityList.468002@api')"
          ></el-table-column>
          <el-table-column
            prop="type"
            align="center"
            :label="$t('backend_authrity_authrityList.12349D@type')"
          >
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.type == 'btn'?'success':scope.row.type == 'api'?'warning':scope.row.type == 'route'?'primary':scope.row.type == 'sub_menu'?'info':'danger'"
                plain
              >{{scope.row.type}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            :label="$t('public.03EA09@operation')"
            width="200"
          >
            <template slot-scope="scope">
              <kt-button
                type="info"
                :perm="737"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
              ></kt-button>
              <kt-button
                :perm="737"
                @click="deleteRow(tableTreeData,scope.row.id)"
                type="danger"
                size="mini"
                :label="$t('public.1FBB5E@btn_delete')"
                plain
              ></kt-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="$t('public.549A91@edit')" width="50%" :visible.sync="dialogVisible">
      <el-form :model="dialogForm" :rules="rules" ref="dialogForm" label-width="150px">
        <el-row>
          <el-col :span="22">
            <el-form-item :label="$t('backend_authrity_authrityList.B2EBC3@authrity_id')" prop="id">
              <span>{{dialogForm.id}}</span>
            </el-form-item>
            <el-form-item
              :label="$t('backend_authrity_authrityList.0310FF@Superior')"
              prop="parentName"
            >
              <popup-tree-input
                :data="popupTreeData"
                :props="popupTreeProps"
                :prop="dialogForm.parentName==null?'顶级菜单':dialogForm.parentName"
                :nodeKey="''+dialogForm.parentId"
                :currentChangeHandle="handleTreeSelectChange"
              ></popup-tree-input>
            </el-form-item>
            <el-form-item :label="$t('backend_authrity_authrityList.12349D@type')" prop="type">
              <el-select
                v-model="dialogForm.type"
                style="width:100%"
                :placeholder="$t('placeholder.F0DDC0@select')"
              >
                <el-option label="顶级菜单" value="顶级"></el-option>
                <el-option label="btn" value="btn"></el-option>
                <el-option label="sub_menu" value="sub_menu"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item
              :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"
              prop="name_hk"
            >
              <el-input v-model="dialogForm.name_cn"></el-input>
            </el-form-item>-->
            <el-form-item
              :label="$t('backend_authrity_authrityList.A75174@Traditional_Chinese')"
              prop="name_hk"
            >
              <el-input v-model="dialogForm.name_hk"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('backend_authrity_authrityList.B25958@english')"
              prop="name_en"
            >
              <el-input v-model="dialogForm.name_en"></el-input>
            </el-form-item>

            <el-form-item :label="$t('backend_authrity_authrityList.D22040@router')">
              <el-input v-model="dialogForm.route"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_authrity_authrityList.468002@api')">
              <el-input v-if="addModify" v-model="otherApi" @blur="keyWords(otherApi)"></el-input>
              <el-input v-else v-model="dialogForm.api" @blur="keyWords(dialogForm.api)"></el-input>
            </el-form-item>
            <el-form-item>
              <el-tag
                style="margin-right:10px;"
                v-for="tag in tags"
                :key="tag"
                closable
                @close="handleClose(tag)"
              >{{ tag}}</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitForm()">{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/index.js";
import pageTop from "../../components/PageTop/PageTop";
import PopupTreeInput from "@/components/PopupTreeInput/PopupTreeInput.vue";
import ktButton from "../../components/ktButton/KtButton";
import { format } from "@/utils/dateTime.js";
export default {
  components: {
    pageTop,
    PopupTreeInput,
    format,
    ktButton
  },
  data() {
    return {
      loading: false,
      loading: true,
      tableTreeData: [],
      dialogVisible: false,
      dialogForm: {},
      otherApi: "",
      popupTreeData: [],
      popupTreeProps: {
        label: "name_hk",
        children: "children"
      },
      menuData: [],
      menuSelections: [],
      menuLoading: false,
      currentRoleMenus: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      addModify: undefined,
      authType: 1,
      project: "admin",
      tags: [],
      backendNum: "",
      backendBtn: false,
      resNum: "",
      resBtn: false,
      supplierNum: "",
      supplierBtn: false,
      rules: {
        parentName: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        type: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  created() {
    //获取本地缓存
    // this.getLocal();

    this.getParentMenuTree();
    this.getTableData();
  },
  computed: {
    backendText() {
      let num = +this.backendNum == 0 ? "" : "(" + this.backendNum + ")";
      return `${this.$t("aboutus.273505@Refresh_permissions_manually")} ${num}`;
    },
    resText() {
      let num = +this.resNum == 0 ? "" : "(" + this.resNum + ")";
      return `${this.$t("aboutus.273505@Refresh_permissions_manually")} ${num}`;
    },
    supplierText() {
      let num = +this.supplierNum == 0 ? "" : "(" + this.supplierNum + ")";
      return `${this.$t("aboutus.273505@Refresh_permissions_manually")} ${num}`;
    }
  },

  methods: {
    // 请求
    getTableData() {
      api
        .getAuth({
          project: this.project
        })
        .then(res => {
          this.loading = false;
          this.tableTreeData = res.data;
          this.$setlocalStorage("tableData", JSON.stringify(res.data));
          this.loading = false;
        });
    },
    getId() {
      return +(
        parseInt(new Date().getTime() / 1000) +
        "" +
        Math.round(Math.random() * (9999 - 1000) + 1000)
      );
    },
    // 获取表格数据
    getData() {
      let tableTreeData = JSON.parse(this.$getlocalStorage("tableData"));
      this.popupTreeData = this.getParentMenuTree(tableTreeData);
    },
    async Refresh(val) {
      if (val == "backend") {
        this.backendNum = 5;
        this.backendBtn = !this.backendBtn;
        api.refreshAuth({ project: "admin" });
        let time = setInterval(() => {
          this.backendNum--;
        }, 1000);
        setTimeout(() => {
          this.backendBtn = !this.backendBtn;
          clearInterval(time);
        }, 5000);
      } else if (val == "res") {
        this.resNum = 5;
        this.resBtn = !this.resBtn;
        api.refreshAuth({ project: "restaurant" });
        let time = setInterval(() => {
          this.resNum--;
        }, 1000);
        setTimeout(() => {
          this.resBtn = !this.resBtn;
          clearInterval(time);
        }, 5000);
      } else {
        this.supplierNum = 5;
        this.supplierBtn = !this.supplierBtn;
        api.refreshAuth({ project: "supplier" });
        let time = setInterval(() => {
          this.supplierNum--;
        }, 1000);
        setTimeout(() => {
          this.supplierBtn = !this.supplierBtn;
          clearInterval(time);
        }, 5000);
      }
    },
    // 弹框上级机构
    getParentMenuTree: function(data) {
      let parent = {
        parentId: 0, //id设为0，标注是最高层的的节点
        name_hk: "权限管理",
        children: data
      };
      return [parent];
    },

    // 用弹框新增行
    add() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["dialogForm"].clearValidate();
      });
      this.addModify = 0;
      this.tags = [];
      (this.dialogForm = {
        id: this.getId(),
        name_cn: "",
        name_en: "",
        name_hk: "",
        route: "",
        api: "",
        parentId: 0,
        parentName: "",
        children: []
      }),
        this.getData();
    },

    keyWords(data) {
      //api失去焦點時触发，将值push进tags
      this.otherApi = "";
      if (data) {
        this.tags.push(data);
      }
      console.log(this.tags); //数组是由值的字符串组成的数组
    },

    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
    },

    // 机构树选中
    handleTreeSelectChange(data, node) {
      this.dialogForm.parentId = data.id;
      this.dialogForm.parentName = data.name_hk;
    },

    modify(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["dialogForm"].clearValidate();
      });
      this.addModify = 1;
      this.row = row;

      // 将api 赋给tags，并清空api
      if (row.api) {
        this.tags = row.api;
      }
      this.dialogForm = row;
      // this.dialogForm.api = '';
      this.getData();
    },

    submitForm() {
      this.$refs["dialogForm"].validate(valid => {
        if (valid) {
          if (this.addModify) {
            this.dialogForm.api = this.tags; //将数组的api赋给form
            Object.assign(this.row, this.dialogForm);
            console.log("tableTreeData==>", this.tableTreeData);
          } else {
            // 新增
            this.dialogForm.api = this.tags; //将数组的api赋给form
            if (!this.dialogForm.parentId) {
              this.tableTreeData.push(this.dialogForm);
            } else {
              let tableData = this.tableTreeData;
              this.recursion(tableData);
            }
          }
          let tableData = JSON.stringify(this.tableTreeData);
          this.$setlocalStorage("tableData", tableData);
          this.fix();
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },

    // 新增递归
    recursion(data) {
      data.forEach(v => {
        if (v.id == this.dialogForm.parentId) {
          // 将api 的数组形式变为字符串
          v.children.push(this.dialogForm);
        }
        if (v.children) {
          this.recursion(v.children);
        }
      });
    },

    // 时间格式化
    dateFormat: function(row, column, cellValue, index) {
      return format(row[column.property]);
    },
    fix() {
      api
        .modifyAuth({
          auth_json: this.$getlocalStorage("tableData"),
          project: this.project
        })
        .then(res => {
          console.log(res);
        });
    },

    deleteRow(data, id) {
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        let newData = this.filter(data, id);
        let newJson = JSON.stringify(newData);
        this.$setlocalStorage("tableData", newJson);
        this.fix();
      });
    },
    filter(arr, id) {
      for (var i = 0; i < arr.length; i++) {
        var el = arr[i];
        if (el.id === id) {
          arr.splice(i, 1);
        } else {
          if (el.children && el.children.length) {
            this.filter(el.children, id);
          }
        }
      }
      return arr;
    },

    handleClick(tab) {
      this.loading = true;
      this.project =
        tab.index == "0"
          ? "admin"
          : tab.index == "1"
          ? "restaurant"
          : "supplier";
      this.getTableData();
    }
  }
};
</script>

<style>
</style>