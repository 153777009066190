<template>
  <div class="favorite-container">
    <page-top :title="$t('pos.DD6478@title')"></page-top>
    <el-card>
      <section class="flex-end">
        <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="16185743701910"
            @click="showDialog(0)">
        </kt-button>
      </section>
      <el-table
          border
          v-loading="loading"
          :data="posList"
          style="width: 100%;margin-top:20px;">
        <!--     公司名稱   -->
        <el-table-column
            min-width="200"
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')"
            align="center"
            show-overflow-tooltip>
        </el-table-column>
        <!--     POS   -->
        <el-table-column
            min-width="150"
            prop="pos_name"
            label="POS"
            align="center"
            show-overflow-tooltip>
        </el-table-column>
        <!--     狀態   -->
        <el-table-column
            min-width="100"
            prop="status"
            :label="$t('backend_authrity_user.07D89C@status')"
            align="center"
            show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status" style="color: #13ce66;font-weight: bold">
              {{ $t('status.861FAE@enable') }}
            </span>
            <span v-else style="color: #ff4949;font-weight: bold">
              {{ $t('status.EA56D8@disable') }}
            </span>
          </template>
        </el-table-column>
        <!--     修改按鈕   -->
        <el-table-column
            min-width="200"
            prop="operation"
            align="center"
            show-overflow-tooltip>
          <template slot-scope="scope">
            <kt-button type="danger"
                       :label="$t('public.1FBB5E@btn_delete')"
                       :perm="16185744354161"
                       @click="delPos(scope.row.id)">
            </kt-button>
            <kt-button
                type="primary"
                :label="$t('public.B6D57F@btn_modify')"
                :perm="16187606687178"
                @click="modifyPos(scope.row.id)">
            </kt-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10,25,50,100]"
            :page-size="size"
            @current-change="handleCurrentChange"
            :current-page="page"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog :visible.sync="show" :title="modify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
               width="50%" center>
      <el-form :model="form" ref="form" label-width="150px">
        <el-row>
          <el-col :span="20" :offset="2">
            <!--    餐廳        -->
            <el-form-item :label="$t('Contact.F7D2F0@Restaurant')">
              <el-select v-model="form.cid" style="width:80%"
                         :placeholder="$t('aboutus.033E98@Please_Select_Restaurant')"
                         filterable remote automatic-dropdown @change="cidChange">
                <el-option v-for="item in restaurantList"
                           :key="item.cid" :label="item.company_name" :value="item.cid">
                </el-option>
              </el-select>
            </el-form-item>
            <!--      選用POS      -->
            <el-form-item label="POS">
              <el-select v-model="form.type" filterable clearable>
                <el-option v-for="item in supportPosList" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <!--      狀態      -->
            <el-form-item :label="$t('backend_authrity_user.07D89C@status')">
              <el-switch v-model="form.status"
                         :active-text="$t('status.861FAE@enable')" active-color="#13ce66" :active-value="1"
                         :inactive-text="$t('status.EA56D8@disable')" inactive-color="#ff4949" :inactive-value="0">
              </el-switch>
            </el-form-item>
            <!--      businessId      -->
            <el-form-item label="Business ID">
              <el-input v-model="form.businessId" type="text" style="width: 80%">
              </el-input>
            </el-form-item>
            <!--      Branch ID      -->
            <el-form-item label="Branch ID">
              <el-input v-model="form.branchId" type="text" style="width: 80%">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button type="primary" size="small" @click="addOrModify">
          {{ $t('public.3B45A2@confirm') }}
        </el-button>
        <el-button type="danger" @click="show=false" size="small">
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import KtButton from "components/ktButton/KtButton";
import pageTop from "../../components/PageTop/PageTop"

export default {
  name: "",
  components: {KtButton, pageTop},
  data() {
    return {
      loading: true,
      //當前支援的POS清單
      supportPosList: [],
      restaurantList: [],
      posList: [],
      searchText: "",
      total: 0,
      size: 10,
      page: 1,
      //0=add,1=modify
      modify: 0,
      show: false,
      form: {
        id: "",
        type: "",
        cid: "",
        status: 1,
        businessId: "",
        branchId: "",
      },
    }
  },
  methods: {
    //更新 Pos
    modifyPos(id) {
      this.$http.getPosInfo({
        id: id
      }).then(res => {
        if (res) {
          this.form.cid = res.cid
          this.form.status = res.status
          this.form.type = res.pos_type
          this.form.id = id
          this.form.businessId = res.businessId
          this.form.branchId = res.branchId
          this.modify = 1
          this.show = true
        }
      })
    },
    //餐廳改變時
    cidChange() {
      this.$http.getPosInfo({
        cid: this.form.cid
      }).then(res => {
        if (res) {
          this.modify = 1
          this.form.status = res.status
          this.form.type = res.pos_type
          this.form.businessId = res.businessId
          this.form.branchId = res.branchId
        }
      })
    },
    //刪除POS
    delPos(id) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delPos({
          id: id
        }).then(res => {
          if (res.errno == 0) {
            this.getData()
          }
        })
      })
    },
    //新增或修改
    addOrModify() {
      if (this.modify) {
        this.$http.modifyPos({
          id: this.form.id,
          type: this.form.type,
          status: this.form.status,
          cid: this.form.cid,
          businessId: this.form.businessId,
          branchId: this.form.branchId,
        }).then(res => {
          if (res.errno == 0) {
            this.cleanData()
            this.getData()
          }
        })
      } else {
        this.addPos()
      }
    },
    //新增POS
    addPos() {
      this.$http.addPos({
        cid: this.form.cid,
        type: this.form.type,
        status: this.form.status,
        businessId: this.form.businessId,
        branchId: this.form.branchId,
      }).then(res => {
        if (res.errno == 0) {
          this.cleanData()
          this.getData()
        }
      })
    },
    //取得支援POS List
    getPosNameList() {
      this.$http.getPosNameList().then(res => {
        this.supportPosList = res.data
      })
    },
    //取得餐廳List
    getRestaurantList() {
      this.$http.getAllRestaurantList().then(res => {
        this.restaurantList = res.data
      })
    },
    //取得PosList
    getPosList() {
      this.$http.getPosList({
        page: this.page,
        size: this.size,
      }).then(res => {
        this.total = res.data.total
        this.posList = res.data.list
      })
    },
    //show dialog
    showDialog(status) {
      this.modify = status
      this.show = true
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    getData() {
      this.getRestaurantList()
      this.getPosNameList()
      this.getPosList()
      this.loading = false
    },
    cleanData() {
      this.show = false
      this.modify = 0
      this.form.type = ""
      this.form.cid = ""
      this.form.status = 1
      this.form.id = ""
      this.form.businessId = ""
      this.form.branchId = ""
    },
    handleSizeChange(val) {
      this.size = val

    },
    handleCurrentChange(val) {
      this.page = val
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>
