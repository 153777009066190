<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-15 16:54:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\components\Layout\Layout.vue
 -->
<template>
  <div :class="{ root: true, chatOpen, sidebarClose, sidebarStatic }">
    <Sidebar :userInfo="userInfo" />
    <div class="wrap" style="height:100%;">
      <Header :userInfo="userInfo" v-on:modifyPass="modifyPass" />
      <el-scrollbar style="width:100%;height:calc(100% - 50px);">
        <v-touch class="content" :swipe-options="{ direction: 'horizontal', threshold: 100 }">
          <div name="move" mode="out-in">
            <router-view />
          </div>
        </v-touch>
      </el-scrollbar>
    </div>
    <el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="50%" center>
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form ref="form" :model="dialogForm" label-width="80px">
            <el-form-item label="原密码" prop="password">
              <el-input type="password" v-model="dialogForm.password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
              <el-input type="password" v-model="dialogForm.new_password"></el-input>
            </el-form-item>
            <el-form-item label="重复密码" prop="repeat_password">
              <el-input type="password" v-model="dialogForm.repeat_password"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="confirmPass">确 定</el-button>
        <el-button size="mini" @click="centerDialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";
import api from "../../api/index";
import "./Layout.scss";

export default {
  name: "Layout",
  components: { Sidebar, Header },
  data() {
    return {
      userInfo: "",
      centerDialogVisible: false,
      dialogForm: {
        password: "",
        new_password: "",
        repeat_password: ""
      }
    };
  },
  methods: {
    ...mapActions("layout", ["switchSidebar", "changeSidebarActive"])
  },
  computed: {
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
      chatOpen: state => state.chatOpen
    })
  },
  created() {
    this.userInfo =
      JSON.parse(this.$getlocalStorage("BackstageUserInfo")) || "";
    if (!this.userInfo) {
      this.$message.warning(this.$t("aboutus.8FD945@please_log_in_again"));
      localStorage.clear();
      this.$router.push("/login");
    }
    const staticSidebar = JSON.parse(this.$getlocalStorage("sidebarStatic"));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      // setTimeout(() => {
      //   this.switchSidebar(true);
      //   this.changeSidebarActive(null);
      // }, 100);
    }
  },
  methods: {
    modifyPass(data) {
      this.centerDialogVisible = data;
    },
    confirmPass() {
      let params = Object.assign({}, this.dialogForm);
      api.modifyPass(params).then(res => {
        if (res.errno == 0) {
          this.$refs["form"].resetFields();
          this.centerDialogVisible = false;
        }
      });
    }
  }
};
</script>

<style src="./Layout.scss" lang="scss" />
