<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-18 11:13:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_backend_UI\src\pages\Authority\user.vue
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.61A3ED@user')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <section style="display:flex;justify-content:flex-end;">
        <kt-button
            class="table-btn btn-yellow"
            :perm="726"
            @click="add"
            :label="$t('public.DC9752@btn_add')"
        ></kt-button>
      </section>
      <el-table
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          :data="tableData"
          style="width: 100%;margin-top:20px;"
      >
        <el-table-column
            prop="uid"
            align="center"
            :label="$t('backend_shopUser_supplierUser.CB120A@user_id')"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="group_name"
            align="center"
            :label="$t('backend_authrity_grouping.5EDDBF@grouping_name')"
        ></el-table-column>
        <el-table-column
            prop="user_name"
            align="center"
            :label="$t('backend_authrity_user.9A4F2F@username')"
        ></el-table-column>
        <el-table-column
            prop="nick_name"
            align="center"
            :label="$t('backend_authrity_user.8319AA@nickname')"
        ></el-table-column>
        <el-table-column
            prop="avatar"
            align="center"
            :label="$t('backend_authrity_user.CA6925@avatar')"
        >
          <template slot-scope="scope">
            <div style="width:50px;height:50px;margin:0 auto;border-radius:50%;overflow:hidden;">
              <img class="img-auto" :src="scope.row.avatar"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="last_login_ip"
            align="center"
            :label="$t('backend_authrity_user.29E7EE@last_ip')"
        ></el-table-column>
        <el-table-column
            show-overflow-tooltip
            prop="last_login_time"
            align="center"
            :label="$t('backend_authrity_user.BAF37C@last_login_time')"
        ></el-table-column>
        <el-table-column
            prop="status"
            align="center"
            :label="$t('backend_authrity_user.07D89C@status')"
        ></el-table-column>
        <el-table-column
            :label="$t('public.03EA09@operation')"
            align="center"
            width="360"
            fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.allow_modify == 1">
              <kt-button
                  :perm="2173"
                  v-if="scope.row.status == 'LOCKED'"
                  :label="$t('public.564C21@unlock')"
                  @click="locked(scope.row,0)"
              ></kt-button>
              <kt-button
                  :perm="2173"
                  v-if="scope.row.status == 'NORMAL'"
                  :label="$t('public.DBC110@locked')"
                  @click="locked(scope.row,1)"
              ></kt-button>
              <kt-button
                  :perm="640"
                  :label="$t('public.72A46B@reset_pass')"
                  @click="resetPass(scope.row)"
              ></kt-button>
              <kt-button
                  type="info"
                  :perm="515"
                  v-if="scope.row.allow_modify"
                  :label="$t('public.B6D57F@btn_modify')"
                  @click="modify(scope.row)"
              ></kt-button>
              <kt-button
                  type="danger"
                  :perm="672"
                  v-if="scope.row.status != 'DELETED'"
                  :label="$t('public.1FBB5E@btn_delete')"
                  @click="deleteUser(scope.row)"
              ></kt-button>
            </div>
            <span v-else>{{ $t('public.25F6B0@tips') }}</span>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5,10,15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <el-dialog
        :title="addModify?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')"
        width="60%"
        :visible.sync="dialogVisible"
    >
      <el-row>
        <el-col :span="20" :offset="2">
          <el-form :model="dialogForm" :rules="rules" ref="form" label-width="120px">
            <el-form-item :label="$t('backend_authrity_user.BBA818@grouping_id')" prop="group_id">
              <el-select
                  v-model="dialogForm.group_id"
                  :placeholder="$t('placeholder.F0DDC0@select')"
              >
                <el-option
                    v-for="item in options"
                    :key="item.group_id"
                    :value="item.group_id"
                    :label="item.group_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('backend_authrity_user.9A4F2F@username')" prop="user_name">
              <el-input
                  v-model="dialogForm.user_name"
                  :disabled="Boolean(addModify)"
                  :placeholder="$t('backend_authrity_user.9A4F2F@username')"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_authrity_user.8319AA@nickname')" prop="nick_name">
              <el-input
                  v-model="dialogForm.nick_name"
                  :placeholder="$t('backend_authrity_user.8319AA@nickname')"
              ></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_authrity_user.948EEB@password')"
                prop="password"
                v-if="!addModify"
            >
              <el-input
                  v-model="dialogForm.password"
                  type="password"
                  :placeholder="$t('backend_authrity_user.948EEB@password')"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{ $t('public.836FD4@cancel') }}</el-button>
        <el-button
            size="small"
            type="primary"
            @click="comfirmDialog"
        >{{ $t('public.3B45A2@confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import api from "../../api/index";
import pageTop from "../../components/PageTop/PageTop";

export default {
  components: {
    pageTop,
    ktButton
  },
  filters: {
    isSuper(data) {
      return data ? "是" : "否";
    }
  },
  data() {
    return {
      loading: true,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      dialogForm: {
        group_id: undefined,
        user_name: "",
        nick_name: "",
        password: ""
      },
      options: [],
      addModify: 0,
      dialogVisible: false,
      row: {},
      rules: {
        group_id: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        user_name: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        password: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  created() {
    this.getTableData();
    this.changeSelect();
  },
  methods: {
    getTableData() {
      api.getUser({
        size: this.pageSize,
        page: this.currentPage
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.sum;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    locked(row, type) {
      api.lockUser({
        uid: row.uid,
        type: type
      }).then(res => {
        this.getTableData();
      });
    },
    deleteUser(row) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        api.deleteUser({
          uid: row.uid
        }).then(res => {
          this.getTableData();
        });
      });
    },
    changeSelect() {
      api.dialogSelect().then(res => {
        this.options = res.data;
      });
    },

    add() {
      this.addModify = 0;
      this.dialogVisible = true;
      if (this.dialogForm.user_name) {
        this.$refs["form"].resetFields();
      }
    },
    modify(row) {
      this.addModify = 1;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.row = row;
      api.queryUser({
        uid: row.uid
      }).then(res => {
        this.dialogForm = res.data;
      });
    },
    comfirmDialog() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.addModify) {
            let params = {
              uid: this.dialogForm.uid,
              nick_name: this.dialogForm.nick_name,
              group_id: this.dialogForm.group_id
            };
            api.modifyUser(params).then(res => {
              if (!res.errno) {
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          } else {
            let params = Object.assign({}, this.dialogForm);
            api.addUser(params).then(res => {
              if (!res.errno) {
                this.dialogVisible = false;
                this.getTableData();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    resetPass(row) {
      api.resetPass({
        uid: row.uid
      })
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>