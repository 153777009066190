<template>
  <div class="dashboard-container">
    <page-top :title="$t('backend_submenu.ABAAF2@dashboard')">
      <span style="font-size:26px;padding-bottom:10px;" class="fa fa-dashboard"></span>
    </page-top>
    <ul class="row-box">
      <li class="block block-red">
        <section>
          <i class="glyphicon glyphicon-shopping-cart"></i>
          <div>
            <h5>{{ $t('backend_dashboard.E2556A@total_gmv') }}</h5>
            <h4>{{ data.totalGMV }}</h4>
            <h5>{{ $t('backend_dashboard.E2556B@current_year_gmv') }}</h5>
            <h4>{{ data.yearGMV }}</h4>
            <h5>{{ $t('backend_dashboard.E2556C@current_month_gmv') }}</h5>
            <h4>{{ data.monthGMV }}</h4>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
      <li class="block block-blue">
        <section>
          <i class="glyphicon glyphicon-align-justify"></i>
          <div>
            <h5>{{ $t('backend_dashboard.E2556D@total_order_count') }}</h5>
            <h4>{{ data.totalOrderCount }}</h4>
            <h5>{{ $t('backend_dashboard.E2556E@current_year_order_count') }}</h5>
            <h4>{{ data.yearOrderCount }}</h4>
            <h5>{{ $t('backend_dashboard.E2556F@current_month_order_count') }}</h5>
            <h4>{{ data.monthOrderCount }}</h4>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
      <li class="block block-green">
        <section>
          <i class="glyphicon glyphicon-star-empty"></i>
          <div>
            <h5>{{ $t('backend_dashboard.E2556G@total_order_avg') }}</h5>
            <h4>{{ data.totalOrderAvg }}</h4>
            <h5>{{ $t('backend_dashboard.E2556H@current_year_order_avg') }}</h5>
            <h4>{{ data.yearOrderAvg }}</h4>
            <h5>{{ $t('backend_dashboard.E2556I@current_month_order_avg') }}</h5>
            <h4>{{ data.monthOrderAvg }}</h4>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
      <li class="block block-yellow">
        <section>
          <i class="glyphicon glyphicon-star-empty"></i>
          <div>
            <h5>{{ $t('FunctionUsageRecord.FunctionUsageRecord_content.NumberOfRestaurants') }}</h5>
            <h4>{{ data.restaurantCount}}</h4>
            <h5>{{ $t('backend_dashboard.E2556J@active_restaurant_count') }}</h5>
            <h4>{{ data.activeRestaurantCount}}</h4>
            <h5>{{ $t('backend_dashboard.E2556K@supplier_count') }}</h5>
            <h4>{{ data.supplierCount }}</h4>
            <h5>{{ $t('OrderUser.title') }}</h5>
            <h4>{{ data.orderUser }}</h4>
          </div>
        </section>
        <img class="ball-img" src="./images/bull.png"/>
      </li>
    </ul>

    <!-- test权限button -->
    <!-- <el-row>
      <kt-button :perm="789"></kt-button>
    </el-row>-->
    <!--    <el-card class="echart">-->
    <!--      <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">-->
    <!--        <line-chart :chart-data="lineChartData" />-->
    <!--      </el-row>-->
    <!--    </el-card>-->
    <el-card class="echart">
      <!-- <section>
        <b-button class="table-btn btn-yellow">{{$t('dashboard.btn1')}}</b-button>
        <b-button class="table-btn btn-gray">{{$t('dashboard.btn2')}}</b-button>
      </section>
      <chart :options="options"></chart>-->

      <el-row :gutter="32">
        <el-col :xs="24" :sm="24" :lg="8">
          <div class="chart-wrapper">
            <bar-chart :title="$t('backend_dashboard.A12477@top_ten_sale_good')" :show-x="false"
                       :data="saleGoods" theme="vintage"/>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :lg="8">
          <div class="chart-wrapper">
            <bar-chart :title="$t('backend_dashboard.A12476@top_ten_purchase_restaurant')" :show-x="false"
                       :data="purchaseRestaurant" theme="roma"/>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :lg="8">
          <div class="chart-wrapper">
            <bar-chart :title="$t('backend_dashboard.A12475@top_ten_keywords')" :show-x="false" :data="keywords"/>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import pageTop from "@/components/PageTop/PageTop";
import chart from "@/components/Charts/Charts";

// import ktButton from '../../components/KtButton/KtButton.vue'

import LineChart from "./components/LineChart";
import RaddarChart from "./components/RaddarChart";
import PieChart from "./components/PieChart";
import BarChart from "./components/BarChart";


export default {
  name: "Dashboard",
  components: {
    pageTop,
    chart,
    LineChart,
    RaddarChart,
    PieChart,
    BarChart
  },
  data() {
    return {
      data: {},
      keywords: {},
      purchaseRestaurant: {},
      saleGoods: {},
    };
  },
  methods: {
    top10SaleGood() {
      this.$http.top10SaleGood().then(res => {
        this.saleGoods = res.data
      })
    },
    top10PurchaseRestaurant() {
      this.$http.top10PurchaseRestaurant().then(res => {
        this.purchaseRestaurant = res.data
      })
    },
    top10Keywords() {
      this.$http.top10Keywords().then(res => {
        this.keywords = res.data
      })
    },
    dashboard() {
      this.$http.dashboard().then(res => {
        this.data = res.data
      })
    },
  },
  created() {
    this.top10SaleGood()
    this.top10PurchaseRestaurant()
    this.top10Keywords()
    this.dashboard()
  }
  // data () {
  //   return {
  //     options: {
  //       xAxis: {
  //         type: 'category',
  //         data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  //       },
  //       yAxis: {
  //         type: 'value'
  //       },
  //       series: [{
  //         type: 'line',
  //         data: [820, 932, 901, 934, 1290, 1330, 1320],
  //         itemStyle: {
  //           color: ['#ffc125']
  //         }
  //       }]
  //     }
  //   };
  // },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  .row-box {
    display: flex;

    .block {
      width: calc(33.33% - 26.7px);
      height: 380px;
      border-radius: 10px;
      margin-right: 40px;
      padding: 20px 40px 0;
      box-sizing: border-box;
      position: relative;

      .ball-img {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }

      section {
        color: #fff;

        i {
          display: block;
          margin-left: auto;
          font-size: 21px;
          float: right;
        }

        h5 {
          padding-top: 21px;
          font-size: 20px;
          font-weight: 900;
        }

        span {
          font-size: 36px;
          font-weight: 900;
        }
      }
    }


    .block-red {
      background-image: linear-gradient(-90deg, #ff688a 0%, #ffbb90 100%);
    }

    .block-blue {
      background-image: linear-gradient(-90deg, #0d83e1 0%, #8ec9f8 100%);
    }

    .block-green {
      background-image: linear-gradient(-90deg, #1ed0b4 0%, #83d9d2 100%);
    }

    .block-yellow {
      margin-right: 0;
      background-image: linear-gradient(-90deg, #ffff00 0%, #fdd017 100%);
    }
  }

  .echart {
    margin-top: 40px;
  }
}
</style>
