<!-- restaurant -->
<template>
  <section class="restaurant">
    <section class="justify-between" style="margin-bottom: 50px">
      <section class="flex-items">
        <el-select
            v-model="company_id"
            class="mr10"
            @change="getOrderAverage"
            filterable
            clearable
            :placeholder="$t('AverageOrderPrice.AverageOrderPrice_content.ChooseARestaurant')"
            style="width: 250px">
          <el-option
              v-for="item in restaurantList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-select
            v-model="is_b2b"
            class="mr10"
            @change="getOrderAverage"
            clearable
            style="width: 150px">
          <el-option
              v-for="item in orderList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
        <datetimepicker
            @datetimeChange="datetimeChange"
            @dateTypeSelectChange="dateTypeSelectChange"
            :isYear="true"
        ></datetimepicker>
      </section>
      <el-button
          type="primary"
          class="table-btn btn-gray"
          size="default"
          @click="downloadexcel"
      >{{ $t("aboutus.2AECD0@Export_Order") }}
      </el-button
      >
    </section>
    <line-chart :xAxis="lineX" :chart-data="lineData"></line-chart>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import datetimepicker from "../../../components/datetimepicker";
import LineChart from "../../../components/LineChart";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "restaurant",
  components: {datetimepicker, LineChart},
  data() {
    //这里存放数据
    return {
      type: 0,
      is_b2b: "",
      company_id: "",
      dateTime: "",
      orderList: [
        {
          label: this.$t("AverageOrderPrice.AverageOrderPrice_content.all"),
          value: "",
        },
        {
          label: this.$t("AverageOrderPrice.AverageOrderPrice_content.general"),
          value: 0
        },
        {
          label: 'B2B',
          value: 1
        },
      ],
      restaurantList: [],
      lineX: [],
      lineData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getOrderAverage() {
      this.$http.orderAverage({
        type: this.type || 0,
        project: 2,
        is_b2b: this.is_b2b,
        company_id: this.company_id,
        start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
        end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
      }).then((res) => {
        this.lineX = res.data.date;
        this.lineData = res.data.amount;
      });
    },
    // 获取类型
    dateTypeSelectChange(val) {
      if (val) {
        this.type = val;
      } else {
        this.type = 0;
        this.dateTime = "";
        this.getOrderAverage();
      }
    },
    downloadexcel() {
      this.$http.orderAverageExcel({
        type: this.type || 0,
        project: 2,
        is_b2b: this.is_b2b,
        company_id: this.company_id,
        start_time: this.dateTime ? this.dateTime[0] / 1000 : "",
        end_time: this.dateTime ? this.dateTime[1] / 1000 : "",
      }).then((res) => {
        this.downloadFile(res, "AverageOrderPrice_Restaurant.xlsx")
      })
    },
    // 获取时间
    datetimeChange(val) {
      if (val) {
        this.dateTime = val;
        this.getOrderAverage();
      }
    },
    getRestaurantList() {
      this.$http.restaurantShopSelector().then((res) => {
        this.restaurantList = res.data.filter(s => {
          return s.id != 41 && s.id != 45 && s.id != 47 && s.id != 68 &&
              s.id != 74 && s.id != 423 && s.id != 428
        })
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getOrderAverage();
    this.getRestaurantList();
  },
};
</script>
<style src='./restaurant.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
