<!--
 * @Author: your name
 * @Date: 2019-10-30 16:01:30
 * @LastEditTime: 2019-11-20 16:54:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\pages\Classify\Classify.vue
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('aboutus.6505D3@Plan_Subscription')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-card>
      <section class="form-select">
        <section>
          <kt-button
            class="table-btn btn-yellow"
            :perm="8508"
            :label="$t('public.DC9752@btn_add')"
            @click="add"
          ></kt-button>
        </section>
      </section>
      <el-table
        ref="multipleTable"
        :data="tableData"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%;margin-top:20px;"
        row-key="cid"
        :tree-props="{children: 'sub_category', hasChildren: 'hasChildren'}"
        :row-style="{'height':'80px'}"
      >
        <el-table-column
          prop="plan_name"
          :label="$t('aboutus.ED5B08@Plan_Name')"
          width="130"
          align="left"
          header-align="left"
        ></el-table-column>
        <el-table-column
          prop="shop_num"
          :label="$t('aboutus.B4FA87@Number_of_planned_stores')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="basic_cost"
          :label="$t('aboutus.C4970F@Basic_Cost')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="shop_cost"
          :label="$t('aboutus.A32813@Exceeding_Planned_Store_Costs')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="is_active	"
          :label="$t('aboutus.8F1D59@Shelves')"
          align="center"
          header-align="center"
        >
          <template scope="scope">
            <span
              :style="{
                color: scope.row.is_active== 1 ? '#37B328' : '#F56C6C'
              }"
            >
              {{
              scope.row.is_active == 1
              ? $t('aboutus.980E0D@yes')
              : $t('aboutus.A03514@no')
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('public.D17BCA@created_time')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('public.03EA09@operation')"
          width="250"
          fixed="right"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <kt-button
              :perm="1176"
              :label="$t('public.B6D57F@btn_modify')"
              @click="modify(scope.row.plan_id)"
              type="info"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
      :title="type==1?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')"
      width="35%"
      @close="resetForm"
      :visible.sync="dialogFormVisible"
      top="12vh"
      center
    >
      <el-row>
        <el-col :span="18" :offset="2">
          <el-form ref="dataForm" :rules="rules" :model="dialogForm" label-width="140px">
            <el-form-item prop="plan_name_hk" :label="$t('aboutus.1F998B@Plan_Name_Chinese')">
              <el-input style="width:100%;" v-model="dialogForm.plan_name_hk"></el-input>
            </el-form-item>
            <el-form-item prop="plan_name_en" :label="$t('aboutus.7FF405@Plan_Name_English')">
              <el-input style="width:100%;" v-model="dialogForm.plan_name_en"></el-input>
            </el-form-item>
            <el-form-item prop="shop_num" :label="$t('aboutus.B4FA87@Number_of_planned_stores')">
              <el-input
                style="width:100%;"
                type="number"
                @input.native="$inputLenght"
                v-model="dialogForm.shop_num"
              ></el-input>
            </el-form-item>
            <el-form-item prop="basic_cost" :label="$t('aboutus.C4970F@Basic_Cost')">
              <el-input
                style="width:100%;"
                type="number"
                @input.native="$inputLenght"
                v-model="dialogForm.basic_cost"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="shop_cost"
              :label="$t('aboutus.A32813@Exceeding_Planned_Store_Costs')"
            >
              <el-input
                style="width:100%;"
                type="number"
                @input.native="$inputLenght"
                v-model="dialogForm.shop_cost"
              ></el-input>
            </el-form-item>
            <el-form-item prop="is_active" :label="$t('aboutus.8F1D59@Shelves')">
              <el-switch
                style="line-height: 40px"
                :active-value="1"
                :inactive-value="0"
                v-model="dialogForm.is_active"
                active-color="#13ce66"
              ></el-switch>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <section slot="footer">
        <el-button @click="dialogFormVisible=false" size="small">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button @click="addclassify" type="primary" size="small">{{$t('public.3B45A2@confirm')}}</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      type: "",
      dialogFormVisible: false,
      dialogForm: {
        plan_name_en: "",
        plan_name_hk: "",
        shop_num: "",
        basic_cost: "",
        shop_cost: "",
        is_active: 0
      },
      plan_id: "",
      loading: false,
      rules: {
        plan_name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        plan_name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        shop_num: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        basic_cost: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        shop_cost: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.$http
        .getPlanList({
          page: this.currentPage,
          size: this.pageSize
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.sum;
          this.loading = false;
        });
    },
    add() {
      this.type = 2;
      this.dialogFormVisible = true;
    },
    // 点击修改按钮
    modify(plan_id) {
      this.type = 1;
      this.plan_id = plan_id;

      this.$http
        .getPlanInfo({
          plan_id
        })
        .then(res => {
          this.dialogFormVisible = true;
          this.$nextTick(() => {
            this.dialogForm = res.data;
          });
        });
    },
    //弹框内确认按钮
    addclassify() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            //  修改
            this.$http
              .updatePlan({ plan_id: this.plan_id, ...this.dialogForm })
              .then(res => {
                this.dialogFormVisible = false;
                this.getTableData();
              });
          } else {
            // 新增
            this.$http.addPlan({ ...this.dialogForm }).then(res => {
              this.dialogFormVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });
    },

    // 关闭弹框时清空表单内容和图片
    resetForm() {
      this.$refs["dataForm"].resetFields();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss">
.favorite-container {
  .form-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    section {
      display: flex;
      align-items: center;
    }
  }
  .pagination-container {
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    .pagination {
      float: right;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>