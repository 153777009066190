<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-18 11:17:55
 * @LastEditTime: 2019-11-15 11:18:58
 * @LastEditors: Please set LastEditors
 -->
<!--  -->
<template>
  <div class="error">
    <img src="./404.png" alt />
    <button @click="goback">回到首頁</button>
    <span>噢~這個頁面飛到其他星球去啦~返回試試其他頁面吧！</span>
    <span>Ooops! This page went out of space!Go back and try a different link.</span>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "",
  components: {},
  data() {
    //这里存放数据
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //方法集合
  methods: {
    goback() {
      this.$router.push("/app/dashboard");
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='scss' scoped>
@import "../../styles/app";

//@import url(); 引入公共css类
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: pxToper(583);
    margin-top: pxToper(60);
  }
  button {
    margin: pxToper(60) 0 pxToper(40);
    width: pxToper(150);
    height: pxToper(50);
    background-color: #333333;
    border-radius: pxToper(5);
    border: none;
    color: #f5f5f5;
    font-size: pxToper(16);
  }
  span {
    font-size: pxToper(16);
    color: #666666;
    line-height: pxToper(30);
    font-family: MicrosoftYaHei;
  }
}
</style>