<template>
  <div class="rateManage">
    <el-card class="my-filter">
      <el-row :gutter="4">
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
          <span style="font-weight: bold;font-size: 1.2rem;">{{ $t('rating.D54EA1@sortBy') }}：</span>
          <el-select v-model="sortBy" clearable>
            <!--     最新評論     -->
            <el-option :label="$t('rating.ADE215@top')" :value="1"></el-option>
            <!--     最舊評論     -->
            <el-option :label="$t('rating.ED215F@bottom')" :value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8" :offset="3">
          <span style="font-weight: bold;font-size: 1.2rem;">{{ $t('rating.AE2121@filterBy') }}：
            <el-select v-model="filterBy">
              <!--     所有評論     -->
              <el-option :label="$t('rating.BF215E1@All_Reviews')" :value="1"></el-option>
              <!--     已顯示的評論     -->
              <el-option :label="$t('rating.D545F1@Display_Reviews')" :value="2"></el-option>
              <!--     不顯示的評論     -->
              <el-option :label="$t('rating.F21565@Not_Display_Reviews')" :value="3"></el-option>
            </el-select>
          </span>
          <span>
          <el-select v-model="star" style="">
            <!--     幾顆星評論     -->
            <el-option :label="$t('rating.E2DF56@all')" :value="0"></el-option>
            <el-option :label="$t('rating.A12E55@five_star_only')" :value="5"></el-option>
            <el-option :label="$t('rating.A12E54@four_star_only')" :value="4"></el-option>
            <el-option :label="$t('rating.A12E53@three_star_only')" :value="3"></el-option>
            <el-option :label="$t('rating.A12E52@two_star_only')" :value="2"></el-option>
            <el-option :label="$t('rating.A12E51@one_star_only')" :value="1"></el-option>
          </el-select>
          </span>
        </el-col>
        <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2" :offset="7">
          <el-button size="middle" style="background: #F24040;border-color: #F24040;color: white;"
                     @click="getRatingList">
            {{ $t('rating.F54645@filter') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>

    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane :label="$t('rating.ED2551@product_reviews')" name="productReviews">
        <el-row v-for="item in rateList" :gutter="2" style="margin-bottom: 10px">
          <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2">
            <img :src="item.logo" class="company_logo" style="border-radius: 50%;" alt>
          </el-col>
          <el-col :xs="18" :sm="18" :md="18" :lg="18" :xl="18">
            <div class="contentDiv">
              <span style="padding-right: 1vw;font-weight: 800;font-size: 10pt">{{ item.company_name }}</span>
              <span style="color:#F24040;font-weight: 700;font-size: 10pt">{{ item.goods_name }}</span>
              <div v-html="item.buyer_comment" style="color: grey;padding-bottom: 1.5vh;font-size: 14pt">
              </div>
              <span style="color:#F24040;font-weight: 700;">
                {{ item.buyer_score_time }}
              </span>
            </div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div>
              <el-button size="middle" @click="modifyRatingStatus(item.oid, 1)"
                         style="background: #F24040;border-color: #F24040;color: white">
                {{ $t('rating.B25ED5@Approve') }}
              </el-button>
              <el-button size="middle" @click="modifyRatingStatus(item.oid, 0)">
                {{ $t('rating.A2575E@Hide') }}
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <section class="flex-end mt20">
      <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[10,25,50,100]"
          :page-size="size"
          @current-change="handleCurrentChange"
          :current-page="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      ></el-pagination>
    </section>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      rateList: [],
      activeName: 'productReviews',
      sortBy: "",
      filterBy: "",
      star: "",
      size: 10,
      page: 1,
      total: 0,
    }
  },
  methods: {
    modifyRatingStatus(oid, status) {
      this.$http.modifyRatingStatus({
        oid: oid,
        status: status,
      }).then(res => {
        if (res.errno == 0) {
          this.getRatingList()
        }
      })
    },
    getRatingList() {
      console.log(this.sortBy)
      this.$http.getRatingList({
        size: this.size,
        page: this.page,
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        star: this.star,
      }).then(res => {
        this.rateList = res.data.list
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.size = val
      this.getRatingList()
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRatingList()
    }
  },
  created() {
    this.getRatingList()
  }
}
</script>

<style lang="scss" scoped>

.rateManage {
  .my-filter {
    margin-top: 1vw;
    margin-bottom: 1vw;


    .rating {
      width: 100vw;
      display: flex;
      flex-direction: row;

      .reviews {
        margin-bottom: 1vh;
        display: flex;
        flex-direction: row;
        width: 70vw;

        .logoDiv {
          width: 10%;
          display: flex;
          flex-direction: column;

          .company_logo {
            width: 90px;
            height: 90px;
          }
        }

        .contentDiv {
          display: flex;
          flex-direction: row;
          width: 80%;
        }
      }

      > .myButton {
        display: flex;
        flex-direction: row;
        width: 70vw;
      }
    }
  }

}
</style>
