<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.DD811F@payment_list')">
      <span style="font-size:26px;" class="fa fa-credit-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
        <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="16129003481961"
            @click="showDialog(0)"
        >
        </kt-button>
      </section>
      <el-table
          border
          v-loading="loading"
          :data="search"
          :element-loading-text="$t('public.4ABAB1@loading')"
          style="width: 100%;margin-top:20px;">
        <el-table-column
            min-width="200"
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')"
            align="center"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            min-width="50"
            prop="commission_rate"
            :label="$t('aboutus.B0A0B1@radio')"
            align="center"
            show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.commission_rate }}%</span>
          </template>
        </el-table-column>
        <el-table-column
            min-width="50"
            prop="opration"
            align="center"
            show-overflow-tooltip>
          <template slot-scope="scope">
            <kt-button
                @click="showDialog(1, scope.row.id)"
                type="primary"
                size="small"
                :perm="16128981667321"
                :label="$t('public.B6D57F@btn_modify')">
            </kt-button>
            <kt-button
                @click="delPayment(scope.row.id)"
                type="danger"
                size="small"
                :perm="16128985457182"
                :label="$t('public.1FBB5E@btn_delete')"
                plain>
            </kt-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[10,25,50,100]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="page"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paymentLength"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog :visible.sync="show" :title="modify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
               width="50%" center>
      <el-form :model="form" :rules="rules" ref="form" label-width="150px">
        <el-row>
          <el-col :span="20" :offset="2">
            <!--    供應商        -->
            <el-form-item :label="$t('aboutus.351BB4@All_suppliers')">
              <el-select v-model="form.cid" style="width:80%"
                         :placeholder="$t('shop-goods.1213D7@Please_select_supplier')"
                         filterable remote automatic-dropdown>
                <el-option
                    v-for="item in supplierList"
                    :key="item.company_id"
                    :label="item.company_name"
                    :value="item.company_id">
                </el-option>
              </el-select>
            </el-form-item>
            <!--    付款ID        -->
            <el-form-item :label="$t('payment.B3DEED@payee_id')" prop="payee_id">
              <el-input type="text" v-model="form.payee_id" style="width:80%"
                        :placeholder="$t('payment.A3DE8B@placeholder')"></el-input>
            </el-form-item>
            <!--    抽佣比例        -->
            <el-form-item :label="$t('aboutus.B0A0B1@radio')" prop="commission_rate">
              <el-input-number :min="0" :max="100" :step="0.1" :precision="2" v-model="form.commission_rate">
              </el-input-number>
            </el-form-item>
            <!--    啟用狀態        -->
            <el-form-item :label="$t('backend_authrity_user.07D89C@status')">
              <el-switch v-model="form.isUse"
                         :active-text="$t('status.861FAE@enable')" active-color="#13ce66" :active-value="1"
                         :inactive-text="$t('status.EA56D8@disable')" inactive-color="#ff4949" :inactive-value="0">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button type="primary" size="small" @click="addOrModify">
          {{ $t('public.3B45A2@confirm') }}
        </el-button>
        <el-button type="danger" @click="show=false" size="small">
          {{ $t('public.836FD4@cancel') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue"
import pageTop from "../../components/PageTop/PageTop"

export default {
  computed: {
    search() {
      if (this.searchText == "") {
        return this.paymentList
      } else {
        return this.paymentList.filter(i => {
          return i.company_name.indexOf(this.searchText) > -1
        })
      }
    }
  },
  components: {
    ktButton,
    pageTop
  },
  name: "",
  data() {
    return {
      show: false,
      //0=add,1=modify
      modify: 0,
      searchText: "",
      supplierLength: 0,
      pageSize: 10,
      page: 1,
      supplierList: [],
      loading: true,
      paymentList: [],
      paymentLength: 0,
      form: {
        cid: "",
        commission_rate: 0,
        id: 0,
        isUse: 0,
        payee_id: "",
      },
      rules: {
        commission_rate: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        payee_id: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
      }
    }
  },
  methods: {
    //新增或修改
    addOrModify(){
      if(this.modify){
        this.modifyPayment()
      }else{
        this.addPayment()
      }
    },
    //新增支付
    addPayment() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$http.addPayment({
            cid: this.form.cid,
            commission_rate: this.form.commission_rate,
            isUse: this.form.isUse,
            payee_id: this.form.payee_id,
          }).then(res=>{
            if(res.errno==0){
              this.loading = true
              this.getPaymentList()
              this.show = false
            }
          })
        } else {
          return false
        }
      })
    },
    //更新支付
    modifyPayment() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$http.modifyPayment({
            id: this.form.id,
            cid: this.form.cid,
            commission_rate: this.form.commission_rate,
            isUse: this.form.isUse,
            payee_id: this.form.payee_id,
          }).then(res => {
            if (res.errno == 0) {
              this.loading = true
              this.getPaymentList()
              this.show = false
            }
          })
        } else {
          return false;
        }
      })
    },
    //取得Payment info
    getPaymentInfo(id) {
      this.$http.getPaymentInfo({
        id: id
      }).then(res => {
        this.form = res.data.result
      })
    },
    //顯示對話視窗
    showDialog(type, id) {
      this.modify = type
      this.show = true
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });

      if (this.modify) {
        this.getPaymentInfo(id)
      } else {
        this.form.cid = ""
        this.form.commission_rate = 0
        this.form.id = 0
        this.form.isUse = 0
        this.form.payee_id = ""
      }
    },
    //刪除Payment
    delPayment(id) {
      this.$confirm(
          this.$t("shop-goods.2ECFD0@Confirm_delete"),
          this.$t("public.553159@tips"),
          {
            confirmButtonText: this.$t("public.3B45A2@confirm"),
            cancelButtonText: this.$t("public.836FD4@cancel")
          }
      ).then(() => {
        this.$http.delPayment({
          id: id
        }).then(() => {
          this.getPaymentList()
        })
      })
    },
    //取得已經設定的付款清單
    getPaymentList() {
      this.$http.getPaymentList({
        size: this.pageSize,
        page: this.page,
      }).then(res => {
        this.paymentList = res.data.list
        this.paymentLength = res.data.total
        this.loading = false
      })
    },
    //取得供應商清單
    getSupplierList() {
      this.$http.getAllSupplierList().then(res => {
        this.supplierList = res.data.list
        this.supplierLength = res.data.sum
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPaymentList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPaymentList();
    },
  },
  created() {
    this.getSupplierList()
    this.getPaymentList()
  }
}
</script>

<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>