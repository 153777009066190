<!--
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-20 18:46:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\pages\shopManage\supplierList.vue
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.BE2794@supplier_list')">
      <span style="font-size:26px;" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <el-input prefix-icon="el-icon-search" type="text" v-model="searchText"
                  style="padding-bottom: 5px;padding-right: 20px;display: flex;justify-content: flex-start;border-radius: 10px;"></el-input>
        <kt-button
            class="table-btn btn-yellow"
            :label="$t('public.DC9752@btn_add')"
            :perm="762"
            @click="add"
        ></kt-button>
      </section>
      <section class="flex-start">
        <el-select size="small" v-model="show" @change="statusChange">
          <el-option :key="2" :value="2" :label="$t('hidden_or_show.EAD5E1@display_all_supplier')"></el-option>
          <el-option :key="1" :value="1" :label="$t('hidden_or_show.DFE51A@display_hide_supplier')"></el-option>
          <el-option :key="0" :value="0"
                     :label="$t('hidden_or_show.DE2D1E@display_none_hidden_supplier')"></el-option>
        </el-select>
      </section>
      <el-table
          border
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          :data="tableData"
          style="width: 100%;margin-top:20px;">
        <el-table-column
            prop="company_name"
            :label="$t('backend_shop_supplierList.995038@company_name')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="display_name"
            :label="$t('backend_shop_supplierList.B85EE6@display_name')"
            align="center"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="admin"
            :label="$t('backend_shop_supplierList.E919F3@super_user')"
            align="center"
            min-width="100"
        ></el-table-column>
        <el-table-column
            prop="contact"
            :label="$t('backend_shop_supplierList.5A75EE@Contact_person')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="email"
            :label="$t('backend_shop_supplierList.F9618A@company_email')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="telephone"
            :label="$t('backend_shop_supplierList.65868F@company_phone')"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="status"
            :label="$t('backend_shop_supplierList.760B82@company_status')"
            align="center"
            min-width="50"
        >
          <template slot-scope="scope">{{ scope.row.status | status }}</template>
        </el-table-column>
        <el-table-column
            prop="desc"
            :label="$t('backend_shop_supplierList.51CC83@company_desc')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="create_time"
            :label="$t('backend_shop_supplierList.D4ED09@registration_time')"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="opration"
            :label="$t('public.03EA09@operation')"
            width="250"
            align="center"
            fixed="right"
        >
          <template slot-scope="scope">
            <kt-button
                v-if="scope.row.status == 'NORMAL'"
                :perm="736"
                :label="$t('public.DBC110@locked')"
                @click="lock(scope.row,1)"
            ></kt-button>
            <kt-button
                v-else
                :perm="736"
                :label="$t('public.564C21@unlock')"
                @click="lock(scope.row,0)"
            ></kt-button>
            <!--     隱藏       -->
            <kt-button
                v-if="scope.row.is_hidden==0"
                :perm="16259193376582"
                :label="$t('rating.A2575E@Hide')"
                type="danger"
                @click="showOrHiddenCompany(scope.row.company_id, 1)"
            >
            </kt-button>
            <!--     顯示       -->
            <kt-button
                v-else
                :perm="16259193376582"
                :label="$t('public.B3669C@show')"
                type="success"
                @click="showOrHiddenCompany(scope.row.company_id, 0)"
            >
            </kt-button>
            <kt-button
                type="info"
                :perm="861"
                :label="$t('public.B6D57F@btn_modify')"
                @click="modify(scope.row)"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-button type="primary" size="small" @click="exportSupplierList">
          {{ $t('backend_submenu.BCC156@export_supplier_list') }}
        </el-button>
        <el-button type="success" size="small" @click="exportSupplierProduct">
          {{ $t('backend_submenu.AE5F11@export_supplier_product_list') }}
        </el-button>
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[25,50,100,200]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <el-dialog
        :title="addModify==0?$t('public.DC9752@btn_add'):$t('public.B6D57F@btn_modify')"
        center
        width="60%"
        top="8vh"
        :visible.sync="dialogFormVisible"
    >
      <el-form ref="form" :rules="rules" :model="dialogForm" label-width="150px">
        <el-row>
          <el-col :span="18" :offset="2">
            <!-- <el-form-item
              :label="$t('public.8D75D5@Hong_Kong_Administrative_Region')"
              prop="district_id"
            >
              <el-select
                v-model="dialogForm.district_id"
                :placeholder="$t('placeholder.F0DDC0@select')"
              >
                <el-option
                  v-for="item in HKDistrictSelector"
                  :key="item.district_id"
                  :label="item.district"
                  :value="item.district_id"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item
                :label="$t('placeholder.9A92DD@username')"
                prop="user_name"
                v-if="addModify==0"
            >
              <el-input style="width:100%;" v-model="dialogForm.user_name"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.F703D1@company_name_hk')"
                prop="company_name_hk"
            >
              <el-input style="width:100%;" v-model="dialogForm.company_name_hk"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.394FBB@company_name_en')"
                prop="company_name_en"
            >
              <el-input style="width:100%;" v-model="dialogForm.company_name_en"></el-input>
            </el-form-item>
            <!--      顯示名稱  中文    -->
            <el-form-item
                :label="$t('backend_shop_supplierList.B32F11@display_name_hk')"
                prop="display_name_hk">
              <el-input style="width:100%;" v-model="dialogForm.display_name_hk"></el-input>
            </el-form-item>
            <!--      顯示名稱 英文      -->
            <el-form-item
                :label="$t('backend_shop_supplierList.A36EFF@display_name_en')"
                prop="display_name_en">
              <el-input style="width:100%;" v-model="dialogForm.display_name_en"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.5A75EE@Contact_person')"
                prop="contact"
            >
              <el-input style="width:100%;" v-model="dialogForm.contact"></el-input>
            </el-form-item>
            <el-form-item :label="$t('public.417BDC@E-mail')" prop="email">
              <el-tooltip placement="left">
                <div slot="content">{{ $t('public.ADF5E@email_tip') }}</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <el-input v-model="dialogForm.email" type="textarea" :autosize="{ minRows: 3, maxRows: 4}"></el-input>
            </el-form-item>
            <!--     訂單通知       -->
            <el-form-item :label="$t('public.821DEE@E-mail_order')" prop="email_order">
              <el-tooltip placement="left">
                <div slot="content">{{ $t('public.ADF5E@email_tip') }}</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <el-input v-model="dialogForm.email_order" type="textarea"
                        :autosize="{ minRows: 3, maxRows: 4}"></el-input>
            </el-form-item>
            <!--      訊息通知      -->
            <el-form-item :label="$t('public.A98F12@E-mail_message')" prop="email_message">
              <el-tooltip placement="left">
                <div slot="content">{{ $t('public.ADF5E@email_tip') }}</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <el-input v-model="dialogForm.email_message" type="textarea"
                        :autosize="{ minRows: 3, maxRows: 4}"></el-input>
            </el-form-item>
            <!--      Invoice通知      -->
            <el-form-item :label="$t('public.6EF123@E-mail_invoice')" prop="email_invoice">
              <el-tooltip placement="left">
                <div slot="content">{{ $t('public.ADF5E@email_tip') }}</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <el-input v-model="dialogForm.email_invoice" type="textarea"
                        :autosize="{ minRows: 3, maxRows: 4}"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_shop_supplierList.65868F@company_phone')"
                prop="telephone"
            >
              <el-input style="width:100%;" v-model="dialogForm.telephone"></el-input>
            </el-form-item>
            <!--     WhatsApp群組       -->
            <el-form-item
                :label="$t('supplier_company_information.A5E131@whatsapp_group')"
                prop="whatsappGroup">
              <el-input v-model="dialogForm.whatsappGroup"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.B08D37@addr_hk')" prop="addr_hk">
              <el-input style="width:100%;" v-model="dialogForm.addr_hk"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.EFBD4B@addr_en')" prop="addr_en">
              <el-input style="width:100%;" v-model="dialogForm.addr_en"></el-input>
            </el-form-item>
            <el-form-item :label="$t('hidden_or_show.FEA51D@show_label')" prop="show_restaurant">
              <el-tooltip placement="left">
                <div slot="content">{{ $t('hidden_or_show.AD5E46@tip') }}</div>
                <i class="el-icon-question"/>
              </el-tooltip>
              <el-select v-model="dialogForm.show_restaurant" clearable filterable multiple collapse-tags
                         @change="showRestaurantChange" style="width: 97%" placeholder="不進行任何選擇，將會讓全部餐廳看見">
                <el-option :key="-1" :value="-1" :label="$t('shop_cart.7E9DE5@check_all')"></el-option>
                <el-option v-for="item in restaurantList" :key="item.cid" :value="item.cid"
                           :label="item.company_name"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item :label="$t('backend_shop_supplierList.5AEC72@remark_hk')" prop="desc_hk">
              <el-input style="width:100%;" v-model="dialogForm.desc_hk"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_shop_supplierList.EF978E@remark_en')" prop="desc_en">
              <el-input style="width:100%;" v-model="dialogForm.desc_en"></el-input>
            </el-form-item>-->
            <el-form-item :label="$t('aboutus.B0A0B1@radio')" prop="cost_ratio">
              <el-input style="width:100%;" v-model="dialogForm.cost_ratio"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <section slot="footer">
        <el-button
            @click="dialogFormVisible = false"
            class="table-btn btn-gray"
        >{{ $t('public.836FD4@cancel') }}
        </el-button>
        <el-button
            @click="confirmDialog"
            class="table-btn btn-yellow"
        >{{ $t('public.3B45A2@confirm') }}
        </el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/index";
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";

export default {
  watch: {
    searchText() {
      if (this.searchText) {
        this.currentPage = 1
      }
      this.getTableData()
    }
  },
  filters: {
    status(data) {
      return data == "NORMAL" ? "正常" : "锁定";
    }
  },
  data() {
    return {
      //2=顯示所有餐廳, 1=已隱藏的餐廳, 0=未隱藏的餐廳
      show: 0,
      restaurantList: [],
      //全選
      isSelectAll: false,
      loading: true,
      select: {
        search: ""
      },
      searchText: "",
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 25,
      HKDistrictSelector: [],
      dialogFormVisible: false,
      dialogForm: {
        show_restaurant: "",
        district_id: "",
        user_name: "",
        company_name_en: "",
        company_name_hk: "",
        display_name_en: "",
        display_name_hk: "",
        email: "",
        email_order: "",
        email_message: "",
        email_invoice: "",
        telephone: "",
        addr_en: "",
        addr_hk: "",
        desc_en: "",
        desc_hk: "",
        contact: "",
        cost_ratio: "",
        whatsappGroup: "",
      },
      addModify: 0,
      row: {},
      rules: {
        user_name: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        company_name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        company_name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        display_name_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        display_name_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        email: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        telephone: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        addr_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        addr_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        contact: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        cost_ratio: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getAllRestaurantList()
    this.getTableData();
    this.getHKDistrictSelector();
  },
  methods: {
    statusChange() {
      this.getTableData()
    },
    //隱藏公司
    showOrHiddenCompany(company_id, status) {
      this.$http.showOrHiddenCompany({
        company_id: company_id,
        project: 'supplier',
        status: status,
      }).then(res => {
        if (res.errno == 0) {
          this.getTableData()
        }
      })
    },
    //取得所有餐廳清單
    getAllRestaurantList() {
      this.$http.getAllRestaurantList().then(res => {
        this.restaurantList = res.data
      })
    },
    //取得餐廳選單異動時
    showRestaurantChange() {
      if (this.isSelectAll) {
        //全選狀態
        //找尋是否有全選
        let selectAll = this.dialogForm.show_restaurant.find(s => {
          return s === -1
        })
        if (!selectAll) {
          this.isSelectAll = false
          this.dialogForm.show_restaurant = ""
        } else {
          this.isSelectAll = false
          this.dialogForm.show_restaurant = this.dialogForm.show_restaurant.filter(s => {
            return s !== -1
          })
        }
      } else {
        //非全選狀態
        //找尋是否有全選
        let selectAll = this.dialogForm.show_restaurant.find(s => {
          return s === -1
        })
        if (selectAll) {
          this.isSelectAll = true
          //如果使用者點擊全選先清空再新增所有餐廳id
          this.dialogForm.show_restaurant = [-1]
          this.restaurantList.forEach(s => {
            this.dialogForm.show_restaurant.push(s.cid)
          })
        }
      }
    },
    //下載供應商產品
    exportSupplierList() {
      this.$http.exportSupplierList().then(res => {
        this.downloadFile(res)
      })
    },
    //下載供應商產品
    exportSupplierProduct() {
      this.$http.exportSupplierProduct().then(res => {
        this.downloadFile(res)
      })
    },
    // 香港行政分区选择器
    getHKDistrictSelector() {
      api.HKDistrictSelector().then(res => {
        this.HKDistrictSelector = res.data;
      });
    },
    getTableData() {
      api.getSupplier({
        size: this.pageSize,
        page: this.currentPage,
        search: this.searchText,
        status: this.show,
      }).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.sum;
        this.loading = false;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    add() {
      if (this.dialogForm.company_name_en) {
        this.$refs["form"].resetFields();
      }
      this.dialogFormVisible = true;
      this.addModify = 0;
    },
    modify(row) {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.addModify = 1;
      this.row = row;
      api.querySupplier({
        company_id: row.company_id
      }).then(res => {
        Object.assign(this.dialogForm, res.data);
        this.dialogForm.show_restaurant = JSON.parse(res.data.show_restaurant)
      });
    },
    cleanForm() {
      this.dialogForm.show_restaurant = ""
      this.dialogForm.district_id = ""
      this.dialogForm.user_name = ""
      this.dialogForm.company_name_en = ""
      this.dialogForm.company_name_hk = ""
      this.dialogForm.display_name_en = ""
      this.dialogForm.display_name_hk = ""
      this.dialogForm.email = ""
      this.dialogForm.email_invoice = ""
      this.dialogForm.email_message = ""
      this.dialogForm.email_order = ""
      this.dialogForm.telephone = ""
      this.dialogForm.addr_en = ""
      this.dialogForm.addr_hk = ""
      this.dialogForm.desc_en = ""
      this.dialogForm.desc_hk = ""
      this.dialogForm.contact = ""
      this.dialogForm.cost_ratio = ""
      this.dialogForm.whatsappGroup = ""
    },
    confirmDialog() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.addModify) {
            //修改
            api.modifySupplier({
              company_id: this.row.company_id,
              show_restaurant: JSON.stringify(this.dialogForm.show_restaurant),
              district_id: this.dialogForm.district_id,
              user_name: this.dialogForm.user_name,
              company_name_en: this.dialogForm.company_name_en,
              company_name_hk: this.dialogForm.company_name_hk,
              display_name_en: this.dialogForm.display_name_en,
              display_name_hk: this.dialogForm.display_name_hk,
              email: this.dialogForm.email,
              email_order: this.dialogForm.email_order,
              email_message: this.dialogForm.email_message,
              email_invoice: this.dialogForm.email_invoice,
              telephone: this.dialogForm.telephone,
              addr_en: this.dialogForm.addr_en,
              addr_hk: this.dialogForm.addr_hk,
              desc_en: this.dialogForm.desc_en,
              desc_hk: this.dialogForm.desc_hk,
              contact: this.dialogForm.contact,
              cost_ratio: this.dialogForm.cost_ratio,
              whatsappGroup: this.dialogForm.whatsappGroup,
            }).then(res => {
              if (res.errno == 0) {
                this.cleanForm()
                this.getTableData();
                this.dialogFormVisible = false;
              }
            });
          } else {
            api.addSupplier({
              show_restaurant: JSON.stringify(this.dialogForm.show_restaurant),
              district_id: this.dialogForm.district_id,
              user_name: this.dialogForm.user_name,
              company_name_en: this.dialogForm.company_name_en,
              company_name_hk: this.dialogForm.company_name_hk,
              display_name_en: this.dialogForm.display_name_en,
              display_name_hk: this.dialogForm.display_name_hk,
              email: this.dialogForm.email,
              telephone: this.dialogForm.telephone,
              addr_en: this.dialogForm.addr_en,
              addr_hk: this.dialogForm.addr_hk,
              desc_en: this.dialogForm.desc_en,
              desc_hk: this.dialogForm.desc_hk,
              contact: this.dialogForm.contact,
              cost_ratio: this.dialogForm.cost_ratio,
              whatsappGroup: this.dialogForm.whatsappGroup,
            }).then(res => {
              if (res.errno == 0) {
                this.cleanForm()
                this.dialogFormVisible = false;
                this.getTableData();
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    lock(row, type) {
      api
          .lockSupplier({
            company_id: row.company_id,
            type: type
          })
          .then(res => {
            this.getTableData();
          });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  section {
    display: flex;
    align-items: center;
  }
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}
</style>
