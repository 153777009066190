<template>
  <div class="favorite-container">
    <page-top :title="$t('index.ABA598@Marketing_email')">
      <span style="font-size: 26px" class="fa fa-tags"></span>
    </page-top>
    <el-card>
      <el-table
        :data="tableData"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="email"
          :label="$t('backend_config_platformInfo.837359@email')"
          align="center"
          min-width="120"
        ></el-table-column>
        <el-table-column
          prop="status_text"
          :label="$t('Contact.9EC622@Whether_to_Deal_With')"
          align="center"
          min-width="180"
        ></el-table-column>

        <el-table-column
          prop="create_time"
          :label="$t('backend_emailList.0C2F58@send_time')"
          align="center"
        ></el-table-column>
      </el-table>
      <section class="pagination-container">
        <ktButton
          :label="$t('aboutus.2AECD0@Export_Order')"
          class="table-btn btn-gray"
          :perm="15942065434671"
          @click="downloadexcel"
        ></ktButton>
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5, 10, 15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";
import api from "../../api/index";
export default {
  data() {
    return {
      loading: true,
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
    };
  },

  components: {
    pageTop,
    ktButton,
  },
  created() {
    this.getTableData();
  },

  methods: {
    getTableData() {
      this.$http
        .newsletterList({
          size: this.pageSize,
          page: this.currentPage,
        })
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.sum;
          this.loading = false;
        });
    },
    // 下载模板
    downloadexcel() {
      this.$http.newsletterExport().then((res) => {
        console.log(res);
        this.downloadFile(res);
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-left {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
</style>