<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-10-31 15:41:28
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="company">
    <page-top :title="$t('backend_submenu.49DFF3@integral')">
      <span style="font-size:26px;" class="fa fa-cog"></span>
    </page-top>
    <el-card>
      <section slot="header">
        <div slot="header" class="header">
          <i class="fa fa-info-circle"></i>
          <span class="title">{{$t('backend_submenu.49DFF3@integral')}}</span>
        </div>
      </section>
      <el-form ref="form" :rules="rules" :model="form" label-width="250px">
        <el-row>
          <el-col :span="12" :offset="6">
            <el-form-item
              :label="$t('backend_config_integral.BEC1AD@bonus_rate')"
              prop="gift_ratio"
            >
              <el-input v-model="form.gift_ratio"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('backend_config_integral.0B80C2@deduce_rate')"
              prop="deduction_ratio"
            >
              <el-input v-model="form.deduction_ratio"></el-input>
            </el-form-item>
            <el-form-item :label="$t('backend_config_integral.160EFC@isBonus')" prop="gift_status">
              <el-switch
                v-model="form.gift_status"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="10">
            <KtButton
              :label="$t('public.A738BA@update')"
              class="form-btn btn-yellow"
              :perm="1231"
              @click="updatePlatform('form')"
            ></KtButton>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import api from "../../api/index";
export default {
  data () {
    return {
      form: {
        gift_ratio: "",
        deduction_ratio: "",
        gift_status: ""
      },
      rules: {
        gift_ratio: { required: true, message: "Please enter" },
        deduction_ratio: { required: true, message: "Please enter" },
        gift_status: { required: true, message: "Please enter" }
      }
    };
  },
  components: {
    pageTop,
    KtButton
  },
  created () {
    this.getCreditSetting();
  },
  methods: {
    getCreditSetting () {
      api.getCreditSetting().then(res => {
        console.log(res);
        Object.assign(this.form, res.data);
      });
    },
    updatePlatform (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          api.updateCreditSetting(this.form).then(res => {
            console.log(res);
            this.getCreditSetting();
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.company {
  .header {
    display: flex;
    font-size: 16px;
    align-items: center;
    font-weight: 900;
    span {
      margin-left: 10px;
    }
  }
  .tips {
    font-size: 12px;
    color: #999;
  }
  .btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>