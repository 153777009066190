/*
 * @Author: your name
 * @Date: 2019-10-18 15:37:37
 * @LastEditTime: 2019-11-18 11:04:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /c:\Users\test\Desktop\chefstation_backend_UI\src\Routes.js
 */
import Vue from "vue";
import Router from "vue-router";
import { Message } from "element-ui";
import { getlocalStorage } from "./utils/localStorage";

import Layout from "@/components/Layout/Layout";
const Login = () => import(/*webpackChunkName:'Login'*/ "@/pages/Login/Login");
const error = () => import(/*webpackChunkName:'error'*/ "@/pages/error/error");
const kong = () => import(/*webpackChunkName:'kong'*/ "@/pages/error/kong");
const space = () => import(/*webpackChunkName:'space'*/ "@/pages/Login/space");
const Dashboard = () =>
  import(/*webpackChunkName:'Dashboard'*/ "@/pages/Dashboard/Dashboard");

const Authority = () =>
  import(/*webpackChunkName:'Authority'*/ "@/pages/Authority/Authority");
const Grouping = () =>
  import(/*webpackChunkName:'Grouping'*/ "@/pages/Authority/grouping");
const operationLog = () =>
  import(/*webpackChunkName:'operationLog'*/ "@/pages/Authority/operationLog");
const User = () => import(/*webpackChunkName:'User'*/ "@/pages/Authority/user");
const noAuthrity = () =>
  import(/*webpackChunkName:'noAuthrity'*/ "@/pages/Login/noAuthrity");

// const configItem=()=> import(/*webpackChunkName:'configItem'*/'@/pages/ConfigItem/ConfigItem')
import advertising from "@/pages/ConfigItem/advertising";
const UnitConverter = () => import("@/pages/ConfigItem/UnitConverter");
const PermissionManage = () => import("@/pages/restaurant/PermissionManage");
const CustomPermissionGroup = () =>
  import("@/pages/restaurant/CustomPermissionGroup");
const platform = () =>
  import(/*webpackChunkName:'platform'*/ "@/pages/ConfigItem/platform");
const homeClassify = () =>
  import(/*webpackChunkName:'homeClassify'*/ "@/pages/ConfigItem/homeClassify");
const integral = () =>
  import(/*webpackChunkName:'integral'*/ "@/pages/ConfigItem/integral");
const plan = () =>
  import(/*webpackChunkName:'plan'*/ "@/pages/ConfigItem/plan");
const ProductUnit = () =>
  import(/*webpackChunkName:'ProductUnit'*/ "@/pages/ConfigItem/ProductUnit");
const GroupConfig = () =>
  import(/*webpackChunkName:'GoodsOrder'*/ "@/pages/userManage/GroupConfig");

const productCategory = () =>
  import(/*webpackChunkName:'productCategory'*/ "@/pages/productCategory/productCategory");

const restaurantList = () =>
  import(/*webpackChunkName:'restaurantList'*/ "@/pages/shopManage/restaurantList");
const supplierList = () =>
  import(/*webpackChunkName:'supplierList'*/ "@/pages/shopManage/supplierList");

const BillClassification = () =>
  import(/*webpackChunkName:'BillClassification'*/ "@/pages/BillClassification/BillClassification");

const orderDetail = () =>
  import(/*webpackChunkName:'orderDetail'*/ "@/pages/order/orderDetail");
const print = () => import(/*webpackChunkName:'print'*/ "@/pages/order/print");
const purchase = () =>
  import(/*webpackChunkName:'purchase'*/ "@/pages/order/purchase");

const restaurantUser = () =>
  import(/*webpackChunkName:'restaurantUser'*/ "@/pages/userManage/restaurant");
const supplierUser = () =>
  import(/*webpackChunkName:'supplierUser'*/ "@/pages/userManage/supplier");

const BlogCategory = () =>
  import(/*webpackChunkName:'BlogCategory'*/ "@/pages/Blog/BlogCategory");
const BlogList = () =>
  import(/*webpackChunkName:'BlogList'*/ "@/pages/Blog/BlogList");

const Email = () => import(/*webpackChunkName:'Email'*/ "@/pages/Email/Email");
const EmailMarketing = () =>
  import(/*webpackChunkName:'EmailMarketing'*/ "@/pages/Email/EmailMarketing");

const chatrecord = () =>
  import(/*webpackChunkName:'chatrecord'*/ "@/pages/chat/chatrecord");

const webAdv = () =>
  import(/*webpackChunkName:'WebAdv'*/ "@/pages/ConfigItem/WebAdvSetting");

const AddGoodsOrder = () =>
  import(/*webpackChunkName:'GoodsOrder'*/ "@/pages/GoodsOrder/AddGoodsOrder");
const GMV = () =>
  import(/*webpackChunkName:'GMV'*/ "@/pages/Statistics/GMV/GMV");
const AverageOrderPrice = () =>
  import(/*webpackChunkName:'AverageOrderPrice'*/ "@/pages/Statistics/AverageOrderPrice/AverageOrderPrice");
const KeyWords = () =>
  import(/*webpackChunkName:'KeyWords'*/ "@/pages/Statistics/KeyWords/KeyWords");
const ActiveUser = () =>
  import(/*webpackChunkName:'ActiveUser'*/ "@/pages/Statistics/ActiveUser/ActiveUser");
const LoginStatistics = () =>
  import(/*webpackChunkName:'LoginStatistics'*/ "@/pages/Statistics/LoginStatistics/LoginStatistics");
const CumulativeSalesVolume = () =>
  import(/*webpackChunkName:'CumulativeSalesVolume'*/ "@/pages/Statistics/CumulativeSalesVolume/CumulativeSalesVolume");
const GoodsSales = () =>
  import(/*webpackChunkName:'GoodsSales'*/ "@/pages/Statistics/GoodsSales/GoodsSales");
const CommodityAnalysis = () =>
  import(/*webpackChunkName:'CommodityAnalysis'*/ "@/pages/Statistics/CommodityAnalysis/CommodityAnalysis");
const FunctionUsageRecord = () =>
  import(/*webpackChunkName:'FunctionUsageRecord'*/ "@/pages/Statistics/FunctionUsageRecord/FunctionUsageRecord");

//支付管理
const paymentList = () => import(/*webpackChunkName:'paymentList'*/"@/pages/shopManage/paymentList");
//POS管理
const ManagePos = () => import(/*webpackChunkName:'ManagePos'*/"@/pages/shopManage/ManagePos");

//供應商及產品排序
const MarketRank = () => import("@/pages/shopManage/MarketRank.vue");
//發票
const Invoice = () => import("@/pages/Invoice/Invoice.vue");
//發票細節
const printInvoice = () => import("@/pages/Invoice/PrintInvoice.vue");
//評價管理
const RateManage = () =>
  import(/*webpackChunkName:'RateManage'*/ "@/pages/order/RateMange");

const OrderUser = () =>
    import(/*webpackChunkName:'RateManage'*/ "@/pages/Statistics/OrderUser/OrderUser");
//用戶評價
const HomeVideo = () =>
    import(/*webpackChunkName:'RateManage'*/ "@/pages/ConfigItem/HomeVideo");

const registerUser = () =>
    import(/*webpackChunkName:'RegisterUser'*/ "@/pages/userManage/RegisterUser");

const specialArea = ()=>
    import(/*webpackChunkName:'specialArea'*/ "@/pages/ConfigItem/specialArea");

//教學文章分類
const AppGuidelineCategory = () =>
    import(/*webpackChunkName:'BlogCategory'*/ "@/pages/AppGuideline/AppGuidelineCategory");
//教學文章清單
const AppGuidelineList = () =>
    import(/*webpackChunkName:'BlogList'*/ "@/pages/AppGuideline/AppGuidelineList");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/printInvoice",
      name: "printInvoice",
      component: printInvoice,
    },
    {
      path: "/print",
      name: "print",
      component: print,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/error",
      name: "error",
      component: error,
    },
    {
      path: "/kong",
      name: "kong",
      component: kong,
    },
    {
      path: "/noAuthrity",
      name: "noAuthrity",
      component: noAuthrity,
    },
    {
      path: "/app",
      name: "Layout",
      component: Layout,
      alias: "/",
      redirect: "/app/dashboard",
      children: [
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
        },
        {
          path: "space",
          name: "space",
          component: space,
        },
        {
          path: "configItem/webadv",
          name: "webadv",
          component: webAdv,
        },
        {
          path: "authority/authority",
          name: "authority",
          component: Authority,
        },
        {
          path: "authority/grouping",
          name: "grouping",
          component: Grouping,
        },
        {
          path: "authority/log",
          name: "log",
          component: operationLog,
        },
        {
          path: "authority/user",
          name: "user",
          component: User,
        },

        // {
        //   path: 'configItem',
        //   name: 'configItem',
        //   component: configItem,
        // },
        {
          path: "configItem/advertising",
          name: "advertising",
          component: advertising,
        },
        {
          path: "configItem/platform",
          name: "platform",
          component: platform,
        },
        {
          path: "configItem/homeClassify",
          name: "homeClassify",
          component: homeClassify,
        },
        {
          path: "configItem/integral",
          name: "integral",
          component: integral,
        },
        {
          path: "configItem/plan",
          name: "plan",
          component: plan,
        },
        {
          path: "configItem/ProductUnit",
          name: "ProductUnit",
          component: ProductUnit,
        },
        {
          path: "productCategory",
          name: "productCategory",
          component: productCategory,
        },
        {
          path: "BillClassification",
          name: "BillClassification",
          component: BillClassification,
        },
        {
          path: "shopManage/restaurantList",
          name: "restaurantList",
          component: restaurantList,
        },
        {
          path: "shopManage/supplierList",
          name: "supplierList",
          component: supplierList,
        },
        {
          path: "order/addGoodsOrder",
          name: "addGoodsOrder",
          component: AddGoodsOrder,
        },
        {
          path: "order/purchase",
          name: "purchase",
          component: purchase,
        },
        {
          path: "order/orderDetail",
          name: "orderDetail",
          component: orderDetail,
        },

        {
          path: "userManage/restaurantUser",
          name: "restaurantUser",
          component: restaurantUser,
        },
        {
          path: "userManage/supplierUser",
          name: "supplierUser",
          component: supplierUser,
        },
        {
          path: "Email",
          name: "Email",
          component: Email,
        },
        {
          path: "email-marketing",
          name: "EmailMarketing",
          component: EmailMarketing,
        },
        {
          path: "Blog/BlogCategory",
          name: "BlogCategory",
          component: BlogCategory,
        },
        {
          path: "Blog/BlogList",
          name: "BlogList",
          component: BlogList,
        },
        {
          path: "chatrecord",
          name: "chatrecord",
          component: chatrecord,
        },
        {
          path: "Restaurant/PermissionManage",
          name: "permissionManage",
          component: PermissionManage,
        },
        {
          path: "Restaurant/CustomPermissionGroup",
          name: "CustomPermissionGroup",
          component: CustomPermissionGroup,
        },
        {
          path: "Statistics/GMV",
          name: "GMV",
          component: GMV,
        },
        {
          path: "Statistics/AverageOrderPrice",
          name: "AverageOrderPrice",
          component: AverageOrderPrice,
        },
        {
          path: "Statistics/KeyWords",
          name: "KeyWords",
          component: KeyWords,
        },
        {
          path: "Statistics/ActiveUser",
          name: "ActiveUser",
          component: ActiveUser,
        },
        {
          path: "Statistics/LoginStatistics",
          name: "LoginStatistics",
          component: LoginStatistics,
        },
        {
          path: "Statistics/CumulativeSalesVolume",
          name: "CumulativeSalesVolume",
          component: CumulativeSalesVolume,
        },
        {
          path: "Statistics/GoodsSales",
          name: "GoodsSales",
          component: GoodsSales,
        },
        {
          path: "Statistics/CommodityAnalysis",
          name: "CommodityAnalysis",
          component: CommodityAnalysis,
        },
        {
          path: "Statistics/FunctionUsageRecord",
          name: "FunctionUsageRecord",
          component: FunctionUsageRecord,
        },
        {
          path: "Statistics/OrderUser",
          name: "OrderUser",
          component: OrderUser,
        },
        {
          path: "configItem/unitConverter",
          name: "unitConverter",
          component: UnitConverter,
        },
        {
          path: "userManage/groupConfig",
          name: "groupConfig",
          component: GroupConfig,
        },
        {
          path: "shopManage/paymentList",
          name: "paymentList",
          component: paymentList,
        },
        {
          path: "shopManage/marketRank",
          name: "marketRank",
          component: MarketRank,
        },
        {
          path: "invoice",
          name: "invoice",
          component: Invoice,
        },
        {
          path: "RateManage",
          name: "RateManage",
          component: RateManage,
        },
        {
          path: "shopManage/ManagePos",
          name: "ManagePos",
          component: ManagePos,
        },
        {
          path: "shopManage/registerUser",
          name: "registerUser",
          component: registerUser,
        },
        {
          path: "configItem/specialArea",
          name: "specialArea",
          component: specialArea,
        },
        {
          path: "AppGuideline/AppGuidelineCategory",
          name: "AppGuidelineCategory",
          component: AppGuidelineCategory,
        },
        {
          path: "AppGuideline/AppGuidelineList",
          name: "AppGuidelineList",
          component: AppGuidelineList,
        }
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  let route_id = getlocalStorage("backstageRoute_id");
  let btn_id = getlocalStorage("backstageBtn_id");
  let tableData = getlocalStorage("backstageTableData");
  let token = getlocalStorage("backstageToken");
  let auth_id_json = getlocalStorage("backstageAuth_id_json");
  let BackstageUserInfo = getlocalStorage("BackstageUserInfo");
  if (to.matched.length == 0) {
    next("/error");
  }
  if (
    token &&
    route_id &&
    btn_id &&
    tableData &&
    auth_id_json &&
    BackstageUserInfo
  ) {
    // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
    if (to.path === "/login") {
      next({ path: "/app/dashboard" });
    } else {
      next();
    }
  } else {
    let arr = ["/login", "/print", "/printInvoice"];
    if (arr.indexOf(to.path) > -1) {
      next();
    } else if (to.name == "dashboard") {
      next("/login");
    } else {
      next("/login");
      Message.warning(
        getlocalStorage("lang") == "en"
          ? "Failed to access the page, please login first!"
          : "訪問頁面失敗，請先登錄!"
      );
    }
  }
});
export default router;
