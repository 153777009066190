<template>
  <el-button
    :type="type"
    size="mini"
    :disabled="!hasPerms(perm)"
    @click="handleClick"
    plain
  >{{label}}</el-button>
</template>

<script>
import { hasPermission } from '../../permission/index.js'
export default {
  name: 'KtButton',
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: 'Button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    perm: {
      type: Number,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleClick: function () {
      // 按钮操作处理函数
      this.$emit('click', {})
    },
    hasPerms: function (perm) {
      // console.log('btn==>', hasPermission(perm) && !this.disabled)
      return hasPermission(perm) && !this.disabled
    },
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>