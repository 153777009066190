import { getlocalStorage } from '../utils/localStorage'
//  btn
export function hasPermission(perm) {
  let hasPermission = false
  let permissions = JSON.parse(getlocalStorage('backstageBtn_id'));
  // let permissions = [723, 893, 683, 781, 726, 515, 672, 918, 640, 651, 854, 879, 762, 861, 736, 536, 862, 834, 519, 635, 955];
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i] === perm) {
      hasPermission = true;
      break
    }
  }
  return hasPermission
}


// router
export function hasRoutePermission(perm) {
  let hasPermission = false
  let permissions = JSON.parse(getlocalStorage('backstageRoute_id'));
  // let permissions = [861, 850, 636, 845, 990, 686, 948, 911, 908, 723];
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i] === perm) {
      hasPermission = true;
      break
    }
  }
  return hasPermission
}
