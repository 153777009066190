<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.0265E5@Advertisement')">
      <span style="font-size: 26px" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
            :label="$t('public.DC9752@btn_add')"
            class="table-btn btn-yellow"
            :perm="9448"
            @click="add"
        ></KtButton>
      </section>
      <el-table
          :data="tableData"
          v-loading="loading"
          :element-loading-text="$t('public.4ABAB1@loading')"
          :row-class-name="$tableRowClassName"
          style="width: 100%; margin-top: 20px"
          :header-cell-style="{ 'text-align': 'center' }"
          :cell-style="{ 'text-align': 'center' }">
        <el-table-column
            prop="id"
            :label="$t('backend_config_Advertisement.0672CC@ad_id')"
            width="120"></el-table-column>
        <el-table-column
            prop="type"
            :label="$t('backend_config_Advertisement.D34399@ad_type')"
            width="160"
        ></el-table-column>
        <el-table-column
            prop="title"
            :label="$t('backend_config_Advertisement.88A5B8@ad_title')"
            width="120">
        </el-table-column>
        <el-table-column
            prop="url"
            :label="$t('backend_config_Advertisement.88A6D5@ad_url')"
        ></el-table-column>
        <el-table-column
            prop="picture"
            :label="$t('backend_config_Advertisement.8A317E@photo')">
          <template slot-scope="scope">
            <img style="height: 70px" :src="scope.row.picture" alt/>
          </template>
        </el-table-column>
        <el-table-column
            prop="is_open"
            :label="$t('backend_config_Advertisement.46F8F7@isShow')" width="120">
        </el-table-column>
        <!-- <el-table-column prop="sort" :label="$t('backend_config_Advertisement.1E4172@sort')"></el-table-column> -->
        <el-table-column
            prop="create_time"
            :label="$t('public.D17BCA@created_time')"
            width="160"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="modify_time"
            :label="$t('public.E1A718@update_time')"
            width="160"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            :label="$t('public.03EA09@operation')"
            width="220"
            fixed="right">
          <template slot-scope="scope">
            <KtButton
                type="info"
                :label="$t('public.B6D57F@btn_modify')"
                plain
                :perm="8726"
                @click="modify(scope.row)"
            ></KtButton>
            <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                plain
                :perm="3056"
                @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
            class="pagination"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog width="50%" :visible.sync="dialogVisible" top="8vh">
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form
              :model="dialogForm"
              :rules="rules"
              ref="form"
              label-width="150px">
            <el-form-item
                :label="$t('backend_config_Advertisement.0672CC@ad_id')"
                prop="id"
                v-show="dialogForm.id">
              <span>{{ dialogForm.id }}</span>
            </el-form-item>
            <el-form-item
                :label="$t('backend_config_Advertisement.D34399@ad_type')"
                prop="type">
              <el-select v-model="dialogForm.type">
                <el-option :label="$t('backend_web_adv.E52D69@class1')" :value="1"></el-option>
                <el-option :label="$t('backend_web_adv.E52D70@class2')" :value="2"></el-option>
                <el-option :label="$t('backend_web_adv.E52D71@class3')" :value="3"></el-option>
                <el-option :label="$t('backend_web_adv.E52D72@class4')" :value="4"></el-option>
                <el-option :label="$t('backend_web_adv.E52D73@class5')" :value="5"></el-option>
                <el-option :label="$t('backend_web_adv.E52D74@class5_slider')" :value="6"></el-option>
                <el-option :label="$t('backend_web_adv.E52D75@class6')" :value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
                :label="$t('backend_config_Advertisement.005381@title_hk')"
                prop="title_hk"
                v-if="dialogForm.type <= 4"
            >
              <el-input v-model="dialogForm.title_hk"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_config_Advertisement.23FE59@title_en')"
                prop="title_en"
                v-if="dialogForm.type <= 4"
            >
              <el-input v-model="dialogForm.title_en"></el-input>
            </el-form-item>
            <el-form-item
                :label="$t('backend_config_Advertisement.88A6D5@ad_url')"
                prop="url"
                v-if="dialogForm.type <= 4">
              <!--              url-->
              <el-input v-model="dialogForm.url"></el-input>
            </el-form-item>
            <!--            是否顯示-->
            <el-form-item
                :label="$t('backend_config_Advertisement.46F8F7@isShow')"
                prop="is_open">
              <el-switch
                  style="line-height: 40px"
                  v-model="dialogForm.is_open"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
              ></el-switch>
            </el-form-item>
            <!-- ------------------------------------------ -->
            <section style="width: 330px" v-show="dialogForm.type > 4">
              <section>
                <el-form-item
                    :label="$t('restaurant_buy_genner.99D144@type')"
                    prop="supplierType">
                  <div class="mt10" style="width: 330px">
                    <el-radio-group
                        v-model="dialogForm.supplierType"
                        @change="radioChange">
                      <el-radio class="ml50" :label="1">{{ $t('placeholder.F8EA00@select_type') }}</el-radio>
                      <el-radio class="ml50" :label="2">The Market</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
              </section>
              <!--             選擇分類 -->
              <el-form-item
                  :label="$t('restaurant_buy_genner.59739F@choose_supplier')"
                  v-show="dialogForm.supplierType==1">
                <el-select
                    v-model="dialogForm.Classify"
                    class="mt10 input"
                    style="width: 300px"
                    size="small"
                    @change="classifyChange"
                    :placeholder="$t('placeholder.C209ED@select')">
                  <el-option
                      v-for="item in CategoryList"
                      :label="item.name"
                      :value="item.cid"
                      :key="item.cid">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--            選擇供應商-->
              <el-form-item
                  :label="$t('restaurant_buy_genner.59739F@choose_supplier')"
                  prop="supplier_id"
                  v-show="dialogForm.supplierType==2">
                <el-select
                    class="mt10 input"
                    v-model="dialogForm.supplier_id"
                    @change="selectSupplier"
                    style="width: 300px"
                    size="small"
                    :placeholder="$t('placeholder.C209ED@select')">
                  <el-option
                      v-for="item in SupplierSelect"
                      :key="item.company_id"
                      :label="item.company_name"
                      :value="item.company_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <!--            產品-->
              <el-form-item
                  :label="$t('restaurant_buy_B2BProduct.6C52C8@product_name')"
                  prop="gid"
                  v-show="dialogForm.supplier_id">
                <el-select
                    v-model="dialogForm.gid"
                    class="mt10"
                    style="width: 300px"
                    clearable
                    filterable
                    remote
                    :placeholder="
                    $t('aboutus.900298@Please_Enter_a_Product_Name_or_Keyword')
                  "
                    @change="gidChange()"
                    size="small">
                  <el-option
                      v-for="item in goodsList"
                      :key="item.gid"
                      :label="item.full_name"
                      :value="item.gid">
                  </el-option>
                </el-select>
              </el-form-item>
            </section>
            <el-form-item
                :label="$t('backend_config_Advertisement.1E4172@sort')"
                prop="sort"
            >
              <el-input v-model="dialogForm.sort"></el-input>
            </el-form-item>
            <!-- ------------------------------------------ -->
            <el-form-item
                :label="$t('backend_config_Advertisement.8A317E@photo')"
                prop="picture">
              <el-upload
                  ref="upload"
                  class="avatar-uploader"
                  :show-file-list="false"
                  action="no"
                  :before-upload="beforeAvatarUpload"
                  :http-request="uploadFile">
                <img class="img-auto" v-if="dialogForm.picture" :src="dialogForm.picture"/>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{
            $t("public.836FD4@cancel")
          }}</el-button>
        <el-button size="small" type="primary" @click="comfirmDialog">{{
            $t("public.3B45A2@confirm")
          }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import api from "../../api/index";

export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      addModify: "",
      input: "",
      total: 0,
      dialogForm: {
        id: "",
        type: "",
        title_hk: "",
        title_en: "",
        url: "",
        is_open: 0,
        sort: "",
        picture: "",
        is_b2b: "",
        supplier_id: "",
        gid: "",
        supplierType: "",
        Classify: "",
      },
      SupplierSelect: [],
      goodsList: [],
      CategoryList: [],
      loading: false,
      rules: {
        type: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
        title_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur",
        },
        title_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur",
        },
        picture: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change",
        },
      },
    };
  },
  components: {
    pageTop,
    KtButton,
  },
  created() {
    this.getTableData();
  },
  methods: {
    //   获取列表数据
    getTableData() {
      this.loading = true;
      api.getWebAdvList({
        size: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        this.loading = false;
        if (res.errno == 0) {
          this.tableData = res.data.list;
          this.total = res.data.sum;
        }
      });
      this.getSelector();
    },
    add() {
      this.addModify = 0;
      this.dialogVisible = true;
      if (this.dialogForm.type) {
        this.dialogForm.id = "";
        this.dialogForm.type = "";
        this.dialogForm.title_hk = "";
        this.dialogForm.title_en = "";
        this.dialogForm.url = "";
        this.dialogForm.is_open = 0;
        this.dialogForm.sort = "";
        this.dialogForm.picture = "";
        this.dialogForm.is_b2b = "";
        this.dialogForm.supplier_id = "";
        this.dialogForm.gid = "";
        this.dialogForm.supplierType = "";
        this.dialogForm.Classify = "";
        this.$refs["form"].resetFields();
      }
    },
    // 刪除
    deleteUser(row) {
      api.delWebAdv({
        id: row.id,
      }).then((res) => {
        this.getTableData();
      });
    },
    // 修改时获取信息
    modify(row) {
      this.addModify = 1;
      this.row = row;
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      api.getWebAdvInfo({
        id: row.id,
      }).then((res) => {
        this.dialogForm = res.data;
        if (res.data.type >= 5) {
          this.dialogForm.supplierType = parseInt(res.data.title_hk)
          if (this.dialogForm.supplierType == 2) {
            let sidAndPid = JSON.parse(res.data.title_en)
            this.dialogForm.is_b2b = 1
            this.dialogForm.supplier_id = parseInt(sidAndPid[0])
            this.dialogForm.gid = parseInt(sidAndPid[1])
            this.getSupplierSelect();
            this.showGoodsListBySupplier();
          } else {
            this.dialogForm.Classify = parseInt(res.data.title_en)
          }
        }
      });
    },
    // 选中类别
    radioChange(e) {
      this.$forceUpdate()
      if (this.dialogForm.supplierType == 1) {
        this.dialogForm.supplier_id = "";
      } else {
        this.dialogForm.supplier_id = "";
        this.dialogForm.Classify = ""
        this.dialogForm.is_b2b = 1;
        this.getSupplierSelect();
      }
    },
    //选择供应商
    selectSupplier(e) {
      this.$forceUpdate()
      this.dialogForm.gid = "";
      this.showGoodsListBySupplier();
    },
    classifyChange() {
      this.$forceUpdate()
    },
    gidChange() {
      this.$forceUpdate()
    },
    // 获取供应商商品选择器
    async showGoodsListBySupplier(v) {
      const res = await this.$http.showGoodsListBySupplier({
        is_b2b: this.dialogForm.is_b2b,
        supplier_id: this.dialogForm.supplier_id,
      });
      this.goodsList = res.data;
    },
    //获取供应商选择器
    async getSupplierSelect() {
      const res = await this.$http.supplierSelector({
        type: this.dialogForm.supplierType,
      });
      this.SupplierSelect = res.data;
    },
    //獲取分類
    async getSelector() {
      const res = await this.$http.getClassify({
        lang: this.$getlocalStorage('lang'),
      })
      this.CategoryList = res.data;
    },
    // 自定义上传图片
    uploadFile(param) {
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(param["file"]);
      reader.onload = function (event) {
        that.dialogForm.picture = this.result;
      };
      this.dialogVisible = true;
    },
    beforeAvatarUpload(file) {
      let fileNameArr = file.name.split(".");
      let type = fileNameArr[fileNameArr.length - 1].toLowerCase()
      const isJPG = Boolean(type == "jpg");
      const isLt2M = Boolean(file.size / 1024 / 1024 < 5);
      if (!isJPG) {
        this.$message.error(this.$t("aboutus.23D2D3@Upload_failed"));
      }
      if (!isLt2M) {
        this.$message.error(this.$t("aboutus.2F8186@Upload_failed"));
      }
      return isJPG && isLt2M;
    },
    // 点击确认订单
    comfirmDialog() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let params = Object.assign({}, this.dialogForm);
          if (this.addModify) {
            api.updateWebAdv(params).then((res) => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            api.addWebAdv(params).then((res) => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.form-select {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .pagination {
    float: right;
  }
}

.tabs {
  width: 170px;
  margin-bottom: -20px;

  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}

.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  // width: 400px;
  height: 150px;
  display: block;
}
</style>
