<!--
 * @Author: your name
 * @Date: 2019-10-30 16:01:30
 * @LastEditTime: 2019-11-20 16:54:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\pages\Classify\Classify.vue
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('aboutus.CEA76C@Product_unit')">
      <span style="font-size:26px;" class="fa fa-user"></span>
    </page-top>
    <el-card>
      <section class="form-select">
        <section>
          <kt-button
            :plain="false"
            class="table-btn btn-yellow"
            :perm="1663"
            :label="$t('public.DC9752@btn_add')"
            @click="add()"
          ></kt-button>
        </section>
      </section>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column
          prop="unit_id"
          :label="$t('aboutus.C9B5FF@Product_Unit_ID')"
          width="130"
          align="left"
          header-align="left"
        ></el-table-column>
        <el-table-column
          prop="unit_en"
          :label="$t('aboutus.47C789@Product_Unit_Name')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="unit_hk"
          :label="$t('aboutus.840BD3@Product_Unit_Name')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          :label="$t('public.D17BCA@created_time')"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="opration"
          :label="$t('public.03EA09@operation')"
          width="250"
          fixed="right"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <kt-button
              :perm="7406"
              :label="$t('public.B6D57F@btn_modify')"
              @click="modify(scope.row.unit_id)"
              type="info"
            ></kt-button>
          </template>
        </el-table-column>
      </el-table>
      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog
      :title="type==1?$t('public.B6D57F@btn_modify'):$t('public.DC9752@btn_add')"
      width="35%"
      @close="resetForm"
      :visible.sync="dialogFormVisible"
      top="12vh"
      center
    >
      <el-row>
        <el-col :span="18" :offset="2">
          <el-form ref="dataForm" :rules="rules" :model="dialogForm" label-width="150px">
            <el-form-item
              v-if="type==1"
              prop="unit_id"
              :label="$t('aboutus.C9B5FF@Product_Unit_ID')"
            >{{dialogForm.unit_id}}</el-form-item>
            <el-form-item prop="unit_en" :label="$t('aboutus.47C789@Product_Unit_Name')">
              <el-input style="width:100%;" v-model="dialogForm.unit_en"></el-input>
            </el-form-item>
            <el-form-item prop="unit_hk" :label="$t('aboutus.840BD3@Product_Unit_Name')">
              <el-input style="width:100%;" v-model="dialogForm.unit_hk"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <section slot="footer">
        <el-button @click="dialogFormVisible=false" size="small">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button @click="addclassify" type="primary" size="small">{{$t('public.3B45A2@confirm')}}</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import ktButton from "../../components/ktButton/KtButton.vue";
import pageTop from "../../components/PageTop/PageTop";

export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogFormVisible: false,
      dialogForm: {
        unit_id: "",
        unit_en: "",
        unit_hk: ""
      },
      type: "",
      loading: false,
      rules: {
        unit_en: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        },
        unit_hk: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },

  components: {
    pageTop,
    ktButton
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.$http
        .getUnitList({
          page: this.currentPage,
          size: this.pageSize
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.sum;
        });
    },
    add() {
      // 分类
      this.type = 2;
      this.dialogFormVisible = true;
    },

    // 点击修改按钮
    modify: async function(unit_id) {
      this.type = 1;
      this.dialogFormVisible = true;

      this.$http
        .getUnitInfo({
          unit_id
        })
        .then(res => {
          Object.assign(this.dialogForm, res.data);
        });
    },

    //弹框内确认按钮
    addclassify() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            //  修改
            this.$confirm(
              this.$t("aboutus.C676AA@The_operation_will"),
              this.$t("public.553159@tips"),
              {
                confirmButtonText: this.$t("public.3B45A2@confirm"),
                cancelButtonText: this.$t("public.836FD4@cancel")
              }
            ).then(() => {
              this.$http.updateUnit({ ...this.dialogForm }).then(res => {
                this.dialogFormVisible = false;
                this.getTableData();
              });
            });
          } else {
            // 新增
            this.$confirm(
              this.$t("aboutus.FFCC64@Are_you_sure_to_add"),
              this.$t("public.553159@tips"),
              {
                confirmButtonText: this.$t("public.3B45A2@confirm"),
                cancelButtonText: this.$t("public.836FD4@cancel")
              }
            ).then(() => {
              this.$http
                .addUnit({
                  unit_en: this.dialogForm.unit_en,
                  unit_hk: this.dialogForm.unit_hk
                })
                .then(res => {
                  this.dialogFormVisible = false;
                  this.getTableData();
                });
            });
          }
        } else {
          return false;
        }
      });
    },

    // 关闭弹框时清空表单内容和图片
    resetForm() {
      this.$refs["dataForm"].resetFields();
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  section {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>