<!-- GoodsSales -->
<template>
  <section class="GoodsSales">
    <page-top :title="$t('GoodsSales.GoodsSales_tit')">
      <span
          style="font-size: 26px; padding-bottom: 10px"
          class="fa fa-bar-chart-o"
      ></span>
    </page-top>
    <el-card>
      <section class="justify-between mb20">
        <section class="flex-items">
          <el-date-picker
              v-model="datetime"
              type="daterange"
              value-format="timestamp"
              @change="changeDatetime"
              range-separator="~"
              :start-placeholder="$t('placeholder.524620@start_time')"
              :end-placeholder="$t('placeholder.ABB7CF@end_time')"
              :default-time="['00:00:00', '23:59:59']"
              style="width: 220px"
              class="mr10"
          ></el-date-picker>
          <el-select
              v-model="company_id"
              class="mr10"
              @change="changeFilter"
              clearable
              filterable
              :placeholder="
              $t('AverageOrderPrice.AverageOrderPrice_content.ChooseSupplier')
            "
              style="width: 250px"
          >
            <el-option
                v-for="item in CompanyList"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id"
            ></el-option>
          </el-select>
        </section>
        <el-button
            type="primary"
            class="table-btn btn-gray"
            size="default"
            @click="downloadexcel"
        >{{ $t("aboutus.2AECD0@Export_Order") }}
        </el-button
        >
      </section>
      <section>
        <el-table
            border
            :data="tableData"
            v-loading="loading"
            :row-class-name="$tableRowClassName"
            :element-loading-text="$t('public.4ABAB1@loading')"
            :header-cell-style="{ 'text-align': 'center' }"
            :cell-style="{ 'text-align': 'center' }"
            :highlight-current-row="true"
            :default-sort="{ prop: 'total_sales', order: 'descending' }"
            @sort-change="sortChange"
        >
          <el-table-column
              prop="goods_name"
              :label="$t('restaurant_supplier_addSupProduct.E9513A@product_name')"
          ></el-table-column>
          <el-table-column
              prop="supplier_name"
              :label="$t('restaurant_buy_genner.586E4F@supplier_name')"
          ></el-table-column>
          <el-table-column
              prop="total_sales"
              sortable="custom"
              :label="$t('CumulativeSalesVolume.CumulativeSalesVolume_content.TotalSales')"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.total_sales ? "$" + scope.row.total_sales : "$0" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="total_num"
              sortable="custom"
              :label="$t('CumulativeSalesVolume.CumulativeSalesVolume_content.SalesAmount')"
          >
          </el-table-column>
        </el-table>
        <section
            style="margin-top: 20px; display: flex; justify-content: flex-end"
        >
          <el-pagination
              class="pagination"
              @size-change="handleSizeChange"
              :page-sizes="[5, 10, 15]"
              :page-size="pageSize"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          ></el-pagination>
        </section>
      </section>
    </el-card>
  </section>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import pageTop from "@/components/PageTop/PageTop";

export default {
  //import引入的组件需要注入到对象中才能使用
  name: "GoodsSales",
  components: {pageTop},
  data() {
    //这里存放数据
    return {
      company_id: "",
      datetime: "",
      tableData: [],
      CompanyList: [],
      loading: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      sort_type: 1,
      sort: 2,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getGoodsSales() {
      let params = {
        sort_type: this.sort_type,
        sort: this.sort,
        company_id: this.company_id || "",
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
        size: this.pageSize,
        page: this.currentPage,
      }
      this.loading = true
      this.$http.goodsSales(params).then((res) => {
        this.loading = false
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    changeDatetime(val) {
      this.datetime = val || ""
      this.currentPage = 1
      this.getGoodsSales()
    },
    changeFilter() {
      this.currentPage = 1
      this.getGoodsSales()
    },
    // 获取选择器
    getCompanyList() {
      this.$http.b2bSupplierSelector().then((res) => {
        this.CompanyList = res.data.filter(s => {
          return s.company_id != 43 && s.company_id != 44 && s.company_id != 46 && s.company_id != 429
        })
      })
    },
    // 排序
    sortChange({column, prop, order}) {
      this.sort_type = prop == "total_num" ? 2 : 1
      this.sort = order == "ascending" ? 1 : 2
      this.getGoodsSales()
    },
    downloadexcel() {
      this.$http.goodsSalesExcel({
        sort_type: this.sort_type,
        sort: this.sort,
        company_id: this.company_id || "",
        start_time: this.datetime[0] / 1000 || "",
        end_time: this.datetime[1] / 1000 || "",
        size: this.pageSize,
        page: this.currentPage,
      }).then((res) => {
        this.downloadFile(res, "GoodsSales.xlsx");
      })
    },
    clear() {
      this.getGoodsSales();
    },
    getCheckedNodes(idArr) {
      this.company_id = idArr[0]
      this.shop_id = idArr[1] || ""
      this.getGoodsSales()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getGoodsSales()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getGoodsSales()
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getGoodsSales()
    this.getCompanyList()
  },
};
</script>
<style src='./GoodsSales.scss' lang='scss' scoped>
//@import url(); 引入公共css类
</style>
