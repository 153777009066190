<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 17:01:13
 * @LastEditTime: 2019-11-01 02:07:31
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('backend_submenu.4F09AA@index')">
      <span style="font-size:26px;" class="fa fa-address-card"></span>
    </page-top>
    <el-card>
      <section class="flex-end">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          class="table-btn btn-yellow"
          :perm="649"
          @click="add"
        ></KtButton>
      </section>
      <el-table
        :data="tableData"
        style="width: 100%;margin-top:20px;"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        :row-class-name="$tableRowClassName"
        :header-cell-style="{'text-align':'center',}"
        :cell-style="{'text-align': 'center',}"
      >
        <el-table-column prop="id" label="ID"></el-table-column>
        <!-- <el-table-column prop="cid" :label="$t('backend_config_home.1DC9DD@classify_id')"></el-table-column> -->
        <el-table-column prop="name" :label="$t('backend_config_home.966A47@classify_name')"></el-table-column>
        <el-table-column prop="picture" :label="$t('backend_config_home.9266EE@classify_photo')">
          <template slot-scope="scope">
            <img style="width:120px" :src="scope.row.picture" alt />
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('backend_config_home.A7684E@isShow')" width="150"></el-table-column>
        <el-table-column prop="sort" :label="$t('backend_config_home.47380E@sort')"></el-table-column>
        <el-table-column prop="create_time" :label="$t('public.D17BCA@created_time')"></el-table-column>
        <el-table-column prop="modify_time" :label="$t('public.E1A718@update_time')"></el-table-column>
        <el-table-column :label="$t('public.03EA09@operation')" width="220" fixed="right">
          <template slot-scope="scope">
            <KtButton
              :label="$t('public.B6D57F@btn_modify')"
              plain
              type="info"
              :perm="2694"
              @click="modify(scope.row)"
            ></KtButton>
            <KtButton
              :label="$t('public.1FBB5E@btn_delete')"
              type="danger"
              plain
              :perm="3088"
              @click="deleteUser(scope.row)"
            ></KtButton>
          </template>
        </el-table-column>
      </el-table>

      <section class="pagination-container">
        <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>
    <el-dialog width="40%" :visible.sync="dialogVisible" top="8vh">
      <el-row>
        <el-col :span="16" :offset="3">
          <el-form :model="dialogForm" :rules="rules" ref="form" label-width="120px">
            <el-form-item prop="cid" :label="$t('backend_config_home.1DC9DD@classify_id')">
              <el-select v-model="dialogForm.cid" :placeholder="$t('placeholder.F0DDC0@select')">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.cid"
                  :label="item.name"
                  :value="item.cid"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('backend_config_home.47380E@sort')" prop="sort">
              <el-input v-model="dialogForm.sort"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">{{$t('public.836FD4@cancel')}}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="comfirmDialog"
        >{{$t('public.3B45A2@confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import api from "../../api/index";
export default {
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      selectOptions: [],
      currentPage: 1,
      pageSize: 10,
      addModify: 0,
      input: "",
      total: 0,
      id: "",
      dialogForm: {
        cid: "",
        sort: ""
      },
      loading: false,
      rules: {
        cid: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "change"
        },
        sort: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      }
    };
  },
  components: {
    pageTop,
    KtButton
  },
  created() {
    this.getTableData();
    this.homeCategorySelector();
  },
  methods: {
    //   获取列表数据
    getTableData() {
      this.loading = true;
      api
        .getHomeCategoryList({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.loading = false;
          console.log(res);
          if (res.errno == 0) {
            this.tableData = res.data.list;
            this.total = res.data.sum;
          }
        });
    },
    add() {
      this.addModify = 0;
      this.dialogVisible = true;
      if (this.dialogForm.cid) {
        this.$refs["form"].resetFields();
      }
    },
    homeCategorySelector() {
      api.homeCategorySelector().then(res => {
        console.log(res);

        this.selectOptions = res.data;
      });
    },
    // 刪除
    deleteUser(row) {
      api
        .delHomeCategory({
          id: row.id
        })
        .then(res => {
          this.getTableData();
        });
    },
    getCheckedNodes(leafOnly) {
      console.log(leafOnly);

      // this.getSelector();
      this.dialogForm.cid = leafOnly;
    },
    // 修改时获取信息
    modify(row) {
      this.addModify = 1;
      this.row = row;
      this.id = row.id;
      api
        .getHomeCategoryInfo({
          id: row.id
        })
        .then(res => {
          this.dialogVisible = true;
          this.$nextTick(() => {
            this.$refs["form"].clearValidate();
            this.dialogForm = res.data;
          });
        });
    },
    // 点击确认订单
    comfirmDialog() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.dialogForm);
          if (this.addModify) {
            params.id = this.id;
            api.updateHomeCategory(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          } else {
            api.addHomeCategory(params).then(res => {
              this.dialogVisible = false;
              this.getTableData();
            });
          }
        } else {
          return false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}
.tabs {
  width: 170px;
  margin-bottom: -20px;
  > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    li {
      text-align: center;
      width: 40px;
      font-size: 14px;
      line-height: 50px;
      color: #333333;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      border-bottom: 2px solid transparent;
    }
  }
}
.active {
  font-weight: 400;
  // border-bottom: 2px solid #333333;
  border-color: #333333 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>