<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from "echarts";

require('echarts/theme/macarons') // echarts theme
import resize from "./mixins/resize";

const animationDuration = 3500;

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "300px"
    },
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    showX: {
      type: Boolean,
      default: true,
    },
    theme:{
      type: String,
      default: "macarons",
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.initChart();
      });
    }
  },
  mounted() {
    console.log(this.data)

  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, this.theme);

      this.chart.setOption({
        title: {
          text: this.title,
          left: 'center'
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "line" // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: "10%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            data: this.data.x,
            // axisLabel: {interval: 0, rotate: 30},
            show: this.showX,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            type: "bar",
            stack: "vistors",
            barWidth: "60%",
            data: this.data.y,
            animationDuration
          },
        ]
      });
    }
  }
};
</script>
